<template>
  <v-autocomplete
    multiple
    chips
    clearable
    clear-icon="mdi-close-circle"
    append-icon="mdi-chevron-down"
    class="tag-autocomplete"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:selection="data">
      <tag-chip v-bind="data.attrs" @click="$emit('tag-clear', data.item.id)">
        {{ data.item.id }}
      </tag-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import TagChip from "@/components/Tags/TagChip.vue";

export default {
  components: {
    TagChip,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.tag-autocomplete {
  &.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
    max-height: inherit;
  }
  .v-select__selections {
    flex-wrap: nowrap;
    overflow-x: hidden;

    .tag-chip {
      flex-shrink: 0 !important;
    }
  }
  .theme--light & {
    .v-input__append-inner {
      margin: 8px 0;
      .v-icon {
        color: var(--v-black-lighten2);
        font-size: 26px;
      }
      .v-input__icon {
        width: 22px;
        min-width: 22px;
      }
      .v-input__icon--clear .v-icon {
        font-size: 20px;
      }
    }
  }
}
</style>
