<template>
  <div class="motion-item corporateBlue--text">
    <svg
      width="253"
      height="170"
      viewBox="0 0 253 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="35"
        y="29"
        :width="rectWidth"
        :height="rectHeight"
        :rx="rectRadius"
        stroke="#4DABF5"
        stroke-width="10"
      />
      <circle :cx="coords[0]" :cy="coords[1]" r="20" :fill="fillPoint" />
      <circle cx="35" cy="85" r="35" :fill="circleLeftFill" />
      <path
        fill="#EEF7FE"
        :fill-opacity="questionOpacity"
        d="M33.076 88.196C33.076 87.2253 33.2907 86.4227 33.72 85.788C34.168 85.1533 34.812 84.4533 35.652 83.688C36.268 83.128 36.716 82.6613 36.996 82.288C37.2947 81.896 37.444 81.4573 37.444 80.972C37.444 80.2813 37.164 79.74 36.604 79.348C36.0627 78.9373 35.3347 78.732 34.42 78.732C33.5427 78.732 32.7587 78.9187 32.068 79.292C31.396 79.6467 30.8267 80.1507 30.36 80.804L26.972 78.816C27.756 77.6213 28.8107 76.6973 30.136 76.044C31.48 75.3907 33.0667 75.064 34.896 75.064C37.0427 75.064 38.76 75.5213 40.048 76.436C41.3547 77.3507 42.008 78.62 42.008 80.244C42.008 81.0093 41.8773 81.6813 41.616 82.26C41.3733 82.8387 41.0653 83.3333 40.692 83.744C40.3373 84.136 39.8707 84.5933 39.292 85.116C38.6013 85.732 38.0973 86.2547 37.78 86.684C37.4627 87.0947 37.304 87.5987 37.304 88.196H33.076ZM35.204 95.224C34.4387 95.224 33.7947 94.9813 33.272 94.496C32.768 93.992 32.516 93.3853 32.516 92.676C32.516 91.9667 32.768 91.3787 33.272 90.912C33.776 90.4267 34.42 90.184 35.204 90.184C35.988 90.184 36.632 90.4267 37.136 90.912C37.64 91.3787 37.892 91.9667 37.892 92.676C37.892 93.3853 37.6307 93.992 37.108 94.496C36.604 94.9813 35.9693 95.224 35.204 95.224Z"
      />
      <path
        fill="#EEF7FE"
        :fill-opacity="checkLeftOpacity"
        d="M31.89 94.52L23 85.64L25.50 83.13L31.89 89.49L45.38 76L47.89 78.52L31.89 94.52Z"
      />
      <circle cx="218" cy="85" r="35" :fill="circleRightFill" />
      <path
        d="M214.889 94.5244L206 85.6356L208.507 83.1289L214.889 89.4933L228.382 76L230.889 78.5244L214.889 94.5244Z"
        fill="#EEF7FE"
        :fill-opacity="checkRightOpacity"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AdvantageMotion2",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rectLeft: 35,
      rectTop: 29,
      rectWidth: 183,
      rectHeight: 112,
      rectRadius: 35,
      neutralColorRgb: [77, 171, 245],
      warningColorRgb: [245, 193, 77],
      successColorRgb: [128, 195, 66],
    };
  },
  computed: {
    calcRgb() {
      return (rgbSrc, rgbDst, progress) => {
        return this.makeRgb([
          (rgbDst[0] - rgbSrc[0]) * progress + rgbSrc[0],
          (rgbDst[1] - rgbSrc[1]) * progress + rgbSrc[1],
          (rgbDst[2] - rgbSrc[2]) * progress + rgbSrc[2],
        ]);
      };
    },
    makeRgb() {
      return (rgb) => `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
    },
    totalLength() {
      return (
        (this.rectWidth + this.rectHeight + (Math.PI - 4) * this.rectRadius) * 2
      );
    },
    breakpoints() {
      const W = this.rectWidth;
      const H = this.rectHeight;
      const R = this.rectRadius;
      const L = this.rectLeft;
      const T = this.rectTop;
      const Pi = Math.PI;
      const increments = [
        [H / 2 - R, L, T + H / 2, "dy", 1],
        [(Pi * R) / 2, L + R, T + H - R, "dr", -Pi / 2],
        [W - 2 * R, L + R, T + H, "dx", 1],
        [(Pi * R) / 2, L + W - R, T + H - R, "dr", 0],
        [H - 2 * R, L + W, T + H - R, "dy", -1],
        [(Pi * R) / 2, L + W - R, T + R, "dr", Pi / 2],
        [W - 2 * R, L + W - R, T, "dx", -1],
        [(Pi * R) / 2, L + R, T + R, "dr", Pi],
        [H / 2 - R, L, T + R, "dy", 1],
      ];
      const t = increments.reduce(
        (prod, incr) => {
          const from = Math.round(prod.path * 10000) / 10000;
          prod.path += incr[0] / this.totalLength;
          const to = Math.round(prod.path * 10000) / 10000;
          prod.data.push({
            from,
            to,
            x: incr[1],
            y: incr[2],
            f: incr[3],
            m: incr[4],
          });
          return prod;
        },
        { path: 0, data: [] }
      );
      return t.data;
    },
    bp() {
      return this.breakpoints.find(
        (item) => item.from <= this.progress && this.progress <= item.to
      );
    },
    coords() {
      const pt = [this.bp.x, this.bp.y];
      if (this.bp.f == "dx") {
        pt[0] += (this.progress - this.bp.from) * this.totalLength * this.bp.m;
      } else if (this.bp.f == "dy") {
        pt[1] += (this.progress - this.bp.from) * this.totalLength * this.bp.m;
      } else if (this.bp.f == "dr") {
        const angle =
          this.bp.m +
          ((Math.PI / 2) * (this.progress - this.bp.from)) /
            (this.bp.to - this.bp.from);
        pt[0] += Math.sin(angle) * this.rectRadius;
        pt[1] += Math.cos(angle) * this.rectRadius;
      }
      return pt;
    },
    fillPoint() {
      return this.progress <= 0.5
        ? this.makeRgb(this.warningColorRgb)
        : this.makeRgb(this.successColorRgb);
    },
    questionOpacity() {
      if (this.bp.to == 1) {
        return (1 - this.progress) / (1 - this.bp.from);
      } else if (this.bp.from == 0) {
        return this.progress / this.bp.to;
      } else {
        return 1;
      }
    },
    checkLeftOpacity() {
      if (this.bp.to == 1) {
        return (this.progress - this.bp.from) / (1 - this.bp.from);
      } else if (this.progress == 1) {
        return 1;
      } else {
        return 0;
      }
    },
    circleLeftFill() {
      if (this.bp.to == 1) {
        return this.calcRgb(
          this.warningColorRgb,
          this.successColorRgb,
          (this.progress - this.bp.from) / (1 - this.bp.from)
        );
      } else if (this.progress == 1) {
        return this.makeRgb(this.successColorRgb);
      } else {
        return this.makeRgb(this.warningColorRgb);
      }
    },
    circleRightFill() {
      if (this.bp.from <= 0.5 && this.bp.to >= 0.5)
        return this.calcRgb(
          this.neutralColorRgb,
          this.successColorRgb,
          (this.progress - this.bp.from) / (this.bp.to - this.bp.from)
        );
      if (this.bp.from > 0.5) return this.makeRgb(this.successColorRgb);
      if (this.bp.to < 0.5) return this.makeRgb(this.neutralColorRgb);
      return this.makeRgb(this.neutralColorRgb);
    },
    checkRightOpacity() {
      if (this.bp.from <= 0.5 && this.bp.to >= 0.5)
        return (this.progress - this.bp.from) / (this.bp.to - this.bp.from);
      if (this.bp.from > 0.5) return 1;
      if (this.bp.to < 0.5) return 0;
      return 0;
    },
  },
};
</script>
