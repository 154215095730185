<template>
  <div class="section">
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ jobsData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row
      class="section__content list-content mb-10 align-stretch justify-center justify-md-start job-container"
    >
      <v-col
        v-for="(job, index) in jobsData.children"
        :key="index"
        cols=""
        sm="9"
        md="6"
        lg="4"
        class="flex-grow-0"
      >
        <div
          class="black-lighten4 d-flex flex-column justify-space-between pa-5 pa-md-10 job-card"
        >
          <div class="mb-3 mb-md-6">
            <div class="subtitle2 textLight--text">
              {{ job.fields.title }}
            </div>
          </div>
        </div>
      </v-col>

      <div class="fog-of-war" />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "JointProjects",
  props: {
    jobsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.job-container {
  position: relative;
  .job-card {
    min-height: 128px;
  }
  .fog-of-war {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 81px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 67.7%
    );
    z-index: 2;
    pointer-events: none;
  }
}
@media (max-width: 640px) {
  .job-container {
    .fog-of-war {
      height: 60px;
    }
  }
}
</style>
