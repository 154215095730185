<template>
  <div class="motion-item corporateBlue--text">
    <svg
      class="motion-item__svg"
      :width="svgWidth"
      height="70"
      :viewBox="`0 0 ${svgWidth} 70`"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :style="svgStyle"
    >
      <circle
        v-for="index in elementCount"
        :key="index"
        :cx="index * (elementRadius * 2 + elementMargin)"
        cy="35"
        r="35"
        :fill="fill(index)"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AdvantageMotion4",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      elementRadius: 35,
      elementMargin: 20,
      elementCount: 9,
      breakPoint1: 0.3,
      breakPoint2: 0.7,
      beginColorRgb: [77, 171, 245],
      middleColorRgb: [245, 193, 77],
      endColorRgb: [128, 195, 66],
    };
  },
  computed: {
    fill() {
      return (index) => {
        if (index == Math.round(this.elementCount / 2)) {
          if (this.progress < this.breakPoint1) {
            return this.calcRgb(
              this.beginColorRgb,
              this.middleColorRgb,
              this.progress / this.breakPoint1
            );
          } else if (this.progress < this.breakPoint2) {
            return this.makeRgb(this.middleColorRgb);
          } else {
            return this.calcRgb(
              this.middleColorRgb,
              this.endColorRgb,
              (this.progress - this.breakPoint2) / (1 - this.breakPoint2)
            );
          }
        } else {
          if (this.progress < this.breakPoint2) {
            return this.makeRgb(this.beginColorRgb);
          } else {
            return this.calcRgb(
              this.beginColorRgb,
              this.endColorRgb,
              (this.progress - this.breakPoint2) / (1 - this.breakPoint2)
            );
          }
        }
      };
    },
    calcRgb() {
      return (rgbSrc, rgbDst, progress) => {
        return this.makeRgb([
          (rgbDst[0] - rgbSrc[0]) * progress + rgbSrc[0],
          (rgbDst[1] - rgbSrc[1]) * progress + rgbSrc[1],
          (rgbDst[2] - rgbSrc[2]) * progress + rgbSrc[2],
        ]);
      };
    },
    makeRgb() {
      return (rgb) => `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
    },
    svgStyle() {
      const left =
        -(this.elementRadius + this.elementMargin) * 2 * this.progress -
        this.svgWidth / 2;
      return {
        "margin-left": `${left}px`,
      };
    },
    svgWidth() {
      return (
        (this.elementRadius * 2 + this.elementMargin) * this.elementCount +
        this.elementRadius
      );
    },
  },
};
</script>
<style scoped lang="scss">
.motion-item {
  position: relative;
  .motion-item__svg {
    position: relative;
    left: 50%;
  }
}
</style>
