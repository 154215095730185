<template>
  <div class="section">
    <title-with-button :title="title" :button-title="buttonTitle" :to="to" />
    <v-row>
      <v-col cols="12" lg="4" xl="6" class="three-previews__first">
        <slot name="first"></slot>
      </v-col>
      <v-col cols="12" lg="8" xl="6">
        <v-row>
          <v-col cols="12" md="6" xl="6" class="three-previews__second">
            <slot name="second"></slot>
          </v-col>
          <v-col cols="12" md="6" xl="6" class="three-previews__third">
            <slot name="third"></slot>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleWithButton from "@/components/TitleWithButton.vue";
export default {
  components: { TitleWithButton },
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    to: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>
