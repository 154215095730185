<template>
  <div class="presentation-catchup pa-6 corporateBlue-lighten4 mb-3">
    <a
      v-if="item.url"
      :href="item.url"
      class="btn-text d-flex align-center mb-2"
    >
      <span>{{ item.title }}</span>
      <v-icon color="primary">mdi-chevron-right</v-icon>
    </a>
    <router-link
      v-else-if="item.ident"
      :to="{ path: `/${item.ident}` }"
      class="btn-text d-flex align-center mb-2"
    >
      <span>{{ item.title }}</span>
      <v-icon color="primary">mdi-chevron-right</v-icon>
    </router-link>
    <div class="catchup-text caption-sc" v-html="item.text" />
  </div>
</template>
<script>
export default {
  name: "PresentationThanksCatchup",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>
<style scoped lang="scss">
.catchup-text {
  ::v-deep p {
    margin-top: 8px !important;
    line-height: 22px !important;
  }
}
</style>
