<template>
  <div class="advantage-item">
    <component :is="motions[motionId]" :progress="progress" class="mb-5" />
    <div class="subtitle1 mb-6">{{ item.fields.title }}</div>
    <div class="body1" v-html="item.fields.text" />
  </div>
</template>

<script>
import AdvantageMotion1 from "./AdvantageMotion1.vue";
import AdvantageMotion2 from "./AdvantageMotion2.vue";
import AdvantageMotion3 from "./AdvantageMotion3.vue";
import AdvantageMotion4 from "./AdvantageMotion4.vue";
import AdvantageMotion5 from "./AdvantageMotion5.vue";
import AdvantageMotion6 from "./AdvantageMotion6.vue";
export default {
  name: "AdvantageItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    motionId: {
      type: Number,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      motions: [
        AdvantageMotion1,
        AdvantageMotion2,
        AdvantageMotion3,
        AdvantageMotion4,
        AdvantageMotion5,
        AdvantageMotion6,
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.advantage-item {
  overflow-x: hidden;
  background-color: var(--v-corporateBlue-lighten4);
  padding: 20px;
  height: 100%;
}

@media (min-width: 1024px) {
  .advantage-item {
    padding: 40px;
  }
}
</style>
