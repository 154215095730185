<template>
  <div class="corporateBlue-lighten4 pa-5 pa-md-10">
    <v-row no-gutters class="justify-space-between align-center">
      <v-col class="subtitle1">{{ title }}</v-col>
      <v-col class="flex-grow-0">
        <v-btn
          color="textBase"
          x-large
          icon
          text
          @click="$emit('request-random-item')"
        >
          <v-icon class="random__icon" large>$vuetify.icons.reload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="text" class="mt-0 mt-md-4">
      <v-col>
        <kk-dynamic-html :html="text" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "RandomRefreshBlock",
  components: { KkDynamicHtml },

  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.random__icon {
  &:hover {
    transform: rotate(-180deg);
  }
}
</style>
