<template>
  <div ref="eChartContainer" class="echarts__scrollbox">
    <div ref="eChart" class="echarts" />
  </div>
</template>

<script>
export default {
  name: "Chart",
  props: {
    chartOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartObject: null,
    };
  },
  watch: {
    async chartOptions(val) {
      await this.initEchart();
      let currentOptions = this.chartObject.getOption();
      if (
        val.series.length != currentOptions.series.length ||
        (val.graphic || []).length != (currentOptions.graphic || []).length
      ) {
        this.chartObject.clear();
      }
      this.chartObject.setOption(val);
    },
  },

  mounted() {
    this.$nextTick(async () => {
      await this.initEchart();
      this.chartObject.setOption(this.chartOptions);
    });
  },
  methods: {
    async initEchart() {
      if (this.chartObject == null) {
        const echarts = await import("echarts/core");
        const { LegendComponent, GridComponent } = await import(
          "echarts/components"
        );
        const { BarChart, PieChart } = await import("echarts/charts");
        const { CanvasRenderer } = await import("echarts/renderers");

        // Register the required components
        echarts.use([
          BarChart,
          PieChart,
          GridComponent,
          LegendComponent,
          CanvasRenderer,
        ]);

        this.chartObject = echarts.init(this.$refs.eChart);
      }
    },
    refreshChart() {
      if (this.chartObject) {
        this.chartObject.resize();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.echarts__scrollbox {
  overflow-x: auto;
  .echarts {
    width: 100%;
    min-width: 750px;
    height: 450px;
  }
}
@mixin md {
  @media (max-width: 1400px) {
    @content;
  }
}
@include md() {
  .echarts {
    height: 280px;
  }
}
</style>
