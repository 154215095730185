export const TitleWrap = function (str, maxWidth, newLineStr) {
  const whiteRegexp = new RegExp(/^[\s-]$/);
  let found = false;
  let res = "";
  while (str.length > maxWidth) {
    found = false;
    // Inserts new line at first whitespace of the line
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (whiteRegexp.test(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join("");
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join("");
      str = str.slice(maxWidth);
    }
  }
  return res + str;
};

export const applyCategoryStyles = function (entries) {
  const style = document.createElement("style");
  style.innerHTML = entries
    .map((entry) => {
      return (
        `.sv-article-category-${entry[0]} article ul li::before { color: ${entry[1].color}; } ` +
        `.sv-article-category-${entry[0]} sv-chapter-title:hover { border-color: ${entry[1].color}; }` +
        `.sv-article-category-${entry[0]} .sv-chapter-title.sv-menu-active { border-color: ${entry[1].color}; }` +
        `.sv-article-category-${entry[0]} .kk-notification::before { background: ${entry[1].color}; }`
      );
    })
    .join("\n");
  document.getElementsByTagName("head")[0].appendChild(style);
};
