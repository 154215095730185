<template>
  <div>
    <chart-responsive
      ref="chart"
      :chart-options="currentChartOptions"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import ChartResponsive from "@/components/Charts/ChartResponsive.vue";
import ChartResponsiveConfigFactory from "@/components/Charts/ChartResponsiveConfigFactory.js";

export default {
  name: "StatisticsChartBars",
  components: {
    ChartResponsive,
  },
  props: {
    chartPayload: {
      type: Object,
      required: true,
    },
    chartKeys: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      chartConfig: ChartResponsiveConfigFactory(),
    };
  },
  computed: {
    currentChartOptions() {
      const series = this.chartPayload;
      const zones = this.chartKeys.zones;
      const years = this.chartKeys.years;
      return this.chartConfig.getConfig(
        "bars",
        { series, zones, years },
        this.$vuetify.breakpoint.name
      );
    },
    height() {
      return this.width * 0.75;
    },
  },
  methods: {
    refreshChart() {
      this.$refs.chart.refreshChart();
    },
    getHeight() {
      return this.height;
    },
  },
};
</script>
