<template>
  <v-row class="align-stretch">
    <v-col>
      <product-card
        :product-id="favProducts[0] || 0"
        style="min-height: 420px"
        with-image
        reversed
      />
    </v-col>
  </v-row>
</template>

<script>
import ProductCard from "@/components/ProductCard/ProductCard.vue";
export default {
  components: {
    ProductCard,
  },
  props: {
    favProducts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
