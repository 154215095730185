<template>
  <div
    class="ordered-list-number__container heading1 d-flex justify-center align-center"
  >
    <div>{{ index + 1 }}</div>
  </div>
</template>

<script>
export default {
  name: "OrderedListNumber",
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.ordered-list-number__container {
  width: 40px;
  height: 40px;
  background-color: var(--v-corporateBlue-lighten2);
  color: white;
  border-radius: 20px;
}
@media (min-width: 640px) {
  .ordered-list-number__container {
    width: 70px;
    height: 70px;
    border-radius: 35px;
  }
}
</style>
