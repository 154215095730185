<template>
  <div class="section">
    <v-row>
      <v-col cols="12" class="pb-0">
        <breadcrumbs class="no-marker px-0" :items="breadcrumbsItems" />
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col cols="12" class="text-center">
        <badge
          v-for="(categoryItem, index) in categoriesList"
          :key="index"
          :text="categoryItem?.title || ''"
          :color="categoryItem?.color || ''"
          text-class="body-3"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <h1 class="accent-lg text-center mb-2 mb-md-3 mt-0 mt-md-1">
          {{ pageData.fields.title || "" }}
        </h1>
      </v-col>
    </v-row>
    <v-row dense class="justify-center">
      <v-col cols="12" md="8">
        <div class="quote-lg text-center">
          {{ pageData.fields.subtitle || "" }}
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center mt-md-8 mt-2">
      <v-col align="center" class="pb-1">
        <v-btn
          depressed
          x-large
          color="primary"
          class="mobile-button-width"
          @click="showDialog = true"
        >
          {{ pageData.fields.button_consult }}
        </v-btn>
      </v-col>
    </v-row>
    <heading-animation
      style="margin-top: 120px"
      :heading-data="pageData.smsc_heading"
    />

    <form-communication-modal v-model="showDialog" />
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Article/Breadcrumbs.vue";
import Badge from "@/components/Badge.vue";
import FormCommunicationModal from "@/components/FormCommunicationModal.vue";
import HeadingAnimation from "./HeadingAnimation.vue";
export default {
  components: {
    Breadcrumbs,
    Badge,
    FormCommunicationModal,
    HeadingAnimation,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_page.fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.products_page.fields.title || "",
          to: { name: "Products" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.fields.title || "",
          disabled: true,
        },
      ];
    },
    categoriesList() {
      if (this.pageData.pid) {
        return [this.$store.getters.getProductCategory(this.pageData.pid)];
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-button-width {
  width: 100%;
}
@media (min-width: 1024px) {
  .mobile-button-width {
    width: fit-content;
  }
}
</style>
