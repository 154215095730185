<template>
  <div class="crosspage d-flex align-center">
    <v-row
      class="crosspage__row crosspage__row--md justify-center align-center"
    >
      <v-col cols="12" lg="" class="crosspage__text">
        <div class="heading1 mb-5 mb-md-10">
          {{ $t("PageNotFound.NokiaBent.title") }}
        </div>

        <div class="mb-3 mb-md-6">
          {{ $t("PageNotFound.NokiaBent.text") }}
        </div>

        <v-row class="justify-center justify-sm-start">
          <v-col class="flex-grow-0">
            <header-menu
              :menu="menu"
              disable-search
              class="crosspage__menu mt-4"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" lg="" class="crosspage__image-container">
        <v-img :src="`${baseUrl}img/404/nokia-bent.png`" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeaderMenu from "@/components/Header/HeaderMenu.vue";
export default {
  name: "PageNotFoundNokiaBent",

  components: {
    HeaderMenu,
  },

  computed: {
    menu() {
      const menu = this.$store.getters.getMenu;
      const filteredChildren = menu.children.filter((item) =>
        ["products", "contacts"].includes(item.node_name)
      );
      return {
        fields: menu.fields,
        children: [
          {
            node_name: "",
            fields: { title: this.$t("Common.mainPageTitle"), href: "" },
          },
          ...filteredChildren,
        ],
      };
    },

    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/pages/CrossPages/CrossPage.scss";
</style>
