<template>
  <div v-if="pageLoaded">
    <v-row>
      <v-col class="text-left page__title">
        <h1>{{ pageData.fields.title || "" }}</h1>
      </v-col>
    </v-row>

    <v-row class="align-stretch">
      <v-col cols="12" lg="7" xl="7">
        <div
          class="cut-last-margin corporateBlue-lighten4 d-flex flex-column justify-center"
          :class="
            $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
              ? 'pl-16 pr-16 pt-10 pb-10'
              : 'pl-5 pr-5 pt-5 pb-5'
          "
          style="z-index: 2; height: 100%"
        >
          <v-row class="flex-grow-0">
            <v-col>
              <v-img :src="pageData.fields.image" max-width="184" />
            </v-col>
          </v-row>

          <v-row class="flex-grow-0">
            <v-col>
              <span class="heading2">{{ pageData.fields.subtitle }}</span>
            </v-col>
          </v-row>

          <v-row class="flex-grow-0">
            <v-col>
              <div v-html="pageData.fields.full_text" />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col xs="12" lg="5" xl="5">
        <validation-observer ref="formInvitationValidator">
          <v-row>
            <v-col class="pt-0 pb-0 mb-2">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                mode="eager"
                :name="pageData.fields.field_name"
              >
                <v-text-field
                  v-model="formData.name"
                  :label="pageData.fields.field_name"
                  maxlength="50"
                  clearable
                  clear-icon="mdi-close-circle"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0 mb-2">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                mode="eager"
                :name="pageData.fields.field_org"
              >
                <v-text-field
                  v-model="formData.org"
                  :label="pageData.fields.field_org"
                  maxlength="50"
                  clearable
                  clear-icon="mdi-close-circle"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0 mb-2">
              <validation-provider
                v-slot="{ errors }"
                rules="required|phone"
                mode="eager"
                :name="pageData.fields.field_phone"
              >
                <v-text-field
                  v-model="formData.phone"
                  :label="pageData.fields.field_phone"
                  maxlength="25"
                  clearable
                  clear-icon="mdi-close-circle"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0 mb-2">
              <validation-provider
                v-slot="{ errors }"
                rules="required|email"
                mode="eager"
                :name="pageData.fields.field_email"
              >
                <v-text-field
                  v-model="formData.email"
                  :label="pageData.fields.field_email"
                  maxlength="50"
                  clearable
                  clear-icon="mdi-close-circle"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" class="pt-0 pb-0 mb-2">
              <v-select
                v-model="formData.date"
                item-text="text"
                item-value="id"
                :items="datesRange"
                :label="pageData.fields.field_date"
                :menu-props="{
                  nudgeBottom: 41,
                }"
              />
            </v-col>

            <v-col cols="12" sm="6" class="pt-0 pb-0 mb-2">
              <v-select
                v-model="formData.time"
                item-text="text"
                item-value="id"
                :items="timesRange"
                :label="pageData.fields.field_time"
                :menu-props="{
                  nudgeBottom: 41,
                }"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0 mb-2">
              <v-text-field
                v-model="formData.comment"
                :label="pageData.fields.field_comment"
                maxlength="100"
                clearable
                clear-icon="mdi-close-circle"
              />
            </v-col>
          </v-row>

          <v-row class="align-center">
            <v-col cols="12" md="" class="flex-grow-0">
              <v-btn
                depressed
                x-large
                color="primary"
                :disabled="!agreement || !btnEnabled"
                block
                @click="commitForm"
                >{{ pageData.fields.button_title }}</v-btn
              >
            </v-col>

            <v-col cols="12" md="" class="pt-2 d-flex">
              <v-checkbox v-model="agreement" class="mt-0 pt-0" hide-details />
              <div class="agreement-label caption-sc" @click="toggleAgreement">
                <span class="textLight--text">{{
                  formDataDefault.agreement
                }}</span>
                <span>
                  <router-link
                    :to="{ name: 'PrivacyPolicy' }"
                    class="caption-sc textLight--text text-decoration-underline"
                  >
                    {{ formDataDefault.agreement_link }}
                  </router-link>
                </span>
              </div>
            </v-col>
          </v-row>
        </validation-observer>
      </v-col>
    </v-row>

    <zuruck />
  </div>
</template>

<script>
import InvitationRepository from "@/api/invitation.js";
import moment from "moment";
import Zuruck from "@/components/Zuruck.vue";

const formFactory = () => ({
  name: "",
  org: "",
  email: "",
  phone: "",
  date: "",
  time: "",
  comment: "",
  node_name: "",
});

export default {
  name: "Invitation",

  components: { Zuruck },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },

  data() {
    return {
      pageLoaded: false,
      agreement: true,
      btnEnabled: true,
      formData: formFactory(),
      pageData: {
        fields: {},
      },
    };
  },

  computed: {
    formDataDefault() {
      return this.$store.getters.defaultFeedbackForm;
    },

    nodename() {
      if (this.pageData.node_name !== undefined) {
        return this.pageData.node_name;
      }
      return this.$store.getters.defaultFeedbackFormName;
    },

    datesRange() {
      if (!this.pageLoaded) return [];
      const minDate = moment(this.pageData.fields.date_min, "YYYY-MM-DD");
      const maxDate = moment(this.pageData.fields.date_max, "YYYY-MM-DD");
      const result = [];
      for (
        let dt = minDate, iter = 0;
        !dt.isAfter(maxDate);
        dt.add(1, "day"), iter++
      ) {
        if (iter > 1000) {
          console.log("date iter over 1000!");
          return [];
        }
        const id = dt.format("DD.MM.YYYY");
        result.push({ id, text: id });
      }
      return result;
    },

    timesRange() {
      if (!this.pageLoaded) return [];
      const minTime = moment(this.pageData.fields.time_min, "HH:mm");
      const maxTime = moment(this.pageData.fields.time_max, "HH:mm");
      const result = [];
      for (
        let dt = minTime, iter = 0;
        !dt.isAfter(maxTime);
        dt.add(1, "hour"), iter++
      ) {
        if (iter > 1000) {
          console.log("date iter over 1000!");
          return [];
        }
        const id = dt.format("HH:mm");
        result.push({ id, text: id });
      }
      return result;
    },
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },

  mounted() {
    this.loadPage();
  },

  methods: {
    async loadPage() {
      const { data } = await InvitationRepository.getInvitationMwc();
      this.pageData = data;
      this.formData.date = this.pageData.fields.date_min;
      this.formData.time = this.pageData.fields.time_min;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },

    resetForm() {
      this.formData = formFactory();
    },

    toggleAgreement(event) {
      const tagName = event.target.tagName || "";
      if (tagName.toLowerCase() == "a") return;
      this.agreement = !this.agreement;
    },

    async commitForm() {
      if (!(await this.$refs.formInvitationValidator.validate())) return;
      const payload = new FormData();
      payload.append("name", this.formData.name);
      payload.append("org", this.formData.org);
      payload.append("phone", this.formData.phone);
      payload.append("email", this.formData.email);
      payload.append("date", this.formData.date);
      payload.append("time", this.formData.time);
      payload.append("comment", this.formData.comment);
      payload.append("nodename", this.nodename);
      this.btnEnabled = false;
      const { data } = await InvitationRepository.putInvitationMwc(payload);
      this.btnEnabled = true;
      if (data.code == 0) {
        this.resetForm();
        this.$router.push({ name: "Thanks" });
      } else {
        alert(this.$t(`Common.feedback.error${data.code}`));
      }
    },
  },
};
</script>
<style scoped>
.agreement-label {
  cursor: pointer;
}
</style>
