<template>
  <div class="motion-item corporateBlue--text">
    <svg
      width="370"
      height="104"
      viewBox="0 0 370 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0"
        y="12"
        width="80"
        height="80"
        :transform="`rotate(${rotateDown}) scale(${scaleDown})`"
        transform-origin="40 52"
        fill="#4DABF5"
      />
      <circle
        cx="40"
        cy="52"
        r="40"
        fill="#4DABF5"
        :transform="`scale(${scaleUp})`"
        transform-origin="40 52"
      />
      <path
        d="M136 12L182 92H90L136 12Z"
        fill="#4DABF5"
        :transform="`rotate(${rotateDown}) scale(${scaleDown})`"
        transform-origin="136 52"
      />
      <circle
        cx="136"
        cy="52"
        r="40"
        fill="#4DABF5"
        :transform="`scale(${scaleUp})`"
        transform-origin="136 52"
      />
      <circle cx="232" cy="52" r="40" fill="#4DABF5" />
      <path
        fill="#4DABF5"
        :transform="`rotate(${rotateDown}) scale(${scaleDown})`"
        transform-origin="328 52"
        d="M321.97 13.15C322.89 11.62 325.11 11.62 326.03 13.15L338.71 34.18C339.04 34.73 339.58 35.12 340.21 35.27L364.16 40.81C365.90 41.21 366.59 43.32 365.42 44.67L349.3 63.21C348.88 63.69 348.67 64.33 348.73 64.97L350.85 89.42C351 91.20 349.21 92.51 347.56 91.81L324.93 82.23C324.33 81.98 323.67 81.98 323.07 82.23L300.44 91.81C298.79 92.51 297 91.20 297.15 89.42L299.27 64.97C299.33 64.33 299.12 63.69 298.7 63.21L282.58 44.67C281.41 43.32 282.10 41.21 283.84 40.81L307.79 35.27C308.42 35.12 308.96 34.73 309.29 34.18L321.97 13.15Z"
      />
      <circle
        cx="328"
        cy="52"
        r="40"
        fill="#4DABF5"
        :transform="`scale(${scaleUp})`"
        transform-origin="328 52"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AdvantageMotion5",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      scaleUpBound: 0.1,
      scaleDownBound: 0.9,
    };
  },
  computed: {
    rotateDown() {
      return this.progress * 270;
    },
    scaleDown() {
      const scale = (this.scaleDownBound - this.progress) / this.scaleDownBound;
      if (scale < 0) return 0;
      if (scale > 1) return 1;
      return scale;
    },
    scaleUp() {
      const scale =
        (this.progress - this.scaleUpBound) / (1 - this.scaleUpBound);
      if (scale < 0) return 0;
      if (scale > 1) return 1;
      return scale;
    },
    itemProgress() {
      return (index) => {
        const newProgress =
          (this.progress - this.startTimes[index]) / this.duration;
        if (newProgress < 0) return 0;
        if (newProgress > 1) return 1;
        return newProgress;
      };
    },
  },
};
</script>
