<template>
  <div v-if="pageLoaded" v-resize="onResize">
    <v-row>
      <v-col class="text-left page__title">
        <h1>{{ pageData.fields.title || "" }}</h1>
      </v-col>
    </v-row>

    <v-row v-if="pageData.fields.gallery" class="mb-8">
      <v-col>
        <v-img
          v-if="$vuetify.breakpoint.lgAndUp"
          :src="pageData.fields.gallery || ''"
          eager
          @load="onResize"
        />
        <v-img
          v-else-if="$vuetify.breakpoint.mdAndUp"
          :src="pageData.fields.gallery_lg || ''"
          eager
          @load="onResize"
        />
        <v-img
          v-else
          :src="pageData.fields.gallery_md || ''"
          eager
          @load="onResize"
        />
      </v-col>
    </v-row>

    <installation-statistics class="section" @trigger-resize="onResize" />

    <activity-directions
      v-if="show('about_dir_images')"
      :images-section="pageData.about_dir_images"
      :list-section="pageData.about_dir_list"
      @trigger-resize="onResize"
    />

    <company-history
      v-if="show('about_history')"
      :history-data="pageData.about_history"
      @trigger-resize="onResize"
    />

    <management
      v-if="show('about_management')"
      ref="managementref"
      :management-data="pageData.about_management"
      @trigger-resize="onResize"
    />

    <team
      v-if="show('about_team')"
      ref="teamref"
      :team-data="pageData.about_team"
      @trigger-resize="onResize"
    />

    <approach
      v-if="show('about_approach')"
      :approach-data="pageData.about_approach"
      @trigger-feedback="showDialog = true"
    />

    <letters
      v-if="show('about_letters')"
      :letters-data="pageData.about_letters"
    />

    <joint-projects
      v-if="show('about_joint')"
      :joint-data="pageData.about_joint"
    />
    <partners
      v-if="show('about_partners')"
      :partners-data="pageData.about_partners"
      @trigger-feedback="showDialog = true"
    />

    <form-communication :form-id="pageData.links.feedback_form[0]" />

    <form-communication-modal v-model="showDialog" />

    <zuruck />
  </div>
</template>

<script>
import AboutRepository from "@/api/about.js";
import FormCommunication from "@/components/FormCommunication.vue";
import FormCommunicationModal from "@/components/FormCommunicationModal.vue";
import InstallationStatistics from "@/components/InstallationStatistics.vue";
import ActivityDirections from "./ActivityDirections.vue";
import CompanyHistory from "./CompanyHistory.vue";
import Management from "./Management.vue";
import Team from "./Team.vue";
import Approach from "./Approach.vue";
import Letters from "./Letters.vue";
import JointProjects from "./JointProjects.vue";
import Partners from "./Partners.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    FormCommunication,
    InstallationStatistics,
    ActivityDirections,
    CompanyHistory,
    Management,
    Team,
    Approach,
    Letters,
    JointProjects,
    Partners,
    FormCommunicationModal,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      showDialog: false,
      pageLoaded: false,
      pageData: {
        fields: {},
        about_dir_images: { fields: {}, children: [] },
        about_dir_list: { fields: {}, children: [] },
        about_history: { fields: {}, children: [] },
        about_management: { fields: {}, children: [], links: { person: [] } },
        about_team: { fields: {}, children: [], links: { person: [] } },
        about_approach: { fields: {}, children: [] },
        about_letters: { fields: {}, children: [] },
        about_joint: { fields: {}, children: [] },
        about_partners: { fields: {}, children: [] },
        links: { feedback_form: [] },
      },
    };
  },
  computed: {
    show() {
      return (section) => this.pageData[section] !== undefined;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await AboutRepository.getAbout();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
      this.$nextTick(() => {
        this.$refs.managementref?.restartRandomFacts();
        this.onResize();
      });
    },
    onResize() {
      this.$refs.teamref?.refreshChart();
    },
  },
};
</script>
