<template>
  <div class="section">
    <div class="heading1 text-center mb-16">
      {{ implementationData.fields.title || "" }}
    </div>
    <v-row v-for="(row, row_index) in products" :key="row_index" class="mx-n2">
      <v-col
        v-for="product_id in row"
        :key="product_id"
        cols="12"
        :lg="12 / row.length"
        class="px-2 py-2"
      >
        <product-card hide-badges :product-id="product_id" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard/ProductCard.vue";
export default {
  components: { ProductCard },
  props: {
    implementationData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    products() {
      let items = this.implementationData.product_index_id ?? [];
      switch (items.length) {
        case 4:
          return [items.slice(0, 2), items.slice(2, 4)];
        case 5:
          return [items.slice(0, 2), items.slice(2, 4)];
        case 6:
          return [items.slice(0, 3), items.slice(3, 6)];
        case 7:
          return [items.slice(0, 3), items.slice(3, 7)];
        case 8:
          return [items.slice(0, 2), items.slice(2, 5), items.slice(5, 8)];
        default:
          return [items];
      }
    },
  },
};
</script>
