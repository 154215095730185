<template>
  <div v-if="pageLoaded">
    <heading :page-data="pageData" @scroll-to-test="onTestSolution" />

    <what-is :what-data="pageData.esim_what_is" />

    <useful :useful-data="pageData.esim_useful" />

    <growth :growth-data="pageData.esim_growth" />

    <potential :potential-data="pageData.esim_potential" />

    <offer :offer-data="pageData.esim_offer" />

    <components :components-data="pageData.esim_components" />

    <extension :extension-data="pageData.esim_extension" />

    <advantages :advantages-data="pageData.esim_advantages" />

    <connect :connect-data="pageData.esim_connect" />

    <test-solution
      :test-solution-data="pageData.esim_test"
      style="margin-top: -100px"
    />

    <form-communication :form-id="pageData.links.feedback_form[0] || 0" />

    <zuruck />
  </div>
</template>

<script>
import LandingRepository from "@/api/landing.js";
import FormCommunication from "@/components/FormCommunication.vue";
import Heading from "./Heading.vue";
import WhatIs from "./WhatIs.vue";
import Useful from "./Useful.vue";
import Growth from "./Growth.vue";
import Potential from "./Potential.vue";
import Offer from "./Offer.vue";
import Components from "./Components.vue";
import Extension from "./Extension.vue";
import Advantages from "./Advantages.vue";
import Connect from "./Connect.vue";
import TestSolution from "./TestSolution.vue";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    Heading,
    WhatIs,
    Useful,
    Potential,
    Growth,
    Offer,
    Components,
    Extension,
    Advantages,
    Connect,
    TestSolution,
    FormCommunication,
    Zuruck,
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        links: { feedback_form: [] },
        esim_what_is: { fields: {} },
        esim_useful: { fields: {}, children: [] },
        esim_growth: { fields: {} },
        esim_potential: { fields: {} },
        esim_offer: { fields: {} },
        esim_components: { fields: {}, children: [] },
        esim_extension: { fields: {}, links: { product: [] } },
        esim_advantages: { fields: {}, children: [] },
        esim_connect: { fields: {}, children: [] },
        esim_test: { fields: {} },
        main_page: { fields: {} },
        products_page: { fields: {} },
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await LandingRepository.getEsim();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
    onTestSolution() {
      elementScrollIntoView(document.querySelector("#test-solution"), {
        behavior: "smooth",
      });
    },
  },
};
</script>
