<template>
  <div>
    <v-row class="section__title-row">
      <v-col class="text-center">
        <span :class="$vuetify.breakpoint.smAndUp ? 'heading2' : 'heading1'">{{
          usefulData.fields.title || ""
        }}</span>
      </v-col>
    </v-row>
    <v-row class="section">
      <v-col
        v-for="(usefulBlock, index) in usefulData.children"
        :key="index"
        cols="12"
        md="4"
      >
        <div class="subtitle1 section__subtitle">
          {{ usefulBlock.fields.title }}
        </div>
        <div v-html="usefulBlock.fields.description" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Useful",
  props: {
    usefulData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
