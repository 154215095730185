import Repository from "@/plugins/axios";

export default {
  // Получить данные о приглашении на MWC
  getInvitationMwc() {
    return Repository.get("/invitation-mwc");
  },
  // Отправить данные о встрече на MWC
  putInvitationMwc(params) {
    return Repository.post("/invitation-mwc", params);
  },
};
