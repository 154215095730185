<template>
  <div class="section">
    <div class="heading1 text-center mb-16">
      {{ experienceData.fields.title || "" }}
    </div>
    <v-row>
      <v-col
        v-for="(children, index) in experienceData.children"
        :key="index"
        cols="12"
        lg="4"
        align="center"
        class="py-3 py-md-10 py-lg-3"
      >
        <gradient-title-block :item="children" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GradientTitleBlock from "@/components/GradientTitleBlock.vue";
export default {
  components: {
    GradientTitleBlock,
  },
  props: {
    experienceData: {
      type: Object,
      required: true,
    },
  },
};
</script>
