<template>
  <div>
    <v-row class="section heading__section justify-center">
      <v-col cols="12" class="heading__content heading">
        <h1 class="accent-lg text-center primary--text mb-3">
          {{ pageData.fields.title || "Welcome!" }}
        </h1>

        <div>
          <div class="accent-md text-center mb-10">
            {{ pageData.fields.subtitle || "" }}
          </div>

          <v-row class="justify-center">
            <v-col align="center">
              <v-btn
                depressed
                x-large
                color="primary"
                class="mobile-button-width"
                @click="$emit('scroll-to-offer')"
              >
                {{ pageData.fields.button_offer }}
              </v-btn>

              <v-btn
                depressed
                outlined
                x-large
                color="primary"
                class="mobile-button-width"
                @click="$emit('scroll-to-about')"
              >
                {{ pageData.fields.button_about }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <div
      id="qr-heading"
      class="d-none d-md-block section qr-heading-images"
      style="position: relative"
    >
      <div class="d-flex flex-column" style="height: 100%">
        <div class="d-flex justify-center flex-grow-0">
          <div class="shadow-block">
            <v-img
              :src="pageData.fields.company_image || ''"
              eager
              contain
              max-height="78px"
            />
          </div>
        </div>

        <div
          v-if="pageData.fields.additional_image"
          class="qr-heading-images-additional-block flex-grow-1 my-13"
        >
          <div
            class="qr-heading-images-additional-slide d-flex justify-center"
            :style="getSolutionStyle('additional-up')"
          >
            <div
              class="shadow-block"
              :style="getSolutionStyle('additional-transform')"
            >
              <v-img
                :src="pageData.fields.additional_image || ''"
                eager
                contain
                max-height="78px"
              />
            </div>
          </div>
        </div>

        <div class="d-flex justify-space-between flex-grow-0">
          <div class="d-flex justify-center">
            <div
              class="shadow-block-small"
              :style="getSolutionStyle('side-down')"
            >
              <div class="d-flex justify-center mb-2">
                <v-img
                  :src="pageData.fields.installations_image || ''"
                  eager
                  contain
                  class="shadow-block-small-image"
                />
              </div>
              <div class="d-flex justify-center body1">
                {{ pageData.fields.installations_text }}
              </div>
            </div>
          </div>

          <div class="d-flex justify-center">
            <div class="shadow-block-small">
              <div class="d-flex justify-center mb-2">
                <v-img
                  :src="pageData.fields.subscribers_image || ''"
                  eager
                  contain
                  class="shadow-block-small-image"
                />
              </div>
              <div class="d-flex justify-center body1">
                {{ pageData.fields.subscribers_text }}
              </div>
            </div>
          </div>

          <div class="d-flex justify-center">
            <div
              class="shadow-block-small"
              :style="getSolutionStyle('side-down')"
            >
              <div class="d-flex justify-center mb-2">
                <v-img
                  :src="pageData.fields.products_image || ''"
                  eager
                  contain
                  class="shadow-block-small-image"
                />
              </div>
              <div class="d-flex justify-center body1">
                {{ pageData.fields.products_text }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="qr-heading-images-site-block">
        <v-img
          :src="pageData.fields.site_image || ''"
          eager
          class="qr-heading-images-site-image"
        />
      </div>
    </div>

    <div class="d-block d-md-none section">
      <div class="section">
        <v-img :src="pageData.fields.site_image || ''" eager />
      </div>

      <div class="d-flex justify-center mb-10">
        <div class="d-flex justify-center shadow-block">
          <v-img
            :src="pageData.fields.company_image || ''"
            eager
            class="shadow-block-image"
          />
        </div>
      </div>

      <div
        v-if="pageData.fields.additional_image"
        class="d-flex justify-center mb-10"
      >
        <div class="d-flex justify-center shadow-block">
          <v-img
            :src="pageData.fields.additional_image || ''"
            eager
            class="shadow-block-image"
          />
        </div>
      </div>

      <div class="d-flex justify-center mb-10">
        <div class="shadow-block-small">
          <div class="d-flex justify-center mb-2">
            <v-img
              :src="pageData.fields.installations_image || ''"
              eager
              contain
              class="shadow-block-small-image"
            />
          </div>

          <div class="d-flex justify-center body1">
            {{ pageData.fields.installations_text }}
          </div>
        </div>
      </div>

      <div class="d-flex justify-center mb-10">
        <div class="shadow-block-small">
          <div class="d-flex justify-center mb-2">
            <v-img
              :src="pageData.fields.subscribers_image || ''"
              eager
              contain
              class="shadow-block-small-image"
            />
          </div>

          <div class="d-flex justify-center body1">
            {{ pageData.fields.subscribers_text }}
          </div>
        </div>
      </div>

      <div class="d-flex justify-center mb-10">
        <div class="shadow-block-small">
          <div class="d-flex justify-center mb-2">
            <v-img
              :src="pageData.fields.products_image || ''"
              eager
              contain
              class="shadow-block-small-image"
            />
          </div>

          <div class="d-flex justify-center body1">
            {{ pageData.fields.products_text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
export default {
  name: "Heading",

  mixins: [ProgressObserverMixin],

  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      obvOptions: {
        viewportTopPct: 0,
        viewportBottomPct: 100,
        pivotBegin: "center", // avail: top, bottom, center
        pivotEnd: "center", // avail: top, bottom, center
      },
    };
  },

  computed: {
    getSolutionStyle() {
      return (type) => {
        const progress = this.obv(`qr-heading`);
        if (type == "side-down") {
          return {
            transform: `translateY(${-30 + progress * 100 * 0.3}vh)`,
          };
        }
        if (type == "additional-up") {
          return {
            bottom: `${100 * progress}%`,
          };
        }
        if (type == "additional-transform") {
          return {
            transform: `translate(-50%, ${progress * 100}%)`,
          };
        }
      };
    },
  },

  mounted() {
    this.obvAddElement(`qr-heading`);
  },
};
</script>
<style lang="scss" scoped>
.qr-heading-images {
  height: 727px;
  position: relative;

  .qr-heading-images-site-block {
    position: absolute;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);

    .qr-heading-images-site-image {
      max-width: 771px;
      max-height: 440px;
    }
  }

  .qr-heading-images-additional-block {
    position: relative;

    .qr-heading-images-additional-slide {
      position: absolute;
      left: 50%;
    }
  }
}
.mobile-button-width {
  margin-bottom: 10px;
}

.shadow-block,
.shadow-block-small {
  z-index: 1;
  background: white;
  max-width: 100%;
  box-shadow: 0px 4.22037px 13.264px rgba(0, 133, 238, 0.2),
    0px 8.44075px 13.264px rgba(33, 150, 243, 0.1),
    0px 57.8794px 79.5842px rgba(33, 150, 243, 0.08);
  border-radius: 12px;
}

.shadow-block {
  padding: 24px;
}

.shadow-block-image {
  max-height: 64px;
  max-width: 240px;
}

.shadow-block-small {
  padding: 20px;
  width: 246px;

  .body1 {
    font-size: 14px !important;
  }
}

.shadow-block-small-image {
  max-height: 48px;
  max-width: 240px;
}

@media (min-width: 1024px) {
  .mobile-button-width {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
  }

  .shadow-block {
    padding: 40px;
  }

  .shadow-block-image {
    max-height: none;
    max-width: none;
  }

  .shadow-block-small {
    padding: 32px;
    width: 232px;
  }

  .shadow-block-small-image {
    max-height: 64px;
  }
}

@media (min-width: 1280px) {
  .qr-heading-images {
    height: 847px;

    .qr-heading-images-site-block {
      bottom: 42px;

      .qr-heading-images-site-image {
        max-width: 1170px;
        max-height: 670px;
      }
    }
  }

  .shadow-block-small {
    width: 272px;

    .body1 {
      font-size: 18px !important;
    }
  }
}
</style>
