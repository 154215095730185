<template>
  <div class="history-year">
    <div class="subtitle1 mb-4 text-center">
      {{ yearData.title }}
    </div>

    <div class="history-year__canvas">
      <div class="history-year__canvas-dot" />
    </div>

    <div class="history-year__padding-box">
      <div
        class="history-year__window mb-3 pa-5 pa-md-10"
        :style="`width: ${width}px`"
      >
        <div class="subtitle2">{{ terms.term_prod }}</div>
        <div class="heading1 mb-6" v-html="styleIncrement(yearData.cnt_prod)" />
        <div class="subtitle2">{{ terms.term_cntr }}</div>
        <div class="heading1 mb-6" v-html="styleIncrement(yearData.cnt_cntr)" />
        <div class="subtitle2">{{ terms.term_inst }}</div>
        <div class="heading1 mb-6" v-html="styleIncrement(yearData.cnt_inst)" />
      </div>
      <div
        v-if="hasText"
        class="caption-sc corporateBlue-lighten4 pa-5 pa-md-10 cut-last-margin"
        v-html="yearData.text"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyHistoryYear",
  props: {
    yearData: {
      type: Object,
      required: true,
    },
    terms: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    hasText() {
      return this.yearData.text && this.yearData.text.length > 0;
    },
  },
  methods: {
    styleIncrement(str) {
      const matches = str.match(/^([^\d]+)(\d+)$/);
      if (matches === null) {
        return `<span class="primary--text">${str}</span>`;
      } else {
        return (
          `<span class="corporateBorder--text">${matches[1]}</span>` +
          `<span class="primary--text">${matches[2]}</span>`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.history-year {
  direction: ltr;
  .history-year__canvas {
    position: relative;
    height: 3px;
    background-color: #cae6fc;
    margin-bottom: 15px;

    .history-year__canvas-dot {
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 10px;
      background-color: #cae6fc;
      position: absolute;
      left: 50%;
      top: 1px;
    }
  }

  .history-year__padding-box {
    padding: 15px;

    .history-year__window {
      border: 2px solid var(--v-corporateBorder-base);
    }
  }
}
</style>
