<template>
  <div class="section">
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ productsData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="align-start">
      <v-col cols="12" lg="5" xl="6">
        <kk-dynamic-html :html="productsData.fields.description" />
      </v-col>
      <v-col cols="12" lg="7" xl="6">
        <random-refresh-block
          :title="productsData.fields.example_title"
          :text="randomExample"
          @request-random-item="generateRandomExample"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import RandomRefreshBlock from "@/components/RandomRefreshBlock.vue";
import shuffle from "lodash/shuffle";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "AboutProducts",
  components: { KkDynamicHtml, RandomRefreshBlock },
  props: {
    productsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      randomExamplesIndex: 0,
      randomExamplesBox: [],
      randomExample: "",
    };
  },
  computed: {
    cursorExample() {
      return this.randomExamplesBox[this.randomExamplesIndex]
        ? this.randomExamplesBox[this.randomExamplesIndex].fields.description
        : "";
    },
  },
  methods: {
    restartRandomExamples() {
      this.resetRandomExamples();
      this.generateRandomExample();
    },
    resetRandomExamples() {
      this.randomExamplesBox = shuffle(this.productsData.children);
      this.randomExamplesIndex = 0;
      // чтобы избежать повторения при повторном перемешивании массива
      if (this.cursorExample == this.randomExample) {
        this.randomExamplesIndex++;
      }
    },
    generateRandomExample() {
      this.randomExamplesIndex++;
      if (this.randomExamplesIndex >= this.randomExamplesBox.length) {
        this.resetRandomExamples();
      }
      if (this.cursorExample.length) {
        this.randomExample = this.cursorExample;
      }
    },
  },
};
</script>
