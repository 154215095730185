import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ru from "vuetify/es5/locale/ru";
import CloseIcon from "@/components/Icons/Close.vue";
import DotsIcon from "@/components/Icons/Dots.vue";
import ReloadIcon from "@/components/Icons/Reload.vue";
import SearchIcon from "@/components/Icons/Search.vue";

Vue.use(Vuetify);

const options = {
  lang: {
    locales: { ru },
    current: "ru",
  },
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    values: {
      close: { component: CloseIcon },
      dots: { component: DotsIcon },
      reload: { component: ReloadIcon },
      search: { component: SearchIcon },
    },
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#2196f3",
        // Additional
        primaryAdditional2: "#CAE6FC",
        primaryAdditional3: "#DBEEFD",
        primaryAdditional4: "#EDF7FE",

        // Corporate
        corporateGrey: "#2E323A",
        corporateBorder: "#D8E0E6",

        // Semantic
        info: "#0F7FFF",
        success: "#4CAF50",
        error: "#FF4F44",
        warning: "#FF9800",

        // Text
        textBase: "#404040",
        textLight: "#8E9094",

        // Others
        multiplatform: "#80C342",
      },
    },
  },

  breakpoint: {
    thresholds: {
      xs: 640,
      sm: 1024,
      md: 1280,
      lg: 1440,
    },
    scrollBarWidth: 0,
  },
};

export default new Vuetify(options);
