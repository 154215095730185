<template>
  <div>
    <v-row>
      <v-col>
        <breadcrumbs class="no-marker px-0" :items="breadcrumbsItems" />
      </v-col>
    </v-row>

    <v-row class="section">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="3"
        xl="2"
        class="overline-sc black-lighten2--text"
      >
        {{ humanizedDate }}
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="6"
        xl="7"
        class="text-left root py-0"
      >
        <v-row no-gutters>
          <v-col>
            <h1>{{ pageData.content.fields.title || "" }}</h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <article v-html="pageData.content.fields.full_text || ''" />
          </v-col>
        </v-row>

        <v-row class="mt-12" no-gutters>
          <v-col class="mx-n1">
            <tag-chip
              v-for="(tag, index) in pageData.content.fields.tags"
              :key="index"
              @click="goNewsTag(tag)"
            >
              {{ tag }}
            </tag-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <three-previews
      :title="pageData.news_page.fields.another_news || ''"
      :button-title="pageData.news_page.fields.button_all || ''"
      :to="{ name: 'NewsList' }"
      class="section"
    >
      <template v-slot:first>
        <preview
          :preview-info="newsItem(0)"
          bigger-xxl
          bigger-xl
          bigger-md
          @tagclick="goNewsTag"
          @previewclick="goNewsItem"
        />
      </template>

      <template v-slot:second>
        <preview
          :preview-info="newsItem(1)"
          @tagclick="goNewsTag"
          @previewclick="goNewsItem"
        />
      </template>

      <template v-slot:third>
        <preview
          :preview-info="newsItem(2)"
          @tagclick="goNewsTag"
          @previewclick="goNewsItem"
        />
      </template>
    </three-previews>

    <form-communication
      :form-id="pageData.news_page.links.feedback_form[0] || 0"
      kind="subscription"
    />

    <zuruck />
  </div>
</template>

<script>
import NewsRepository from "@/api/news.js";
import ThreePreviews from "@/components/Preview/ThreePreviews.vue";
import TagChip from "@/components/Tags/TagChip.vue";
import Preview from "@/components/Preview/Preview.vue";
import Breadcrumbs from "@/components/Article/Breadcrumbs.vue";
import FormCommunication from "@/components/FormCommunication.vue";
import moment from "moment";
import Zuruck from "@/components/Zuruck.vue";

export default {
  name: "NewsItem",

  components: {
    ThreePreviews,
    TagChip,
    Breadcrumbs,
    Preview,
    FormCommunication,
    Zuruck,
  },

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.pageData.news_page.fields.title
      ),
    };
  },

  data() {
    return {
      pageLoaded: false,
      pageData: {
        news_page: {
          fields: {},
          links: { feedback_form: [] },
        },
        main_page: { fields: {} },
        content: { fields: {} },
        more: [],
      },
    };
  },

  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_page.fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.news_page.fields.title || "",
          to: { name: "NewsList" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.content.fields.title || "",
          disabled: true,
        },
      ];
    },

    humanizedDate() {
      if (!this.pageData.content.fields.date) return "";
      return moment(this.pageData.content.fields.date).format("D MMMM yyyy");
    },

    hasTags() {
      if (!this.pageData.content.fields.tags) return false;
      return this.pageData.content.fields.tags.length > 0;
    },

    newsItem() {
      return (index) => this.pageData.more[index] || {};
    },
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },

  mounted() {
    this.loadPage();
  },

  methods: {
    async loadPage() {
      const { data } = await NewsRepository.getNewsItem(this.id);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.content.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },

    goNewsTag(tag) {
      this.$router.push({ name: "NewsList", params: { tag } });
    },

    goNewsItem(id) {
      this.$router.push({ name: "NewsItem", params: { id } });
    },
  },
};
</script>
