<template>
  <v-row class="mb-4 mb-md-0">
    <v-col cols="12" class="subtitle1 pb-2">
      {{ approachFields.subtitle }}
    </v-col>
    <div class="col col-12 pb-2" v-html="approachFields.text" />
    <v-col cols="12" md="" class="flex-md-grow-0">
      <v-btn
        depressed
        x-large
        block
        color="primary"
        @click="$emit('trigger-feedback')"
      >
        {{ approachFields.button }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ApproachProcess",
  props: {
    approachFields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
