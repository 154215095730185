<template>
  <div class="section">
    <div class="heading1 text-center">
      {{ functionalData.fields.title || "" }}
    </div>
    <div class="subtitle2 text-center mt-4 mt-md-6">
      {{ functionalData.fields.subtitle || "" }}
    </div>
    <div class="d-flex flex-column smsc-functional">
      <v-btn-toggle
        v-model="mode"
        mandatory
        borderless
        class="mt-6 mt-md-16 smsc-functional-button-toggle"
      >
        <v-btn
          v-for="(children, index) in functionalData.children"
          :key="index"
          x-large
          class="smsc-functional-button px-3 px-md-8"
          :color="mode == index ? 'primary' : ''"
        >
          {{ children.fields.title }}
        </v-btn>
      </v-btn-toggle>
      <v-list class="smsc-functional-list py-0 mt-6 text-left">
        <template v-for="(element, index) in limitedListElements">
          <v-list-item :key="index">
            <v-list-item-content>
              <div v-html="element.fields.text"></div>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="index < limitedListElements.length - 1"
            :key="`${index}_divider`"
          ></v-divider>
        </template>
        <template v-if="listElements.length > 9">
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content class="justify-center">
              <v-btn text color="primary" @click="expand = !expand">
                {{ expandButtonText }}
                <v-icon class="ml-1">
                  {{ expand ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    functionalData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mode: null,
      expand: false,
    };
  },
  computed: {
    listElements() {
      return this.functionalData.children[this.mode]?.children ?? [];
    },
    limitedListElements() {
      return this.expand ? this.listElements : this.listElements.slice(0, 9);
    },
    expandButtonText() {
      return this.expand
        ? this.functionalData?.fields?.button_hide_all
        : this.functionalData?.fields?.button_show_all;
    },
  },
};
</script>

<style lang="scss" scoped>
.smsc-functional {
  align-items: stretch;
  max-width: min-content;
  margin: 0 auto;
}
.smsc-functional-button-toggle {
  overflow-x: auto;
  .smsc-functional-button {
    opacity: 1 !important;
    &.primary {
      color: #ffffff;
    }
  }
}
.smsc-functional-list {
  box-shadow: 0px 16.56403px 41.41006px 0px rgba(16, 73, 118, 0.15),
    0px 3.3128px 12.42302px 0px rgba(20, 90, 147, 0.1) !important;
  border-radius: 12px;
}
</style>
