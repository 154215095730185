import { render, staticRenderFns } from "./CompanyHistoryYear.vue?vue&type=template&id=011bf7e2&scoped=true"
import script from "./CompanyHistoryYear.vue?vue&type=script&lang=js"
export * from "./CompanyHistoryYear.vue?vue&type=script&lang=js"
import style0 from "./CompanyHistoryYear.vue?vue&type=style&index=0&id=011bf7e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "011bf7e2",
  null
  
)

export default component.exports