<template>
  <div class="section">
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ jointData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="section__content list-content mb-10">
      <v-col
        v-for="(jointProject, index) in jointData.children"
        :key="index"
        cols="12"
        md="6"
        lg="4"
      >
        <client-card :client-data="jointProject.fields" is-html />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClientCard from "@/components/ClientCard/ClientCard.vue";

export default {
  name: "JointProjects",
  components: {
    ClientCard,
  },
  props: {
    jointData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
