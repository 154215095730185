<template>
  <div class="sv-notification">
    <div class="sv-close">
      <v-btn icon @click="$emit('close', false)">
        <v-icon color="rgba(0, 0, 0, 0.32)">mdi-close</v-icon>
      </v-btn>
    </div>
    <v-row class="align-start justify-end justify-lg-space-between">
      <v-col class="align-self-center flex-grow-0 ml-2" cols="12" lg="">
        <v-img :src="notificationData.fields.image" max-width="184" />
      </v-col>
      <v-col class="sv-header-and-text flex-grow-0" cols="12" lg="">
        <v-row class="align-start justify-start">
          <v-col class="flex-grow-0 col-12 col-xxl">
            <div class="heading2 text-no-wrap">
              {{ notificationData.fields.title }}
            </div>
            <div class="subtitle2 text-no-wrap">
              {{ notificationData.fields.subtitle }}
            </div>
          </v-col>
          <v-col class="sv-fulltext body1 col-12 col-xxl">
            <div v-html="notificationData.fields.full_text" />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="align-self-center flex-grow-0 pr-lg-12" cols="12" sm="">
        <v-btn
          depressed
          x-large
          block
          color="primary"
          :to="{ name: 'Invitation' }"
        >
          {{ notificationData.fields.button_title }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SvBadge",
  props: {
    notificationData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goInvitation() {
      this.$router.push({ name: "Invitation" });
    },
  },
};
</script>
<style lang="scss" scoped>
.sv-notification {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 408px;
  padding: 40px 16px;
  background-color: var(--v-corporateBlueAdditional-lighten4);
  z-index: 270;

  .sv-close {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}

@media (min-width: 1280px) {
  .sv-notification {
    height: 246px;
    padding-left: 55px;
    padding-right: 55px;
  }
  .sv-fulltext {
    max-width: 610px;
    min-width: 610px;
  }
}

@media (min-width: 1440px) {
  .sv-notification {
    padding-left: 135px;
    padding-right: 135px;
  }
}

@media (min-width: 1920px) {
  .sv-notification {
    height: 162px;
    padding-left: 175px;
    padding-right: 175px;
  }
  .sv-header-and-text {
    flex-grow: 1 !important;
    .row {
      justify-content: space-around !important;
    }
  }
  .sv-fulltext {
    max-width: 450px;
    min-width: 450px;
  }
}
</style>
