<template>
  <div id="statistics-desktop" class="section">
    <statistics-title :title="statisticsData.fields.title || ''" />

    <v-row class="flex-nowrap">
      <v-col class="stat-col-par flex-grow-0">
        <statistics-paragraph
          v-for="(block, blockIndex) in statisticsData.children"
          :id="`statparblock-${blockIndex}`"
          :key="blockIndex"
          :title="block.fields.title"
          :text="block.fields.text"
          class="stat-par"
        />
      </v-col>

      <v-col class="stat-col-graph flex-grow-1 flex-shrink-1">
        <div class="sv-sticky-container" :style="stickyContainerStyle">
          <div class="sv-sticky" :style="stickyStyle">
            <div
              v-for="(block, blockIndex) in statisticsData.children"
              :key="blockIndex"
              class="stat-sticky-graph-container"
              :style="chartContainerStyle(blockIndex)"
            >
              <statistics-chart-donut
                v-if="block.fields.typ == 'donut'"
                :id="`chart-${blockIndex}`"
                :ref="`refchart${blockIndex}`"
                :chart-keys="block.keys"
                :chart-payload="block.payload"
                :chart-angles="block.angles"
                :width="graphWidth"
                :style="chartStyle(blockIndex)"
              />

              <statistics-chart-bars
                v-else-if="block.fields.typ == 'bars'"
                :id="`chart-${blockIndex}`"
                :ref="`refchart${blockIndex}`"
                :chart-keys="block.keys"
                :chart-payload="block.payload"
                :width="graphWidth"
                :style="chartStyle(blockIndex)"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatisticsTitle from "./StatisticsTitle.vue";
import StatisticsParagraph from "./StatisticsParagraph.vue";
import StatisticsChartDonut from "./StatisticsChartDonut.vue";
import StatisticsChartBars from "./StatisticsChartBars.vue";
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
export default {
  name: "StatisticsDesktop",

  components: {
    StatisticsTitle,
    StatisticsParagraph,
    StatisticsChartDonut,
    StatisticsChartBars,
  },

  mixins: [ProgressObserverMixin],

  props: {
    statisticsData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      graphWidth: 0,
      viewportHeight: 0,
      chartHeights: [],
      obvOptions: {
        viewportTopPct: 60,
        viewportBottomPct: 90,
        pivotBegin: "top",
        pivotEnd: "top",
      },
    };
  },

  computed: {
    mainLoading() {
      return this.$store.getters.getMainLoading;
    },

    stickyStyle() {
      const hFirst = this.chartHeights[0] ? this.chartHeights[0] : 0;
      const hLast = this.chartHeights[this.chartHeights.length - 1]
        ? this.chartHeights[this.chartHeights.length - 1]
        : 0;
      const stickyHeight = Math.round((hLast + hFirst) / 2);
      return {
        height: `${stickyHeight}px`,
      };
    },

    stickyContainerStyle() {
      const h0 = this.chartHeights[0] ? this.chartHeights[0] : 0;
      const graphMarginTop = -Math.round((this.viewportHeight - h0) / 2);
      return {
        "margin-top": `${graphMarginTop}px`,
      };
    },

    chartContainerStyle() {
      return (blockIndex) => {
        const opacity = this.obv(`statparblock-${blockIndex}`);
        const pi = opacity == 1 ? {} : { "pointer-events": "none" };

        return {
          width: `${this.graphWidth}px`,
          height: `${this.viewportHeight}px`,
          opacity: blockIndex == 0 ? 1 : opacity,
          ...pi,
        };
      };
    },

    chartStyle() {
      return (blockIndex) => {
        const h = this.chartHeights[blockIndex]
          ? this.chartHeights[blockIndex]
          : 0;
        const graphMarginTop = Math.round((this.viewportHeight - h) / 2);

        return {
          "margin-top": `${graphMarginTop}px`,
        };
      };
    },
  },

  watch: {
    mainLoading(val) {
      if (!val) {
        this.refreshChart();
      }
    },
  },

  mounted() {
    this.refreshChart();
    this.initObserver();
  },

  methods: {
    initObserver() {
      const local = this;
      this.statisticsData.children.forEach((block, blockIndex) => {
        local.obvAddElement(`statparblock-${blockIndex}`);
      });
    },

    async refreshChart() {
      const el = document.getElementById("statistics-desktop");
      if (el) {
        this.graphWidth = el.offsetWidth - 570;
        this.viewportHeight = window.innerHeight - 80;
      } else {
        console.log("no statistics desktop element found!");
      }
      await this.$nextTick();

      this.statisticsData.children.forEach(async (block, blockIndex) => {
        const ref = this.$refs[`refchart${blockIndex}`];
        if (ref?.[0]) {
          const height = ref[0].getHeight();
          this.$set(this.chartHeights, blockIndex, height);
          ref[0].refreshChart();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.stat-col-par {
  max-width: 470px;
  min-width: 470px;
  margin-right: 100px;

  .stat-par:not(:last-child) {
    margin-bottom: 375px;
  }

  .stat-par:last-child {
    margin-bottom: -40px;
  }
}

.stat-col-graph {
  overflow-x: visible;
}

.sv-sticky-container {
  height: 100%;

  .sv-sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 80px;

    .stat-sticky-graph-container {
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
    }
  }
}
</style>
