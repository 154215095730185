export default {
  data() {
    return {
      obvOptions: {
        viewportTopPct: 0,
        viewportBottomPct: 100,
        pivotBegin: "top", // avail: top, bottom, center
        pivotEnd: "bottom", // avail: top, bottom, center
      },
      obvProgress: {},
    };
  },
  computed: {
    offsetMultBegin() {
      switch (this.obvOptions.pivotBegin) {
        case "bottom":
          return 1;
        case "center":
          return 0.5;
        default:
          return 0;
      }
    },
    offsetMultEnd() {
      switch (this.obvOptions.pivotEnd) {
        case "bottom":
          return 1;
        case "center":
          return 0.5;
        default:
          return 0;
      }
    },
    obv() {
      return (id) => {
        return this.obvProgress[id] || 0;
      };
    },
  },
  created() {
    window.addEventListener("scroll", this.obvOnScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.obvOnScroll);
  },
  methods: {
    obvAddElement(id) {
      this.$set(this.obvProgress, id, 0);
    },
    obvOnScroll() {
      const scrollY = window.scrollY;
      const local = this;
      const opt = this.obvOptions;
      Object.keys(this.obvProgress).forEach((key) => {
        const element = document.getElementById(key);
        if (!element) return;
        const offsetBegin = this.offsetMultBegin * element.clientHeight;
        const offsetEnd = this.offsetMultEnd * element.clientHeight;
        const viewportHeight =
          (window.innerHeight * (opt.viewportBottomPct - opt.viewportTopPct)) /
          100;
        const viewportBottom =
          (window.innerHeight * opt.viewportBottomPct) / 100 + scrollY;
        const pathLength = viewportHeight - offsetBegin + offsetEnd;
        const progress =
          (viewportBottom - element.offsetTop - offsetBegin) / pathLength;
        if (progress <= 0) {
          local.obvProgress[key] = 0;
        } else if (progress >= 1) {
          local.obvProgress[key] = 1;
        } else {
          local.obvProgress[key] = progress;
        }
      });
    },
  },
};
