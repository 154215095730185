<template>
  <div id="job-offers" class="section" style="padding-top: 100px">
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ jobsData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row v-if="jobsData.fields.description" class="mb-2">
      <v-col>
        <span class="body1">{{ jobsData.fields.description }}</span>
      </v-col>
    </v-row>

    <v-row
      class="section__content list-content mb-10 align-stretch justify-center justify-md-start"
    >
      <v-col
        v-for="(job, index) in jobsData.children"
        :key="index"
        cols=""
        sm="9"
        md="6"
        lg="4"
        class="flex-grow-0"
      >
        <div
          class="corporateBlue-lighten4 d-flex flex-column justify-space-between pa-5 pa-md-10 job-card"
        >
          <div class="mb-3 mb-md-6">
            <div class="subtitle2 corporateGrey--text">
              {{ job.fields.title }}
            </div>
            <div class="subtitle2 corporateGrey--text">
              ({{ job.fields.city }})
            </div>
          </div>
          <div>
            <v-btn
              :to="{ name: 'CareerJobOffer', params: { ident: job.node_name } }"
              color="primary"
              outlined
              x-large
              exact
            >
              {{ jobsData.fields.button }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "JointProjects",
  props: {
    jobsData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.job-card {
  height: 100%;
}
</style>
