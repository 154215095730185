<template>
  <div class="section">
    <v-row no-gutters class="section__title">
      <v-col>
        <span class="heading2">{{ historyData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <horizontal-scroller
      :items="historyChildrenReversed"
      :item-width="historyYearWidth"
      direction-of-items="rtl"
      has-control-buttons
    >
      <template #prepend_to_buttons>
        <div
          class="section__text corporateBlue-lighten4 pa-5 pa-md-10 cut-last-margin"
          v-html="historyData.fields.text"
        />
      </template>
      <template v-slot="{ item }">
        <company-history-year
          :year-data="item.fields"
          :terms="historyData.fields"
          :width="historyYearWidth"
        />
      </template>
    </horizontal-scroller>
  </div>
</template>

<script>
import CompanyHistoryYear from "./CompanyHistoryYear.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";

export default {
  name: "CompanyHistory",
  components: {
    CompanyHistoryYear,
    HorizontalScroller,
  },
  props: {
    historyData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    historyChildrenReversed() {
      const copy = [...this.historyData.children];
      return copy.reverse();
    },
    historyYearWidth() {
      return this.$vuetify.breakpoint.xs ? 230 : 270;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .section__text {
    max-width: 670px;
  }
}
</style>
