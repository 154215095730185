<template>
  <div v-if="pageLoaded">
    <heading :page-data="pageData" />

    <problems
      ref="refproblems"
      :section-data="pageData.ceir_problems"
      class="section"
    />

    <offer ref="refoffer" :section-data="pageData.ceir_offer" class="section" />

    <components :section-data="pageData.ceir_components" class="section" />

    <resolutions :section-data="pageData.ceir_resolution" class="section" />

    <solution :section-data="pageData.ceir_solution" class="section" />

    <advantages
      ref="refadvantages"
      :section-data="pageData.ceir_advantages"
      class="section"
    />

    <why :section-data="pageData.ceir_why" class="section" />

    <extension :section-data="pageData.ceir_extension" class="section" />

    <form-communication
      :form-id="pageData.products_page.links.feedback_form[0] || 0"
      :person-set="pageData.links.person || []"
    />

    <zuruck />
  </div>
</template>

<script>
import LandingRepository from "@/api/landing.js";
import FormCommunication from "@/components/FormCommunication.vue";
import Heading from "./Heading.vue";
import Problems from "./Problems.vue";
import Offer from "./Offer.vue";
import Components from "./Components.vue";
import Solution from "./Solution.vue";
import Advantages from "./Advantages.vue";
import Why from "./Why.vue";
import Extension from "./Extension.vue";
import Resolutions from "./Resolutions.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    Heading,
    Problems,
    Offer,
    Components,
    FormCommunication,
    Solution,
    Advantages,
    Why,
    Extension,
    Resolutions,
    Zuruck,
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        main_page: { fields: {} },
        products_page: { fields: {}, links: {} },
        ceir_problems: { fields: {}, children: [] },
        ceir_offer: { fields: {}, children: [] },
        ceir_components: { fields: {}, children: [] },
        ceir_solution: { fields: {}, children: [] },
        ceir_advantages: { fields: {}, children: [] },
        ceir_why: { fields: {}, children: [] },
        ceir_extension: { fields: {}, links: {} },
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await LandingRepository.getCeir();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
      this.$nextTick(() => {
        this.$refs.refoffer.initObserver();
        this.$refs.refproblems.initObserver();
        this.$refs.refadvantages.initObserver();
      });
    },
  },
};
</script>
