<template>
  <div class="motion-item corporateBlue--text">
    <svg
      class="motion-item__svg"
      :width="svgWidth"
      height="80"
      :viewBox="`0 0 ${svgWidth} 80`"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :style="svgStyle"
    >
      <g
        v-for="elementIndex in elementCount"
        :key="elementIndex"
        :transform="`translate(${
          (elementWidth + elementMargin) * (elementIndex - 1)
        } 0)`"
      >
        <path
          v-if="elementIndex % 2 > 0"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 45.20L0 80.20L70 80.207L70 45.20L52.20 45.20L35 28L17.80 45.20L0 45.20Z"
          fill="#4DABF5"
        />
        <path
          v-if="elementIndex % 2 > 0"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#4DABF5"
          :transform="`translate(0 ${itemProgress(elementIndex - 1) * 11})`"
          d="M0 35L0 0L70 0L70 35L52.20 35L35 17.80L17.80 35L0 35Z"
        />
        <path
          v-if="elementIndex % 2 == 0"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#4DABF5"
          d="M0 45.207L0 80.207L70 80.207L70 45.20L55.42 45.20C55.42 34 46.28 24.80 35 24.80C23.73 24.80 14.58 33.93 14.58 45.20L0 45.20Z"
        />
        <path
          v-if="elementIndex % 2 == 0"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#4DABF5"
          :transform="`translate(0 ${itemProgress(elementIndex - 1) * 11})`"
          d="M0 35.207L0 0.20L70 0.20L70 35.20L55.42 35.20C55.42 35.20 55.42 35.20 55.42 35.20C55.42 23.94 46.28 14.79 35 14.79C23.72 14.79 14.58 23.93 14.58 35.20C14.58 35.20 14.58 35.20 14.58 35.20L0 35.20Z"
        />
        <path
          fill="#EEF7FE"
          :fill-opacity="opacityProgress(elementIndex - 1)"
          transform="translate(0 10)"
          d="M31.90 43.73L23 34.84L25.50 32.34L31.90 38.70L45.38 25.20L47.90 27.73L31.90 43.73Z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "AdvantageMotion1",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      elementWidth: 70,
      elementMargin: 20,
      elementDelay: 0.11,
      elementCount: 9,
    };
  },
  computed: {
    itemProgressApp() {
      return (index) => {
        return Math.round(this.itemProgress(index) * 100) / 100;
      };
    },
    itemProgress() {
      return (index) => {
        const newProgress =
          (this.progress - this.elementDelay * index) /
          (1 - this.elementDelay * (this.elementCount - 1));
        if (newProgress < 0) return 0;
        if (newProgress > 1) return 1;
        return newProgress;
      };
    },
    opacityProgress() {
      return (index) => {
        if (this.itemProgress(index) < 0.5) return 0;
        return (this.itemProgress(index) - 0.5) * 2;
      };
    },
    svgStyle() {
      const left =
        -(this.elementWidth + this.elementMargin * 2) * this.progress -
        this.svgWidth / 2;
      return {
        "margin-left": `${left}px`,
      };
    },
    svgWidth() {
      return (this.elementWidth + this.elementMargin) * this.elementCount;
    },
  },
};
</script>
<style scoped lang="scss">
.motion-item {
  position: relative;
  .motion-item__svg {
    position: relative;
    left: 50%;
  }
}
</style>
