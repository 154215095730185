<template>
  <div class="section">
    <div class="d-flex justify-center">
      <div class="px-5 py-5 image-shadow">
        <v-img :src="homegrownData.fields.image" eager max-width="80px" />
      </div>
    </div>
    <div class="d-flex justify-center text-center mt-8">
      <div style="max-width: 770px">
        <div class="subtitle2">
          {{ homegrownData.fields.title }}
        </div>
        <div class="body1 mt-2 mt-md-4" v-html="homegrownData.fields.text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    homegrownData: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.image-shadow {
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(33, 150, 243, 0.05),
    0px 3px 12px rgba(0, 133, 238, 0.1);
}
</style>
