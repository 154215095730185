<template>
  <div>
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ galleryData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <component
      :is="componentsList[blockData.class_name]"
      v-for="(blockData, index) in galleryData.children"
      :key="index"
      :block-data="blockData"
    />
  </div>
</template>
<script>
import Gallery3BlockLeft from "./Gallery3BlockLeft.vue";
import Gallery3BlockRight from "./Gallery3BlockRight.vue";
import Gallery6BlockLeft from "./Gallery6BlockLeft.vue";

export default {
  name: "Gallery",

  components: { Gallery3BlockLeft, Gallery3BlockRight, Gallery6BlockLeft },

  props: {
    galleryData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      componentsList: {
        career_life_3b_left: Gallery3BlockLeft,
        career_life_3b_right: Gallery3BlockRight,
        career_life_6b_left: Gallery6BlockLeft,
      },
    };
  },
};
</script>
