<template>
  <div class="section">
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ teamData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" class="col-xxl-9">
        <team-charts ref="chart" :charts-categories="teamData.children" />

        <v-img
          :src="teamData.fields.structure"
          eager
          class="d-none d-md-block mb-8 mb-md-16"
          @load="$emit('trigger-resize')"
        />

        <team-hr-form
          v-if="hasHr"
          :team-fields="teamData.fields"
          :team-links="teamData.links || {}"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TeamCharts from "./TeamCharts.vue";
import TeamHrForm from "./TeamHrForm.vue";

export default {
  name: "Team",
  components: {
    TeamCharts,
    TeamHrForm,
  },
  props: {
    teamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    hasHr() {
      return (
        this.teamData.links.person && this.teamData.links.person.length > 0
      );
    },
  },
  methods: {
    refreshChart() {
      if (this.$refs.chart) this.$refs.chart.refreshChart();
    },
  },
};
</script>
