<template>
  <div class="statistics-title">
    <div class="statistics-title__title accent-lg text-center">
      {{ title }}
    </div>
    <div class="statistics-title__radarok" />
  </div>
</template>

<script>
export default {
  name: "StatisticsTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.statistics-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 184px;
  margin-bottom: 100px;

  @media (min-width: 640px) {
    min-height: 350px;
  }

  @media (min-width: 1024px) {
    min-height: 450px;
    margin-bottom: 200px;
  }

  .statistics-title__radarok {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 184px;
    height: 184px;
    margin-top: -92px;
    margin-left: -92px;
    border-radius: 50%;
    background: conic-gradient(
      from 90deg at 50% 50%,
      rgba(33, 150, 243, 0) 0deg,
      #2196f3 359.96deg,
      rgba(33, 150, 243, 0) 360deg
    );
    animation-name: statistics-title-radarok;
    animation-delay: 0s;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: 1;

    @media (min-width: 640px) {
      width: 350px;
      height: 350px;
      margin-top: -175px;
      margin-left: -175px;
    }

    @media (min-width: 1024px) {
      width: 450px;
      height: 450px;
      margin-top: -225px;
      margin-left: -225px;
    }
  }

  .statistics-title__title {
    position: relative;
    z-index: 2;
  }
}

@keyframes statistics-title-radarok {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
</style>
