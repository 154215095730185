<template>
  <div class="section">
    <v-row>
      <v-col class="page__title">
        <h1>{{ $t("Common.searchPage.title") }}</h1>
      </v-col>
    </v-row>

    <v-row class="mb-4 mb-md-8">
      <v-col cols="12" md="9" lg="8" xl="6" class="col-xxl-5">
        <v-text-field
          v-model="term"
          clearable
          clear-icon="mdi-close-circle"
          class="align-center"
          hide-details
          height="45"
          @change="onInputChange"
          @blur="onInputBlur"
          @click:clear="onInputClear"
        >
          <template v-slot:prepend-inner>
            <v-btn icon @click="onInputChange">
              <v-icon class="mx-2">$vuetify.icons.search</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="searchData.results.length > 0">
      <v-col cols="12" lg="10" class="col-xxl-7">
        <div
          v-for="(item, index) in searchData.results"
          :key="index"
          class="mb-10 mb-md-10"
        >
          <div class="mb-1 mb-md-3">
            <router-link
              :to="getResultLink(item)"
              class="subtitle1 search-link"
            >
              <div v-html="getItemHeader(item)" />
            </router-link>
          </div>

          <div
            class="search-quote body2 mb-1 mb-md-3"
            v-html="item.quote.best_quote"
          />

          <div v-if="item.quote.cnt_more > 0" class="body3 textLight--text">
            {{ $tc("Common.searchPage.matches", item.quote.cnt_more) }}
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-else-if="searchData.requested.length > 0">
      <v-col>{{
        $t("Common.searchPage.notfound1", { term: searchData.requested })
      }}</v-col>
    </v-row>

    <v-row
      v-if="hasNews && searchData.results.length == 0"
      class="mb-7 mb-md-14 mt-0"
    >
      <v-col>{{ $t("Common.searchPage.notfound2") }}</v-col>
    </v-row>

    <three-previews-search
      v-if="hasNews && searchData.results.length == 0"
      :title="searchData.more_news.fields.title || ''"
      :button-title="searchData.more_news.fields.button_all || ''"
      :to="{ name: 'NewsList' }"
      class="section"
    >
      <template v-slot:first>
        <preview
          :preview-info="newsItem(0)"
          bigger-xxl
          bigger-xl
          bigger-md
          @tagclick="goNewsTag"
          @previewclick="goNewsItem"
        />
      </template>

      <template v-slot:second>
        <preview
          :preview-info="newsItem(1)"
          @tagclick="goNewsTag"
          @previewclick="goNewsItem"
        />
      </template>

      <template v-slot:third>
        <preview
          :preview-info="newsItem(2)"
          @tagclick="goNewsTag"
          @previewclick="goNewsItem"
        />
      </template>
    </three-previews-search>
  </div>
</template>

<script>
import ThreePreviewsSearch from "@/components/Preview/ThreePreviewsSearch.vue";
import Preview from "@/components/Preview/Preview.vue";
import SupplementRepository from "@/api/supplement.js";
import upperFirst from "lodash/upperFirst";
import kebabCase from "lodash/kebabCase";

const searchDataFactory = () => ({
  results: [],
  more_news: {
    fields: {},
    children: [],
  },
  requested: "",
});

export default {
  name: "Search",

  components: {
    ThreePreviewsSearch,
    Preview,
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.$t("Common.searchPage.title")
      ),
    };
  },

  data() {
    return {
      term: "",
      searchData: searchDataFactory(),
    };
  },

  computed: {
    hasNews() {
      return (
        this.searchData.more_news.children &&
        this.searchData.more_news.children.length > 0
      );
    },

    newsItem() {
      return (index) => this.searchData.more_news.children[index] || {};
    },

    getResultLink() {
      return (item) => {
        if (item.class_name.match(/^landing_/)) {
          const landingType = upperFirst(
            kebabCase(item.class_name.replace(/^landing_/, ""))
          );
          return { name: `Landing${landingType}` };
        }
        switch (item.class_name) {
          case "product":
            return { name: "Product", params: { ident: item.node_name } };
          case "news":
            return { name: "NewsItem", params: { id: item.id } };
          case "career_job_opened":
            return {
              name: "CareerJobOffer",
              params: { ident: item.node_name },
            };
          default:
            return { name: "Main" };
        }
      };
    },

    getItemHeader() {
      return (item) => {
        const type = item.class_name.match(/^landing_/)
          ? "product"
          : item.class_name;
        const term = this.$t(`Common.searchPage.classes.${type}`);
        return `${item.fields.title} <span class="item-typ">(${term})</span>`;
      };
    },
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.onInputChange();
      }
    },

    "$store.getters.getAppSearch"(newV) {
      this.term = newV;
      this.onInputChange();
    },
  },

  mounted() {
    this.term = this.$store.getters.getAppSearch;
    this.onInputChange();
  },

  methods: {
    async onInputChange() {
      if (this.term && this.term.length > 0) {
        try {
          const { data } = await SupplementRepository.search(this.term);
          this.searchData = data;
        } catch (error) {
          if (error.response && error.response.status == 422) {
            this.searchData = searchDataFactory();
          }
        }
      } else {
        this.searchData = searchDataFactory();
      }
      this.$emit("page-loaded");
    },

    onInputBlur() {
      if (!(this.term && this.term.length > 0)) {
        this.searchData = searchDataFactory();
      }
    },

    onInputClear() {
      this.term = "";
      this.onInputChange();
    },

    goNewsTag(tag) {
      this.$router.push({ name: "NewsList", params: { tag } });
    },

    goNewsItem(id) {
      this.$router.push({ name: "NewsItem", params: { id } });
    },
  },
};
</script>
<style scoped lang="scss">
.search-link {
  color: var(--v-textBase-base);

  ::v-deep .item-typ {
    color: var(--v-primary-base);
  }
}
</style>
