<template>
  <div>
    <article-with-contents
      v-if="pageLoaded"
      ref="refarticle"
      :contents="career_job.fields.contents"
      :breadcrumbs="breadcrumbsItems"
      class="section"
    >
      <v-row class="mb-2">
        <v-col>
          <h1>{{ career_job.fields.title }}</h1>
        </v-col>
      </v-row>
      <article>
        <kk-dynamic-html :html="career_job.fields.full_text" />
      </article>
    </article-with-contents>

    <form-communication
      :form-id="
        pageData.career_page.career_jobs_opened.links.feedback_form[0] || 0
      "
    />
  </div>
</template>

<script>
import CareerRepository from "@/api/career.js";
import FormCommunication from "@/components/FormCommunication.vue";
import ArticleWithContents from "@/components/Article/ArticleWithContents.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "CareerJobOffer",

  components: { FormCommunication, ArticleWithContents, KkDynamicHtml },

  props: {
    ident: {
      type: String,
      required: true,
    },
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.career_job.fields.title),
    };
  },

  data() {
    return {
      pageLoaded: false,
      pageData: {
        main_page: { fields: {} },
        career_page: {
          fields: {},
          career_jobs_opened: {
            fields: {},
            links: { feedback_form: [] },
            career_job_opened: { fields: {} },
          },
        },
      },
    };
  },

  computed: {
    career_job() {
      return this.pageData.career_page.career_jobs_opened.career_job_opened;
    },

    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_page.fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.career_page.fields.title || "",
          to: { name: "Career" },
          exact: true,
          disabled: false,
        },
        {
          text: this.career_job.fields.title || "",
          disabled: true,
        },
      ];
    },
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },

  mounted() {
    this.loadPage();
  },

  methods: {
    async loadPage() {
      const { data } = await CareerRepository.getCareerJobOffer(this.ident);
      this.pageData = data;
      this.pageLoaded = true;
      this.$store.commit("setLangsAvail", this.career_job.published);
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
