<template>
  <v-row class="justify-center justify-md-space-between section">
    <v-col
      v-for="(statItem, index) in statData.children"
      :key="index"
      cols="12"
      md=""
      class="flex-grow-0"
      style="z-index: 2; min-width: 250px"
    >
      <v-row no-gutters class="justify-center justify-md-start">
        <v-col class="text-left" style="max-width: 300px">
          <img
            :src="statItem.fields.image_amount"
            max-height="96"
            class="d-block"
            alt=""
            @load="$emit('trigger-resize')"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-center justify-md-start mt-0">
        <div
          class="col text-left"
          style="max-width: 300px"
          v-html="statItem.fields.detail"
        />
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "WorkStatistics",

  props: {
    statData: {
      type: Object,
      required: true,
    },
  },
};
</script>
