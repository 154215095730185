<template>
  <v-row class="align-stretch">
    <v-col cols="12" lg="5">
      <product-card :product-id="favProducts[0] || 0" style="height: 100%" />
    </v-col>
    <v-col cols="12" lg="7">
      <v-row>
        <v-col cols="12">
          <product-card :product-id="favProducts[1] || 0" />
        </v-col>
        <v-col cols="12">
          <product-card :product-id="favProducts[2] || 0" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ProductCard from "@/components/ProductCard/ProductCard.vue";
export default {
  components: {
    ProductCard,
  },
  props: {
    favProducts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
