import Repository from "@/plugins/axios";

export default {
  // Получить соглашение об использовании персональной информации
  getPrivacyPolicy() {
    return Repository.get("/privacy-policy");
  },
  // Искать на сайте по строке
  search(term) {
    return Repository.get("/search", { params: { term } });
  },
  feedback(params) {
    return Repository.post("/fhtagn", params);
  },
  subscribe(params) {
    return Repository.post("/subscribe", params);
  },
  presentation(params) {
    return Repository.post("/rlyeh", params);
  },
};
