<template>
  <div>
    <v-row
      class="section heading__section corporateGrey align-center justify-center"
    >
      <v-col cols="12" md="" class="heading__content mr-md-4 mr-lg-8 mr-xl-16">
        <breadcrumbs dark class="no-marker px-0" :items="breadcrumbsItems" />
        <div class="mb-1 mb-md-2">
          <badge
            v-for="(categoryItem, index) in categoriesList"
            :key="index"
            :text="categoryItem.title"
            :color="categoryItem.color"
          />
        </div>
        <h1 class="white--text mb-4 mb-md-8">
          {{ mpData.fields.title || "" }}
        </h1>
        <div
          class="white--text mb-4 mb-md-8"
          v-html="mpData.fields.full_text"
        />
        <masked-icon-link
          v-if="(mpData.fields.catalog || '').length > 0"
          :mask-icon="`${baseUrl}img/pdf.svg`"
          :title="mpData.fields.catalog_button || ''"
          :href="mpData.fields.catalog || ''"
          color="var(--v-multiplatform-base)"
        />
      </v-col>
      <v-col
        cols="12"
        md=""
        class="heading__cover-container flex-grow-0 ml-md-4 ml-lg-8 ml-xl-16"
      >
        <div class="heading__cover">
          <div class="heading__cover-paint" />
        </div>
        <v-img :src="mpData.fields.image_lg" max-width="570" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Article/Breadcrumbs.vue";
import Badge from "@/components/Badge.vue";
import MaskedIconLink from "@/components/MaskedIconLink.vue";

export default {
  components: {
    Breadcrumbs,
    Badge,
    MaskedIconLink,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    mpData() {
      return this.pageData.products_page.landing_multiplatform;
    },
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_page.fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.products_page.fields.title || "",
          to: { name: "Products" },
          exact: true,
          disabled: false,
        },
        {
          text: this.mpData.fields.title || "",
          disabled: true,
        },
      ];
    },
    categoriesList() {
      if (this.mpData.links.product_category) {
        return this.mpData.links.product_category
          .map((categoryId) =>
            this.$store.getters.getProductCategory(categoryId)
          )
          .filter((category) => category !== undefined);
      } else {
        return [];
      }
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./Heading.scss";
</style>
