<template>
  <div>
    <v-row class="section__title-row offer-child">
      <v-col class="text-center">
        <span class="heading1">{{ sectionData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="section__content align-start">
      <v-col cols="12" lg="6">
        <product-card :product-id="product(0) || 0" />
      </v-col>
      <v-col cols="12" lg="6">
        <v-row>
          <v-col cols="12">
            <product-card :product-id="product(1) || 0" />
          </v-col>
          <v-col cols="12">
            <product-card :product-id="product(2) || 0" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard/ProductCard.vue";
export default {
  name: "Extension",
  components: { ProductCard },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    product() {
      return (index) => (this.sectionData.links.product || [])[index];
    },
  },
};
</script>
<style scoped lang="scss"></style>
