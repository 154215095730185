import Repository from "@/plugins/axios";

export default {
  // Получить список продуктов, разбитый на категории
  getProductsList() {
    return Repository.get("/products");
  },

  // Получить полную информацию о продукте
  // productName -- это поле node_name продукта
  getProductItem(productName) {
    return Repository.get(`/products/${productName}`);
  },
};
