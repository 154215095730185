<template>
  <div id="heading-dms-digest">
    <v-row class="section heading__section justify-center">
      <v-col cols="12" class="heading__content heading-dms-digest">
        <div class="heading-dms-digest-header text-center mx-n4">
          {{ pageData.fields.title || "" }}
        </div>
        <div class="subheading-popup">
          <div class="heading-dms-digest-subheader text-center mb-5 mb-md-8">
            <v-img
              eager
              class="device-1"
              :src="pageData.fields.image_top_left"
              :style="getSolutionStyle()"
              @load="$emit('trigger-resize')"
            />
            <h1 class="heading-dms-digest-subheader">
              {{ pageData.fields.subtitle || "" }}
            </h1>
            <v-img
              eager
              class="device-2"
              :src="pageData.fields.image_top_right"
              :style="getSolutionStyle(true)"
              @load="$emit('trigger-resize')"
            />
          </div>
          <div
            class="annotation-dms-digest text-center mb-5 mb-md-8"
            v-html="pageData.fields.annotation"
          />

          <v-row
            class="justify-center"
            style="position: relative; flex: 0 0 auto"
          >
            <v-col align="center">
              <v-img
                eager
                class="device-3"
                :src="pageData.fields.image_bottom_left"
                :style="getSolutionStyle()"
                @load="$emit('trigger-resize')"
              />
              <v-btn
                depressed
                x-large
                color="primary"
                class="mobile-button-width"
                @click="$emit('scroll-to-trust')"
              >
                {{ pageData.fields.button_trust }}
              </v-btn>
              <v-btn
                depressed
                outlined
                x-large
                color="primary"
                class="mobile-button-width"
                @click="$emit('scroll-to-special-offer')"
              >
                {{ pageData.fields.button_special_offer }}
              </v-btn>
              <v-img
                eager
                class="device-4"
                :src="pageData.fields.image_bottom_right"
                :style="getSolutionStyle(true)"
                @load="$emit('trigger-resize')"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <div id="heading-hidden-block" style="position: absolute; top: 100vh"></div>
  </div>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
export default {
  name: "Heading",
  mixins: [ProgressObserverMixin],
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
    getSolutionStyle() {
      return (rightMode = false) => {
        const progress = this.obv(`heading-hidden-block`);
        if (rightMode) {
          const right = `${-100 * progress}%`;
          return {
            right,
          };
        } else {
          const left = `${-100 * progress}%`;
          return {
            left,
          };
        }
      };
    },
  },
  mounted() {
    this.obvAddElement(`heading-hidden-block`);
  },
};
</script>
<style lang="scss" scoped>
#heading-dms-digest .section {
  margin-bottom: 160px;
}
.heading-dms-digest {
  margin-top: 0px;
}
.heading-dms-digest-header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;

  color: var(--v-primary-base);

  animation-delay: 0s;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  animation-name: heading-popup;
}
.heading-dms-digest-subheader {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;

  color: var(--v-corporateGrey-base);
}
.annotation-dms-digest {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;

  color: var(--v-corporateGrey-base);

  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.mobile-button-width {
  margin-bottom: 10px;
}
.device-1,
.device-2,
.device-3,
.device-4 {
  max-width: 30vw;
  position: absolute;
  animation-delay: 0s;
  animation-duration: 3.6s;
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
.device-1 {
  top: 0;
  left: 0;
  transform: translate(-80%, -10%);
  animation-name: device-1;
}
.device-2 {
  top: 0;
  right: 0;
  transform: translate(80%, -10%);
  animation-name: device-2;
}
.device-3 {
  bottom: 0;
  left: 0;
  transform: translate(-50%, 100%);
  animation-name: device-3;
}
.device-4 {
  bottom: 0;
  right: 0;
  transform: translate(60%, 90%);
  animation-name: device-4;
}
.subheading-popup {
  animation-delay: 0s;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  animation-name: subheading-popup;
}
@keyframes heading-popup {
  0% {
    opacity: 0;
    transform: translateY(200%);
    font-size: 48px;
  }
  55% {
    opacity: 1;
    transform: translateY(50%);
    font-size: 48px;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    font-size: 40px;
  }
}
@keyframes subheading-popup {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  55% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes device-1 {
  0% {
    transform: translate(-220%, -300%);
  }
  55% {
    transform: translate(-220%, -300%);
  }
  100% {
    transform: translate(-80%, -10%);
  }
}
@keyframes device-2 {
  0% {
    transform: translate(220%, -300%);
  }
  55% {
    transform: translate(220%, -300%);
  }
  100% {
    transform: translate(80%, -10%);
  }
}
@keyframes device-3 {
  0% {
    transform: translate(-220%, 300%);
  }
  55% {
    transform: translate(-220%, 300%);
  }
  100% {
    transform: translate(-50%, 100%);
  }
}
@keyframes device-4 {
  0% {
    transform: translate(220%, 300%);
  }
  55% {
    transform: translate(220%, 300%);
  }
  100% {
    transform: translate(60%, 90%);
  }
}
@media (min-width: 640px) {
  #heading-dms-digest .section {
    margin-bottom: 225px;
  }
  .heading-dms-digest {
    margin-top: 40px;
  }
  .heading-dms-digest-header {
    font-size: 40px;
    line-height: 48px;
  }
  .heading-dms-digest-subheader {
    font-size: 32px;
    line-height: 39px;
  }
  .annotation-dms-digest {
    font-size: 16px;
    line-height: 21px;
  }
  @keyframes heading-popup {
    0% {
      opacity: 0;
      transform: translateY(200%);
      font-size: 60px;
    }
    55% {
      opacity: 1;
      transform: translateY(50%);
      font-size: 60px;
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
      font-size: 40px;
    }
  }
  .device-1 {
    transform: translate(-120%, -40%);
  }
  .device-2 {
    transform: translate(120%, -40%);
  }
  .device-3 {
    transform: translate(-100%, 100%);
  }
  .device-4 {
    transform: translate(100%, 100%);
  }
  @keyframes device-1 {
    0% {
      transform: translate(-220%, -300%);
    }
    55% {
      transform: translate(-220%, -300%);
    }
    100% {
      transform: translate(-120%, -40%);
    }
  }
  @keyframes device-2 {
    0% {
      transform: translate(220%, -300%);
    }
    55% {
      transform: translate(220%, -300%);
    }
    100% {
      transform: translate(120%, -40%);
    }
  }
  @keyframes device-3 {
    0% {
      transform: translate(-220%, 300%);
    }
    55% {
      transform: translate(-220%, 300%);
    }
    100% {
      transform: translate(-100%, 100%);
    }
  }
  @keyframes device-4 {
    0% {
      transform: translate(220%, 300%);
    }
    55% {
      transform: translate(220%, 300%);
    }
    100% {
      transform: translate(100%, 100%);
    }
  }
}
@media (min-width: 1024px) {
  #heading-dms-digest .section {
    margin-bottom: 445px;
  }
  .heading-dms-digest {
    margin-top: 46px;
  }
  .heading-dms-digest-header {
    font-size: 128px;
    line-height: 156px;
  }
  .heading-dms-digest-subheader {
    font-size: 96px;
    line-height: 117px;
  }
  .annotation-dms-digest {
    font-size: 32px;
    line-height: 40px;
  }
  .mobile-button-width {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
  }
  .device-1 {
    transform: translate(-100%, -100%);
    animation-name: device-1;
  }
  .device-2 {
    transform: translate(100%, -100%);
    animation-name: device-2;
  }
  @keyframes device-1 {
    0% {
      transform: translate(-220%, -300%);
    }
    55% {
      transform: translate(-220%, -300%);
    }
    100% {
      transform: translate(-100%, -100%);
    }
  }
  @keyframes device-2 {
    0% {
      transform: translate(220%, -300%);
    }
    55% {
      transform: translate(220%, -300%);
    }
    100% {
      transform: translate(100%, -100%);
    }
  }
  @keyframes heading-popup {
    0% {
      opacity: 0;
      transform: translateY(200%);
      font-size: 200px;
    }
    55% {
      opacity: 1;
      transform: translateY(25%);
      font-size: 200px;
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
      font-size: 128px;
    }
  }
}
@media (min-width: 1280px) {
  .heading-dms-digest {
    margin-top: 80px;
  }
  .heading-dms-digest-header {
    font-size: 128px;
    line-height: 156px;
  }
  .heading-dms-digest-subheader {
    font-size: 96px;
    line-height: 117px;
  }
  .annotation-dms-digest {
    font-size: 32px;
    line-height: 40px;

    max-width: 70%;
  }
  .device-1 {
    transform: translate(-80%, -100%);
  }
  .device-2 {
    transform: translate(80%, -100%);
  }
  .device-3 {
    transform: translate(-40%, 90%);
  }
  .device-4 {
    transform: translate(40%, 90%);
  }
  @keyframes device-1 {
    0% {
      transform: translate(-220%, -300%);
    }
    55% {
      transform: translate(-220%, -300%);
    }
    100% {
      transform: translate(-80%, -100%);
    }
  }
  @keyframes device-2 {
    0% {
      transform: translate(220%, -300%);
    }
    55% {
      transform: translate(220%, -300%);
    }
    100% {
      transform: translate(80%, -100%);
    }
  }
  @keyframes device-3 {
    0% {
      transform: translate(-220%, 300%);
    }
    55% {
      transform: translate(-220%, 300%);
    }
    100% {
      transform: translate(-40%, 90%);
    }
  }
  @keyframes device-4 {
    0% {
      transform: translate(220%, 300%);
    }
    55% {
      transform: translate(220%, 300%);
    }
    100% {
      transform: translate(40%, 90%);
    }
  }
  @keyframes heading-popup {
    0% {
      opacity: 0;
      transform: translateY(200%);
      font-size: 152px;
    }
    55% {
      opacity: 1;
      transform: translateY(50%);
      font-size: 152px;
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
      font-size: 128px;
    }
  }
}
@media (min-width: 1440px) {
  .heading-dms-digest {
    margin-top: 80px;
  }
  .heading-dms-digest-header {
    font-size: 128px;
    line-height: 156px;
  }
  .heading-dms-digest-subheader {
    font-size: 96px;
    line-height: 117px;
  }
  .annotation-dms-digest {
    font-size: 32px;
    line-height: 40px;
  }
  .device-3 {
    transform: translate(-60%, 90%);
  }
  .device-4 {
    transform: translate(60%, 90%);
  }
  @keyframes device-3 {
    0% {
      transform: translate(-220%, 300%);
    }
    55% {
      transform: translate(-220%, 300%);
    }
    100% {
      transform: translate(-60%, 90%);
    }
  }
  @keyframes device-4 {
    0% {
      transform: translate(220%, 300%);
    }
    55% {
      transform: translate(220%, 300%);
    }
    100% {
      transform: translate(60%, 90%);
    }
  }
  @keyframes heading-popup {
    0% {
      opacity: 0;
      transform: translateY(200%);
      font-size: 168px;
    }
    55% {
      opacity: 1;
      transform: translateY(50%);
      font-size: 168px;
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
      font-size: 128px;
    }
  }
}
@media (min-width: 1920px) {
  .heading-dms-digest {
    margin-top: 150px;
  }
  .heading-dms-digest-header {
    font-size: 128px;
    line-height: 156px;
  }
  .heading-dms-digest-subheader {
    font-size: 96px;
    line-height: 117px;
  }
  .annotation-dms-digest {
    font-size: 32px;
    line-height: 40px;
  }
  @keyframes heading-popup {
    0% {
      opacity: 0;
      transform: translateY(200%);
      font-size: 216px;
    }
    55% {
      opacity: 1;
      transform: translateY(50%);
      font-size: 216px;
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
      font-size: 128px;
    }
  }
}
</style>
