const ChartResponsiveConfigFactory = () => ({
  getColor() {
    return [
      "#3E89C4",
      "#A6D5FA",
      "#F5C14D",
      "#F56D4D",
      "#4DABF5",
      "#FAB6A6",
      "#FAE0A6",
    ];
  },

  getConfig(template, options, breakpointName) {
    const funcTerm = template.charAt(0).toUpperCase() + template.slice(1);
    return this[`template${funcTerm}`](options, breakpointName);
  },

  templatePie(options, breakpointName) {
    const fontSize = ["xs", "sm"].includes(breakpointName) ? 14 : 18;
    const lineHeight = fontSize * 1.5;
    const edgeDistance = 10;

    return {
      selectedMode: false,
      selectedOffset: 0,
      series: [
        {
          name: options.title,
          color: this.getColor(),
          type: "pie",
          radius: ["53%", "70%"],
          grid: {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
          },
          startAngle: options.startAngle || 135,
          label: {
            alignTo: "edge",
            fontFamily: "Open Sans",
            fontSize,
            lineHeight,
            minMargin: 5,
            edgeDistance,
            backgroundColor: "transparent",
            formatter: "{b}\n{pctFormat|{c}%}",
            rich: {
              pctFormat: {
                fontSize,
                color: "#8E9094",
              },
            },
          },
          labelLayout: function (params) {
            const isLeft = params.labelRect.x < edgeDistance * 2;
            const points = params.labelLinePoints;
            // Update the end point.
            points[2][0] = isLeft
              ? params.labelRect.x
              : params.labelRect.x + params.labelRect.width;
            return {
              labelLinePoints: points,
            };
          },
          labelLine: {
            length: 15,
            length2: 0,
            maxSurfaceAngle: 80,
            lineStyle: {
              color: "#D8E0E6",
              opacity: 1,
            },
          },
          data: options.series.map((item) => {
            return {
              name: item.title,
              value: item.val,
            };
          }),
        },
      ],
    };
  },

  templateBars(options, breakpointName) {
    const breakWordz = ["xs", "sm"].includes(breakpointName);
    const fontSize = ["xs", "sm"].includes(breakpointName) ? 12 : 14;
    const titleField = ["xs"].includes(breakpointName)
      ? "title_short"
      : "title";
    const lineHeight = fontSize * 1.5;

    const series = [];
    const stackBottomIndexes = {};
    const indexedSeries = this.indexSeriesForBars(
      options.series,
      options.zones
    );

    Object.entries(indexedSeries).forEach((entryYear) => {
      const year = entryYear[0];
      Object.entries(entryYear[1]).forEach((entryKey, entryIndex) => {
        series.push(entryKey[1]);
        if (entryIndex == 0) {
          const breakingIndex = series.length - 1;
          stackBottomIndexes[`ind${breakingIndex}`] = year;
        }
      });
    });

    const labelOption = {
      show: true,
      formatter: function (params) {
        const yearKey = stackBottomIndexes[`ind${params.seriesIndex}`] || "";
        const yearRec = options.years.find((rec) => rec.id == yearKey);
        return yearRec ? yearRec.title : "";
      },
      fontSize,
      lineHeight,
      rich: {
        name: {},
      },
    };

    series.forEach((serie) => {
      serie.label = labelOption;
    });

    return {
      grid: {
        left: 0,
        top: 45,
        right: 0,
        bottom: 64,
      },
      legend: {
        bottom: 0,
        icon: "circle",
        textStyle: {
          fontFamily: "Open Sans",
          fontSize,
          lineHeight,
          color: "#263238",
        },
      },
      tooltip: {
        trigger: "item",
        formatter: "{a}<br /><b>{c0}%</b>",
      },
      xAxis: [
        {
          type: "category",
          position: "top",
          data: options.zones.map((zone) =>
            breakWordz
              ? zone[titleField].replace(/\s+/, "\n")
              : zone[titleField]
          ),
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            fontFamily: "Open Sans",
            fontSize,
            lineHeight,
            color: "#404040",
          },
        },
      ],
      yAxis: {
        show: false,
      },
      color: this.getColor(),
      series,
    };
  },

  indexSeriesForBars(series, zones) {
    const indexedYearsAndKeys = {};
    Object.entries(series).forEach((entryZone, zoneIndex) => {
      Object.entries(entryZone[1]).forEach((entryYear) => {
        const year = entryYear[0];
        if (indexedYearsAndKeys[year] === undefined) {
          indexedYearsAndKeys[year] = {};
        }
        entryYear[1].forEach((pair) => {
          if (indexedYearsAndKeys[year][pair.title] == undefined) {
            indexedYearsAndKeys[year][pair.title] = {
              name: pair.title,
              type: "bar",
              stack: year,
              barGap: 0.1,
              labelLayout: {
                y: "100%",
                dy: -36,
                verticalAlign: "bottom",
                align: "center",
              },
              data: new Array(zones.length).fill("0"),
            };
          }
          indexedYearsAndKeys[year][pair.title].data[zoneIndex] = pair.val;
        });
      });
    });
    return indexedYearsAndKeys;
  },
});

export default ChartResponsiveConfigFactory;
