<template>
  <div
    id="pilot"
    class="section"
    style="padding-top: 100px; margin-top: -100px"
  >
    <div class="heading1 text-center" style="z-index: 2; position: relative">
      {{ pilotData.fields.title || "" }}
      <span style="color: var(--v-primary-base)">
        {{ pilotData.fields.title_accent }}
      </span>
      {{ pilotData.fields.title_after_accent }}
    </div>
    <div
      class="subtitle2 text-center mt-4 mt-lg-6"
      style="z-index: 2; position: relative"
    >
      {{ pilotData.fields.subtitle || "" }}
    </div>
    <v-row class="mt-4 mt-lg-12">
      <v-col style="z-index: 2">
        <div class="subtitle1">
          {{ pilotData.fields.org_title || "" }}
        </div>
        <div class="body2 mt-2 mt-lg-2">
          {{ pilotData.fields.org_subtitle || "" }}
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-4 mt-lg-12">
      <v-col cols="12" lg="6" style="z-index: 2">
        <v-row
          v-for="(children, index) in pilotData.children"
          :key="index"
          dense
          :class="{ 'mt-6 mt-lg-12': index != 0 }"
        >
          <v-col class="flex-grow-0 pr-5">
            <ordered-list-number :index="index" />
          </v-col>
          <v-col align-self="center" style="z-index: 2">{{
            children.fields.title
          }}</v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        align-self="center"
        class="offset-lg-2"
        style="position: relative"
      >
        <orbits-static
          class="d-none d-lg-block pilot__orbits"
          style="opacity: 0.5"
          :count="4"
          :sizes="[300, 420, 560, 760]"
        />
        <div
          class="pilot__orbits"
          style="
            position: absolute;
            z-index: 1;
            box-shadow: inset 230px 0px 40px 90px #ffffff;
          "
        ></div>
        <v-img :src="pilotData.fields.image" eager />
      </v-col>
    </v-row>
    <v-row
      class="justify-center mt-lg-6 mt-4"
      style="position: relative; z-index: 2"
    >
      <v-col align="center">
        <v-btn depressed x-large color="primary" @click="showDialog = true">
          {{ pilotData.fields.button_title }}
        </v-btn>
      </v-col>
    </v-row>

    <form-communication-modal v-model="showDialog" />
  </div>
</template>

<script>
import OrderedListNumber from "@/components/OrderedListNumber.vue";
import OrbitsStatic from "@/components/OrbitsStatic.vue";
import FormCommunicationModal from "../../../components/FormCommunicationModal.vue";
export default {
  components: {
    OrderedListNumber,
    OrbitsStatic,
    FormCommunicationModal,
  },
  props: {
    pilotData: { type: Object, required: true },
  },
  data() {
    return {
      showDialog: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.pilot__orbits {
  width: 700%;
  height: 200%;
  z-index: 0;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
