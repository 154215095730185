<template>
  <div class="section">
    <title-with-button
      v-if="withTitle"
      :title="favData.fields.title || ''"
      :button-title="favData.fields.button_products || ''"
      :to="{ name: 'ProductsAll' }"
    />
    <v-component
      :is="getTemplate(favRow.template)"
      v-for="(favRow, index) in favData.children"
      :key="index"
      :fav-products="favRow.products"
    />
    <v-row v-if="withFooter && favData.fields.footer_button">
      <v-col>
        <v-row no-gutters class="product-fav-footer text-center">
          <div class="product-orbits">
            <div class="product-orbit product-orbit__mercury" />
            <div class="product-orbit product-orbit__venus" />
            <div class="product-orbit product-orbit__earth" />
            <div class="product-orbit product-orbit__mars" />
          </div>
          <v-col class="pa-10 product-fav-footer__content">
            <p class="subtitle1 corporateGrey--text">
              {{ favData.fields.footer_title }}
            </p>
            <p class="subtitle2">{{ favData.fields.footer_subtitle }}</p>
            <p class="pb-3">
              <badge
                v-for="(category, index) in categories"
                :key="index"
                :text="category.title"
                :color="category.color"
              />
            </p>
            <p>
              <router-link :to="{ name: 'ProductsAll' }" class="btn-text">
                {{ favData.fields.footer_button }}
                <v-icon color="primary">mdi-chevron-right</v-icon>
              </router-link>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleWithButton from "@/components/TitleWithButton.vue";
import Badge from "@/components/Badge.vue";
import TemplateRow1A from "./TemplateRow1A.vue";
import TemplateRow1B from "./TemplateRow1B.vue";
import TemplateRow2 from "./TemplateRow2.vue";
import TemplateRow3A from "./TemplateRow3A.vue";
import TemplateRow3B from "./TemplateRow3B.vue";
import "./FavouriteProducts.scss";

export default {
  components: {
    TitleWithButton,
    Badge,
    TemplateRow1A,
    TemplateRow1B,
    TemplateRow2,
    TemplateRow3A,
    TemplateRow3B,
  },
  props: {
    favData: {
      type: Object,
      required: true,
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
    withFooter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    categories() {
      return Object.values(this.$store.getters.getAllCategories);
    },
  },
  methods: {
    getTemplate(name) {
      return name
        .replace(/^main_section_fav_(template_.+)$/, "$1")
        .replace(/_/g, "-");
    },
  },
};
</script>
