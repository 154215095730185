<template>
  <div class="section">
    <div class="heading1 mb-16">
      {{ ourTeamData.fields.title || "" }}
    </div>

    <v-row>
      <v-col
        v-for="(person, index) in persons"
        :key="index"
        cols="12"
        md="6"
        lg="4"
      >
        <div class="mb-3">
          <v-img :src="person.image_lg || ''" eager />
        </div>

        <div class="subtitle1 mb-1">{{ person.title }}</div>

        <div class="body1 mb-3">{{ person.position }}</div>

        <div class="mb-3 d-flex">
          <img
            class="mr-3"
            :src="`${baseUrl}img/telegram-alternative.svg`"
            alt="telegram"
          />

          <a :href="`https://t.me/${person.social_telegram}`" target="_blank">
            @{{ person.social_telegram }}
          </a>
        </div>

        <div class="d-flex">
          <img class="mr-3" :src="`${baseUrl}img/mail.svg`" alt="mail" />
          <a :href="`mailto:${person.email}`" target="_blank">
            {{ person.email }}
          </a>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "OurTeam",

  props: {
    ourTeamData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },

    persons() {
      return this.ourTeamData.links.map((id) =>
        this.$store.getters.getPerson(id)
      );
    },
  },
};
</script>
