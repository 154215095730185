<template>
  <div id="test-solution" style="padding-top: 100px">
    <v-row class="section__title-row">
      <v-col class="text-center">
        <span class="heading2">{{ testSolutionData.fields.title || "" }}</span>
      </v-col>
    </v-row>
    <v-row class="justify-center mb-5 mb-md-10">
      <div
        class="col col-12 col-md-9 col-xxl-6"
        v-html="testSolutionData.fields.text"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TestSolution",
  props: {
    testSolutionData: {
      type: Object,
      required: true,
    },
  },
};
</script>
