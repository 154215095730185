<template>
  <div v-if="pageLoaded" v-resize="onResize">
    <v-row>
      <v-col class="text-left page__title">
        <h1>{{ pageData.fields.title || "" }}</h1>
      </v-col>
    </v-row>

    <v-row class="mb-8">
      <v-col>
        <v-img
          v-if="$vuetify.breakpoint.lgAndUp"
          :src="pageData.fields.gallery || ''"
          eager
          @load="onResize"
        />
        <v-img
          v-else-if="$vuetify.breakpoint.mdAndUp"
          :src="pageData.fields.gallery_lg || ''"
          eager
          @load="onResize"
        />
        <v-img
          v-else
          :src="pageData.fields.gallery_md || ''"
          eager
          @load="onResize"
        />
      </v-col>
    </v-row>

    <work-statistics
      class="section"
      :stat-data="pageData.career_company_stat_block"
      @trigger-resize="onResize"
    />

    <about-us :about-data="pageData.career_about" @trigger-resize="onResize" />

    <about-products
      ref="aboutproductref"
      :products-data="pageData.career_products"
    />

    <tech-stack
      :tech-stack-data="pageData.career_tech_stack"
      @trigger-resize="onResize"
    />

    <job-offers-current
      :jobs-data="pageData.career_jobs_opened"
      style="margin-top: -100px"
    />

    <job-offers-past :jobs-data="pageData.career_jobs_closed" />

    <form-communication
      class="section"
      :form-id="pageData.career_jobs_closed.links.feedback_form[0] || 0"
      @trigger-resize="onResize"
    />

    <for-students :students-data="pageData.career_students" />

    <employees-about-company :employees-data="pageData.career_employees" />

    <what-we-offer ref="whatweofferref" :what-data="pageData.career_offers" />

    <gallery :gallery-data="pageData.career_life" />

    <zuruck />
  </div>
</template>

<script>
import CareerRepository from "@/api/career.js";
import WorkStatistics from "./WorkStatistics.vue";
import AboutUs from "./AboutUs.vue";
import AboutProducts from "./AboutProducts.vue";
import TechStack from "./TechStack.vue";
import JobOffersCurrent from "./JobOffersCurrent.vue";
import JobOffersPast from "./JobOffersPast.vue";
import FormCommunication from "@/components/FormCommunication.vue";
import ForStudents from "./ForStudents.vue";
import EmployeesAboutCompany from "./EmployeesAbout/EmployeesAboutCompany.vue";
import WhatWeOffer from "./WhatWeOffer.vue";
import Gallery from "./Gallery/Gallery.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    WorkStatistics,
    AboutUs,
    AboutProducts,
    TechStack,
    JobOffersCurrent,
    JobOffersPast,
    FormCommunication,
    ForStudents,
    EmployeesAboutCompany,
    WhatWeOffer,
    Gallery,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        career_company_stat_block: { fields: {}, children: [] },
        career_about: { fields: {} },
        career_products: { fields: {}, children: [] },
        career_tech_stack: { children: [], fields: {} },
        career_jobs_opened: {
          children: [],
          fields: {},
          links: { feedback_form: [] },
        },
        career_jobs_closed: {
          children: [],
          fields: {},
          links: { feedback_form: [] },
        },
        feedback_form: { fields: {}, links: { person: [] } },
        career_students: { fields: {} },
        career_employees: { fields: {}, children: [] },
        career_offers: { children: [], fields: {} },
        career_life: { children: [], fields: {} },
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await CareerRepository.getCareer();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.aboutproductref.restartRandomExamples();
        this.onResize();
      });
    },
    onResize() {
      this.$refs.whatweofferref.updateSticky();
    },
  },
};
</script>
