<template>
  <v-row>
    <!-- Маленькое фото и текст -->
    <v-col cols="12" class="col-xxl-4">
      <v-row class="row-spec">
        <v-col cols="12" md="7" xl="5" class="col-xxl-12 col-spec">
          <image-extended
            :image="blockData.fields.image_1"
            class="image-small"
          />
        </v-col>

        <v-col
          cols="12"
          md="5"
          xl="7"
          class="col-xxl-12 d-flex align-center col-spec2"
        >
          <div class="gallery-txt-block" v-html="blockData.fields.text" />
        </v-col>
      </v-row>
    </v-col>

    <!-- Большое фото -->
    <v-col cols="12" class="col-xxl-8">
      <image-extended :image="blockData.fields.image_2" class="image-big" />
    </v-col>
  </v-row>
</template>
<script>
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  name: "Gallery3BlockLeft",

  components: { ImageExtended },

  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.image-big {
  height: 60vw;

  @media (min-width: 640px) {
    height: 45vw;
  }

  @media (min-width: 1024px) {
    height: 60vw;
  }

  @media (min-width: 1280px) {
    height: 50vw;
  }

  @media (min-width: 1440px) {
    height: 50vw;
  }

  @media (min-width: 1920px) {
    height: 30vw;
  }
}

.image-small {
  height: 60vw;

  @media (min-width: 640px) {
    height: 45vw;
  }

  @media (min-width: 1024px) {
    height: 28vw;
  }

  @media (min-width: 1280px) {
    height: 33vw;
  }

  @media (min-width: 1440px) {
    height: 20vw;
  }

  @media (min-width: 1920px) {
    height: 16vw;
  }
}

@media (min-width: 1280px) {
  .gallery-txt-block {
    max-width: 570px;
  }
}

@media (min-width: 1920px) {
  .row-spec {
    flex-direction: column;
    align-items: start;
    height: 100%;
  }

  .col-spec {
    flex: auto 0 0;
  }

  .col-spec2 {
    flex: auto 1 0;
  }
}
</style>
