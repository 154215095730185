<template>
  <div class="section">
    <v-row class="section__title">
      <v-col>
        <span class="heading2">{{ techStackData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <logo-slider
      v-for="(line, index) in techStackData.logo_children"
      :id="`tech-slide-line-${index}`"
      :key="index"
      :items="line"
      :direction="index % 2 == 0 ? 'left' : 'right'"
      :progress="slidersProgress(index)"
    />
  </div>
</template>

<script>
import LogoSlider from "@/components/LogoSlider.vue";
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";

export default {
  name: "TechStack",

  components: { LogoSlider },

  mixins: [ProgressObserverMixin],

  props: {
    techStackData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      obvOptions: {
        viewportTopPct: 0,
        viewportBottomPct: 100,
        pivotBegin: "top",
        pivotEnd: "bottom",
      },
    };
  },

  computed: {
    slidersProgress() {
      return (i) => {
        return this.obv(`tech-slide-line-${i}`);
      };
    },
  },

  mounted() {
    for (let i = 0; i < this.techStackData.logo_children.length; i++) {
      this.obvAddElement(`tech-slide-line-${i}`);
    }
  },
};
</script>
<style scoped lang="scss">
@media (max-width: 1024px) {
  .tech-band-block {
    margin-left: -127px;
    margin-right: -127px;
    user-select: none;
    cursor: grab;

    .tech-band-container {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
}

@media (max-width: 640px) {
  .tech-band-block {
    margin-left: -16px;
    margin-right: -16px;
  }
}
</style>
