<template>
  <div class="logo-slider">
    <div class="logo-slider-wrapper">
      <div
        class="d-inline-flex logo-slider-inner align-items-center"
        :class="{
          'logo-slider-left': direction == 'left',
          'logo-slider-right': direction == 'right',
        }"
        :style="getStyle()"
      >
        <v-img
          v-for="(item, index) in items"
          :key="index"
          :src="item || ''"
          alt="logo"
          contain
          eager
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    direction: {
      type: String,
      default: "left",
    },
    progress: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    getStyle() {
      return () => {
        const progress = this.progress;
        if (this.direction == "left") {
          return {
            left: `${progress * 100}%`,
            transform: `translateX(${-progress * 100}%)`,
          };
        } else if (this.direction == "right") {
          return {
            right: `${progress * 100}%`,
            transform: `translateX(${progress * 100}%)`,
          };
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-slider {
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 36px;
  overflow-x: hidden;
}
.logo-slider-wrapper {
  position: relative;
  height: 60px;
}
.logo-slider-inner {
  position: absolute;
  white-space: nowrap;
  column-gap: 40px;
  overflow-x: clip;
  height: 100%;
}
.logo-slider-left {
  left: 0;
}
.logo-slider-right {
  right: 0;
}
@media (min-width: 640px) {
  .logo-slider {
    margin-left: -127px;
    margin-right: -127px;
    padding-left: 127px;
    padding-right: 127px;
  }
}
@media (min-width: 1024px) {
  .logo-slider {
    margin-bottom: 64px;
  }
  .logo-slider-wrapper {
    height: 120px;
  }
  .logo-slider-inner {
    column-gap: 80px;
  }
}
@media (min-width: 1280px) {
  .logo-slider {
    margin-left: -55px;
    margin-right: -55px;
    padding-left: 55px;
    padding-right: 55px;
  }
}
@media (min-width: 1440px) {
  .logo-slider {
    margin-left: -135px;
    margin-right: -135px;
    padding-left: 135px;
    padding-right: 135px;
  }
}
@media (min-width: 1920px) {
  .logo-slider {
    margin-left: -175px;
    margin-right: -175px;
    padding-left: 175px;
    padding-right: 175px;
  }
}
</style>
