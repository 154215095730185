<template>
  <div v-if="pageLoaded">
    <div class="page__title">
      <v-row no-gutters class="align-center">
        <v-col class="flex-md-grow-0 mb-6 mb-lg-0" cols="12" lg="">
          <h1>{{ pageData.fields.title }}</h1>
        </v-col>

        <v-col cols="12" md="" class="mb-6 mb-md-0">
          <v-btn-toggle
            v-if="btnCount > 1"
            class="custom-selector ml-lg-11"
            active-class="custom-selector__active"
          >
            <v-btn
              v-if="pageData.fields.selector_group_main"
              x-large
              tile
              depressed
              class="custom-btn px-6"
              :to="{ name: 'Products' }"
              exact
            >
              {{ pageData.fields.selector_group_main }}
            </v-btn>

            <v-btn
              v-if="pageData.fields.selector_group_all"
              x-large
              tile
              depressed
              class="custom-btn px-6"
              :to="{ name: 'ProductsAll' }"
              exact
            >
              {{ pageData.fields.selector_group_all }}
            </v-btn>

            <v-btn
              v-if="pageData.fields.selector_group_custom"
              x-large
              tile
              depressed
              class="custom-btn px-6"
              :to="{ name: 'ProductsCustom' }"
              exact
            >
              {{ pageData.fields.selector_group_custom }}
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-spacer />

        <v-col cols="12" md="" class="flex-grow-0">
          <masked-icon-link
            v-if="(pageData.fields.catalog || '').length > 0"
            :mask-icon="`${baseUrl}img/pdf.svg`"
            :title="pageData.fields.button_catalog || ''"
            :href="pageData.fields.catalog"
          />
        </v-col>
      </v-row>
    </div>

    <div v-if="filterMessage" class="mb-16">
      {{ filterMessage }}
    </div>

    <v-container v-if="filter == 0" fluid class="px-0 py-0">
      <favourite-products :fav-data="pageData.main_fav" />
    </v-container>

    <v-row v-else class="section mt-0">
      <v-col
        v-for="(column, colIndex) in childrenSortedFiltered"
        :key="colIndex"
      >
        <v-row
          v-for="(element, rowIndex) in column"
          :key="rowIndex"
          class="mx-0"
          style="margin-bottom: 30px"
        >
          <v-col class="product-card-row">
            <v-card
              tile
              elevation="0"
              height="100%"
              class="product-background"
              :color="element.fields.color"
            >
            </v-card>

            <v-container class="product-content text-left pa-10">
              <v-row no-gutters>
                <v-col class="pb-0" style="z-index: 4">
                  <span
                    v-if="!element.node_name"
                    class="subtitle1"
                    :style="{ color: element.fields.color }"
                    >{{ element.fields.title }}</span
                  >
                  <router-link
                    v-else
                    :to="`/${element.node_name}`"
                    class="subtitle1"
                  >
                    <span>{{ element.fields.title }}</span>
                  </router-link>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-2">
                <v-col class="overline-sc pb-0" style="z-index: 4">
                  <span v-if="!element.node_name">{{
                    element.fields.subtitle || element.fields.description
                  }}</span>
                  <router-link
                    v-else
                    :to="`/${element.node_name}`"
                    class="textBase--text"
                  >
                    <span>{{
                      element.fields.subtitle || element.fields.description
                    }}</span>
                  </router-link>
                </v-col>
              </v-row>

              <v-row
                v-for="(child, index) in element.children"
                :key="index"
                class="mt-5"
                no-gutters
              >
                <v-col
                  class="pl-3 left-border-hover"
                  style="z-index: 4"
                  :style="{
                    'border-left': '0px solid ' + element.fields.color,
                  }"
                >
                  <router-link
                    :to="`/${child.node_name}`"
                    class="textBase--text d-flex align-center"
                  >
                    <span>{{ child.fields.title }}</span>
                  </router-link>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <form-communication :form-id="pageData.links.feedback_form[0] || 0" />
  </div>
</template>

<script>
import ProductsRepository from "@/api/products.js";
import FavouriteProducts from "@/components/FavouriteProducts/FavouriteProducts.vue";
import FormCommunication from "@/components/FormCommunication.vue";
import MaskedIconLink from "@/components/MaskedIconLink.vue";

export default {
  name: "Products",

  components: { FavouriteProducts, MaskedIconLink, FormCommunication },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },

  data() {
    return {
      pageData: {
        fields: {},
        children: [],
        children_sort: [],
        links: { feedback_form: [] },
        main_fav: { fields: {}, children: [] },
      },
      pageLoaded: false,
    };
  },

  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },

    filter() {
      const filter = this.$route?.meta?.filter || 0;
      const defFilter = 0;
      if (
        (filter == 1 && !this.pageData.fields.selector_group_all) ||
        (filter == 2 && !this.pageData.fields.selector_group_custom)
      ) {
        return defFilter;
      }
      return filter;
    },

    btnCount() {
      let cnt = 0;
      if (this.pageData.fields.selector_group_main) {
        cnt++;
      }
      if (this.pageData.fields.selector_group_all) {
        cnt++;
      }
      if (this.pageData.fields.selector_group_custom) {
        cnt++;
      }
      return cnt;
    },

    childrenSortedFiltered() {
      const columns = [];
      this.pageData.children_sort.forEach((row) => {
        const newColumn = [];
        row.forEach((category) => {
          if (category.node_name) {
            if (this.checkCustom(category, this.filter)) {
              newColumn.push(category);
            }
          } else {
            const newCategory = { ...category, children: [] };
            category.children.forEach((product) => {
              if (this.checkCustom(product, this.filter)) {
                newCategory.children.push(product);
              }
            });
            if (newCategory.children.length > 0) {
              newColumn.push(newCategory);
            }
          }
        });
        columns.push(newColumn);
      });
      return columns;
    },

    filterMessage() {
      switch (this.filter) {
        case 0:
          return this.pageData.fields.group_main_subtitle;
        case 1:
          return this.pageData.fields.group_all_subtitle;
        case 2:
          return this.pageData.fields.group_custom_subtitle;
      }
      return "";
    },
  },

  watch: {
    "$vuetify.breakpoint.xl"(val) {
      if (val) {
        this.reorderElements();
      }
    },

    "$vuetify.breakpoint.lg"(val) {
      if (val) {
        this.reorderElements();
      }
    },

    "$vuetify.breakpoint.md"(val) {
      if (val) {
        this.reorderElements();
      }
    },

    "$vuetify.breakpoint.sm"(val) {
      if (val) {
        this.reorderElements();
      }
    },

    "$vuetify.breakpoint.xs"(val) {
      if (val) {
        this.reorderElements();
      }
    },
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.initPage();
      }
    },
  },

  mounted() {
    this.initPage();
  },

  methods: {
    checkCustom(item, filter) {
      if (item.fields.custom_project == "1") {
        return [2].includes(filter);
      } else {
        return [0, 1].includes(filter);
      }
    },

    async initPage() {
      this.pageLoaded = false;
      const { data } = await ProductsRepository.getProductsList();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.reorderElements();
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },

    reorderElements() {
      let orderMode = "";
      if (this.$vuetify.breakpoint.xl) {
        orderMode = "xl";
      } else if (this.$vuetify.breakpoint.lg) {
        orderMode = "lg";
      } else if (this.$vuetify.breakpoint.md) {
        orderMode = "md";
      } else if (this.$vuetify.breakpoint.sm) {
        orderMode = "sm";
      } else if (this.$vuetify.breakpoint.xs) {
        orderMode = "sm";
      }
      let childrenSortObj = {};
      this.pageData.children.forEach((child) => {
        if (!childrenSortObj[child.fields["col_index_" + orderMode]]) {
          childrenSortObj[child.fields["col_index_" + orderMode]] = [];
        }
        childrenSortObj[child.fields["col_index_" + orderMode]].push(child);
      });
      this.$set(
        this.pageData,
        "children_sort",
        this.setupOrder(this.recombineSort(childrenSortObj), orderMode)
      );
    },

    recombineSort(obj) {
      const childrenSort = [];
      Object.keys(obj)
        .map((x) => {
          if (x == "") {
            return 0;
          } else {
            return parseInt(x);
          }
        })
        .sort(function (a, b) {
          if (a > b) {
            return 1;
          } else if (a < b) {
            return -1;
          } else {
            return 0;
          }
        })
        .forEach((x) => {
          if (x == 0) {
            childrenSort.push(obj[""]);
          } else {
            childrenSort.push(obj[x]);
          }
        });
      return childrenSort;
    },

    setupOrder(list, mode) {
      return list.map((x) => {
        return x.sort(function (a, b) {
          if (a.fields["order_" + mode] > b.fields["order_" + mode]) {
            return 1;
          } else if (a.fields["order_" + mode] < b.fields["order_" + mode]) {
            return -1;
          } else {
            return 0;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
.left-border-hover:hover {
  border-left-width: 4px !important;
  padding-left: 8px !important;
}

.theme--light.v-btn-toggle.custom-selector {
  overflow-x: auto;

  .theme--light.custom-btn {
    border: none;
    &:hover::before,
    &::before {
      opacity: 0;
    }

    .v-ripple__container {
      display: none !important;
    }
  }

  .v-btn.v-btn .v-icon {
    transition: none;
    color: var(--v-corporateGrey-base);
  }

  .custom-selector__active {
    background-color: var(--v-primary-base) !important;
    color: white !important;

    .v-icon {
      color: white !important;
      transition: none;
    }
  }
}
</style>
