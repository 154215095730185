export default {
  "/MultiSIM": "/multi-sim",
  "/news/196": "/news/21-marta-2005-goda-zaregistrirovana-kompaniya-ooo",
  "/news/197": "/news/nachalas-razrabotka-sistemy-avtomaticheskih",
  "/news/198": "/news/vyshel-pervyy-produkt-kompanii-sms-setting-uzhe",
  "/news/199": "/news/sistema-sms-setting-peredana-v-testovuyu",
  "/news/200": "/news/zaklyuchen-dogovor-o-prodazhe-sistemy-svyazkom",
  "/news/201": "/news/opredeleny-napravleniya-sleduyuschih-proektov",
  "/news/202": "/news/svyazkom-gotovit-servisnuyu-platformu-dlya",
  "/news/203": "/news/ustanovlena-servisnaya-platforma-na-servere",
  "/news/204": "/news/razrabatyvaetsya-novaya-sistema-dlya-uproscheniya",
  "/news/205": "/news/na-sayte-dobavlen-novyy-razdel-katalog-produktov",
  "/news/206": "/news/zaversheny-raboty-po-sozdaniyu-sms-ussd-platform",
  "/news/207": "/news/proizvedeno-obnovlenie-servisnoy-platformy",
  "/news/208": "/news/kompaniya-svyazkom-gotovitsya-vypustit-lbs",
  "/news/209": "/news/zakonchena-razrabotka-sistemy-wap-tarifikacii",
  "/news/210": "/news/kompaniya-svyazkom-proizvela-ustanovku-platformy",
  "/news/211": "/news/proizvedena-installyaciya-svyazkom-smsc-na-set",
  "/news/212": "/news/segodnya-zavershena-integraciya-svyazkom-smsc-v",
  "/news/213": "/news/svyazkom-wap-media-gateway-ustanovlen-v-set-etk",
  "/news/214": "/news/svyazkom-gotovitsya-k-testovomu-zapusku-platformy",
  "/news/215": "/news/usluga-mobilnoe-televidenie-stala-dostupna",
  "/news/216": "/news/kompaniya-svyazkom-zakonchila-razrabotku",
  "/news/217": "/news/svyazkom-msa-v-odnoy-iz-stran-sng",
  "/news/218": "/news/specialisty-kompanii-pristupili-k-installyacii",
  "/news/219": "/news/uzdunrobita-mts-uzbekistan-proizvela",
  "/news/220": "/news/kompaniya-svyazkom-zaklyuchila-novyy-kontrakt-s",
  "/news/221": "/news/kompaniya-svyazkom-vybrana-postavschikom",
  "/news/222": "/news/podpisan-dogovor-na-postavku-platformy-svyazkom",
  "/news/223": "/news/svyazkom-sms-settings-v-seti-bcti-mts",
  "/news/224": "/news/vedutsya-peregovory-s-ulan-udenskaya-sotovaya-set",
  "/news/225": "/news/specialistami-kompanii-razrabotana-novaya",
  "/news/226": "/news/svyazkom-smsc-svyazkom-antifraud-i-svyazkom-msa-v",
  "/news/227": "/news/uspeh-predostavlyaemyh-uslug-na-baze-svyazkom-msa",
  "/news/228": "/news/podpisan-dogovor-na-postavku-neskolkih-platform",
  "/news/229": "/news/proizveden-kommercheskiy-zapusk-srazu-neskolkih",
  "/news/230": "/news/platforma-svyazkom-sms-settings-obretaet-novyy",
  "/news/231": "/news/podpisan-dogovor-na-postavku-mmsc-v-set-zao",
  "/news/232": "/news/svyazkom-wmg-v-seti-uzdunrobita-mts-uzbekistan",
  "/news/233": "/news/razrabotana-novaya-platforma-svyazkom-sync",
  "/news/234": "/news/v-seti-zao-etk-installirovan-mmsc-proizvodstva",
  "/news/235": "/news/proizvedena-integraciya-resheniya-svyazkom-wmg-v",
  "/news/236": "/news/novye-rynki-kompaniya-svyazkom-zaklyuchila",
  "/news/237": "/news/sammit-partnerov-svyazkom",
  "/news/238": "/news/planiruetsya-zapusk-platformy-svyazkom-callback-v",
  "/news/239": "/news/planiruetsya-rasshirenie-platformy-svyazkom-sms",
  "/news/240": "/news/svyazkom-sms-settings-v-seti-zao-baykalvestkom",
  "/news/241": "/news/proizveden-testovyy-zapusk-platformy-sms-settings",
  "/news/242": "/news/dostignuta-dogovorennost-o-testovoy-ekspluatacii",
  "/news/243": "/news/krupneyshiy-sotovyy-operator-irkutskoy-oblasti",
  "/news/244": "/news/platforma-svyazkom-sms-settings-zamestit",
  "/news/245": "/news/vedutsya-peregovory-o-postavke-svyazkom-smss-v",
  "/news/246": "/news/svyazkom-sms-inform-operatoru-indigo-tadzhikistan",
  "/news/247": "/news/podpisan-dogovor-na-postavku-svyazkom-smsc",
  "/news/248": "/news/planiruetsya-zapusk-platformy-svyazkom-wap",
  "/news/249": "/news/v-seti-zao-etk-installirovan-wap-shlyuz-svyazkom",
  "/news/250": "/news/svyazkom-wmg-operatoru-indigo-tadzhikistan",
  "/news/251": "/news/zao-baykalvestkom-priobrel-dopolnitelnye-licenzii",
  "/news/252": "/news/v-seti-zao-bvk-zapuschena-platforma-svyazkom-msa",
  "/news/253": "/news/v-seti-tcell-proizveden-zapusk-platformy-svyazkom",
  "/news/254": "/news/uspeshnoe-vnedrenie-2h-platform-v-seti-zao",
  "/news/255": "/news/uspeh-svyazkom-sms-settings-v-mts-uzbekistan",
  "/news/256": "/news/megafon-zapustil-v-sibirskom-filiale-novuyu",
  "/news/257": "/news/svyazkom-sms2voice-v-filialah-megafona-1",
  "/news/258": "/news/rasshireny-licenzii-svyazkom-sms-settings-v-seti",
  "/news/259": "/news/v-seti-tcell-zapuschen-ussd-portal-na-baze",
  "/news/260": "/news/zamena-smsc-v-seti-zao-baykalvestkom",
  "/news/261": "/news/svyazkom-sms-settings-v-seti-zao-sp-somonkom",
  "/news/262": "/news/svyazkom-sms2voice-v-filialah-megafona-2",
  "/news/263": "/news/vo-vetname-nachalsya-3-y-mezhdunarodnyy-sammit",
  "/news/264": "/news/ocherednoe-vnedrenie-svyazkom-sms2voice-severo",
  "/news/265": "/news/the-commercial-sc-ss7-gateway-solution-was",
  "/news/266": "/news/sc-eir-installed-into-etk-network",
  "/news/267": "/news/integration-process-continuing-of-sc-sms2voice-in",
  "/news/268": "/news/the-contract-between-sc-soft-company-and",
  "/news/269": "/news/one-more-solution-sc-sms2voice-megafon-caucasus",
  "/news/270": "/news/integration-process-of-sc-smsc-at-tcell-network",
  "/news/271": "/news/kcell-starts-new-service-call-filter-based-on-sc",
  "/news/272": "/news/the-commercial-solution-eir-emulator-was-started",
  "/news/273": "/news/our-company-website-has-been-updated",
  "/news/274": "/news/mobile-world-congress-2013",
  "/news/275": "/news/victory-of-bidding-tender-to-deploy-mms-center-at",
  "/news/276": "/news/sc-mmsc-have-been-installed-at-tcell-network",
  "/news/277": "/news/integration-of-eir-and-dms-system-in-tcell",
  "/news/278": "/news/the-5th-sc-soft-mobile-summit-completed",
  "/news/279": "/news/novaya-usluga-cherno-belye-spiski-v-seti",
  "/news/280": "/news/integration-of-dms-at-beeline-network-1",
  "/news/281": "/news/svyazcom-upgraded-service-promissed-payment-at",
  "/news/282": "/news/tcell-starts-new-service-always-available-due-to",
  "/news/283": "/news/integration-of-dms-at-beeline-network-2",
  "/news/284": "/news/svyazcom-installed-an-sms-firewall-on-the-etk",
  "/news/285": "/news/sc-soft-installed-an-mnp-platform-on-the-etk",
  "/news/286": "/news/sc-soft-has-completed-an-mms-center-installation",
  "/news/287": "/news/sc-soft-was-chosen-as-a-supplier-of-sc-bulk-smsc",
  "/news/288": "/news/svyazkom-razrabotal-produkt-dlya-kontrolya-scheta",
  "/news/289": "/news/svyazkom-primet-uchastie-v-mwc-2014",
  "/news/290": "/news/svyazkom-razrabotal-produkt-dlya-bankovskogo",
  "/news/291": "/news/mobile-world-congress-2014",
  "/news/292": "/news/integration-of-specialized-ussd-center-into-kcell",
  "/news/293": "/news/renewal-and-launch-of-sc-sms-settings-solution-in",
  "/news/294": "/news/sc-mnp-srf-complex-launched-in-vainah-telecom",
  "/news/295": "/news/sc-soft-was-chosen-as-a-supplier-of-dms-to-the",
  "/news/296": "/news/kompaniya-svyazkom-poluchila-gosudarstvennuyu",
  "/news/297": "/news/sc-soft-has-implemented-the-dms-platform-named-sc",
  "/news/298": "/news/sc-soft-llc-has-implemented-a-project-for-swiss",
  "/news/299": "/news/sc-soft-implemented-ss7-gateway-for-vainah",
  "/news/300": "/news/integration-of-new-module-sc-eir-for-m2m-devices",
  "/news/301": "/news/svyazkom-flashstatus-teper-abonenty-mogut",
  "/news/302": "/news/zavershena-razrabotka-novogo-produkta-svyazkom",
  "/news/303": "/news/v-ramkah-modernizacii-platformy-svyazkom-eir",
  "/news/304": "/news/zaversheny-raboty-po-integracii-platformy-dms",
  "/news/305": "/news/mobile-world-congress-2015",
  "/news/306": "/news/kompaniya-svyazkom-uchastvovala-v-nbsp",
  "/news/307": "/news/sistema-upravleniya-marketingovymi-akciyami-ot",
  "/news/308": "/news/svyazkom-zapustil-servis-welcome-sms-v-nbsp-ooo",
  "/news/309": "/news/sc-soft-at-mobile-world-congress-2015",
  "/news/310": "/news/sc-soft-announces-the-launch-of-a-new-voice-call",
  "/news/311": "/news/svyazkom-otkryl-svoyu-stranicu-v-socialnoy-seti",
  "/news/312": "/news/mms-transsoding-how-to-transfer-photos-from",
  "/news/313": "/news/devices-used-by-your-subscribers",
  "/news/314": "/news/the-launch-of-the-new-notifycall-service-based-on",
  "/news/315": "/news/mobilizaciya-dannyh",
  "/news/316": "/news/chernaya-i-belaya-storona-svyazkom-call-screening",
  "/news/317": "/news/bolshe-chem-wap-shlyuz",
  "/news/318": "/news/otmena-mobilnogo-rabstva-v-kazahstane",
  "/news/319": "/news/golosovaya-pochta-ot-svyazkom-v-beeline-kazahstan",
  "/news/320": "/news/mwc-2016-invite",
  "/news/321": "/news/svyazkom-smartconnect-v-seti-vodafone",
  "/news/322": "/news/svyazkom-provodit-viii-otkrytyy-sammit-mobilnyh",
  "/news/323": "/news/reshenie-kompanii-svyazkom-pomogaet-bankam",
  "/news/324": "/news/portal-nastroenie-v-seti-operatora-kcell",
  "/news/325": "/news/bon-voyage-i-welcome-sms-v-bilayne-gruziya",
  "/news/326": "/news/sammit-2016",
  "/news/327": "/news/svyazkom-investiruet-v-innovacii",
  "/news/328": "/news/mobile-world-congress-2017",
  "/news/329": "/news/svyazkomu-12-let",
  "/news/330": "/news/svyazkom-na-mwc-2017",
  "/news/331": "/news/sammit-2017",
  "/news/332": "/news/tendencii-v-otrasli-network-functions",
  "/news/333": "/news/mwc-2018-invitation",
  "/news/334": "/news/platforma-avtorizacii-v-obschestvennyh-wi-fi",
  "/news/335": "/news/mobile-world-congress-2019",
  "/news/336": "/news/platforma-kontrolya-registracii-abonentov-v",
  "/news/337": "/news/a-tool-to-combat-the-use-of-illegal-devices-to",
  "/news/338": "/news/sc-soft-expands-vodafone-s-capabilities",
  "/news/339": "/news/sc-soft-new-development-directions",
  "/news/340": "/news/novoe-reshenie-multiplatform-ot-kompanii-svyazkom",
  "/news/341": "/news/kompaniya-svyazkom-zapustila-reshenie-dlya",
  "/news/342": "/news/svyazkom-rasshiryaet-vozmozhnosti-dms-u-operatora",
  "/news/343": "/news/svyazkom-eir-bonus-dlya-operatora-svyazi-tele2-v",
  "/news/344": "/news/svyazkom-ustanovil-sms-ss7-firewall-dlya-kompaniy",
  "/news/345": "/news/okunites-v-mir-novyh-vozmozhnostey-vmeste-s",
  "/news/346": "/news/universalnoe-reshenie-multiplatform-dlya",
  "/news/347": "/news/svyazkom-prodolzhaet-rabotu-v-shtatnom-rezhime",
  "/news/348": "/news/m2pa-m3ua-converter-for-astran",
  "/news/349": "/news/sc-messaging-hub-for-mts-telecom-operator",
  "/news/350": "/news/sc-soft-installs-automatic-network-setting",
  "/news/351": "/news/sc-soft-messaging-hub-platform-complemented-with",
  "/news/352": "/news/sc-soft-expands-messaging-hub-functionality",
  "/news/353": "/news/sc-sms-firewall-for-kcell-telecom-operator",
  "/news/354": "/news/ceir-results-in-uzbekistan",
  "/news/355": "/news/clustering-increasing-the-level-of-fault",
  "/news/356": "/news/sc-sms-firewall-as-reliable-protection-means-for",
  "/news/357": "/news/sc-sms-firewall-with-artificial-intelligence",
  "/news/358": "/news/ceir-platform-efficiency-in-uzbekistan-confirmed",
  "/news/359": "/news/sc-soft-is-admitted-into-the-international",
  "/news/360": "/news/den-rozhdeniya-svyazkoma",
  "/news/361": "/news/sc-soft-participates-in-the-global-mobile-awards",
  "/news/362": "/news/sc-soft-has-already-participated-in-the-mwc",
  "/news/363": "/news/oasis-smart-sim-and-sc-soft-create-an-alliance-to",
  "/news/364": "/news/sc-soft-will-be-at-the-wwc-2021-madrid-on",
  "/news/365": "/news/svyazkom-i-motiv-sovmestnye-proekty",
  "/news/366": "/news/sc-soft-will-be-at-the-mwc2022",
  "/news/718": "/news/svyazkom-prezentuet-free-a2p-smsc-na-wwc",
  "/news/724": "/news/sc-sms-firewall-product-technologies",
  "/news/725": "/news/sc-soft-prepared-the-ceir-system-updates-package",
  "/news/726": "/news/installyaciya-platformy-svyazkom-sms-settings",
  "/news/727": "/news/sc-soft-installed-bulk-smsc-platform-for-africell",
  "/news/730": "/news/svyazkom-eir-proxy",
  "/news/732": "/news/we-wish-you-a-merry-christmas-and-a-happy-new-year",
  "/news/733": "/news/sc-soft-will-be-at-the-mwc2023",
  "/news/735": "/news/vnedrenie-novogo-smsc-dlya-odnogo",
  "/news/1547": "/news/dms-digest-for-our-partners",
  "/news/2060": "/news/svyazkom-v-vosmoy-raz-uchastvuet-v-vystavke-mwc",
  "/news/2870": "/news/our-team-returns-from-mwc-2023",
  "/news/2871": "/news/svyazkom-v-desyatyy-raz-uchastvuet-v-mwc",
  "/news/2872": "/news/sc-soft-took-part-in-a-capacity-middle-east",
  "/news/2875": "/news/ota-i-dms-dlya-operatora-v-vostochnoy-evrope",
  "/news/2878": "/news/sc-soft-and-gsma-have-signed-an-agreement",
  "/news/2879": "/news/see-you-at-cc-mobile-messaging-summit-2023",
  "/news/2970": "/news/we-have-returned-from-the-cc-mobile",
  "/news/2971": "/news/a-transit-smsc-for-a-european-mno",
  "/news/2972": "/news/svyazkom-sms-settings-dlya-peterburgskogo",
  "/news/2973": "/news/svyazkom-eir-dlya-operatora-svyazi-iz-sng",
  "/news/2974": "/news/eir-system-for-the-telecom-provider-in-kazakhstan",
  "/news/3055": "/news/obnovlenie-platformy-messaging-hub",
  "/news/3063": "/news/sc-soft-is-heading-to-the-gssm-exhibition",
  "/news/3146": "/news/results-of-gccm-exhibition-in-almaty",
  "/news/3147": "/news/sc-soft-is-heading-to-wwc",
  "/news/3148": "/news/sc-soft-implemented-messaging-hub-for-a-cis",
  "/news/3149": "/news/svyazkom-uchastvoval-v-vystavke-gccm",
  "/news/3175": "/news/subscriber-control-dlya",
  "/news/3176": "/news/sc-soft-at-wwc-2023",
  "/news/3177": "/news/sc-soft-is-heading-to-mwc-rwanda",
  "/news/3198": "/news/messaging-hub-dlya-krupneyshego-operatora-belarusi",
  "/news/3200": "/news/messaging-hub-for-our-major-customer",
  "/news/3215": "/news/sc-soft-at-mwc-kigali-2023",
  "/news/3222": "/news/sc-soft-is-heading-to-middle-east-gccm",
  "/news/3252": "/news/sc-soft-at-gccm-2023",
  "/news/3253": "/news/sc-soft-is-heading-to-africa-com-2023",
  "/news/3388": "/news/sc-soft-at-africa-com-2023",
  "/news/3390": "/news/mnp-dlya-abonentov-uzbekistana",
  "/news/3442": "/news/kachestvennyy-monitoring",
  "/news/3443": "/news/high-quality-monitoring",
  "/news/3525": "/news/svyazkom-ceir-voshel-v-reestr-rossiyskogo-po",
  "/news/3526": "/news/sc-soft-ceir-at-itu-2023",
  "/news/3551": "/news/sc-soft-will-be-at-the-mwc2024",
  "/news/4356": "/news/svyazkom-razrabotal-koncepciyu-iot-smsc",
  "/news/4357": "/news/sc-soft-has-developed-the-vision-of-the-iot-smsc",
  "/news/4446": "/news/obnovlenie-platformy-dms-ot-svyazkom",
  "/news/4447": "/news/updated-dms-platform-from-sc-soft",
  "/news/4450": "/news/sc-soft-at-mwc-2024",
  "/news/4460": "/news/ceir-ot-svyazkom-bezopasnost",
  "/news/4461": "/news/ceir-from-sc-soft-subscriber-security",
  "/news/4499": "/news/modul-messaging-hub-ot-svyazkom",
  "/news/4500": "/news/the-messaging-hub-module-from-ss-soft",
  "/news/4501": "/news/remote-sim-provisioning-ot-svyazkom",
  "/news/4502": "/news/remote-sim-provisioning-from-sc-soft",
  "/news/4503": "/news/sc-soft-recognized-as-key-player-in-esim",
  "/news/4504": "/news/ceir-kak-sposob-realizacii-novoy-zakonodatelnoy",
  "/news/4510": "/news/multiplatforma-ot-svyazkom-dlya-odnogo-iz",
  "/news/4511": "/news/multiplatform-from-sc-soft-for-one-of-the-mobile",
  "/news/4512": "/news/o-sisteme-ceir-pishet-portal-comnews-ru",
  "/news/4513": "/news/ways-of-updating-the-imei-registration-system-in",
  "/news/4514": "/news/capacity-eurasia-2024-connecting-the-industry",
  "/news/4525": "/news/obnovlenie-sistemy-mnp-v-uzbekistane",
  "/news/4526": "/news/updates-for-the-mnp-system-in-a-cis-country",
  "/news/4927": "/news/esim-technology-a-promising-trend",
  "/news/4928": "/news/esim-perspektivnoe-napravlenie",
  "/news/4929": "/news/internal-operator-network-security-problems",
  "/news/4930": "/news/voprosy-bezopasnosti-vnutrennih",
};
