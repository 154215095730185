<template>
  <v-dialog
    class="presentation-dialog-fallback"
    content-class="dialog"
    :value="value"
    persistent
    :fullscreen="!$vuetify.breakpoint.mdAndUp"
    @input="$emit('input', $event)"
  >
    <div class="dialog-close clickable" @click.stop.prevent="onClose">
      <v-icon :size="24" style="z-index: 8">mdi-close</v-icon>
    </div>

    <div class="presentation-dialog-content">
      <presentation-form
        v-show="!formFilled"
        ref="refform"
        class="presentation-form-window"
        :product-ident="product.node_name"
        @commit="formFilled = true"
        @close-dialog="onClose"
      />
      <presentation-thanks
        v-show="formFilled"
        class="presentation-form-window"
        :product="product"
        @close-dialog="onClose"
      />
    </div>

    <div v-if="withEar" class="ear clickable">
      <kk-arrow :rotate="180" @click.stop.prevent="$emit('ear-left')" />
    </div>
  </v-dialog>
</template>
<script>
import PresentationForm from "@/components/PresentationPreview/PresentationForm.vue";
import PresentationThanks from "@/components/PresentationPreview/PresentationThanks.vue";
import KkArrow from "@/components/KkArrow.vue";

export default {
  name: "PresentationDialogFallback",
  components: { PresentationForm, PresentationThanks, KkArrow },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
    withEar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showForm: true,
      formFilled: false,
    };
  },
  computed: {},
  watch: {
    value(newV) {
      if (newV) {
        this.formFilled = false;
        this.$refs.refform?.reset();
      }
    },
  },
  methods: {
    onClose() {
      this.$emit("input", false);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .dialog {
  background: #fff;
  @media (min-width: 1023px) {
    max-width: 640px;
    width: 100vw;
    margin: 0;
  }
  position: relative;
  overflow: visible;
}

.presentation-dialog-content {
  height: 100%;
}

::v-deep.presentation-form-window {
  overflow-x: visible !important;
  box-shadow: none !important;
  height: 100% !important;
  .dialog-close {
    display: none !important;
  }
}

.dialog-close {
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  overflow: hidden;

  z-index: 4;
  .v-icon {
    color: rgba(0, 0, 0, 0.32) !important;
  }
  @media (max-width: 1024px) {
    &.hover,
    &:hover {
      .v-icon {
        color: rgba(0, 0, 0, 0.64) !important;
      }
    }
  }

  @media (min-width: 1024px) {
    top: 5px;
    right: -50px;

    .v-icon {
      color: #fff !important;
    }

    &.hover,
    &:hover {
      box-shadow: 0px 0px 12px 0px rgba(48, 49, 51, 0.03),
        0px 0px 24px 0px rgba(48, 49, 51, 0.03);
      color: #fff !important;
    }
  }
}

::v-deep.ear {
  position: absolute;
  top: 50%;
  margin-top: -26px;
  width: 26px;
  left: -55px;
  z-index: 4;

  @media (max-width: 1023px) {
    top: 45px;
    left: 20px;
    .kk-arrow {
      &,
      &.hover,
      &:hover {
        box-shadow: none !important;
      }
    }
  }
}
</style>
