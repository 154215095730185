import { TitleWrap } from "@/components/Utilities.js";

const ChartConfigFactory = () => ({
  getConfig(template, options) {
    const funcTerm = template.charAt(0).toUpperCase() + template.slice(1);
    return this[`template${funcTerm}`](options);
  },

  templatePie(options) {
    return {
      selectedMode: false,
      selectedOffset: 0,
      series: [
        {
          name: options.title,
          color: [
            "#4DABF5",
            "#F5C14D",
            "#F56D4D",
            "#FAB6A6",
            "#A6D5FA",
            "#3E89C4",
          ],
          type: "pie",
          radius: ["52%", "70%"],
          startAngle: 135,
          label: {
            fontFamily: "Open Sans",
            fontSize: 18,
            lineHeight: 27,
            backgroundColor: "transparent",
            formatter: "{b}\n{pctFormat|{d}%}",
            rich: {
              pctFormat: {
                fontSize: 18,
                color: "#8E9094",
              },
            },
          },
          labelLayout(params) {
            return {
              verticalAlign: "bottom", // выравнивание по линии
              //y: params.y,
              //dy: 28,
              //dy: -Math.floor((breaks / 2 - 1) * 28), // смещение на 1 строку вниз линии
              // чтобы линия залезла полностью внутрь текста по горизонтали
              align: params.align == "left" ? "right" : "left",
            };
          },
          labelLine: {
            length: 0,
            length2: "15%",
            lineStyle: {
              color: "#D8E0E6",
              opacity: 1,
            },
          },
          data: options.series.map((item) => {
            return {
              name: TitleWrap(item.fields.title, 20, "\n"),
              value: parseInt(item.fields.count),
            };
          }),
        },
      ],
    };
  },

  templateBars(options) {
    const table = options.series.reduce(
      (product, item) => {
        if (!product.years.includes(item.fields.year)) {
          product.years.push(item.fields.year);
        }
        if (!product.titles.includes(item.fields.title)) {
          product.titles.push(item.fields.title);
        }
        return product;
      },
      { years: [], titles: [] }
    );
    table.years.sort();
    return {
      legend: {
        bottom: 20,
        left: "12%",
        icon: "circle",
        textStyle: {
          fontFamily: "Open Sans",
          fontSize: 14,
          lineHeight: 22,
          color: "#263238",
        },
      },
      grid: {
        left: "10%",
        right: "10%",
        bottom: "15%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: table.years,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontFamily: "Open Sans",
            fontSize: 14,
            lineHeight: 22,
            color: "#404040",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          show: false,
          axisLine: {
            show: false,
          },
        },
      ],
      color: [
        "#4DABF5",
        "#FAE0A6",
        "#F56D4D",
        "#FAB6A6",
        "#A6D5FA",
        "#3E89C4",
        "#F5C14D",
      ],
      series: options.series.reduce(
        (product, item) => {
          const titleIndex = table.titles.indexOf(item.fields.title);
          const yearIndex = table.years.indexOf(item.fields.year);
          product[titleIndex].data[yearIndex] = item.fields.count;
          return product;
        },
        table.titles.map((name) => ({
          name,
          type: "bar",
          stack: "stacker",
          data: new Array(table.years.length).fill(0),
        }))
      ),
    };
  },
});

export default ChartConfigFactory;
