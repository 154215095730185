<template>
  <div v-resize="onResize">
    <v-row v-if="hasBreadcrumbs">
      <v-col>
        <breadcrumbs class="no-marker px-0 mb-9" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="2" class="mt-3">
        <slot name="aside"></slot>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6" xl="7" class="sv-article">
        <slot></slot>
      </v-col>
      <v-col class="d-none d-lg-block" lg="4" xl="3">
        <div class="sv-sticky-container" @wheel="onStickyMouseWheel">
          <div class="sv-sticky">
            <v-row
              v-for="(content, index) in contents"
              :id="`contents-link-${index}`"
              :key="index"
              no-gutters
              class="mt-5"
            >
              <v-col class="pl-0">
                <div
                  class="sv-chapter-title pl-2"
                  :class="{ 'sv-menu-active': isActive(index) }"
                >
                  <router-link
                    :to="{ hash: index }"
                    class="d-block textBase--text"
                  >
                    <div v-html="content" />
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>

      <div
        v-if="contentKeys.length > 0"
        class="d-lg-none mobile-menu"
        :class="navMenu ? 'active' : 'inactive'"
        :style="menuStyle"
      >
        <div
          class="sv-menu-button clickable"
          :class="{
            'sv-menu-button--filled': !navMenu,
            'sv-menu-button--opaque': navMenu,
          }"
          @click="navMenu = !navMenu"
        >
          <v-icon v-if="!navMenu" style="flex: 1 0 0" color="corporateGrey"
            >$vuetify.icons.dots</v-icon
          >
          <v-icon v-else style="flex: 1 0 0" color="black-lighten2" size="14"
            >$vuetify.icons.close</v-icon
          >
        </div>

        <div ref="articlecontents" class="sv-menu-content">
          <v-list class="py-0">
            <v-list-item
              v-for="(content, index) in contents"
              :key="index"
              class="sv-menu-item"
              link
              :href="`#${index}`"
            >
              <div v-html="content" />
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-row>

    <zuruck />
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Article/Breadcrumbs.vue";
import Zuruck from "@/components/Zuruck.vue";
export default {
  components: {
    Breadcrumbs,
    Zuruck,
  },
  props: {
    contents: {
      type: Object,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      navMenu: false,
      observerOptions: {
        rootMargin: "0px 0px -50% 0px",
        threshold: 1,
      },
      observer: null,
      activeId: "",
      menuContentWidth: 315,
    };
  },
  computed: {
    isActive() {
      return (id) => id == this.activeId;
    },
    hasBreadcrumbs() {
      return this.breadcrumbs.length > 0;
    },
    contentKeys() {
      return Object.keys(this.contents);
    },
    menuStyle() {
      const menuWidth = this.menuContentWidth;
      const rightIndent = this.navMenu ? menuWidth : 0;
      return {
        right: `${rightIndent}px`,
      };
    },
  },
  methods: {
    async initObserver() {
      const local = this;

      this.observer = new IntersectionObserver((entries) => {
        entries.some((entry) => {
          const targetId = entry.target.getAttribute("id");
          // entry уже внутри, значит активен именно он
          if (entry.isIntersecting) {
            local.activeId = targetId;
            return true;
            // entry на нижней границе, значит активен предыдущий
          } else if (
            entry.boundingClientRect &&
            entry.rootBounds &&
            entry.boundingClientRect.bottom > entry.rootBounds.bottom
          ) {
            const targetIndex = this.contentKeys.indexOf(targetId);
            local.activeId =
              targetIndex > 0 ? this.contentKeys[targetIndex - 1] : targetId;
            return true;
          }
          return false;
        });
      }, this.observerOptions);

      this.contentKeys.forEach((key) => {
        const target = document.querySelector("#" + key);
        if (target) {
          local.observer.observe(target);
        }
      });

      this.onResize();
    },

    onStickyMouseWheel(event) {
      const target = document.querySelector(".sv-sticky");
      if (target) {
        target.scrollTop += event.deltaY;
        const scrolledToTop = target.scrollTop <= 0;
        const scrolledToBottom =
          target.scrollTop + target.clientHeight >= target.scrollHeight;
        if (
          (event.deltaY < 0 && !scrolledToTop) ||
          (event.deltaY > 0 && !scrolledToBottom)
        ) {
          event.preventDefault();
        }
      }
    },

    onResize() {
      const w = this.$refs.articlecontents?.clientWidth || 0;
      if (w == 0) {
        setTimeout(this.onResize, 500);
        return false;
      }
      this.menuContentWidth = w;
    },
  },
};
</script>
<style scoped lang="scss">
.sv-sticky-container {
  height: 100%;
}
.sv-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 80px;
  max-height: calc(100vh - 80px);
  padding-bottom: 20px;
  overflow-y: hidden;
}
.sv-article::v-deep h3 {
  margin-top: -60px;
  padding-top: 100px;
}

.sv-chapter-title {
  border-left: 4px solid transparent;
  border-color: transparent;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);

  &.sv-menu-active {
    border-color: #2196f3;
  }
}

.mobile-menu {
  position: fixed;
  top: 80px;
  right: -54px;
  transition: right 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  z-index: 9;
  box-shadow: 0px 5px 14px 0px rgba(18, 38, 63, 0.08),
    0px 9px 10px 0px rgba(48, 49, 51, 0.12),
    0px 5px 5px 0px rgba(48, 49, 51, 0.16);

  &.inactive,
  &.inactive > .sv-menu-content {
    box-shadow: none;
  }

  .sv-menu-button {
    position: relative;
    width: 54px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;

    &.sv-menu-button--filled {
      background: var(--v-corporateBlueAdditional-lighten3);
    }
    &.sv-menu-button--opaque {
      background: white;
    }
  }

  .sv-menu-content {
    position: absolute;
    top: 0;
    left: 100%;
    max-width: calc(100vw - 80px);
    min-width: 260px;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
    box-shadow: 0px 5px 14px 0px rgba(18, 38, 63, 0.08),
      0px 9px 10px 0px rgba(48, 49, 51, 0.12),
      0px 5px 5px 0px rgba(48, 49, 51, 0.16);
    z-index: 10;

    .sv-menu-item {
      min-height: 24px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;
    }
  }
}

@media (min-width: 1024px) {
  .mobile-menu {
    top: 166px;

    .sv-menu-content {
      max-height: calc(100vh - 166px);
    }
  }
}
</style>
