import Vue from "vue";
import Router from "vue-router";
import Store from "@/store";
import Main from "@/pages/Main";
import About from "@/pages/About/index.vue";
import Career from "@/pages/Career/index.vue";
import CareerJobOffer from "@/pages/CareerJobOffer";
import Contacts from "@/pages/Contacts";
import LandingMultiplatform from "@/pages/Landings/Multiplatform/index.vue";
import LandingEsim from "@/pages/Landings/Esim/index.vue";
import LandingCeir from "@/pages/Landings/Ceir/index.vue";
import LandingFreesmsc from "@/pages/Landings/Freesmsc/index.vue";
import LandingDmsDigest from "@/pages/Landings/DmsDigest/index.vue";
import LandingSmsc from "@/pages/Landings/Smsc/index.vue";
import NewsList from "@/pages/NewsList";
import NewsItem from "@/pages/NewsItem";
import Products from "@/pages/Products";
import Product from "@/pages/Product";
import Clients from "@/pages/Clients";
import Search from "@/pages/Search";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import Invitation from "@/pages/Invitation";
import CustomPage from "@/pages/CustomPage";
import Qr from "@/pages/Qr/index.vue";
import Thanks from "@/pages/CrossPages/Thanks.vue";
import PageNotFound from "@/pages/CrossPages/PageNotFound.vue";
import { sync as RouterSync } from "vuex-router-sync";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import redirectRoutes from "./routes/redirects.js";

Vue.use(Router);

// Создание экземпляра Router
const SvRouter = new Router({
  mode: "history",
  base: process.env.VUE_APP_CLI_PUBLIC_PATH || undefined,

  // При переходе скролить к верху страницы или к Хэшу / ID
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return {
        ...savedPosition,
        behavior: "instant",
      };
    }

    if (to.hash) {
      const element = document.getElementById(to.hash.substring(1));
      if (element) {
        elementScrollIntoView(element, { behavior: "smooth" });
        return {};
      }
    }

    return {};
  },

  routes: [
    ...Object.entries(redirectRoutes).map((entry, index) => ({
      path: entry[0],
      name: `Redirect${index}`,
      redirect: () => {
        return entry[1];
      },
    })),
    {
      path: "/",
      name: "Main",
      component: Main,
    },
    {
      path: "/about",
      name: "About",
      component: About,
    },
    {
      path: "/career",
      name: "Career",
      component: Career,
    },
    {
      path: "/career/:ident",
      name: "CareerJobOffer",
      component: CareerJobOffer,
      props: true,
    },
    {
      path: "/contacts",
      name: "Contacts",
      component: Contacts,
    },
    {
      path: "/multiplatform",
      name: "LandingMultiplatform",
      component: LandingMultiplatform,
      meta: {
        headerClass: "header--dark",
      },
    },
    {
      path: "/esim",
      name: "LandingEsim",
      component: LandingEsim,
    },
    {
      path: "/ceir",
      name: "LandingCeir",
      component: LandingCeir,
    },
    {
      path: "/freesmsc",
      name: "LandingFreesmsc",
      component: LandingFreesmsc,
    },
    {
      path: "/dms-digest",
      name: "LandingDmsDigest",
      component: LandingDmsDigest,
    },
    {
      path: "/smsc",
      name: "LandingSmsc",
      component: LandingSmsc,
    },
    {
      path: "/news",
      name: "NewsList",
      component: NewsList,
      props: true,
    },
    {
      path: "/news/:id",
      name: "NewsItem",
      component: NewsItem,
      props: true,
    },
    {
      path: "/products",
      component: Products,
      props: true,
      children: [
        {
          path: "",
          name: "Products",
          meta: {
            filter: 0,
          },
        },
        {
          path: "all",
          name: "ProductsAll",
          meta: {
            filter: 1,
          },
        },
        {
          path: "custom",
          name: "ProductsCustom",
          meta: {
            filter: 2,
          },
        },
      ],
    },
    {
      path: "/clients",
      name: "Clients",
      component: Clients,
    },
    {
      path: "/search",
      name: "Search",
      component: Search,
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
    },
    {
      path: "/invitation",
      name: "Invitation",
      component: Invitation,
    },
    {
      path: "/thanks",
      name: "Thanks",
      component: Thanks,
    },
    {
      path: "/:lang(ru|en)/*",
      name: "CaptureLanguageLink",
      redirect: (to) => {
        const { params } = to;
        return `/${params.pathMatch || ""}`;
      },
    },
    {
      path: "/pages/:ident",
      name: "CustomPage",
      component: CustomPage,
      props: true,
    },
    {
      path: "/qr/:hash",
      name: "Qr",
      component: Qr,
      props: true,
    },
    {
      path: "/404",
      name: "PageNotFound",
      component: PageNotFound,
    },
    {
      path: "/:ident",
      name: "Product",
      component: Product,
      props: true,
    },
    {
      path: "*",
      name: "NoRoute",
      redirect: () => {
        return `/404`;
      },
    },
  ],
});

RouterSync(Store, SvRouter);
Vue.router = SvRouter;

export default SvRouter;
