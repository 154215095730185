<template>
  <div>
    <v-row class="section__title-row">
      <v-col class="text-center">
        <span class="heading2">{{ advantagesData.fields.title || "" }}</span>
      </v-col>
    </v-row>
    <row-icon-text-blocks :items="advantagesData.children" />
  </div>
</template>

<script>
import RowIconTextBlocks from "@/components/RowIconTextBlocks.vue";
export default {
  name: "Advantages",
  components: {
    RowIconTextBlocks,
  },
  props: {
    advantagesData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
