<template>
  <div>
    <v-row class="section__title-row">
      <v-col class="text-center">
        <span class="heading1">{{ advantagesData.fields.title || "" }}</span>
      </v-col>
    </v-row>
    <image-scroll-sticky
      class="section"
      :items="advantagesData.children"
      :pad-image="padImage"
      :item-style="{
        left: '0%',
        top: '0%',
      }"
      :paragraph-max-multiplier="1.5"
    />
  </div>
</template>

<script>
import ImageScrollSticky from "./ImageScrollSticky.vue";
export default {
  components: {
    ImageScrollSticky,
  },
  props: {
    advantagesData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
    padImage() {
      return this.advantagesData.children[0].fields.image ?? null;
    },
  },
};
</script>
