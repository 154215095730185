<template>
  <div class="section">
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ studentsData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="align-start justify-space-between">
      <v-col cols="12" lg="5">
        <kk-dynamic-html :html="studentsData.fields.text_left" />
      </v-col>

      <v-col v-if="studentsData.fields.button_link" cols="12" lg="6">
        <v-row>
          <v-col cols="12">
            <kk-dynamic-html :html="studentsData.fields.text_right_opened" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="" class="flex-md-grow-0">
            <v-btn
              outlined
              x-large
              block
              link
              :href="studentsData.fields.button_link"
              target="_blank"
              color="primary"
            >
              {{ studentsData.fields.button_title }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-else cols="12" lg="6">
        <kk-dynamic-html
          :html="studentsData.fields.text_right_closed"
          class="mb-6"
        />
        <kk-dynamic-html
          :html="studentsData.fields.text_right_closed_post"
          class="body1 textLight--text"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "ForStudents",
  components: { KkDynamicHtml },
  props: {
    studentsData: {
      type: Object,
      required: true,
    },
  },
};
</script>
