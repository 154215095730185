<template>
  <div ref="chartdonutcontainer">
    <v-row no-gutters class="mt-0">
      <v-col ref="chartcol" cols="12" class="order-lg-last mb-3 mb-lg-5">
        <chart-responsive
          ref="chart"
          :chart-options="currentChartOptions"
          :width="width"
          :height="width * 0.75"
        />
      </v-col>
      <v-col class="d-flex justify-center flex-grow-0 mr-3 mb-3 mb-lg-5">
        <v-btn-toggle
          v-model="selectedYearIndex"
          mandatory
          class="stat-chart-selector"
          active-class="stat-chart-selector__active"
        >
          <v-row no-gutters class="justify-start justify-md-center flex-nowrap">
            <v-col v-for="year in chartKeys.years" :key="year.id">
              <v-btn
                :large="!bigButtons"
                :x-large="bigButtons"
                tile
                depressed
                block
                class="chart-index-btn"
              >
                {{ year.title }}
              </v-btn>
            </v-col>
          </v-row>
        </v-btn-toggle>
      </v-col>
      <v-spacer />
      <v-col class="d-flex justify-left mb-3 mb-lg-5">
        <v-btn-toggle
          v-model="selectedZoneIndex"
          mandatory
          class="stat-chart-selector"
          active-class="stat-chart-selector__active"
        >
          <v-row no-gutters class="justify-start justify-md-center flex-nowrap">
            <v-col v-for="zone in chartKeys.zones" :key="zone.id">
              <v-btn
                :large="!bigButtons"
                :x-large="bigButtons"
                tile
                depressed
                block
                class="chart-index-btn"
              >
                {{ zone[zoneTitleField] }}
              </v-btn>
            </v-col>
          </v-row>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChartResponsive from "@/components/Charts/ChartResponsive.vue";
import ChartResponsiveConfigFactory from "@/components/Charts/ChartResponsiveConfigFactory.js";

export default {
  name: "StatisticsChartDonut",
  components: {
    ChartResponsive,
  },
  props: {
    chartPayload: {
      type: Object,
      required: true,
    },
    chartKeys: {
      type: Object,
      required: true,
    },
    chartAngles: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      chartConfig: ChartResponsiveConfigFactory(),
      selectedYearIndex: 0,
      selectedZoneIndex: 0,
    };
  },
  computed: {
    bigButtons() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    zoneTitleField() {
      return ["xs", "sm", "lg"].includes(this.$vuetify.breakpoint.name)
        ? "title_short"
        : "title";
    },
    currentChartOptions() {
      const series =
        this.chartPayload[`zone${this.selectedZoneIndex}`][
          `year${this.selectedYearIndex}`
        ];
      const startAngle =
        this.chartAngles[`zone${this.selectedZoneIndex}`][
          `year${this.selectedYearIndex}`
        ];
      return this.chartConfig.getConfig(
        "pie",
        { series, startAngle },
        this.$vuetify.breakpoint.name
      );
    },
  },
  methods: {
    async refreshChart() {
      await this.$refs.chart.refreshChart();
    },
    getHeight() {
      return this.$refs.chartdonutcontainer.clientHeight;
    },
  },
};
</script>
<style lang="scss" scoped>
.stat-chart-selector {
  overflow-x: auto;

  .theme--light.chart-index-btn {
    border: none;
    &:hover::before,
    &::before {
      opacity: 0;
    }
    .v-ripple__container {
      display: none !important;
    }
  }
  .stat-chart-selector__active {
    background-color: var(--v-primary-base) !important;
    color: white !important;
  }
}
</style>
