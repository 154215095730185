<template>
  <div v-if="ratingFloat >= 0.0" class="d-flex align-center">
    <div class="subtitle1 rating-value">
      {{ ratingString }}
    </div>
    <div v-for="(starImg, index) in stars" :key="index" class="hhstar">
      <img class="d-block" :src="`${baseUrl}img/${starImg}`" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EmployeesAboutCompanyRating",

  props: {
    rating: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      maxRating: 5,
      starEmpty: "hh-star-empty.svg",
      starHalf: "hh-star-half.svg",
      starFull: "hh-star-full.svg",
    };
  },

  computed: {
    ratingFloat() {
      const parsed = parseFloat(this.rating.replace(",", "."));
      if (isNaN(parsed)) {
        return -1.0;
      }
      return Math.max(Math.min(parsed, this.maxRating), 0.0);
    },

    ratingString() {
      return this.ratingFloat.toString().padEnd(3, ".0");
    },

    stars() {
      const fullCount = Math.floor(this.ratingFloat);
      const decimalRemainder = this.ratingFloat - fullCount;

      let oneStar = this.starEmpty;
      if (decimalRemainder > 0.25 && decimalRemainder <= 0.75) {
        oneStar = this.starHalf;
      } else if (decimalRemainder > 0.75) {
        oneStar = this.starFull;
      }

      const result = [];
      for (let i = 0; i < fullCount; i++) {
        result.push(this.starFull);
      }
      if (result.length < this.maxRating) {
        result.push(oneStar);
      }
      while (result.length < this.maxRating) {
        result.push(this.starEmpty);
      }
      return result;
    },

    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style scoped>
.hhstar {
  padding-left: 2px;
}

.rating-value {
  margin-right: 6px;
}
</style>
