<template>
  <div>
    <v-row id="offer-title" class="section__title-row offer-child">
      <v-col class="text-center">
        <span class="heading1">{{ sectionData.fields.title || "" }}</span>
      </v-col>
    </v-row>
    <div class="parallax-container">
      <div
        class="parallax-waves stick-to-body d-none d-md-block"
        :style="getWavesStyle"
      >
        <v-img :src="`${baseUrl}img/waves.svg`" eager />
      </div>
      <div class="parallax-text" :style="getTextStyle">
        <v-row class="justify-center">
          <v-col cols="12" lg="8" xl="9" class="col-xxl-6">
            <v-row
              v-for="(offer, offerIndex) in sectionData.children"
              :key="offerIndex"
              no-gutters
              class="offer-row"
            >
              <v-col class="offer" cols="12">
                <v-row no-gutters>
                  <v-col class="offer-index flex-grow-0">{{
                    offerIndex + 1
                  }}</v-col>
                  <v-col
                    cols="12"
                    sm=""
                    class="subtitle1 align-self-center"
                    v-html="offer.fields.text"
                  />
                  <v-col
                    v-if="offer.fields.image"
                    cols="12"
                    class="text-center"
                  >
                    <img :src="offer.fields.image" class="mt-8" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
export default {
  name: "Offer",
  mixins: [ProgressObserverMixin],
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      breakpoint: "md",
      obvOptions: {
        viewportTopPct: 20,
        viewportBottomPct: 70,
        pivotBegin: "top",
        pivotEnd: "top",
      },
    };
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
    getWavesStyle() {
      // уходим если размер окна вообще не про это
      if (!this.$vuetify.breakpoint[`${this.breakpoint}AndUp`]) return {};
      const progress = this.obv("offer-title");
      return {
        "margin-top": `${50 * (1 - progress)}vh`,
      };
    },
    getTextStyle() {
      // уходим если размер окна вообще не про это
      if (!this.$vuetify.breakpoint[`${this.breakpoint}AndUp`]) return {};
      const acceleration = 3;
      const progress = this.obv("offer-title") * acceleration;
      return {
        top: `${50 * (acceleration - progress)}vh`,
      };
    },
  },
  methods: {
    initObserver() {
      this.obvAddElement("offer-title");
    },
  },
};
</script>
<style scoped lang="scss">
.offer-row {
  & + .offer-row {
    margin-top: 20px;
  }
  .offer {
    padding: 32px;
    background-color: var(--v-primaryAdditional4-base);
    border-radius: 12px;

    .offer-index {
      padding: 4px;
      text-align: center;
      margin-right: 20px;
      min-width: 70px;
      color: var(--v-corporateBlue-base);
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
    }

    img {
      max-width: 100%;
    }
  }
}

@media (min-width: 640px) {
  .offer-row {
    & + .offer-row {
      margin-top: 30px;
    }
    .offer {
      .offer-index {
        padding: 0;
        font-size: 96px;
        line-height: 72px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .parallax-container {
    position: relative;

    .parallax-waves {
      position: absolute;
      top: -40px;
      height: 50%;
      left: 0;
      right: 0;
    }
    .parallax-text {
      position: relative;
    }
  }
}
</style>
