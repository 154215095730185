<template>
  <div v-if="pageLoaded">
    <heading :page-data="pageData" />

    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{
          mpData.multiplatform_useful.fields.title || ""
        }}</span>
      </v-col>
    </v-row>

    <v-row class="section">
      <v-col
        v-for="(usefulBlock, index) in mpData.multiplatform_useful.children"
        :key="index"
        cols="12"
        md="4"
      >
        <div class="subtitle1 section__subtitle">
          {{ usefulBlock.fields.title }}
        </div>
        <div v-html="usefulBlock.fields.text" />
      </v-col>
    </v-row>

    <div v-if="clients.length > 0">
      <v-row class="section__title-row">
        <v-col>
          <span class="heading2">{{ mpData.fields.clients_title || "" }}</span>
        </v-col>
      </v-row>

      <v-row class="section">
        <v-col
          v-for="client in clients"
          :key="client.id"
          cols="12"
          md="6"
          lg="4"
        >
          <client-card :client-data="client" />
        </v-col>
      </v-row>
    </div>

    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{
          mpData.multiplatform_feedback.fields.title || ""
        }}</span>
      </v-col>
    </v-row>

    <div class="section">
      <v-row
        v-for="(mpfeedback, index) in mpData.multiplatform_feedback.children"
        :key="index"
        class="section__title-row"
      >
        <v-col cols="12" lg="5">
          <blockquote>
            <div v-html="mpfeedback.fields.annotation" />
            <h6>
              {{ mpfeedback.fields.title }}<br />
              {{ mpfeedback.fields.position }}<br />
              <a :href="link(mpfeedback.fields.link)">{{
                mpfeedback.fields.link
              }}</a>
            </h6>
          </blockquote>
        </v-col>
        <v-col cols="12" lg="7">
          <div v-html="mpfeedback.fields.full_text" />
        </v-col>
      </v-row>
    </div>

    <form-communication
      :form-id="pageData.products_page.links.feedback_form[0] || 0"
      :person-set="mpData.links.person || []"
    />

    <zuruck />
  </div>
</template>

<script>
import ClientCard from "@/components/ClientCard/ClientCard.vue";
import FormCommunication from "@/components/FormCommunication.vue";
import LandingRepository from "@/api/landing.js";
import Heading from "./Heading.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    Heading,
    ClientCard,
    FormCommunication,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.mpData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        main_page: { fields: {} },
        products_page: {
          fields: {},
          links: { feedback_form: [] },
          landing_multiplatform: {
            fields: {},
            multiplatform_useful: { fields: {}, children: [] },
            multiplatform_feedback: { fields: {}, children: [] },
            links: { person: [], product_category: [], client: [] },
          },
        },
      },
    };
  },
  computed: {
    mpData() {
      return this.pageData.products_page.landing_multiplatform;
    },
    clients() {
      return this.mpData.links.client
        .map((id) => this.$store.getters.getClient(id))
        .filter((client) => client !== undefined);
    },
    link() {
      return (link) => {
        if (link.startsWith("http://") || link.startsWith("https://"))
          return link;
        return `http://${link}`;
      };
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await LandingRepository.getMultiplatform();
      this.pageData = data;
      this.$store.commit(
        "setLangsAvail",
        data.products_page.landing_multiplatform.published
      );
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
