<template>
  <div class="presentation-card">
    <div class="presentation-container">
      <div
        v-if="!hasCatchups"
        class="presentation-check d-flex align-center justify-center mx-auto mb-16"
      >
        <img
          class="presentation-check-img ml-1 mt-1"
          :src="`${baseUrl}img/presentation-thanks-check.svg`"
          alt=""
        />
      </div>
      <div class="heading2 text-center mb-3">
        {{ catchupDialog.fields.thank_you_title }}
      </div>
      <div
        class="body1 mb-16 text-center"
        v-html="catchupDialog.fields.thank_you_text"
      />
      <div v-if="!hasCatchups" class="text-center">
        <v-btn
          depressed
          x-large
          color="primary"
          @click="$emit('close-dialog')"
          >{{ catchupDialog.fields.thank_you_button }}</v-btn
        >
      </div>
      <div v-else>
        <div class="presentation-catchup-title pt-6 mb-6 text-center">
          {{ catchupBlockTitle }}
        </div>
        <presentation-thanks-catchup
          v-for="(item, index) in catchups"
          :key="index"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PresentationThanksCatchup from "@/components/PresentationPreview/PresentationThanksCatchup.vue";
export default {
  name: "PresentationThanks",
  components: { PresentationThanksCatchup },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    catchupProducts() {
      const result = [];
      Object.entries(this.product?.links || {}).forEach((entry) => {
        entry[1].forEach((id) => {
          const product = this.$store.getters.getProduct(id);
          if (product) {
            result.push(product);
          }
        });
      });
      return result;
    },
    catchupLinks() {
      return this.product?.children || [];
    },
    catchups() {
      if (this.catchupLinks.length > 0) {
        return this.catchupLinks.map((item) => ({
          title: item.fields.title,
          url: item.fields.link_url,
          text: item.fields.text,
        }));
      } else if (this.catchupProducts.length > 0) {
        return this.catchupProducts.map((item) => ({
          title: item.fields.title,
          ident: item.node_name,
          text: item.fields.subtitle,
        }));
      } else {
        return [];
      }
    },
    hasCatchups() {
      return this.catchupProducts.length + this.catchupLinks.length > 0;
    },
    catchupDialog() {
      return this.$store.getters.getCatchupDialogSection;
    },
    catchupBlockTitle() {
      if (this.catchupLinks.length > 0) {
        return (
          this.product.fields.catchup_title ||
          this.catchupDialog.fields.more_custom_links
        );
      } else if (this.catchupProducts.length > 1) {
        return this.catchupDialog.fields.more_some_products;
      } else if (this.catchupProducts.length == 1) {
        return this.catchupDialog.fields.more_one_product;
      } else {
        return "";
      }
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
<style scoped lang="scss">
.presentation-card {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  padding: 64px 48px;
  box-shadow: 0px 0px 12px 0px rgba(48, 49, 51, 0.03),
    0px 0px 24px 0px rgba(48, 49, 51, 0.03);

  .presentation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;

    .presentation-check {
      border-radius: 50%;
      width: 72px;
      height: 72px;
      box-shadow: 0px 96px 132px 0px rgba(33, 150, 243, 0.08),
        0px 14px 22px 0px rgba(33, 150, 243, 0.1),
        0px 7px 22px 0px rgba(0, 133, 238, 0.2);
    }

    .presentation-catchup-title {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
