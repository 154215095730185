<template>
  <div class="motion-item corporateBlue--text">
    <svg
      class="motion-item__svg"
      width="250"
      height="155"
      :viewBox="`0 0 250 155`"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :fill="colors[0]"
        :stroke="colors[0]"
        stroke-width="8.40"
        :transform="`scale(${itemProgress(0)})`"
        d="M4.20 4.41H111.81C119.24 4.41 125.26 10.43 125.26 17.86V27.95C125.26 35.38 119.24 41.40 111.81 41.4H17.65C10.23 41.40 4.20 35.38 4.20 27.95V4.41Z"
      />
      <path
        :fill="colors[1]"
        :stroke="colors[1]"
        stroke-width="8.40"
        transform-origin="243.80 58.21"
        :transform="`scale(${itemProgress(1)})`"
        d="M243.80 58.21H71.46C64.09 58.21 58.00 64.23 58.00 71.66V81.75C58.00 89.18 64.02 95.20 71.46 95.20H230.34C237.78 95.20 243.80 89.18 243.80 81.75V58.21Z"
      />
      <path
        :fill="colors[2]"
        :stroke="colors[2]"
        stroke-width="8.40"
        transform-origin="0 100"
        :transform="`scale(${itemProgress(2)})`"
        d="M4.20 112.02H111.81C119.24 112.02 125.26 118.04 125.26 125.47V135.55C125.26 142.98 119.24 149 111.81 149H17.65C10.22 149 4.20 142.98 4.20 135.55V112.02Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AdvantageMotion3",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      colors: ["#F5C14D", "#4DABF5", "#80C342"],
      startTimes: [0.15, 0.3, 0.45],
      duration: 0.3,
    };
  },
  computed: {
    itemProgress() {
      return (index) => {
        const newProgress =
          (this.progress - this.startTimes[index]) / this.duration;
        if (newProgress < 0) return 0;
        if (newProgress > 1) return 1;
        return newProgress;
      };
    },
  },
};
</script>
