<template>
  <div>
    <div class="subtitle1 mb-5 mb-md-10 px-4 px-md-5">
      {{ title }}
    </div>
    <div class="stat-block-text mb-5 mb-md-10 body1" v-html="text" />
  </div>
</template>

<script>
export default {
  name: "StatisticsParagraph",
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.stat-block-text {
  background: #ffffff;
  box-shadow: 0px 3.3px 12.375px rgba(20, 90, 147, 0.1),
    0px 16.5px 41.25px rgba(16, 73, 118, 0.15);
  border-radius: 12px;
  padding: 12px 16px;
  @media (min-width: 1024px) {
    padding: 20px 24px;
  }
}
</style>
