<template>
  <div class="sv-notification">
    <div class="sv-close">
      <div class="dialog-close clickable" @click="$emit('close', false)">
        <v-icon color="rgba(0, 0, 0, 0.32)">mdi-close</v-icon>
      </div>
    </div>
    <v-row class="align-center justify-start justify-lg-space-between">
      <v-col
        class="align-self-center d-flex align-center justify-start flex-wrap"
        cols="11"
        md=""
      >
        <div class="heading2 text-no-wrap mr-4 d-none d-sm-block">
          {{ notificationData.fields.title }}
        </div>
        <div class="heading2 text-no-wrap d-none d-sm-block">
          {{ notificationData.fields.subtitle }}
        </div>
        <div class="subtitle2 text-no-wrap mr-2 d-sm-none">
          {{ notificationData.fields.title }}
        </div>
        <div class="subtitle2 text-no-wrap d-sm-none">
          {{ notificationData.fields.subtitle }}
        </div>
      </v-col>
      <v-col
        class="flex-grow-0 sv-notification-logo__container"
        cols="12"
        md=""
      >
        <header-logo class="sv-notification-logo" locale="en" />
      </v-col>
      <v-col
        class="align-self-center flex-grow-0"
        cols="12"
        sm=""
        md="12"
        xl=""
      >
        <v-btn
          depressed
          x-large
          :block="$vuetify.breakpoint.xsOnly"
          color="primary"
          href="https://soft.sc/"
          target="_blank"
        >
          {{ notificationData.fields.button_title }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeaderLogo from "./Header/HeaderLogo.vue";
export default {
  name: "NotificationLang",
  components: { HeaderLogo },
  props: {
    notificationData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.sv-notification {
  position: fixed;
  height: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 40px 16px;
  background-color: var(--v-corporateBlueAdditional-lighten4);
  z-index: 270;

  .dialog-close {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &.hover,
    &:hover {
      background: rgba(128, 128, 128, 0.15);
    }
  }

  .sv-close {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .sv-notification-logo__container {
    min-width: 360px;
    min-height: 90px;
    max-width: 360px;
    max-height: 90px;

    .sv-notification-logo {
      transform: scale(2.4) translateX(85px) translateY(7px);
    }
  }
}
@media (min-width: 640px) {
  .sv-notification {
    padding: 40px 40px;

    .sv-close {
      right: 30px;
      top: 30px;
    }
  }
}
@media (min-width: 1024px) {
  .sv-notification {
    padding-left: 127px;
    padding-right: 55px;

    .sv-notification-logo__container {
      .sv-notification-logo {
        transform: scale(1.4) translateX(50px) translateY(7px);
      }
    }
  }
}

@media (min-width: 1280px) {
  .sv-fulltext {
    max-width: 610px;
    min-width: 610px;
  }
}

@media (min-width: 1440px) {
  .sv-notification {
    padding-left: 135px;
    padding-right: 135px;
  }
}

@media (min-width: 1920px) {
  .sv-notification {
    padding-left: 175px;
    padding-right: 175px;
  }
  .sv-header-and-text {
    flex-grow: 1 !important;
    .row {
      justify-content: space-around !important;
    }
  }
  .sv-fulltext {
    max-width: 450px;
    min-width: 450px;
  }
}
</style>
