<template>
  <div v-resize="onScroll">
    <v-row
      v-if="hasControlButtons"
      no-gutters
      class="align-end justify-space-between mb-12"
    >
      <v-col>
        <slot name="prepend_to_buttons" />
      </v-col>
      <v-col
        v-if="canGoLeft || canGoRight"
        class="flex-grow-0 d-none d-md-flex flex-nowrap ml-8"
      >
        <v-btn
          color="primary"
          outlined
          large
          :disabled="!canGoLeft"
          @click="scrollLeft()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          :disabled="!canGoRight"
          class="ml-5"
          @click="scrollRight()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters class="band-wrapper flex-lg-nowrap">
      <slot name="static_block_left" />
      <v-col class="band-content flex-grow-1 col-12 col-lg">
        <v-row no-gutters class="band-block">
          <v-col
            ref="bandContainer"
            v-dragscroll.x
            class="band-container"
            :class="`band-container__dir-${directionOfItems}`"
            @scroll="onScroll"
            @dragscrollstart="onDragStart"
            @dragscrollend="onDragEnd"
          >
            <v-row
              no-gutters
              class="flex-nowrap band justify-start"
              :style="dragRowStyle"
            >
              <v-col
                v-for="(item, index) in items"
                :key="itemKey.length > 0 ? item[itemKey] : index"
                class="band-item flex-grow-0"
                :style="{ 'min-width': `${step}px` }"
              >
                <slot :item="item" :index="index" />
              </v-col>
            </v-row>
          </v-col>
          <div
            class="d-none d-md-block fog fog__left"
            :class="{ 'fog--visible': canGoLeft }"
          />
          <div
            class="d-none d-md-block fog fog__right"
            :class="{ 'fog--visible': canGoRight }"
          />
        </v-row>
      </v-col>
      <slot name="static_block_right" />
    </v-row>

    <v-row
      v-if="hasControlButtons"
      class="d-flex d-md-none justify-space-around mt-0"
    >
      <v-col class="flex-grow-0">
        <v-btn
          color="primary"
          outlined
          large
          :disabled="!canGoLeft"
          @click="scrollLeft()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col class="flex-grow-0">
        <v-btn
          color="primary"
          outlined
          large
          :disabled="!canGoRight"
          class="ml-5"
          @click="scrollRight()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { scrollTo } from "seamless-scroll-polyfill";

export default {
  name: "HorizontalScroller",

  props: {
    items: {
      type: Array,
      required: true,
    },
    itemWidth: {
      type: Number,
      required: true,
    },
    hasControlButtons: {
      type: Boolean,
      default: false,
    },
    // ltr -- начало скролла в начале списка элементов
    // rtl -- начало скролла в конце списка элементов
    directionOfItems: {
      type: String,
      default: "ltr",
    },
    // начиная с какого зазора "примагничиваться" к краям,
    // если недокрутили совсем чуть-чуть
    snapWidth: {
      type: Number,
      default: 64,
    },
    itemKey: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      canGoLeft: this.directionOfItems === "rtl",
      canGoRight: this.directionOfItems !== "rtl",
      dragging: false,
    };
  },

  computed: {
    step() {
      return this.itemWidth + 15 * 2;
    },

    directionClass() {
      if (this.directionOfItems == "ltr") {
        return "band-container__ltr";
      } else if (this.directionOfItems == "rtl") {
        return "band-container__rtl";
      } else {
        return "";
      }
    },

    dragRowStyle() {
      const style = {};
      if (this.dragging) {
        style["pointer-events"] = "none";
      }
      return style;
    },
  },

  async mounted() {
    this.onScroll();

    // ожидаем fade-in всего сайта, если сайт грузится с нуля
    await new Promise((resolve) => setTimeout(resolve, 1000));
    this.onScroll();
  },

  methods: {
    getMaxScrollLeft(container) {
      return this.directionOfItems == "rtl"
        ? -(container.scrollWidth - container.clientWidth)
        : 0;
    },

    getMaxScrollRight(container) {
      return this.directionOfItems == "rtl"
        ? 0
        : container.scrollWidth - container.clientWidth;
    },

    scrollLeft() {
      const container = this.$refs.bandContainer;
      if (container) {
        const scrollPos = container.scrollLeft - this.step;
        const maxScrollPos = this.getMaxScrollLeft(container);
        const left =
          scrollPos - maxScrollPos < this.snapWidth ? maxScrollPos : scrollPos;
        scrollTo(container, { left, behavior: "smooth" });
      }
    },

    scrollRight() {
      const container = this.$refs.bandContainer;
      if (container) {
        const scrollPos = container.scrollLeft + this.step;
        const maxScrollPos = this.getMaxScrollRight(container);
        const left =
          maxScrollPos - scrollPos < this.snapWidth ? maxScrollPos : scrollPos;
        scrollTo(container, { left, behavior: "smooth" });
      }
    },

    onScroll() {
      const container = this.$refs.bandContainer;
      if (container) {
        this.canGoRight =
          container.scrollLeft < this.getMaxScrollRight(container);
        this.canGoLeft =
          container.scrollLeft > this.getMaxScrollLeft(container);
      }
    },

    onDragStart() {
      this.dragging = true;
      this.$emit("drag-start");
    },

    onDragEnd() {
      this.dragging = false;
      this.$emit("drag-end");
    },
  },
};
</script>

<style lang="scss" scoped>
.band-wrapper .band-content,
.band {
  min-width: 0;
}
.band-block {
  position: relative;
  user-select: none;
  cursor: grab;

  .band-container {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;

    &.band-container__dir-ltr {
      direction: ltr;
    }
    &.band-container__dir-rtl {
      direction: rtl;
    }

    .band > * {
      direction: ltr;
    }
  }

  .fog {
    position: absolute;
    top: 0;
    width: 170px;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s linear;
    pointer-events: none;

    &.fog--visible {
      opacity: 1;
      transition: opacity 0.3s linear;
    }

    &.fog__left {
      left: 0;
      background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 41.14%
      );
    }

    &.fog__right {
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 41.14%
      );
    }
  }
}

@media (max-width: 1280px) {
  .band-block {
    .fog {
      width: 70px;
    }
  }
}

@media (max-width: 1024px) {
  .band-block {
    margin-left: -127px;
    margin-right: -127px;
  }
}

@media (max-width: 640px) {
  .band-block {
    margin-left: -16px;
    margin-right: -16px;
  }
}
</style>
