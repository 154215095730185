<template>
  <div class="rowCircle">
    <div ref="loaderCircle">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrbitsAnimated",
  data() {
    return {};
  },
  computed: {},
  mounted() {
    setTimeout(
      function () {
        setTimeout(
          function () {
            if (this.$refs.loaderCircle)
              this.$refs.loaderCircle.className = "loader";
          }.bind(this),
          0
        );
        setTimeout(
          function () {
            if (this.$refs.loaderCircle) this.$refs.loaderCircle.className = "";
          }.bind(this),
          3000
        );
      }.bind(this),
      1500
    );
  },
};
</script>

<style>
.rowCircle {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

@keyframes animate-circle1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.9;
  }
  100% {
    transform: scale(2.3);
    opacity: 0;
  }
}

@keyframes animate-circle2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.8;
  }
  100% {
    transform: scale(2.6);
    opacity: 0;
  }
}

@keyframes animate-circle3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.4);
    opacity: 0.7;
  }
  100% {
    transform: scale(2.8);
    opacity: 0;
  }
}

@keyframes animate-circle4 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.6;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
@keyframes animate-circle5 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.9);
    opacity: 0.5;
  }
  100% {
    transform: scale(3.8);
    opacity: 0;
  }
}

.loader {
  aspect-ratio: 1;
  opacity: 1;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.loader > .circle {
  --duration: 3s;

  position: absolute;
  height: inherit;
  width: inherit;
  border: 2px solid var(--v-primaryAdditional3-base);
  border-radius: 50%;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.loader > .circle:nth-of-type(1) {
  height: 30%;
  width: 30%;
  animation: animate-circle1 var(--duration) cubic-bezier(0.25, 0.1, 0.25, 1)
    infinite;
  animation-delay: 0s;
}
.loader > .circle:nth-of-type(2) {
  height: 37%;
  width: 37%;
  animation: animate-circle2 var(--duration) cubic-bezier(0.25, 0.1, 0.25, 1)
    infinite;
  animation-delay: calc(var(--duration) / -4);
}
.loader > .circle:nth-of-type(3) {
  height: 45%;
  width: 45%;
  animation: animate-circle3 var(--duration) cubic-bezier(0.25, 0.1, 0.25, 1)
    infinite;
  animation-delay: calc(var(--duration) / -5);
}
.loader > .circle:nth-of-type(4) {
  height: 55%;
  width: 55%;
  animation: animate-circle4 var(--duration) cubic-bezier(0.25, 0.1, 0.25, 1)
    infinite;
  animation-delay: calc(var(--duration) / -1);
}
.loader > .circle:nth-of-type(5) {
  height: 65%;
  width: 65%;
  animation: animate-circle5 var(--duration) cubic-bezier(0.25, 0.1, 0.25, 1)
    infinite;
  animation-delay: calc(var(--duration) / -2);
}
</style>
