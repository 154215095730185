<template>
  <div class="gradient-block">
    <div class="gradient-title">
      {{ title }}
      <span class="gradient-title-subtitle">
        {{ subtitle }}
      </span>
    </div>
    <div v-if="item.fields.subtitle">
      {{ item.fields.subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.item.fields.title.split(" ")?.[0];
    },
    subtitle() {
      return this.item.fields.title.split(" ")?.slice(1)?.join(" ");
    },
  },
};
</script>

<style lang="scss">
.gradient-block {
  width: fit-content;
  .gradient-title {
    white-space: nowrap;
    background-image: linear-gradient(
      248deg,
      rgba(3, 98, 175, 1) 14.5%,
      rgba(33, 150, 243, 1) 70.63%
    );
    background-size: 100%;
    background-repeat: repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    font-family: "Open Sans" !important;
    font-weight: 700;
    font-size: 66px !important;
    line-height: 120% !important;

    .gradient-title-subtitle {
      font-size: 56px !important;
    }
  }
}
@media (min-width: 640px) {
  .gradient-block {
    .gradient-title {
      font-size: 96px !important;

      .gradient-title-subtitle {
        font-size: 80px !important;
      }
    }
  }
}
</style>
