<template>
  <div>
    <v-dialog
      class="presentation-dialog"
      content-class="dialog"
      :value="showGalleryDialog"
      persistent
      @input="$emit('input', $event)"
    >
      <div class="dialog-close clickable" @click.stop.prevent="onClose">
        <v-icon :size="24" style="z-index: 8">mdi-close</v-icon>
      </div>

      <div class="presentation-dialog-content">
        <image-gallery
          ref="refgal"
          :items="slideItems"
          non-cycled
          blurred
          class="presentation-gallery"
          @scroll-right="onScrollRight"
          @scroll-left="onScrollLeft"
          @end-right="onEndRight"
        >
          <presentation-form
            v-if="$vuetify.breakpoint.xlOnly"
            v-show="!formFilled"
            ref="refform"
            class="presentation-form-window"
            :class="formClass"
            :product-ident="product.node_name"
            @commit="formFilled = true"
            @close-dialog="onClose"
          />
          <presentation-thanks
            v-if="$vuetify.breakpoint.xlOnly"
            v-show="formFilled"
            class="presentation-form-window"
            :class="formClass"
            :product="product"
            @close-dialog="onClose"
          />
        </image-gallery>
      </div>
    </v-dialog>
    <presentation-dialog-fallback
      v-model="open"
      :product="product"
      with-ear
      @ear-left="scrollLeft"
    />
  </div>
</template>
<script>
import ImageGallery from "@/components/PresentationPreview/ImageGallery.vue";
import PresentationForm from "@/components/PresentationPreview/PresentationForm.vue";
import PresentationThanks from "@/components/PresentationPreview/PresentationThanks.vue";
import PresentationDialogFallback from "./PresentationDialogFallback.vue";

export default {
  name: "PresentationDialog",
  components: {
    ImageGallery,
    PresentationForm,
    PresentationThanks,
    PresentationDialogFallback,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showForm: true,
      formFilled: false,
    };
  },
  computed: {
    open: {
      get() {
        return this.value && this.showForm && !this.$vuetify.breakpoint.xlOnly;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    slideItems() {
      return this.product?.fields?.catchup_presentation?.items || [];
    },
    formClass() {
      return this.showForm ? "visible" : "";
    },
    showGalleryDialog() {
      return this.value && (!this.showForm || this.$vuetify.breakpoint.xlOnly);
    },
  },
  watch: {
    value(newV) {
      if (newV) {
        this.showForm = false;
        this.formFilled = false;
        this.$refs.refgal?.reset();
        this.$refs.refform?.reset();
      }
    },
  },
  methods: {
    onClose() {
      this.$emit("input", false);
    },
    onScrollLeft() {
      this.showForm = false;
    },
    onScrollRight(index) {
      if (
        index === this.slideItems.length - 2 &&
        !this.$vuetify.breakpoint.xlOnly
      ) {
        this.showForm = true;
      }
    },
    onEndRight() {
      this.showForm = true;
    },
    scrollLeft() {
      this.$refs.refgal?.scrollLeft();
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .dialog {
  width: 80vw;
  max-width: 80vw;
  @media (min-width: 1440px) {
    width: 100vw;
    max-width: 1170px;
  }
  margin: 0;
  max-height: 100% !important;
  overflow: visible;
  position: relative;
}

.dialog-close {
  top: 5px;
  right: -50px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  @media (max-width: 1023px) {
    top: -45px;
    right: 0;
  }

  .v-icon {
    color: #fff;
  }
  &.hover,
  &:hover {
    box-shadow: 0px 0px 12px 0px rgba(48, 49, 51, 0.03),
      0px 0px 24px 0px rgba(48, 49, 51, 0.03);
  }
}

.presentation-dialog-content {
  width: 80vw;
  max-width: 80vw;
  @media (min-width: 1440px) {
    width: 100vw;
    max-width: 1170px;
  }
  height: 100%;
  max-height: 100%;
}
.presentation-gallery {
  height: calc(80vw * 658 / 1170);
  @media (min-width: 1440px) {
    height: 658px;
  }
}

.presentation-form-window {
  @media (max-width: 1024px) {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    right: -100vw;
  }

  --shadingTime: 0.5s;

  position: absolute;
  top: 0;
  right: -570px;
  width: 570px;
  height: 100%;
  z-index: 3;
  border-radius: 6px;
  transition: right var(--shadingTime) ease-out;
  &.visible {
    right: 0;
  }
}
</style>
