<template>
  <v-row class="justify-center">
    <v-col cols="12" class="col-xl-9">
      <v-row>
        <v-col cols="12">
          <breadcrumbs class="no-marker px-0 mb-9" :items="breadcrumbsItems" />
        </v-col>
      </v-row>
      <v-row class="section heading__section align-center justify-center">
        <v-col cols="12" md="" class="heading__content mr-md-2 mr-lg-4 mr-xl-0">
          <p class="mb-1 mb-md-2">
            <badge
              v-for="(categoryItem, index) in categoriesList"
              :key="index"
              :text="categoryItem?.title || ''"
              :color="categoryItem?.color || ''"
            />
          </p>
          <h1 class="mb-4 mb-md-8">
            {{ pageData.fields.title_ext || "" }}
          </h1>
          <div class="mb-md-8 body1" v-html="pageData.fields.annotation" />
          <v-row
            v-if="!$vuetify.breakpoint.mdAndUp"
            class="justify-center align-center"
          >
            <v-col cols="12" class="motion-overlay">
              <heading-motion class="motion" />
            </v-col>
          </v-row>
          <v-row class="justify-start">
            <v-col class="flex-sm-grow-0 pb-1" cols="12" sm="">
              <v-btn
                depressed
                x-large
                block
                color="primary"
                @click="showDialog = true"
              >
                {{ pageData.fields.button_consult }}
              </v-btn>
            </v-col>
            <v-col
              class="pb-1 flex-sm-grow-0 d-flex justify-center"
              cols="12"
              sm=""
            >
              <masked-icon-link
                :mask-icon="`${baseUrl}img/pdf.svg`"
                :title="pageData.products_page.fields.button_presentation || ''"
                @click="runPresentation"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          cols="12"
          md=""
          class="motion-overlay"
        >
          <heading-motion class="motion" />
        </v-col>
      </v-row>

      <form-communication-modal v-model="showDialog" />

      <presentation-dialog
        v-model="showPresentationDialog"
        :product="pageData"
      />

      <presentation-dialog-fallback
        v-model="showPresentationFallbackDialog"
        :product="pageData"
      />
    </v-col>
  </v-row>
</template>

<script>
import Breadcrumbs from "@/components/Article/Breadcrumbs.vue";
import Badge from "@/components/Badge.vue";
import FormCommunicationModal from "@/components/FormCommunicationModal.vue";
import PresentationDialog from "@/components/PresentationPreview/PresentationDialog.vue";
import PresentationDialogFallback from "@/components/PresentationPreview/PresentationDialogFallback.vue";
import MaskedIconLink from "@/components/MaskedIconLink.vue";
import HeadingMotion from "./HeadingMotion.vue";

export default {
  name: "Heading",
  components: {
    Breadcrumbs,
    Badge,
    FormCommunicationModal,
    MaskedIconLink,
    HeadingMotion,
    PresentationDialog,
    PresentationDialogFallback,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      showPresentationDialog: false,
      showPresentationFallbackDialog: false,
    };
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_page.fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.products_page.fields.title || "",
          to: { name: "Products" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.fields.title_ext || "",
          disabled: true,
        },
      ];
    },
    categoriesList() {
      const category = this.$store.getters.getProductCategory(
        this.pageData.pid
      );
      if (this.pageData.pid && category) {
        return [category];
      } else {
        return [];
      }
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
  methods: {
    runPresentation() {
      if ((this.pageData.fields.catchup_presentation?.items || []).length > 0) {
        this.showPresentationDialog = true;
      } else {
        this.showPresentationFallbackDialog = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.heading__section {
  padding-bottom: 40px;
  .heading__content {
    z-index: 5;
  }
}

.motion-overlay {
  position: relative;
  width: 170px;
  height: 330px;
}

@media (min-width: 640px) {
  .heading__section {
    .motion-overlay {
      width: 280px;
      height: 500px;
      margin-bottom: -20px;
    }
  }
}

@media (min-width: 1024px) {
  .heading__section {
    padding-bottom: 0px;
    .motion-overlay {
      flex-grow: 0;
      min-width: 320px;
      height: 600px;
      .motion {
        width: 120%;
      }
    }
  }
}

@media (min-width: 1280px) {
  .heading__section {
    .motion-overlay {
      min-width: 450px;
      height: 640px;
      .motion {
        width: 100%;
      }
    }
  }
}
@media (min-width: 1440px) {
  .heading__section {
    .motion-overlay {
      margin-bottom: -50px;
      min-width: 300px;
      height: 740px;
      .motion {
        width: 150%;
      }
    }
  }
}
@media (min-width: 1920px) {
  .heading__section {
    .motion-overlay {
      min-width: 500px;
      height: 740px;
      .motion {
        width: 100%;
      }
    }
  }
}
</style>
