<template>
  <div v-if="pageLoaded" class="body-smsc">
    <div class="content-smsc">
      <heading :page-data="pageData" />
      <experience :experience-data="pageData.smsc_experience" />
      <implementation :implementation-data="pageData.smsc_implementation" />
      <advantages :advantages-data="pageData.smsc_advantages" />
      <functional :functional-data="pageData.smsc_functional" />
      <pilot :pilot-data="pageData.smsc_pilot" />
      <free-test :free-test-data="pageData.smsc_free_test" />
      <homegrown
        v-if="pageData.smsc_homegrown"
        :homegrown-data="pageData.smsc_homegrown"
      />
      <form-communication
        :form-id="pageData.links.feedback_form[0] || 0"
        :person-set="pageData.links.person"
      />
    </div>
    <zuruck />
  </div>
</template>

<script>
import LandingRepository from "@/api/landing.js";
import Heading from "./Heading.vue";
import Experience from "./Experience.vue";
import Implementation from "./Implementation.vue";
import Advantages from "./Advantages.vue";
import Functional from "./Functional.vue";
import Pilot from "./Pilot.vue";
import FreeTest from "./FreeTest.vue";
import Homegrown from "./Homegrown.vue";
import FormCommunication from "@/components/FormCommunication.vue";
import Zuruck from "@/components/Zuruck.vue";
export default {
  components: {
    Heading,
    Experience,
    Implementation,
    Advantages,
    Functional,
    Pilot,
    FreeTest,
    Homegrown,
    FormCommunication,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        links: { feedback_form: [], person: [] },
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await LandingRepository.getSmsc();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>

<style lang="scss" scoped>
.body-smsc {
  margin-top: -92px;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -80px;
  overflow-x: clip;
  overflow-y: visible;
}
.content-smsc {
  padding-top: 92px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 80px;
}
@media (min-width: 640px) {
  .body-smsc {
    margin-left: -127px;
    margin-right: -127px;
    margin-bottom: -160px;
  }
  .content-smsc {
    padding-left: 127px;
    padding-right: 127px;
    padding-bottom: 160px;
  }
}

@media (min-width: 1280px) {
  .body-smsc {
    margin-top: -160px;
    margin-left: -55px;
    margin-right: -55px;
    margin-bottom: -160px;
  }
  .content-smsc {
    padding-top: 160px;
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 160px;
  }
}

@media (min-width: 1440px) {
  .body-smsc {
    margin-left: -135px;
    margin-right: -135px;
    margin-bottom: -160px;
  }
  .content-smsc {
    padding-left: 135px;
    padding-right: 135px;
    padding-bottom: 160px;
  }
}

@media (min-width: 1920px) {
  .body-smsc {
    margin-left: -175px;
    margin-right: -175px;
    margin-bottom: -160px;
  }
  .content-smsc {
    padding-left: 175px;
    padding-right: 175px;
    padding-bottom: 160px;
  }
}
</style>
