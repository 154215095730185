<template>
  <div v-if="pageLoaded">
    <article-with-contents
      ref="refarticle"
      :contents="pageData.product.fields.contents"
      :breadcrumbs="breadcrumbsItems"
      :class="`section sv-article-category-${pageData.product.pid}`"
    >
      <template v-slot:aside>
        <badge :text="categoryField('title')" :color="categoryField('color')" />
      </template>

      <v-row>
        <v-col>
          <h1>{{ pageData.product.fields.title }}</h1>
        </v-col>
      </v-row>

      <v-row v-if="pageData.product.fields.subtitle">
        <v-col class="pt-0">
          <span class="subtitle1">{{ pageData.product.fields.subtitle }}</span>
        </v-col>
      </v-row>

      <v-row
        v-if="
          pageData.product.fields.custom_project &&
          pageData.products_page.fields.product_custom_subtitle
        "
      >
        <v-col class="pt-0 pb-10 d-flex align-center">
          <span class="body3 textLight--text">{{
            pageData.products_page.fields.product_custom_subtitle
          }}</span>
        </v-col>
      </v-row>

      <v-row
        v-if="
          (pageData.product.fields.catchup_presentation?.items || []).length > 0
        "
        class="align-center"
      >
        <v-col
          class="col-anim flex-grow-0 clickable"
          @click="showDialog = true"
        >
          <image-carousel :items="slideItems" height="80px" />
          <div class="anim-shader" />
        </v-col>

        <v-col
          cols="12"
          md=""
          class="flex-grow-0 clickable"
          @click="showDialog = true"
        >
          <div
            class="presentation-link btn-text text-no-wrap primary--text d-flex align-center"
          >
            <span>{{
              pageData.products_page.fields?.button_presentation || ""
            }}</span>
            <v-icon class="primary--text">mdi-chevron-right</v-icon>
          </div>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col>
          <masked-icon-link
            :mask-icon="`${baseUrl}img/pdf.svg`"
            :title="pageData.products_page.fields.button_presentation || ''"
            :color="categoryField('color')"
            @click="runPresentation"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <article>
            <kk-dynamic-html :html="pageData.product.fields.full_text" />
          </article>
        </v-col>
      </v-row>
    </article-with-contents>

    <v-row v-if="catchupProducts.length > 0" class="section">
      <v-col cols="12" class="mb-4 mb-md-8">
        <span class="heading2">{{
          pageData.products_page.fields.catchup_title
        }}</span>
      </v-col>
      <v-col
        v-for="catchupProduct in catchupProducts"
        :key="catchupProduct.id"
        cols="12"
        lg="6"
        xl="4"
      >
        <product-card :product-id="catchupProduct.id" short />
      </v-col>
    </v-row>

    <form-communication
      :form-id="formId"
      :person-set="pageData.product.links.person || []"
    />

    <presentation-dialog v-model="showDialog" :product="pageData.product" />

    <presentation-dialog-fallback
      v-model="showPresentationFallbackDialog"
      :product="pageData.product"
    />
  </div>
</template>

<script>
import ProductsRepository from "@/api/products.js";
import Badge from "@/components/Badge.vue";
import FormCommunication from "@/components/FormCommunication.vue";
import ArticleWithContents from "@/components/Article/ArticleWithContents.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ProductCard from "@/components/ProductCard/ProductCard.vue";
import ImageCarousel from "@/components/PresentationPreview/ImageCarousel.vue";
import PresentationDialog from "@/components/PresentationPreview/PresentationDialog.vue";
import PresentationDialogFallback from "@/components/PresentationPreview/PresentationDialogFallback.vue";
import MaskedIconLink from "@/components/MaskedIconLink.vue";

export default {
  name: "Product",

  components: {
    Badge,
    FormCommunication,
    ArticleWithContents,
    KkDynamicHtml,
    ProductCard,
    ImageCarousel,
    PresentationDialog,
    PresentationDialogFallback,
    MaskedIconLink,
  },

  props: {
    ident: {
      type: String,
      required: true,
    },
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.pageData.products_page.fields.title
      ),
    };
  },

  data() {
    return {
      showDialog: false,
      showPresentationFallbackDialog: false,
      pageLoaded: false,
      pageData: {
        main_page: {
          fields: {},
        },
        products_page: {
          fields: {},
          links: { feedback_form: [] },
        },
        product: { fields: {}, links: { person: [] }, pid: "", children: [] },
      },
    };
  },

  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_page.fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.products_page.fields.title || "",
          to: { name: "Products" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.product.fields.title || "",
          disabled: true,
        },
      ];
    },

    baseUrl() {
      return process.env.BASE_URL;
    },

    category() {
      return this.$store.getters.getProductCategory(this.pageData.product.pid);
    },

    categoryField() {
      return (field) => (this.category ? this.category[field] : "");
    },

    formId() {
      return (
        this.pageData.product?.links?.feedback_form?.[0] ||
        this.pageData.products_page.links.feedback_form[0] ||
        0
      );
    },

    catchupProducts() {
      const result = [];
      Object.entries(this.pageData.product?.links || {}).forEach((entry) => {
        entry[1].forEach((id) => {
          const product = this.$store.getters.getProduct(id);
          if (product) {
            result.push(product);
          }
        });
      });
      return result;
    },

    slideItems() {
      return this.pageData.product?.fields?.catchup_presentation?.items || [];
    },
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },

  mounted() {
    return this.loadPage();
  },

  methods: {
    async loadPage() {
      try {
        const { data } = await ProductsRepository.getProductItem(this.ident);
        this.pageData = data;
        this.$store.commit("setLangsAvail", data.product.published);
        this.pageLoaded = true;
        this.$emit("page-loaded");
        this.$nextTick(() => {
          this.$refs.refarticle.initObserver();
        });
      } catch (error) {
        return true;
      }
    },

    runPresentation() {
      if (
        (this.pageData.product.fields.catchup_presentation?.items || [])
          .length > 0
      ) {
        this.showPresentationDialog = true;
      } else {
        this.showPresentationFallbackDialog = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.col-anim {
  position: relative;
  min-width: 336px;

  .anim-shader {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 40%);
    z-index: 2;
  }
}

@media (min-width: 1024px) {
  .presentation-link {
    position: relative;
    margin-left: -60px;
    z-index: 3;
  }
}
</style>
