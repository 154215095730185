<template>
  <v-row class="section__content justify-space-between">
    <!-- sticky col -->
    <v-col
      cols="12"
      md=""
      :class="`d-none d-${breakpoint}-block`"
      :style="stickyColumnStyle"
    >
      <div class="sv-sticky-container">
        <div class="sv-sticky" :style="stickyElementStyle">
          <div
            ref="refcontainer"
            class="slideshow-container"
            :style="`width:${stickyWidth}px`"
          >
            <v-img
              class="slideshow-image-pad"
              :src="padImage"
              eager
              @load="updateSticky"
            />
            <v-img
              v-for="(image, index) in stickyImages"
              :key="index"
              class="slideshow-image"
              eager
              :src="image"
              :style="stickyImageStyle(index)"
            />
          </div>
        </div>
      </div>
    </v-col>
    <v-col
      cols="12"
      md=""
      class="paragraph-container"
      :class="`ml-${breakpoint}-10`"
      :style="paragraphColumnStyle"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="align-center"
        :class="`d-${breakpoint}-flex`"
        :style="
          $vuetify.breakpoint[`${breakpoint}AndUp`]
            ? `height: ${paragraphInfo.max}px`
            : ''
        "
      >
        <v-row
          no-gutters
          class="d-flex justify-center paragraph-slideshow-image"
          :class="`d-${breakpoint}-none`"
        >
          <v-col cols="12" md="8" class="slideshow-container">
            <v-img :src="item.fields.image" :style="paragraphImageStyle" />
          </v-col>
        </v-row>
        <v-row no-gutters class="align-top">
          <v-col ref="refparagraph" cols="12" sm="">
            <div class="mb-1">
              <badge
                :key="index"
                :text="(index + 1).toString().padStart(2, '0') || ''"
                color="#80C342"
                text-class="body-3"
              />
            </div>
            <div class="subtitle1 mb-3">
              {{ item.fields.title }}
            </div>
            <div class="corporateGrey--text" v-html="item.fields.subtitle" />
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Badge from "@/components/Badge.vue";
export default {
  name: "ImageScrollSticky",
  components: {
    Badge,
  },
  props: {
    // ожидается массив объектов вида
    // { fields: {title: '...', description: '...', image: '...' }}
    items: {
      type: Array,
      required: true,
    },
    // изображение-подложка, оно же и будет задавать размер sticky-элемента
    padImage: {
      type: String,
      required: true,
    },
    // доп. стили изображения (как правило,
    // смещение и размер относительно подложки)
    itemStyle: {
      type: Object,
      default: () => {},
    },
    // точка изменения поведения (sm, md, lg, xl)
    breakpoint: {
      type: String,
      default: "md",
    },
    // на каком относительном расстоянии будет начинаться
    // смена изображения на следующее/предыдущее
    fadeDistance: {
      type: Number,
      default: 0.15,
    },
    // дополнительные поля для параграфов
    paragraphMaxMultiplier: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // храним высоту липкого элемента (берём из него)
      stickyHeight: 0,
      // храним и пересчитываем длину липкого элемента (от размера окна)
      stickyWidth: 0,
      // храним высоты параграфов (обновляем по событиям)
      paragraphInfo: {
        max: 0, // макс. высота параграфа
        first: 0, // высота первого параграфа
        last: 0, // высота последнего параграфа
      },
      // храним глобальную позицию скролла (обновляем по событию)
      position: 0,
    };
  },
  computed: {
    // список изображений для показа
    stickyImages() {
      return this.items.map((item) => item.fields.image);
    },

    // настройки директивы sticky
    stickyOptions() {
      return {
        // чтобы липкий компонент всегда был в центре окна по вертикали
        topSpacing: Math.floor((window.innerHeight - this.stickyHeight) / 2),
        bottomSpacing: 0,
      };
    },

    // стиль контейнера в котором бегает липкий элемент
    stickyElementStyle() {
      return {
        top: `${this.stickyOptions.topSpacing}px`,
      };
    },

    // стиль контейнера в котором бегает липкий элемент
    stickyColumnStyle() {
      return {
        "max-width": `${this.stickyWidth + 30}px`, // жестко и бескомпромиссно ограничим по ширине
      };
    },

    // прозрачность ротируемого изображения в липком элемента
    stickyImageOpacity() {
      return (index) => {
        const max = this.paragraphInfo.max;
        const pivotMin = index * max;
        const pivotMax = (index + 1) * max;
        const { position } = this;
        const dy = max * this.fadeDistance;
        // краевые условия
        if (index == 0 && position < pivotMin + dy) return 1;
        if (index == this.stickyImages.length - 1 && position >= pivotMax - dy)
          return 1;

        // если мы находимся внутри окна отображения
        if (position >= pivotMin + dy && position < pivotMax - dy) return 1;
        // если мы находимся в начале окна отображения
        if (position >= pivotMin - dy && position < pivotMin + dy) {
          return (dy - pivotMin + position) / (2 * dy);
        }
        // если мы находимся в конце окна отображения
        if (position >= pivotMax - dy && position < pivotMax + dy) {
          return (dy + pivotMax - position) / (2 * dy);
        }
        // не показываем во всех остальных случаях
        return 0;
      };
    },

    // стиль ротируемого изображения в липком элементе
    stickyImageStyle() {
      return (index) => ({
        ...this.itemStyle,
        opacity: this.stickyImageOpacity(index),
      });
    },

    // стиль обычного изображения в мобильной версии
    paragraphImageStyle() {
      return {
        opacity: 1,
        ...this.itemStyle,
      };
    },

    // стиль контейнера с кучей параграфов
    paragraphColumnStyle() {
      if (!this.$vuetify.breakpoint[`${this.breakpoint}AndUp`]) {
        return {};
      }

      const offsetTop = Math.floor(
        (this.paragraphInfo.max - this.paragraphInfo.first) / 2
      );
      const offsetBottom = Math.floor(
        (this.paragraphInfo.max - this.paragraphInfo.last) / 2
      );
      return {
        "margin-top": `-${offsetTop}px`,
        "margin-bottom": `-${offsetBottom}px`,
      };
    },
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    updateSticky() {
      // увы, но только вот так. прибиваем ширину липкого компонента гвоздями
      this.stickyWidth = Math.floor(window.innerWidth * 0.33);
      // ждём пока изображение поменяв ширину, поменяет и высоту
      this.$nextTick(() => {
        this.stickyHeight = this.$refs.refcontainer.clientHeight;
      });

      // высчитываем высоту максимального параграфа + где заканчиваются первый и последний параграфы
      const paragraphInfo = this.$refs.refparagraph.reduce(
        (product, item) => {
          const height = item.clientHeight;
          product.last = height;
          if (product.first == 0) {
            product.first = height;
          }
          product.max = Math.max(product.max, height);
          return product;
        },
        { max: 0, first: 0, last: 0 }
      );
      paragraphInfo.max *= this.paragraphMaxMultiplier;
      this.paragraphInfo = paragraphInfo;

      this.onScroll();
    },

    onScroll() {
      // уходим если размер окна вообще не про sticky элемент
      if (!this.$vuetify.breakpoint[`${this.breakpoint}AndUp`]) return;

      this.position = Math.floor(
        window.scrollY - // от текущей позиции скролла в окне
          this.$refs.refparagraph[0].offsetTop + // вычитаем смещение первого параграфа
          this.stickyOptions.topSpacing + // добавляем смещение липкого компонента в окне
          this.paragraphInfo.max / 2 // добавляем половину высоты блока параграфа
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.sv-sticky-container {
  height: 100%;

  .sv-sticky {
    position: sticky;
    position: -webkit-sticky;
  }
}

.slideshow-container {
  position: relative;

  .slideshow-image-pad {
    z-index: 0;
  }

  .slideshow-image {
    position: absolute !important;
    z-index: 6;
    opacity: 0;
  }
}
.paragraph-container {
  :first-child .paragraph-slideshow-image {
    margin-top: 0;
  }
  .paragraph-slideshow-image {
    margin-top: 120px;
    margin-bottom: 64px;
  }
}
@media (max-width: 640px) {
  .paragraph-container {
    .paragraph-slideshow-image {
      margin-top: 60px;
      margin-bottom: 20px;
    }
  }
}
</style>
