<template>
  <div class="section">
    <v-row class="section__title">
      <v-col>
        <span class="heading2">{{ listSection.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="align-center px-md-8 mb-8">
      <v-col class="flex-grow-md-0 mx-md-6" style="height: fit-content">
        <image-slide-show
          :images="imagesSection.children.map((img) => img.fields.image)"
          :image-pad="`${baseUrl}img/pad.svg`"
          :item-style="{
            left: '3.6%',
            top: '5.4%',
            width: '93%',
          }"
          @trigger-resize="$emit('trigger-resize')"
        />
      </v-col>
      <v-col class="mx-md-6">
        <div class="mb-8" v-html="imagesSection.fields.text" />
        <v-row>
          <v-col cols="12" md="" class="flex-md-grow-0">
            <v-btn
              :to="{ name: 'ProductsAll' }"
              color="primary"
              outlined
              x-large
              block
              exact
            >
              {{ imagesSection.fields.button_products }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <row-icon-text-blocks
      :items="listSection.children"
      @trigger-resize="$emit('trigger-resize')"
    />
  </div>
</template>

<script>
import ImageSlideShow from "@/components/ImageSlideShow.vue";
import RowIconTextBlocks from "@/components/RowIconTextBlocks.vue";

export default {
  name: "ActivityDirections",
  components: {
    ImageSlideShow,
    RowIconTextBlocks,
  },
  props: {
    imagesSection: {
      type: Object,
      required: true,
    },
    listSection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
