<template>
  <div v-if="pageLoaded && isDisplayIdentic">
    <div class="huge-text mt-10">
      {{ pageData.fields.check_identic_of_device }}
    </div>
  </div>

  <div v-else-if="pageLoaded">
    <heading
      :page-data="pageData"
      @scroll-to-offer="onOfferSolution"
      @scroll-to-about="onAboutSolution"
    />

    <about-us :about-data="pageData.qr_about_us" />

    <our-team :our-team-data="pageData.qr_our_team" />

    <favourite-products
      id="offer-solution"
      :fav-data="pageData.qr_offer"
      with-title
      :with-footer="false"
    />

    <favourite-products :fav-data="pageData.qr_other_products" with-title />

    <form-communication
      :form-id="pageData.links[0] || 0"
      :person-set="pageData.qr_about_us.links || []"
    />

    <zuruck />
  </div>
</template>

<script>
import QrRepository from "@/api/qr.js";
import FavouriteProducts from "@/components/FavouriteProducts/FavouriteProducts.vue";
import FormCommunication from "@/components/FormCommunication.vue";
import AboutUs from "./AboutUs.vue";
import Heading from "./Heading.vue";
import OurTeam from "./OurTeam.vue";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import Zuruck from "@/components/Zuruck.vue";

export default {
  name: "Qr",

  components: {
    FavouriteProducts,
    FormCommunication,
    AboutUs,
    Heading,
    OurTeam,
    Zuruck,
  },

  props: {
    hash: {
      type: String,
      required: true,
    },
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(null),
    };
  },

  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        links: [],
        qr_about_us: { fields: {}, children: [], logo_children: [] },
        qr_our_team: { fields: {}, links: [] },
        qr_offer: { fields: {}, children: [] },
        qr_other_products: { fields: {}, children: [] },
      },
    };
  },

  computed: {
    isDisplayIdentic() {
      return (this.pageData.fields.check_identic_of_device || "").length > 0;
    },
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },

  mounted() {
    return this.loadPage();
  },

  methods: {
    async loadPage() {
      try {
        const { data } = await QrRepository.getQr(this.hash);
        this.pageData = data;
        this.$store.commit("setLangsAvail", data.published);
        this.pageLoaded = true;
        this.$emit("page-loaded");
      } catch (error) {
        return true;
      }
    },

    onAboutSolution() {
      elementScrollIntoView(document.querySelector("#about-solution"), {
        behavior: "smooth",
      });
    },

    onOfferSolution() {
      elementScrollIntoView(document.querySelector("#offer-solution"), {
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#offer-solution {
  margin-top: -90px;
  padding-top: 90px;
}

.huge-text {
  font-size: 150px;
  font-weight: bold;
}
</style>
