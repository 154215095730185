<template>
  <v-row class="justify-center">
    <v-col cols="12" class="col-xxl-9">
      <v-row class="section__title-row">
        <v-col class="text-center">
          <span class="heading1">{{ sectionData.fields.title || "" }}</span>
        </v-col>
      </v-row>
      <v-row
        v-for="(problem, problemIndex) in sectionData.children"
        :key="problemIndex"
        class="align-stretch problem-row"
      >
        <v-col
          :id="`problem-id-${problemIndex}`"
          cols="12"
          md=""
          class="problem-column"
        >
          <div
            class="problem-title subtitle1 d-flex flex-column justify-center"
            v-html="problem.fields.text"
          />
        </v-col>
        <v-col cols="12" md="" class="problem-solution-column">
          <div
            class="problem-solution-box"
            :style="getSolutionStyle(problemIndex)"
          >
            <div
              v-for="(solution, solutionIndex) in problem.children"
              :key="solutionIndex"
              class="problem-solution"
            >
              <div class="problem-solution__icon">
                <v-icon color="success" size="32">mdi-checkbox-marked</v-icon>
              </div>
              <div class="body3" v-html="solution.fields.text" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
export default {
  name: "Problems",
  mixins: [ProgressObserverMixin],
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      breakpoint: "md",
      obvOptions: {
        viewportTopPct: 50,
        viewportBottomPct: 90,
        pivotBegin: "top",
        pivotEnd: "top",
      },
    };
  },
  computed: {
    getSolutionStyle() {
      return (problemIndex) => {
        // уходим если размер окна вообще не про это
        if (!this.$vuetify.breakpoint[`${this.breakpoint}AndUp`]) return {};
        const opacity = this.obv(`problem-id-${problemIndex}`);
        const left = `${-100 * (1 - opacity)}%`;
        return {
          left,
          opacity,
        };
      };
    },
  },
  methods: {
    initObserver() {
      const local = this;
      this.sectionData.children.forEach((problem, problemIndex) => {
        local.obvAddElement(`problem-id-${problemIndex}`);
      });
    },
  },
};
</script>
<style lang="scss">
.problem-row {
  margin-bottom: 50px;
  overflow-x: hidden;

  .problem-column {
    background-color: #fff;
    background: linear-gradient(
      to right,
      #fff 0%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0) 100%
    );

    position: relative;
    z-index: 2;
    .problem-title {
      box-shadow: 0px 3.29981px 12px rgba(228, 74, 74, 0.15),
        0px -2.5px 30px rgba(228, 74, 74, 0.17);
      background-color: #fff;
      border-radius: 12px;
      height: 100%;
      padding: 32px;
      p {
        b,
        strong {
          color: var(--v-error-base);
          font-weight: 700;
        }
      }
    }
  }
}

.problem-solution-box {
  position: relative;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px -1.7px 11.37px rgba(49, 199, 92, 0.22),
    0px 16.499px 21.25px rgba(49, 199, 92, 0.13);
  border-radius: 12px;

  .problem-solution {
    padding: 18px 18px 18px 54px;
    position: relative;
    border-bottom: 1px solid var(--v-corporateBorder-base);

    .problem-solution__icon {
      position: absolute;
      left: 15px;
      top: 17px;
    }
  }
  .problem-solution:last-child {
    border: none;
  }
}

@media (min-width: 1024px) {
  .problem-column {
    .problem-title {
      box-shadow: 0px 3.29981px 12.3743px rgba(228, 74, 74, 0.1),
        0px 7.5px 61.25px rgba(228, 74, 74, 0.15);
    }
    max-width: 330px;
  }
  .problem-solution-box {
    box-shadow: 0px -1.7px 22.37px rgba(49, 199, 92, 0.25),
      0px 16.499px 41.2476px rgba(49, 199, 92, 0.15);
  }
}

@media (min-width: 1280px) {
  .problem-column {
    max-width: 465px;
  }
}
</style>
