<template>
  <div id="about-solution" class="section">
    <div class="heading1 mb-16">
      {{ aboutData.fields.title || "" }}
    </div>

    <row-icon-text-blocks :items="aboutData.children" />

    <div class="section about-chips-image">
      <v-img :src="aboutData.fields.chips_image || ''" eager />
    </div>

    <div id="about-about-chips-images">
      <logo-slider
        v-for="(line, index) in aboutData.logo_children"
        :key="index"
        :items="line"
        :direction="index % 2 == 0 ? 'left' : 'right'"
        :progress="slidersProgress()"
      />
    </div>
  </div>
</template>

<script>
import RowIconTextBlocks from "@/components/RowIconTextBlocks.vue";
import LogoSlider from "@/components/LogoSlider.vue";
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
export default {
  name: "AboutUs",

  components: { RowIconTextBlocks, LogoSlider },

  mixins: [ProgressObserverMixin],

  props: {
    aboutData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    slidersProgress() {
      return () => {
        const progress = this.obv(`about-about-chips-images`);
        return progress;
      };
    },
  },

  mounted() {
    this.obvAddElement(`about-about-chips-images`);
  },
};
</script>

<style lang="scss" scoped>
#about-solution {
  margin-top: -90px;
  padding-top: 90px;
}

.about-chips-image {
  margin-left: -16px;
  margin-right: -16px;
}

@media (min-width: 640px) {
  .about-chips-image {
    margin-left: -127px;
    margin-right: -127px;
  }
}

@media (min-width: 1024px) {
  .about-chips-image {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
