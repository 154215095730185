<template>
  <div>
    <transition name="fadeapp">
      <v-app v-show="allLoaded">
        <div class="header">
          <Header />
        </div>
        <div class="content">
          <transition name="fade">
            <router-view :key="routeKey" @page-loaded="onPageLoaded" />
          </transition>
        </div>
        <Footer v-show="pageLoaded" />
        <notification
          v-if="hasNotificationAppear"
          :notification-data="notificationAppear"
          @close="hideNotification"
        />
        <notification-lang
          v-if="hasNotificationLang"
          :notification-data="notificationLang"
          @close="hideNotificationLang"
        />
      </v-app>
    </transition>
    <transition name="fade">
      <preloader v-show="!allLoaded" ref="preloader" @finalize="showContent" />
    </transition>
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer";
import moment from "moment";
import Preloader from "@/components/Preloader.vue";
import Notification from "@/components/Notification.vue";
import { applyCategoryStyles } from "@/components/Utilities.js";
import NotificationLang from "@/components/NotificationLang.vue";

export default {
  name: "App",
  components: { Header, Footer, Preloader, Notification, NotificationLang },
  data() {
    return {
      appInitiated: false,
      pageLoaded: false,
      allLoaded: false,
    };
  },
  computed: {
    routeKey() {
      if (this.$route.matched && this.$route.matched.length > 1) {
        return this.$route.matched[0].path;
      } else {
        return this.$route.path;
      }
    },
    hasNotificationAppear() {
      if (this.$route.name === "Invitation") {
        return false;
      } else {
        return this.$store.getters.hasNotificationAppear;
      }
    },
    hasNotificationLang() {
      return this.$store.getters.hasNotificationLang;
    },
    notificationAppear() {
      return this.$store.getters.getNotificationAppear;
    },
    notificationLang() {
      return this.$store.getters.getNotificationLang;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.initApp();
      }
    },
    routeKey() {
      this.pageLoaded = false;
    },
  },
  created() {
    this.initApp();
  },
  methods: {
    async initApp() {
      await this.$store.dispatch("fetchBase");
      const currentLocale = this.$store.getters.getLang;
      this.$vuetify.lang.current = currentLocale;
      this.$i18n.locale = currentLocale;
      moment.locale(currentLocale);

      const html = document.querySelector("html");
      html.setAttribute("lang", currentLocale);
      applyCategoryStyles(Object.entries(this.$store.getters.getAllCategories));

      this.appInitiated = true;
      this.initiateScripts();

      this.checkLoaded();
    },
    async onPageLoaded() {
      this.pageLoaded = true;
      this.checkLoaded();

      // Костыль для трейса в b24, чтобы понимать куда ходил посетитель,
      // оставивший feedback в форме обратной связи
      if (window.b24Tracker) {
        // timeout чтобы он получил название страницы, которое обновляется не сразу
        await new Promise((resolve) => setTimeout(resolve, 500));
        window.b24Tracker.guest.isInit = false;
        window.b24Tracker.guest.init();
      }
    },
    checkLoaded() {
      if (this.appInitiated && this.pageLoaded) {
        document.getElementById("app").scrollIntoView({ behavior: "instant" });
        if (this.$refs.preloader) {
          this.$refs.preloader.finish();
        }
      }
    },
    showContent() {
      this.allLoaded = true;
      this.$nextTick(() => {
        if (location.hash?.length) {
          const element = document.getElementById(location.hash.substring(1));
          if (element) {
            element.scrollIntoView({ behavior: "instant" });
          }
        } else {
          document
            .getElementById("app")
            .scrollIntoView({ behavior: "instant" });
        }
        this.$store.commit("setFinishMainLoading");
      });
    },
    hideNotification() {
      this.$store.commit("hideNotification");
    },
    hideNotificationLang() {
      this.$store.commit("hideNotificationLang");
    },
    initiateScripts() {
      const script = document.createElement("script");
      script.setAttribute("async", true);
      script.setAttribute("src", "/bitrix/call.tracker.js");
      document.body.appendChild(script);
    },
  },
};
</script>

<style lang="scss">
@import "./assets/styles/styles.scss";
</style>
<style lang="scss">
.fadeapp-enter-active,
.fadeapp-leave-active {
  transition-property: opacity;
  transition-duration: 0.5s;
}

.fadeapp-enter,
.fadeapp-leave-active {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.header {
  position: fixed;
  width: 100%;
  height: 52px;
  top: 0;
  z-index: 15;
  background-color: white;
}

.header + .content {
  padding-top: 92px;
}

.content {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 80px;
  width: 100%;
  height: 100%;
}

.footer {
  padding: 20px 16px 40px 16px;
}
@media (min-width: 640px) {
  .content {
    padding-left: 127px;
    padding-right: 127px;
    padding-bottom: 160px;
  }
  .footer {
    padding: 90px 127px;
  }
}

@media (min-width: 1024px) {
  .header {
    height: 80px;
  }
  .header + .content {
    padding-top: 160px;
  }
  .footer {
    padding: 90px 127px;
  }
}

@media (min-width: 1280px) {
  .content {
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 160px;
  }

  .footer {
    padding: 90px 55px;
  }
}

@media (min-width: 1440px) {
  .content {
    padding-left: 135px;
    padding-right: 135px;
    padding-bottom: 160px;
  }
  .footer {
    padding: 90px 135px;
  }
}

@media (min-width: 1920px) {
  .content {
    padding-left: 175px;
    padding-right: 175px;
    padding-bottom: 160px;
  }
  .footer {
    padding: 90px 175px;
  }
}
</style>
