<template>
  <v-row class="justify-center">
    <v-col cols="12" class="col-xxl-9">
      <v-row class="section__title-row offer-child">
        <v-col class="text-center">
          <span class="heading1">{{ sectionData.fields.title || "" }}</span>
        </v-col>
      </v-row>

      <v-row class="section__content align-stretch">
        <v-col cols="12" lg="4" class="d-md-flex flex-column">
          <v-row class="align-stretch">
            <v-col cols="12" md="6" lg="12">
              <advantage-item
                id="advantage-id-0"
                :item="sectionData.children[0]"
                :motion-id="0"
                :progress="obv('advantage-id-0')"
              />
            </v-col>
            <v-col cols="12" md="6" lg="12">
              <advantage-item
                id="advantage-id-1"
                :item="sectionData.children[1]"
                :motion-id="1"
                :progress="obv('advantage-id-1')"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="8" class="d-md-flex flex-column">
          <v-row class="align-stretch">
            <v-col cols="12" lg="6">
              <advantage-item
                id="advantage-id-2"
                :item="sectionData.children[2]"
                :motion-id="2"
                :progress="obv('advantage-id-2')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <advantage-item
                id="advantage-id-3"
                :item="sectionData.children[3]"
                :motion-id="3"
                :progress="obv('advantage-id-3')"
              />
            </v-col>
            <v-col cols="12" md="6" lg="12">
              <advantage-item
                id="advantage-id-4"
                :item="sectionData.children[4]"
                :motion-id="4"
                :progress="obv('advantage-id-4')"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <advantage-item
            id="advantage-id-5"
            :item="sectionData.children[5]"
            :motion-id="5"
            :progress="obv('advantage-id-5')"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AdvantageItem from "./AdvantageItem.vue";
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
export default {
  name: "Solution",
  components: { AdvantageItem },
  mixins: [ProgressObserverMixin],
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      obvOptions: {
        viewportTopPct: 30,
        viewportBottomPct: 80,
        pivotBegin: "top",
        pivotEnd: "top",
      },
    };
  },
  computed: {},
  methods: {
    initObserver() {
      const local = this;
      this.sectionData.children.forEach((advantage, advantageIndex) => {
        local.obvAddElement(`advantage-id-${advantageIndex}`);
      });
    },
  },
};
</script>
