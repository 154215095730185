<template>
  <v-chip
    color="corporateBlue-lighten4"
    text-color="corporateBlue-darken1"
    v-bind="$attrs"
    label
    class="tag-chip body3 my-1"
    v-on="$listeners"
  >
    # <slot></slot>
  </v-chip>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.tag-chip {
  height: 27px !important;
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer !important;
  transition: background-color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) !important;

  .v-chip__content {
    transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  &.v-chip--clickable:active {
    box-shadow: none;
  }
  .v-ripple__container {
    display: none !important;
  }
}
.tag-chip:hover::before {
  opacity: 0 !important;
}
.tag-chip:hover {
  transition: background-color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  background-color: var(--v-corporateBlue-base) !important;
  .v-chip__content {
    transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    color: white;
  }
}
</style>
