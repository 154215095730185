<template>
  <div id="trust-solution" :style="getSolutionStyle()">
    <div class="heading1 text-center mb-16">
      {{ trustData.fields.title || "" }}
    </div>
    <v-row class="justify-center justify-md-space-between section">
      <v-col
        v-for="(children, index) in trustData.children"
        :key="index"
        cols="12"
        md="6"
        lg="3"
      >
        <v-row class="justify-center justify-md-start">
          <v-col
            class="d-flex justify-center d-md-block text-md-left text-center"
          >
            <div class="trust-us-image flex-grow-0">
              <img
                :src="children.fields.image"
                @load="$emit('trigger-resize')"
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="justify-center justify-md-start">
          <v-col class="subtitle1 text-md-left text-center">
            {{ children.fields.title }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="text-md-left text-center"
            v-html="children.fields.text"
          />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
export default {
  name: "TrustUs",
  mixins: [ProgressObserverMixin],
  props: {
    trustData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getSolutionStyle() {
      return () => {
        const progress = this.obv(`heading-hidden-block`);
        let opacity = progress * 5;
        opacity = opacity > 1 ? 1 : opacity;
        return {
          opacity,
        };
      };
    },
  },
  mounted() {
    this.obvAddElement(`heading-hidden-block`);
  },
};
</script>
<style lang="scss" scoped>
#trust-solution {
  margin-top: -90px;
  padding-top: 90px;
}
.trust-us-image {
  display: flex;
  width: 120px;
  height: 120px;
  justify-content: center;
  padding: 25px;
  box-shadow: 0px 3px 12px rgba(0, 133, 238, 0.1),
    0px 4px 12px rgba(33, 150, 243, 0.05);
  border-radius: 50%;
}
</style>
