<template>
  <div>
    <div
      id="data-analytics-title"
      class="data-analytics-title heading2 text-center"
    >
      {{ dataAnalyticsData.fields.title || "" }}
    </div>
    <v-row class="section">
      <v-col
        v-for="(children, index) in dataAnalyticsData.children"
        :key="index"
        cols="12"
        lg="4"
      >
        <div class="data-analytics-item" :style="getSolutionStyle(index)">
          <v-row>
            <v-col class="subtitle1 pb-0">
              {{ children.fields.title }}
            </v-col>
          </v-row>
          <v-row>
            <div class="col pt-2 pt-md-3" v-html="children.fields.text" />
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
export default {
  name: "DataAnalytics",
  mixins: [ProgressObserverMixin],
  props: {
    dataAnalyticsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      obvOptions: {
        viewportTopPct: 20,
        viewportBottomPct: 60,
        pivotBegin: "top",
        pivotEnd: "bottom",
      },
    };
  },
  computed: {
    getSolutionStyle() {
      return (index) => {
        let progress = this.obv(`data-analytics-title`);
        const items_count = this.dataAnalyticsData.children?.length ?? 0;
        if (!items_count) return;
        const max = (index + 1) / items_count;
        const min = index / items_count;
        progress = min > progress ? 0 : (progress - min) / (max - min);
        progress = progress > 1 ? 1 : progress;
        const opacity = progress;
        const transform = `translateY(${100 * (1 - progress)}%)`;
        return {
          opacity,
          transform,
        };
      };
    },
  },
  mounted() {
    this.obvAddElement(`data-analytics-title`);
  },
};
</script>
<style lang="scss" scoped>
.data-analytics-item {
  padding: 12px 16px;
  box-shadow: 0px 3.29981px 12.3743px rgba(20, 90, 147, 0.1),
    0px 16.499px 41.2476px rgba(16, 73, 118, 0.15);
  border-radius: 12px;
}
.data-analytics-title {
  margin-bottom: 80px;
  position: relative;
  z-index: 6;
}
@media (min-width: 640px) {
  .data-analytics-item {
    padding: 20px 24px;
  }
}
@media (min-width: 1024px) {
  .data-analytics-title {
    margin-bottom: 160px;
  }
}
@media (min-width: 1440px) {
  .data-analytics-title {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
