<template>
  <div class="section">
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ lettersData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="section__content">
      <v-col cols="12" md="9" lg="4">
        <v-img :src="first" eager @click="showLightBox(0)" />
      </v-col>
      <v-col cols="12" class="d-block d-md-none">
        <div class="sv-about-letter">
          <v-img :src="second" eager @click="showLightBox(1)" />
        </div>
      </v-col>
      <v-col cols="12" md="3" lg="8" class="d-none d-md-block">
        <v-row>
          <v-col
            v-for="itemSmall in small3"
            :key="itemSmall.index"
            cols="12"
            lg="4"
          >
            <div class="sv-about-letter">
              <v-img
                :src="itemSmall.visibleSrc"
                eager
                @click="showLightBox(itemSmall.index)"
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="d-none d-lg-flex">
          <v-col v-for="itemTiny in tinyFrom4" :key="itemTiny.index" cols="2">
            <div class="sv-about-letter">
              <v-img
                :src="itemTiny.visibleSrc"
                eager
                @click="showLightBox(itemTiny.index)"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="d-none d-md-flex d-lg-none">
      <v-col v-for="itemTiny in tinyFrom4" :key="itemTiny.index" cols="2">
        <div class="sv-about-letter">
          <v-img
            :src="itemTiny.visibleSrc"
            eager
            @click="showLightBox(itemTiny.index)"
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex d-md-none">
      <v-col v-for="itemSmall2 in smallFrom2" :key="itemSmall2.index" cols="6">
        <div class="sv-about-letter">
          <v-img
            :src="itemSmall2.visibleSrc"
            eager
            @click="showLightBox(itemSmall2.index)"
          />
        </div>
      </v-col>
    </v-row>

    <light-box
      ref="lightbox"
      :media="letters"
      :show-thumbs="false"
      :show-caption="false"
      :show-light-box="false"
      :site-loading="siteLoading"
      disable-scroll
    />
  </div>
</template>

<script>
import LightBox from "vue-image-lightbox";
import siteLoading from "vue-image-lightbox/src/siteloading.gif";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";

export default {
  name: "Letters",
  components: { LightBox },
  props: {
    lettersData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      siteLoading,
    };
  },
  computed: {
    letters() {
      return this.lettersData.children.map((item, index) => ({
        index,
        thumb: item.fields.image,
        visibleSrc: item.fields.image,
        src: item.fields.image,
        caption: item.fields.title,
      }));
    },
    item() {
      return (index) => {
        if (!this.letters[index]) return "";
        return this.letters[index].visibleSrc || "";
      };
    },
    first() {
      return this.item(0);
    },
    second() {
      return this.item(1);
    },
    small3() {
      return this.letters.slice(1, 4);
    },
    tinyFrom4() {
      return this.letters.slice(4);
    },
    smallFrom2() {
      return this.letters.slice(2);
    },
  },
  methods: {
    showLightBox(index) {
      this.$refs.lightbox.showImage(index);
    },
  },
};
</script>

<style lang="scss">
.sv-about-letter {
  filter: drop-shadow(0px 4px 8px rgba(48, 49, 51, 0.1))
    drop-shadow(0px 0px 1px rgba(48, 49, 51, 0.15));
}
.vue-lb-container {
  background: rgba(44, 44, 44, 0.5);
  .vue-lb-content {
    margin-bottom: 0px;
    img.vue-lb-modal-image {
      max-height: calc(100vh - 60px);
    }
  }
  .vue-lb-thumbnail-wrapper {
    display: none;
  }
}
html.no-scroll {
  overflow-y: hidden;
}
</style>
