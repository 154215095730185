import { render, staticRenderFns } from "./NokiaBent.vue?vue&type=template&id=d7626852&scoped=true"
import script from "./NokiaBent.vue?vue&type=script&lang=js"
export * from "./NokiaBent.vue?vue&type=script&lang=js"
import style0 from "./NokiaBent.vue?vue&type=style&index=0&id=d7626852&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7626852",
  null
  
)

export default component.exports