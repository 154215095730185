import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
import { Plugin as VueFragment } from "vue-fragment";
import VueMeta from "vue-meta";
import VueLazyLoad from "vue-lazyload";
import VueDragscroll from "vue-dragscroll";
import applyMetrika from "@/plugins/applyMetrika";
import applyGTag from "@/plugins/applyGTag";

// Регистрация компонентов
import "./plugins/components";

Vue.use(VueFragment);
Vue.use(VueMeta);
Vue.use(VueLazyLoad);
Vue.use(VueDragscroll);

applyMetrika();
applyGTag();

Vue.config.productionTip = false;
/* eslint-disable no-new */
new Vue({
  el: "#app",
  i18n,
  router,
  store,
  vuetify,
  components: { App },
  template: "<App/>",
});
