<template>
  <article-with-contents
    v-if="pageLoaded"
    ref="refarticle"
    :contents="pageData.fields.contents"
  >
    <v-row>
      <v-col>
        <h1>{{ pageData.fields.title }}</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <article v-html="pageData.fields.full_text"></article>
      </v-col>
    </v-row>
  </article-with-contents>
</template>

<script>
import SupplementRepository from "@/api/supplement.js";
import ArticleWithContents from "@/components/Article/ArticleWithContents.vue";

export default {
  name: "PrivacyPolicy",

  components: { ArticleWithContents },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },

  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
      },
    };
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },

  mounted() {
    this.loadPage();
  },

  methods: {
    async loadPage() {
      const { data } = await SupplementRepository.getPrivacyPolicy();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
