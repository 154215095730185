<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <!-- Среднее фото сверху слева -->
        <v-col cols="12" md="6">
          <image-extended
            :image="blockData.fields.image_1"
            class="image-row-1"
          />
        </v-col>

        <!-- Пара маленьких фото справа -->
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="6">
              <image-extended
                :image="blockData.fields.image_2"
                class="image-row-1"
              />
            </v-col>

            <v-col cols="6">
              <image-extended
                :image="blockData.fields.image_3"
                class="image-row-1"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-row>
        <!-- Текст -->
        <v-col
          cols="12"
          md="4"
          lg="3"
          class="h-100 d-flex align-center align-self-center"
        >
          <div class="gallery-txt-block" v-html="blockData.fields.text" />
        </v-col>

        <!-- Фото -->
        <v-col cols="12" md="4" lg="5">
          <image-extended
            :image="blockData.fields.image_4"
            class="image-row-2"
          />
        </v-col>

        <!-- Фото -->
        <v-col cols="12" md="4" lg="4">
          <image-extended
            :image="blockData.fields.image_5"
            class="image-row-2"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  name: "Gallery6BlockLeft",

  components: { ImageExtended },

  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.image-row-1 {
  height: 62vw;

  @media (min-width: 640px) {
    height: 40vw;
  }

  @media (min-width: 1024px) {
    height: 26vw;
  }
}

.image-row-2 {
  height: 90vw;

  @media (min-width: 640px) {
    height: 50vw;
  }

  @media (min-width: 1024px) {
    height: 20vw;
  }

  @media (min-width: 1280px) {
    height: 26vw;
  }
}

.gallery-txt-block {
  @media (min-width: 1024px) {
    padding-bottom: 30px;
    max-width: 570px;
  }
}
</style>
