<template>
  <v-row class="justify-center">
    <v-col cols="12" class="col-xxl-9">
      <v-row class="section__title-row offer-child">
        <v-col class="text-center">
          <span class="heading1">{{ sectionData.fields.title || "" }}</span>
        </v-col>
      </v-row>

      <v-row class="section__content align-stretch">
        <v-col
          v-for="(why, whyIndex) in sectionData.children"
          :key="whyIndex"
          cols="12"
          md="6"
          class="mb-4 mb-md-8"
        >
          <div class="why pa-5 pa-md-10 mb-8 mb-md-10">
            <h2 class="subtitle1 mb-3">{{ why.fields.title }}</h2>
            <div class="body1" v-html="why.fields.text" />
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Why",
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.why {
  background: #fff;
  box-shadow: 0px 3.3px 12.37px rgba(20, 90, 147, 0.1),
    0px 16.5px 41.25px rgba(16, 73, 118, 0.15);
  border-radius: 12px;
  height: 100%;
}
</style>
