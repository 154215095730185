<template>
  <div class="crosspage d-flex align-center">
    <v-row
      class="crosspage__row crosspage__row--lg justify-center align-center"
    >
      <v-col cols="12" lg="" class="crosspage__text">
        <div class="heading1 mb-5 mb-md-10">
          {{ $t("PageNotFound.Matrix.title") }}
        </div>

        <div class="mb-3 mb-md-6">
          {{ $t("PageNotFound.Matrix.text") }}
        </div>
      </v-col>

      <v-col cols="12" lg="" class="crosspage__image-container">
        <v-row class="crosspage__image justify-center flex-nowrap">
          <v-col class="flex-grow-0">
            <router-link
              :to="{ name: 'Main' }"
              class="matrix-pill matrix-pill--red"
            >
              <v-img :src="`${baseUrl}img/404/pill-red.png`" max-width="194" />
            </router-link>
          </v-col>

          <v-col class="flex-grow-0">
            <a
              href="https://www.kinopoisk.ru/film/301/"
              target="_blank"
              class="matrix-pill matrix-pill--blue"
            >
              <v-img :src="`${baseUrl}img/404/pill-blue.png`" max-width="194" />
            </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PageNotFoundMatrix",

  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/pages/CrossPages/CrossPage.scss";
.matrix-pill {
  display: block;
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    filter: none !important;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  &.matrix-pill--red {
    filter: drop-shadow(0px 7px 22px rgba(249, 22, 0, 0.2))
      drop-shadow(0px 14px 22px rgba(249, 22, 0, 0.1))
      drop-shadow(0px 96px 132px rgba(249, 22, 0, 0.08));
  }

  &.matrix-pill--blue {
    filter: drop-shadow(0px 7px 22px rgba(0, 133, 238, 0.2))
      drop-shadow(0px 14px 22px rgba(33, 150, 243, 0.1))
      drop-shadow(0px 96px 132px rgba(33, 150, 243, 0.08));
  }
}
</style>
