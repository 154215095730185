<template>
  <div v-if="pageLoaded" v-resize="loadVImage">
    <v-row>
      <v-col class="text-left page__title">
        <h1>{{ pageData.fields.title || "" }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="8" xl="8">
        <div
          v-if="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg"
          class="sv-sticky-container"
        >
          <div class="sv-sticky">
            <v-img
              ref="imgMapContacts"
              :style="`height: ${heightBlockImg}px`"
              :src="pageData.fields.map || ''"
              @load="loadVImage"
            />
          </div>
        </div>
        <v-img v-else ref="imgMapContacts" :src="pageData.fields.map || ''" />
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="4" xl="4">
        <v-row ref="blockContacts">
          <v-col
            v-for="(contact, index) in pageData.contacts_branches.children"
            :key="index"
            cols="12"
            :class="
              $vuetify.breakpoint.xl || $vuetify.breakpoint.lg ? 'pb-4' : 'pb-3'
            "
          >
            <contact :contact-data="contact.fields" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row style="margin-top: 100px; margin-bottom: 100px">
      <v-col
        v-for="(email, index) in pageData.contacts_questions_emails.children"
        :key="index"
        :class="
          $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
            ? 'px-3 py-8'
            : 'pa-6'
        "
        xs="12"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <v-row>
          <v-col>
            <span class="subtitle1">{{ email.fields.title }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <a :href="`mailto:${email.fields.email}?subject=${emailSubject}`">{{
              email.fields.email
            }}</a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <form-communication :form-id="pageData.links.feedback_form[0] || 0" />
    <zuruck />
  </div>
</template>

<script>
import Contact from "@/components/Contacts/Contact.vue";
import ContactsRepository from "@/api/contacts.js";
import FormCommunication from "@/components/FormCommunication.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  name: "Contacts",

  components: { Contact, FormCommunication, Zuruck },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },

  data() {
    return {
      emailSubject: "From%20WWW",
      pageLoaded: false,
      pageData: {
        fields: {},
        contacts_branches: { fields: {}, children: [] },
        contacts_questions_emails: { fields: {}, children: [] },
        links: { feedback_form: [] },
      },
      heightBlockMap: 0,
      heightBlockImg: "",
    };
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadData();
      }
    },
  },

  async mounted() {
    await this.loadData();
    this.$nextTick(() => {
      this.heightBlockMap = this.$refs.blockContacts.offsetHeight;
    });
  },

  methods: {
    async loadData() {
      const { data } = await ContactsRepository.getContacts();
      this.pageData = data;
      this.pageLoaded = true;
      this.$store.commit("setLangsAvail", data.published);
      this.$emit("page-loaded");
    },

    loadVImage() {
      this.heightBlockMap = this.$refs.blockContacts.offsetHeight;
      if (!this.$refs.imgMapContacts.image) return;
      const aspectRatio =
        this.$refs.imgMapContacts.image.naturalWidth /
        this.$refs.imgMapContacts.image.naturalHeight;
      this.heightBlockImg =
        this.$refs.imgMapContacts.$el.offsetWidth / aspectRatio;
    },
  },
};
</script>
<style scoped lang="scss">
.sv-sticky-container {
  padding-bottom: 20px;
  position: relative;
  height: 100%;

  .sv-sticky {
    position: sticky;
    top: 200px;
  }
}
</style>
