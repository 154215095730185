<template>
  <div>
    <v-row class="section__title-row">
      <v-col class="text-center">
        <span class="heading2">{{ componentsData.fields.title || "" }}</span>
      </v-col>
    </v-row>
    <image-scroll-sticky
      class="section"
      :items="componentsData.children"
      :pad-image="`${baseUrl}img/pad.svg`"
      :item-style="{
        left: '3.6%',
        top: '5.4%',
        width: '93%',
      }"
      :paragraph-max-multiplier="1.5"
    />
  </div>
</template>

<script>
import ImageScrollSticky from "@/components/ImageScrollSticky.vue";
export default {
  name: "Components",
  components: {
    ImageScrollSticky,
  },
  props: {
    componentsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
