<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="2" height="2" x="6" y="12" fill="currentColor" />
    <rect width="2" height="2" x="12" y="12" fill="currentColor" />
    <rect width="2" height="2" x="18" y="12" fill="currentColor" />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
