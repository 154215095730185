<template>
  <div>
    <v-row>
      <v-col class="text-left page__title">
        <h1>{{ pageData.fields.title || "" }}</h1>
      </v-col>
    </v-row>

    <v-row class="section list-content mb-10">
      <v-col
        v-for="client in pageData.children"
        :key="client.id"
        cols="12"
        md="6"
        lg="4"
      >
        <client-card :client-data="client.fields" />
      </v-col>
    </v-row>

    <form-communication :form-id="pageData.links.feedback_form[0] || 0" />

    <zuruck />
  </div>
</template>

<script>
import ClientCard from "@/components/ClientCard/ClientCard.vue";
import ClientsRepository from "@/api/clients.js";
import FormCommunication from "@/components/FormCommunication.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  name: "Clients",

  components: {
    ClientCard,
    FormCommunication,
    Zuruck,
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },

  data() {
    return {
      pageData: {
        fields: {},
        children: [],
        links: { feedback_form: [] },
      },
    };
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },

  mounted() {
    this.loadPage();
  },

  methods: {
    async loadPage() {
      const { data } = await ClientsRepository.getClients();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.$emit("page-loaded");
    },
  },
};
</script>
