import Vue from "vue";
import VueGTag from "vue-gtag";

const getGTagId = function () {
  switch (window.location.hostname) {
    case "www.svyazcom.ru":
    case "svyazcom.ru":
      return null;
    case "www.soft.sc":
    case "soft.sc":
      return "G-B4ZNHGJ3R5"; // ID счётчика для soft.sc
    default:
      return null;
  }
};

export default function () {
  const gTagId = getGTagId();

  if (gTagId) {
    Vue.use(VueGTag, {
      config: {
        id: gTagId,
      },
    });
  }
}
