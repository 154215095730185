<template>
  <div>
    <v-row class="section__title-row">
      <v-col class="text-center">
        <span class="heading2">{{ growthData.fields.title || "" }}</span>
      </v-col>
    </v-row>
    <v-row class="section">
      <v-col cols="12" lg="4">
        <div
          class="corporateBlue-lighten4 pa-5 pa-md-10"
          v-html="growthData.fields.text"
        />
      </v-col>
      <v-col cols="12" lg="8" class="graph__container">
        <div class="graph__title text-center">
          {{ growthData.fields.graph_title }}
        </div>
        <v-img
          :src="growthData.fields.graph_image"
          @load="$emit('trigger-resize')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Growth",
  props: {
    growthData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
@import "./Graphs.scss";
</style>
