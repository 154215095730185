<template>
  <div
    class="kk-zuruck d-flex d-md-none align-center justify-center clickable"
    :class="{
      'kk-zuruck--hidden': !visible,
    }"
    @click="onZuruck"
  >
    <v-icon color="corporateGrey">mdi-chevron-up</v-icon>
  </div>
</template>

<script>
import { scrollTo } from "seamless-scroll-polyfill";

export default {
  name: "Zuruck",
  data() {
    return {
      visible: false,
      scrollPos: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    onScroll() {
      const delta = window.scrollY - this.scrollPos;
      this.scrollPos = window.scrollY;
      if (delta > 0 || this.scrollPos == 0) {
        this.visible = false;
      } else if (delta < 0) {
        this.visible = true;
      }
    },
    onZuruck() {
      scrollTo(window, { top: 0, behavior: "smooth" });
    },
  },
};
</script>
<style scoped lang="scss">
.kk-zuruck {
  position: fixed;
  right: 0;
  bottom: 24px;
  width: 54px;
  height: 48px;
  z-index: 8;
  opacity: 1;
  transition: bottom 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  background-color: var(--v-corporateBlueAdditional-lighten2);

  &.kk-zuruck--hidden {
    pointer-events: none;
    bottom: -48px;
    transition: bottom 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
}
</style>
