<template>
  <div class="motion-item corporateBlue--text">
    <svg
      width="343"
      height="96"
      viewBox="0 0 343 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="clipper1">
          <rect
            x="-10"
            y="-40"
            width="180"
            height="90"
            :transform="`rotate(-${20 + itemProgress(0) * 125})`"
            transform-origin="80 40"
          />
        </clipPath>
        <clipPath id="clipper2">
          <rect
            x="-10"
            y="-40"
            width="180"
            height="90"
            :transform="`rotate(-${20 + itemProgress(1) * 125})`"
            transform-origin="80 40"
          />
        </clipPath>
        <clipPath id="clipper3">
          <rect
            x="-10"
            y="-40"
            width="180"
            height="90"
            :transform="`rotate(-${20 + itemProgress(2) * 125})`"
            transform-origin="80 40"
          />
        </clipPath>
      </defs>
      <path
        d="M35 38V71C35 82.05 43.95 91 55 91H105C116.05 91 125 82.05 125 71V38"
        stroke="#4DABF5"
        stroke-width="10"
        clip-path="url(#clipper1)"
      />
      <path
        d="M17 91H105C116.05 91 125 82.05 125 71V38"
        stroke="#4DABF5"
        stroke-width="10"
        transform="translate(90 0)"
        clip-path="url(#clipper2)"
      />
      <path
        d="M17 91H105C116.05 91 125 82.05 125 71V38"
        stroke="#4DABF5"
        stroke-width="10"
        transform="translate(180 0)"
        clip-path="url(#clipper3)"
      />

      <circle cx="35" cy="35" r="34.5" fill="#4DABF5" stroke="#4DABF5" />
      <circle cx="126" cy="35" r="34.5" fill="#4DABF5" stroke="#4DABF5" />
      <path
        fill="#EEF7FE"
        :fill-opacity="checkProgress(0)"
        d="M122.89 44.52L114 35.64L116.51 33.13L122.89 39.49L136.38 26L138.89 28.52L122.89 44.52Z"
      />
      <circle cx="217" cy="35" r="34.5" fill="#4DABF5" stroke="#4DABF5" />
      <path
        fill="#EEF7FE"
        :fill-opacity="checkProgress(1)"
        transform="translate(90 0)"
        d="M122.89 44.52L114 35.64L116.51 33.13L122.89 39.49L136.38 26L138.89 28.52L122.89 44.52Z"
      />
      <circle cx="308" cy="35" r="34.5" fill="#4DABF5" stroke="#4DABF5" />
      <path
        fill="#EEF7FE"
        :fill-opacity="checkProgress(2)"
        transform="translate(180 0)"
        d="M122.89 44.52L114 35.64L116.51 33.13L122.89 39.49L136.38 26L138.89 28.52L122.89 44.52Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AdvantageMotion6",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      checkAppear: 0.8,
    };
  },
  computed: {
    itemProgress() {
      return (index) => {
        const d = this.progress * 3 - index;
        if (d < 0) return 0;
        if (d > 1) return 1;
        return d;
      };
    },
    checkProgress() {
      return (index) => {
        const itp = this.itemProgress(index);
        const cp = (itp - this.checkAppear) / (1 - this.checkAppear);
        if (cp < 0) return 0;
        return cp;
      };
    },
  },
};
</script>
