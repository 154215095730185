<template>
  <div>
    <div class="section">
      <v-row>
        <v-col class="page__title">
          <h1>{{ pageData.fields.title || "" }}</h1>
        </v-col>
      </v-row>

      <v-row id="sc-news-title" class="list-header">
        <v-col cols="12" md="9" lg="8" xl="6">
          <tag-autocomplete
            v-model="tags"
            :items="tagsList"
            item-value="id"
            item-text="text"
            :placeholder="$t('Common.searchByTags')"
            persistent-placeholder
            @change="reloadNews"
            @tag-clear="removeTag"
          />
        </v-col>
      </v-row>

      <v-row class="list-content mb-10">
        <v-col
          v-for="item in pageData.children"
          :key="item.id"
          cols="12"
          md="6"
          lg="4"
        >
          <preview
            :preview-info="item"
            @tagclick="reloadNewsWithTag"
            @previewclick="goNewsItem"
          />
        </v-col>
      </v-row>

      <v-row v-if="pageData.more_news" class="list-footer">
        <v-col class="btn-line text-center">
          <v-btn color="primary" outlined x-large @click="fetchNews()">
            {{ pageData.fields.button_more || "" }}
          </v-btn>
        </v-col>
        <div class="fog-of-war" />
      </v-row>
    </div>

    <form-communication
      :form-id="pageData.links.feedback_form[0] || 0"
      kind="subscription"
    />

    <zuruck />
  </div>
</template>

<script>
import Preview from "@/components/Preview/Preview.vue";
import NewsRepository from "@/api/news.js";
import TagAutocomplete from "@/components/Tags/TagAutocomplete.vue";
import FormCommunication from "@/components/FormCommunication.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  name: "NewsList",

  components: {
    Preview,
    TagAutocomplete,
    FormCommunication,
    Zuruck,
  },

  props: {
    tag: {
      type: String,
      default: "",
    },
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },

  data() {
    return {
      limit: 12,
      offset: 0,
      tags: this.tag.length ? [this.tag] : [],
      pageData: {
        fields: {},
        links: { feedback_form: [] },
        feedback_form: { fields: {}, links: { person: [] } },
        more_news: false,
        all_tags: {},
      },
    };
  },

  computed: {
    tagsList() {
      return Object.entries(this.pageData.all_tags).map((entry) => ({
        id: entry[0],
        text: `${entry[0]} (${entry[1]})`,
      }));
    },
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.tags = [];
        this.reloadNews();
      }
    },
  },

  mounted() {
    this.reloadNews();
  },

  methods: {
    async reloadNews() {
      this.offset = 0;
      const { limit, tags, offset } = this;
      const { data } = await NewsRepository.getNewsList({
        offset,
        limit,
        tags,
      });
      this.offset += data.children.length;
      this.$store.commit("setLangsAvail", data.published);
      this.pageData = data;
      this.$emit("page-loaded");
    },

    async fetchNews() {
      const { offset, limit, tags } = this;
      const { data } = await NewsRepository.getNewsList({
        offset,
        limit,
        tags,
      });
      this.offset += data.children.length;
      data.children.forEach((element) => {
        const index = this.pageData.children.length;
        this.$set(this.pageData.children, index, element);
      });
      this.pageData.more_news = data.more_news;
    },

    async reloadNewsWithTag(tag) {
      this.tags = [tag];
      await this.reloadNews();
      this.$vuetify.goTo("#sc-news-title");
    },

    removeTag(item) {
      const index = this.tags.indexOf(item);
      if (index >= 0) {
        this.tags.splice(index, 1);
        this.reloadNews();
      }
    },

    goNewsItem(id) {
      this.$router.push({ name: "NewsItem", params: { id } });
    },
  },
};
</script>
<style lang="scss">
.list-footer {
  position: relative;

  .btn-line {
    z-index: 3;
  }

  .fog-of-war {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 100%;
    height: 550px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 36.91%,
      #ffffff 79.23%
    );
    z-index: 2;
    pointer-events: none;
  }
}
</style>
