<template>
  <v-row class="justify-center">
    <v-col cols="12" class="col-xxl-9">
      <v-row class="section__title-row offer-child">
        <v-col class="text-center">
          <span class="heading1">{{ sectionData.fields.title || "" }}</span>
        </v-col>
      </v-row>

      <v-row class="section__content align-stretch">
        <!-- sticky col -->
        <v-col cols="12" lg="6">
          <div class="sv-sticky-container">
            <div class="sv-sticky">
              <div class="video-overlay">
                <video
                  id="video-player"
                  class="video-overlay__video"
                  loop
                  muted
                  autoplay
                  playsinline
                  :src="sectionData.fields.video"
                />
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="6">
          <div v-for="(typ, typIndex) in sectionData.children" :key="typIndex">
            <h2 class="heading2 mb-6 text-center">{{ typ.fields.title }}</h2>
            <div class="typ-block mb-16">
              <div
                v-for="(component, componentIndex) in typ.children"
                :key="componentIndex"
                class="typ-block__item body3"
                v-html="component.fields.text"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Components",
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.video-overlay {
  display: flex;
  justify-content: center;
  position: relative;
  pointer-events: none;
  .video-overlay__video {
    position: relative;
    max-width: 100%;
  }
}

.typ-block {
  background: #fff;
  box-shadow: 0px 3.3px 12.37px rgba(20, 90, 147, 0.1),
    0px 16.5px 41.25px rgba(16, 73, 118, 0.15);
  border-radius: 12px;

  .typ-block__item {
    border-bottom: 1px solid #e0e0e0;
    padding: 18px;
  }

  & > .typ-block__item:last-child {
    border-bottom: none;
  }
}

@media (min-width: 1280px) {
  .sv-sticky-container {
    height: 100%;
    padding-top: 72px;
    padding-bottom: 36px;

    .sv-sticky {
      position: sticky;
      position: -webkit-sticky;
      top: 120px;
    }
  }
}
</style>
