<template>
  <v-row id="new-dms-webInterface" class="new-dms-section">
    <v-col
      v-if="$vuetify.breakpoint[`lgAndUp`]"
      lg="7"
      class="d-flex justify-center new-dms-item-block"
    >
      <div class="new-dms-item-image" style="width: 100%">
        <v-img
          v-for="(image, image_index) in webInterface.children"
          :key="image.title"
          eager
          class="new-dms-webInterface-image"
          :src="image.fields.image"
          :style="getWebInterfaceSolutionStyle(image_index)"
          @load="$emit('trigger-resize')"
        />
      </div>
    </v-col>
    <v-col
      v-if="$vuetify.breakpoint[`lgAndUp`]"
      lg="4"
      offset-lg="1"
      class="d-flex new-dms-item-block"
    >
      <div class="new-dms-item-text">
        <v-row>
          <v-col class="subtitle1 pb-0">
            {{ webInterface.fields.title }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-2 pt-md-3" v-html="webInterface.fields.text" />
        </v-row>
      </div>
    </v-col>
    <v-col
      v-if="!$vuetify.breakpoint[`lgAndUp`]"
      cols="12"
      class="d-flex justify-center new-dms-item-block"
      style="flex-direction: column"
    >
      <div class="d-flex new-dms-item-image" style="flex: 1 0 0">
        <div
          style="flex: 0 0 fit-content; align-self: center; position: relative"
        >
          <v-img
            v-for="(image, image_index) in webInterface.children"
            :key="image.title"
            eager
            class="new-dms-webInterface-image"
            :src="image.fields.image"
            :style="getWebInterfaceSolutionStyle(image_index)"
            @load="$emit('trigger-resize')"
          />
        </div>
      </div>
      <div class="new-dms-item-text">
        <v-row>
          <v-col class="subtitle1 pb-0">
            {{ webInterface.fields.title }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-2 pt-md-3" v-html="webInterface.fields.text" />
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
export default {
  name: "WebInterface",
  mixins: [ProgressObserverMixin],
  props: {
    webInterface: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      obvOptions: {
        viewportTopPct: 0,
        viewportBottomPct: 100,
        pivotBegin: "top", // avail: top, bottom, center
        pivotEnd: "bottom", // avail: top, bottom, center
      },
    };
  },
  computed: {
    getWebInterfaceSolutionStyle() {
      return (index) => {
        const progress = this.obv(`new-dms-webInterface`);
        const items_count = this.webInterface.children?.length ?? 0;
        if (!items_count) return {};
        if (progress < 0.25) {
          let transform_progress = progress / 0.25;
          if (transform_progress > 1) {
            transform_progress = 1;
          }
          const opacity = index != 0 ? 0 : 1;
          return {
            transform: `translate(-50%, ${
              -50 * transform_progress
            }%) perspective(200rem) rotateX(${
              -80 * (1 - transform_progress)
            }deg)`,
            "z-index": index,
            opacity,
          };
        } else {
          let opacity = 1;
          if (index != 0) {
            const opacity_progress = ((progress - 0.25) * 8) / 3;
            const max = (index + 1) / items_count;
            const min = index / items_count;
            opacity =
              min > opacity_progress
                ? 0
                : (opacity_progress - min) / (max - min);
            opacity = opacity > 1 ? 1 : opacity;
          }
          return {
            transform: `translate(-50%, -50%)`,
            "z-index": index,
            opacity,
          };
        }
      };
    },
  },
  mounted() {
    this.obvAddElement(`new-dms-webInterface`);
  },
};
</script>

<style lang="scss" scoped>
.new-dms-section {
  height: 100vh;
  margin-bottom: 80px;
}
.new-dms-item-block {
  position: sticky;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0;
  height: 100vh;
}
.new-dms-item-image {
  position: relative;
}
.new-dms-item-image,
.new-dms-item-text {
  height: fit-content;
  align-self: center;
}
.new-dms-item-text {
  padding: 12px 16px;
  box-shadow: 0px 3.29981px 12.3743px rgba(20, 90, 147, 0.1),
    0px 16.499px 41.2476px rgba(16, 73, 118, 0.15);
  border-radius: 12px;
}
.new-dms-elephant-block {
  position: absolute;
  left: 50%;
}
.new-dms-elephant {
  opacity: 0;
}
.new-dms-webInterface-image {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 90vw;
}
@media (min-width: 640px) {
  .new-dms-item-text {
    padding: 20px 24px;
  }
  .new-dms-webInterface-image {
    max-width: 80vw;
  }
}
@media (min-width: 1024px) {
  .new-dms-section {
    margin-bottom: 160px;
    height: 300vh;
  }
  .new-dms-webInterface-image {
    max-width: 70vw;
  }
}
@media (min-width: 1280px) {
  .new-dms-webInterface-image {
    max-width: none;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .new-dms-section {
    margin-bottom: 0px;
  }
}
</style>
