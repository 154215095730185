<template>
  <div class="motion">
    <svg
      width="1120"
      height="1835"
      viewBox="0 0 1120 1835"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="motion__svg"
    >
      <defs>
        <linearGradient
          id="paint0_linear_567_4318"
          x1="566.539"
          y1="-592.523"
          x2="-32.456"
          y2="-472.039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0362AF" />
          <stop offset="1" stop-color="#2196F3" />
        </linearGradient>
        <clipPath id="display">
          <rect
            x="104.894"
            y="68.1914"
            width="710.106"
            height="1537.62"
            rx="79.5319"
            fill="white"
          />
        </clipPath>
        <clipPath id="screen-border">
          <rect
            x="104.894"
            y="68.1914"
            width="710.106"
            height="1544.56"
            rx="91.6266"
            fill="white"
          />
        </clipPath>
      </defs>
      <g transform="rotate(7.56) translate(200 0)" class="drop-shadow">
        <path
          d="M61.3398 422.298C61.3398 419.16 63.8832 416.617 67.0207 416.617H72.7015V458.277H67.0207C63.8832 458.277 61.3398 455.733 61.3398 452.596V422.298Z"
          fill="#2D88D2"
        />
        <path
          d="M61.3398 672.255C61.3398 669.118 63.8832 666.574 67.0207 666.574H72.7015V802.915H67.0207C63.8832 802.915 61.3398 800.372 61.3398 797.234V672.255Z"
          fill="#2D88D2"
        />
        <path
          d="M858.553 568.106C858.553 564.969 856.009 562.425 852.872 562.425H847.191V785.872H852.872C856.009 785.872 858.553 783.329 858.553 780.191V568.106Z"
          fill="#2D88D2"
        />
        <path
          d="M61.3398 509.404C61.3398 506.267 63.8832 503.723 67.0207 503.723H72.7015V640.064H67.0207C63.8832 640.064 61.3398 637.52 61.3398 634.383V509.404Z"
          fill="#2D88D2"
        />
        <rect
          x="67.0215"
          y="30.3191"
          width="785.851"
          height="1613.36"
          rx="123.085"
          fill="#2196F3"
        />
        <rect
          x="67.0215"
          y="30.3191"
          width="785.851"
          height="1613.36"
          rx="123.085"
          fill="url(#paint0_linear_567_4318)"
          fill-opacity="0.5"
        />
        <rect
          x="78.3828"
          y="41.6809"
          width="763.128"
          height="1590.64"
          rx="113.617"
          fill="#232020"
        />
        <g clip-path="url(#display)">
          <path
            d="M312.753 68.1914H605.156V96.5956C605.156 114.375 590.744 128.787 572.965 128.787H344.944C327.166 128.787 312.753 114.375 312.753 96.5957V68.1914Z"
            fill="#232020"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M312.804 77.6596C312.809 77.5336 312.812 77.4069 312.812 77.2796C312.812 72.2604 308.743 68.1915 303.724 68.1915H313.191V77.6596H312.804Z"
            fill="#232020"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M605.196 77.6596C605.191 77.5336 605.188 77.4069 605.188 77.2796C605.188 72.2604 609.257 68.1915 614.276 68.1915H604.809V77.6596H605.196Z"
            fill="#232020"
          />
          <circle cx="354.851" cy="94.7022" r="15.1489" fill="#0F0E0E" />
          <rect
            x="104.894"
            y="68.1914"
            width="710.106"
            height="1544.56"
            rx="91.6266"
            fill="#FEFEFE"
          />
          <circle cx="456.675" cy="1303.5" r="70" fill="#31C75C" />
          <circle cx="270.149" cy="622.9" r="70" fill="#E2E2E2" />
          <circle cx="459.947" cy="622.9" r="70" fill="#E2E2E2" />
          <circle cx="649.745" cy="623.9" r="70" fill="#E2E2E2" />
          <circle cx="270.149" cy="786.5" r="70" fill="#E2E2E2" />
          <circle cx="459.947" cy="786.5" r="70" fill="#E2E2E2" />
          <circle
            cx="649.745"
            cy="786.5"
            r="70"
            fill="#E2E2E2"
            class="animated animated-button-six"
          />
          <circle cx="270.149" cy="950" r="70" fill="#E2E2E2" />
          <circle cx="459.947" cy="950" r="70" fill="#E2E2E2" />
          <circle cx="649.745" cy="950" r="70" fill="#E2E2E2" />
          <circle
            cx="270.149"
            cy="1114"
            r="70"
            fill="#E2E2E2"
            class="animated animated-button-star"
          />
          <circle
            cx="459.947"
            cy="1114"
            r="70"
            fill="#E2E2E2"
            class="animated animated-button-zero"
          />
          <circle
            cx="649.745"
            cy="1114"
            r="70"
            fill="#E2E2E2"
            class="animated animated-button-hash"
          />
          <path
            d="M654.653 1285.52H633.787C630.777 1285.52 627.933 1286.9 626.072 1289.26L616.184 1301.83C615.666 1302.49 615.385 1303.3 615.385
          1304.14C615.385 1305.03 615.701 1305.89 616.276 1306.56L626.08 1318.06C627.945 1320.25 630.676 1321.51 633.552 1321.51H654.653C660.075
          1321.51 664.47 1317.12 664.47 1311.7V1295.33C664.47 1289.91 660.075 1285.52 654.653 1285.52Z"
            fill="#E6E6E6"
          />
          <path
            d="M638.291 1295.33L654.653 1311.7M638.291 1295.33L654.653 1311.7"
            stroke="#232020"
            stroke-width="3.27238"
          />
          <path
            d="M638.291 1295.33L654.653 1311.7M638.291 1295.33L654.653 1311.7"
            stroke="black"
            stroke-opacity="0.2"
            stroke-width="3.27238"
          />
          <path
            d="M654.653 1295.33L638.291 1311.7M654.653 1295.33L638.291 1311.7"
            stroke="#232020"
            stroke-width="3.27238"
          />
          <path
            d="M654.653 1295.33L638.291 1311.7M654.653 1295.33L638.291 1311.7"
            stroke="black"
            stroke-opacity="0.2"
            stroke-width="3.27238"
          />
          <path
            d="M173.735 1456.11C174.29 1454.76 176.21 1454.76 176.764 1456.11L185.449 1477.35C185.505 1477.49 185.579 1477.62
          185.67 1477.73L199.722 1495.88C200.619 1497.03 199.659 1498.7 198.207 1498.5L175.471 1495.4C175.324 1495.38 175.175 1495.38 175.029
          1495.4L152.292 1498.5C150.841 1498.7 149.88 1497.03 150.778 1495.88L164.83 1477.73C164.92 1477.62 164.995 1477.49 165.051 1477.35L173.735
          1456.11Z"
            fill="#A3A3A3"
          />
          <circle cx="315.962" cy="1486.77" r="24.5428" fill="#A3A3A3" />
          <path
            d="M301.236 1490.04H315.962V1472.04"
            stroke="white"
            stroke-opacity="0.83"
            stroke-width="3.27238"
          />
          <circle cx="579.388" cy="1468.77" r="6.545" fill="#216FD6" />
          <circle cx="595.75" cy="1468.77" r="6.545" fill="#216FD6" />
          <circle cx="612.112" cy="1468.77" r="6.545" fill="#216FD6" />
          <circle cx="579.388" cy="1485.13" r="6.545" fill="#216FD6" />
          <circle cx="595.75" cy="1485.13" r="6.545" fill="#216FD6" />
          <circle cx="612.112" cy="1485.13" r="6.545" fill="#216FD6" />
          <circle cx="579.388" cy="1501.49" r="6.545" fill="#216FD6" />
          <circle cx="595.75" cy="1501.49" r="6.545" fill="#216FD6" />
          <circle cx="612.112" cy="1501.49" r="6.545" fill="#216FD6" />
          <circle
            cx="716.829"
            cy="1485.13"
            r="14.7257"
            stroke="#A3A3A3"
            stroke-width="3.27238"
          />
          <circle
            cx="756.097"
            cy="1485.13"
            r="14.7257"
            stroke="#A3A3A3"
            stroke-width="3.27238"
          />
          <path
            d="M715.192 1499.86H759.37"
            stroke="#A3A3A3"
            stroke-width="3.27238"
          />
          <circle
            cx="456.675"
            cy="1486.77"
            r="22.9067"
            stroke="#A3A3A3"
            stroke-width="3.27238"
          />
          <circle cx="456.675" cy="1480.22" r="8.18095" fill="#A3A3A3" />
          <path
            d="M474.673 1498.91C460.274 1485.81 444.676 1493.45 438.677 1498.91C438.677 1498.91 450.009 1508.69 458.475 1508C465.688 1507.42 474.673 1498.91 474.673 1498.91Z"
            fill="#A3A3A3"
            stroke="#A3A3A3"
            stroke-width="3.27238"
          />
          <text x="128" y="1533" class="text display-icons-text">
            Favourites
          </text>
          <text x="275" y="1533" class="text display-icons-text">Recents</text>
          <text x="415" y="1533" class="text display-icons-text">Contacts</text>
          <text x="560" y="1533" class="text display-icons-text selected">
            Keypad
          </text>
          <text x="690" y="1533" class="text display-icons-text">
            Voicemail
          </text>
          <!-- единица -->
          <path
            fill="#232020"
            d="M263.467 634.138H269.027V588.024H263.403L251.131 596.844V602.628L263.339 594.128H263.467V634.138Z"
          />
          <!-- двойка -->
          <path
            fill="#232020"
            d="M443.345 634.138H473.768V629.025H451.238V628.897L462.199 617.648C470.668 608.988 473.001 605.185 473.001 600.136V600.072C473.001
            592.849 466.897 587.257 458.556 587.257C449.96 587.257 443.057 593.169 443.057 601.158V601.35H448.618V601.19C448.618 596.237 452.9
            592.274 458.652 592.274C463.669 592.274 467.217 595.662 467.249 600.359V600.423C467.249 604.258 465.81 606.751 459.579 613.238L443.345
            630.175V634.138Z"
          />
          <!-- тройка -->
          <path
            fill="#232020"
            d="M649.391 634.905C658.626 634.905 665.401 629.216 665.401 621.515V621.451C665.401 614.9 660.831 610.713 654.12 610.138V610.01C659.873
            608.796 663.899 604.897 663.899 599.209V599.145C663.899 592.146 658.115 587.257 649.327 587.257C640.699 587.257 634.755 592.274 634.02
            599.784L633.988 600.104H639.516L639.548 599.784C640.027 595.118 643.894 592.242 649.327 592.242C654.951 592.242 658.115 595.023 658.115
            599.848V599.912C658.115 604.514 654.28 607.901 648.784 607.901H643.255V612.759H649.039C655.495 612.759 659.553 615.922 659.553
            621.579V621.643C659.553 626.532 655.431 629.919 649.391 629.919C643.255 629.919 639.101 626.788 638.621 622.25L638.589 621.93H633.061L633.093
            622.314C633.7 629.6 639.868 634.905 649.391 634.905Z"
          />
          <!-- четверка -->
          <path
            fill="#232020"
            d="M270.325 801.029H275.886V791.442H282.405V786.393H275.886V754.915H267.385L247.157 786.233V791.442H270.325V801.029ZM252.845
            786.393V786.265L270.134 759.836H270.325V786.393H252.845Z"
          />
          <!-- пятерка -->
          <path
            fill="#232020"
            d="M457.087 798.524C466.675 798.524 473.481 792.068 473.513 782.833V782.769C473.513 773.917 467.122 767.493 458.27 767.493C453.796
            767.493 449.961 769.155 447.788 772.255H447.66L449.226 756.756H470.893V751.643H444.496L442.132 777.943H447.213C447.82 776.889
            448.555 775.994 449.386 775.227C451.431 773.374 454.115 772.447 457.247 772.447C463.351 772.447 467.729 776.793 467.761
            782.865V782.929C467.761 789.128 463.415 793.474 457.151 793.474C451.623 793.474 447.532 789.991 446.957 785.421L446.925
            785.166H441.269L441.301 785.517C441.972 793.027 448.267 798.524 457.087 798.524Z"
          />
          <!-- шестерка -->
          <path
            fill="#232020"
            d="M648.986 798.524C658.413 798.524 665.316 791.94 665.316 782.865V782.801C665.316 774.045 658.797 767.557
            650.009 767.557C643.745 767.557 639.015 770.881 637.162 774.971H637.034C637.034 774.62 637.034 774.3 637.066 773.981C637.322
            764.298 640.709 755.957 649.306 755.957C654.035 755.957 657.327 758.322 658.701 762.125L658.829 762.476H664.677L664.581 762.061C663.047
            755.35 657.199 750.876 649.369 750.876C638.089 750.876 631.474 759.952 631.474 775.483V775.547C631.474 792.643 640.485 798.524 648.986
            798.524ZM638.408 782.801V782.769C638.408 776.889 642.978 772.607 649.018 772.607C655.09 772.607 659.468 776.921 659.468
            782.929V782.993C659.468 788.809 654.802 793.41 648.922 793.41C643.01 793.41 638.408 788.713 638.408 782.801Z"
          />
          <!-- семерка -->
          <path
            fill="#232020"
            d="M262.023 961.376H268.062L288.579 920.599V915.262H258.06V920.375H282.89V920.503L262.023 961.376Z"
          />
          <!-- восьмерка -->
          <path
            fill="#232020"
            d="M457.161 962.143C466.94 962.143 473.906 956.646 473.906 948.913V948.849C473.906 943.16 469.912 938.431
            463.999 937.057V936.929C469.049 935.363 472.244 931.528 472.244 926.671V926.607C472.244 919.64 465.885 914.495
            457.161 914.495C448.437 914.495 442.077 919.64 442.077 926.607V926.671C442.077 931.528 445.273 935.363 450.322
            936.929V937.057C444.41 938.431 440.415 943.16 440.415 948.849V948.913C440.415 956.646 447.382 962.143 457.161
            962.143ZM457.161 934.788C451.6 934.788 447.797 931.624 447.797 927.15V927.086C447.797 922.612 451.6 919.448
            457.161 919.448C462.721 919.448 466.524 922.612 466.524 927.086V927.15C466.524 931.624 462.721 934.788 457.161
            934.788ZM457.161 957.125C450.801 957.125 446.263 953.546 446.263 948.561V948.497C446.263 943.448 450.769 939.837
            457.161 939.837C463.552 939.837 468.058 943.448 468.058 948.497V948.561C468.058 953.546 463.52 957.125 457.161
            957.125Z"
          />
          <!-- девятка -->
          <path
            fill="#232020"
            d="M651.811 911.223C642.384 911.223 635.481 917.806 635.481 926.882V926.946C635.481 935.702 642 942.189 650.788 942.189C657.052
            942.189 661.782 938.866 663.635 934.775H663.763C663.763 935.127 663.763 935.446 663.731 935.766C663.475 945.449 660.088 953.789
            651.491 953.789C646.762 953.789 643.47 951.425 642.096 947.622L641.968 947.27H636.12L636.216 947.686C637.75 954.397 643.598 958.87
            651.428 958.87C662.708 958.87 669.323 949.795 669.323 934.264V934.2C669.323 917.103 660.312 911.223 651.811 911.223ZM651.779
            937.14C645.707 937.14 641.329 932.826 641.329 926.818V926.754C641.329 920.938 645.995 916.336 651.875 916.336C657.787 916.336
            662.389 921.034 662.389 926.946V926.978C662.389 932.858 657.819 937.14 651.779 937.14Z"
          />
          <!-- звездочка -->
          <path
            fill="#232020"
            d="M266.55 1125.7H270.449L270.129 1118.29L276.201 1122.16L278.182 1119.15L271.471 1115.61L278.182 1112.09L276.201 1109.09L270.129
            1112.95L270.449 1105.57H266.55L266.869 1112.95L260.798 1109.09L258.816 1112.09L265.591 1115.64L258.816 1119.22L260.798 1122.22L266.869
            1118.35L266.55 1125.7Z"
          />
          <!-- ноль -->
          <path
            fill="#232020"
            d="M457.42 1129.03C468.029 1129.03 474.389 1119.86 474.389 1105.23V1105.16C474.389 1090.53 468.029 1081.39 457.42 1081.39C446.81
            1081.39 440.515 1090.53 440.515 1105.16V1105.23C440.515 1119.86 446.81 1129.03 457.42 1129.03ZM457.42 1124.02C450.389 1124.02
            446.299 1116.79 446.299 1105.23V1105.16C446.299 1093.59 450.389 1086.44 457.42 1086.44C464.45 1086.44 468.605 1093.59 468.605
            1105.16V1105.23C468.605 1116.79 464.45 1124.02 457.42 1124.02Z"
          />
          <text x="433" y="659" class="display-buttons-sub">ABC</text>
          <text x="622" y="659" class="display-buttons-sub">DEF</text>
          <text x="243" y="826" class="display-buttons-sub">GHI</text>
          <text x="440" y="826" class="display-buttons-sub">JKL</text>
          <text x="621" y="826" class="display-buttons-sub">MNO</text>
          <text x="236" y="986" class="display-buttons-sub">PQRS</text>
          <text x="432" y="986" class="display-buttons-sub">TUV</text>
          <text x="614.5" y="986" class="display-buttons-sub">WXYZ</text>
          <path
            d="M451.076 1151.43H455.16V1155.4H456.713V1151.43H460.807V1149.87H456.713V1145.91H455.16V1149.87H451.076V1151.43Z"
            fill="#232020"
          />
          <path
            d="M656.528 1095.72L656.528 1131.71M641.803 1095.72L641.803 1131.71C641.803 1131.71 641.164 1109.78 641.803 1095.72Z"
            stroke="#232020"
            stroke-width="3.27238"
          />
          <path
            d="M632.192 1120.8L668.221 1121.35M631.746 1106.08L667.775 1106.63"
            stroke="#232020"
            stroke-width="3.27238"
          />
          <rect
            x="330.688"
            y="1580.03"
            width="251.973"
            height="9.81714"
            rx="4.90857"
            fill="#232020"
          />
          <g>
            <!-- набираемые цифирки и рамка которая их скрывает -->
            <path
              d="M372.295 297.871H376.194L375.874 290.457L381.946 294.324L383.927 291.32L377.216 287.772L383.927 284.257L381.946 281.253L375.874
              285.12L376.194 277.738H372.295L372.615 285.12L366.543 281.253L364.561 284.257L371.336 287.804L364.561 291.383L366.543 294.387L372.615
              290.521L372.295 297.871ZM400.462 323.34L402.763 310.845H413.468L411.199 323.34H415.865L418.198 310.845H426.315L427.242 306.18H419.061L421.361
              293.94H429.255L430.182 289.274H422.224L424.493 277.227H419.828L417.527 289.274H406.821L409.09 277.227H404.424L402.155 289.274H394.454L393.527
              293.94H401.293L398.992 306.18H391.546L390.619 310.845H398.129L395.796 323.34H400.462ZM403.498 306.307L405.862 293.812H416.824L414.459
              306.307H403.498ZM453.267 324.107C463.877 324.107 470.236 314.936 470.236 300.299V300.236C470.236 285.599 463.877 276.46 453.267 276.46C442.658
              276.46 436.362 285.599 436.362 300.236V300.299C436.362 314.936 442.658 324.107 453.267 324.107ZM453.267 319.09C446.237 319.09 442.146 311.868
              442.146 300.299V300.236C442.146 288.667 446.237 281.509 453.267 281.509C460.298 281.509 464.452 288.667 464.452 300.236V300.299C464.452
              311.868 460.298 319.09 453.267 319.09ZM496.837 324.107C506.264 324.107 513.167 317.524 513.167 308.448V308.385C513.167 299.628 506.648
              293.141 497.86 293.141C491.596 293.141 486.867 296.465 485.013 300.555H484.885C484.885 300.204 484.885 299.884 484.917 299.564C485.173
              289.882 488.56 281.541 497.157 281.541C501.886 281.541 505.178 283.906 506.552 287.708L506.68 288.06H512.528L512.432 287.645C510.898
              280.934 505.05 276.46 497.221 276.46C485.94 276.46 479.325 285.535 479.325 301.066V301.13C479.325 318.227 488.337 324.107 496.837
              324.107ZM486.259 308.385V308.353C486.259 302.473 490.829 298.19 496.869 298.19C502.941 298.19 507.319 302.504 507.319 308.512V308.576C507.319
              314.392 502.653 318.994 496.773 318.994C490.861 318.994 486.259 314.297 486.259 308.385ZM529.925 323.34L532.226 310.845H542.932L540.663
              323.34H545.328L547.661 310.845H555.778L556.705 306.18H548.524L550.825 293.94H558.718L559.645 289.274H551.688L553.957 277.227H549.291L546.99
              289.274H536.285L538.554 277.227H533.888L531.619 289.274H523.917L522.991 293.94H530.756L528.455 306.18H521.009L520.083 310.845H527.592L525.26
              323.34H529.925ZM532.961 306.307L535.326 293.812H546.287L543.922 306.307H532.961Z"
              fill="#232020"
            />
            <rect
              width="200"
              height="78"
              transform="translate(360.894 261.34)"
              fill="#ffffff"
              class="animated animated-phone-number"
            />
          </g>
          <text x="357" y="397" class="text display-subtext">Add Number</text>

          <path
            opacity="0.35"
            d="M739.076 109.101H762.662C766.578 109.101 769.752 112.276 769.752 116.191V119.88C769.752 123.796 766.578 126.97 762.662 126.97H739.076C735.16 126.97 731.986 123.796 731.986 119.88V116.191C731.986 112.276 735.16 109.101 739.076 109.101Z"
            stroke="#232020"
            stroke-width="3.27238"
          />
          <path
            opacity="0.4"
            d="M773.254 114.305V121.766C774.755 121.134 775.731 119.664 775.731 118.036C775.731 116.407 774.755 114.937 773.254 114.305"
            fill="#232020"
          />
          <path
            d="M734.081 114.795C734.081 112.807 735.693 111.196 737.681 111.196H764.059C766.047 111.196 767.658 112.807 767.658 114.795V121.276C767.658 123.264 766.047 124.875 764.059 124.875H737.681C735.693 124.875 734.081 123.264 734.081 121.276V114.795Z"
            fill="#232020"
          />

          <!-- иконка сигнала -->
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="#232020"
            d="M681.228 108.086H679.362C678.332 108.086 677.497 108.921 677.497 109.951V126.118C677.497 127.149 678.332 127.984 679.362
            127.984H681.228C682.258 127.984 683.093 127.149 683.093 126.118V109.951C683.093 108.921 682.258 108.086 681.228 108.086ZM670.657
            112.439H672.523C673.553 112.439 674.388 113.274 674.388 114.304V126.118C674.388 127.149 673.553 127.984 672.523 127.984H670.657C669.627
            127.984 668.792 127.149 668.792 126.118V114.304C668.792 113.274 669.627 112.439 670.657 112.439ZM663.818 116.791H661.952C660.922 116.791
            660.087 117.626 660.087 118.656V126.118C660.087 127.148 660.922 127.983 661.952 127.983H663.818C664.848 127.983 665.683 127.148 665.683
            126.118V118.656C665.683 117.626 664.848 116.791 663.818 116.791ZM655.113 120.522H653.247C652.217 120.522 651.382 121.357 651.382
            122.388V126.119C651.382 127.149 652.217 127.984 653.247 127.984H655.113C656.143 127.984 656.978 127.149 656.978 126.119V122.388C656.978
            121.357 656.143 120.522 655.113 120.522Z"
          />
          <!-- иконка WiFi -->
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="#232020"
            d="M706.716 111.708C710.85 111.708 714.825 113.296 717.821 116.144C718.046 116.364 718.407 116.362 718.629 116.138L720.786 113.962C720.898
            113.849 720.961 113.695 720.96 113.536C720.959 113.376 720.894 113.223 720.781 113.111C712.918 105.576 700.513 105.576 692.65 113.111C692.536
            113.223 692.472 113.376 692.471 113.535C692.47 113.695 692.532 113.849 692.645 113.962L694.802 116.138C695.024 116.362 695.385 116.365 695.61
            116.144C698.606 113.296 702.582 111.708 706.716 111.708ZM706.716 118.788C708.987 118.788 711.177 119.632 712.86 121.156C713.088 121.373 713.447
            121.368 713.669 121.146L715.823 118.969C715.936 118.855 715.999 118.7 715.997 118.539C715.996 118.379 715.93 118.225 715.814 118.113C710.687
            113.345 702.748 113.345 697.622 118.113C697.506 118.225 697.44 118.379 697.438 118.54C697.437 118.701 697.5 118.855 697.614 118.969L699.767
            121.146C699.989 121.368 700.348 121.373 700.575 121.156C702.258 119.633 704.446 118.789 706.716 118.788ZM711.031 123.552C711.034 123.714
            710.971 123.869 710.856 123.982L707.13 127.742C707.021 127.853 706.872 127.915 706.716 127.915C706.561 127.915 706.412 127.853 706.303
            127.742L702.577 123.982C702.461 123.869 702.398 123.713 702.401 123.552C702.405 123.391 702.475 123.238 702.595 123.13C704.974 121.117
            708.459 121.117 710.838 123.13C710.958 123.238 711.028 123.391 711.031 123.552Z"
          />
          <!-- часы -->
          <text x="165" y="125" class="text display-time">{{ time }}</text>
          <!-- верхняя черная плашка -->
          <path
            fill="#232020"
            d="M258.007 68.1914H663.782V68.1914C659.88 69.309 657.144 72.8163 657.011 76.873L656.957 78.5049V78.5049C656.957 103.533
            636.668 123.822 611.64 123.822H310.149C285.121 123.822 264.832 103.533 264.832 78.5049V78.5049L264.778 76.873C264.644 72.8163
            261.909 69.309 258.007 68.1914V68.1914Z"
          />
          <!-- иконка трубки -->
          <path
            fill="white"
            d="M429 1288.75C428.947 1287.11 429.851 1284.64 432.056 1282.65C433.73 1281.14 435.245 1279.47 436.866 1277.88C439.337 1275.47
            442.951 1275.34 445.449 1277.72C448.451 1280.59 451.428 1283.53 454.271 1286.52C457.194 1289.6 456.051 1294.29 452.145 1295.88C448.558
            1297.34 447.123 1300.04 448.531 1303.62C451.401 1310.88 456.636 1315.94 463.97 1318.7C466.84 1319.79 470.135 1318.22 471.091 1315.28C472.367
            1311.36 477.655 1309.61 481.189 1313.32C483.766 1316.05 486.503 1318.62 489.161 1321.3C491.765 1323.92 491.818 1327.6 489.24 1330.28C487.407
            1332.19 485.52 1334.07 483.607 1335.9C480.95 1338.39 477.708 1338.47 474.386 1337.92C469.417 1337.07 464.953 1334.95 460.755 1332.24C448.425
            1324.24 438.434 1314.09 431.897 1300.78C430.249 1297.42 429.107 1293.84 429 1288.75Z"
          />
        </g>
        <rect
          opacity="0.2"
          x="67.0215"
          y="255.66"
          width="11.3617"
          height="28.4043"
          fill="#2B2B2B"
        />
        <rect
          opacity="0.2"
          x="67.0215"
          y="1382.36"
          width="11.3617"
          height="28.4043"
          fill="#2B2B2B"
        />
        <rect
          opacity="0.2"
          x="841.511"
          y="255.66"
          width="11.3617"
          height="28.4043"
          fill="#2B2B2B"
        />
        <rect
          opacity="0.2"
          x="841.511"
          y="1382.36"
          width="11.3617"
          height="28.4043"
          fill="#2B2B2B"
        />
        <rect
          opacity="0.2"
          x="659.724"
          y="41.6809"
          width="11.3617"
          height="28.4043"
          transform="rotate(-90 659.724 41.6809)"
          fill="#2B2B2B"
        />
        <rect
          opacity="0.2"
          x="243.128"
          y="1643.68"
          width="11.3617"
          height="28.4043"
          transform="rotate(-90 243.128 1643.68)"
          fill="#2B2B2B"
        />
        <rect
          x="407.872"
          y="49.2552"
          width="104.149"
          height="15.1489"
          rx="7.57447"
          fill="#0F0E0E"
        />
        <g clip-path="url(#screen-border)">
          <!-- шторка -->
          <g class="animated animated-curtain">
            <rect
              x="0.449219"
              y="0.834717"
              width="710.106"
              height="1433.47"
              rx="18"
              fill="white"
            />
            <rect
              x="226.668"
              y="1410.27"
              width="253.862"
              height="9.67379"
              rx="4.83689"
              fill="#0D0D0D"
            />

            <text x="193" y="155" class="text text-curtain-header">
              Device Info
            </text>
            <text x="31" y="63" class="text text-curtain-subtext">Cancel</text>
            <text x="346" y="316" class="text text-curtain">EID</text>

            <text x="66" y="360" class="text text-curtain">
              89049032007008882600095762099999
            </text>
            <text x="192" y="654.50" class="text text-curtain">
              IMEI 357491020286009
            </text>
            <text x="189" y="946" class="text text-curtain">
              IMEI2 357491020211289
            </text>
            <text x="214" y="1240" class="text text-curtain">
              MEID 35749292299928
            </text>

            <rect
              x="200.093"
              y="1315"
              width="6.08911"
              height="88"
              fill="black"
            />
            <rect
              x="207.196"
              y="1315"
              width="4.44985"
              height="88"
              fill="black"
            />
            <rect
              x="216.251"
              y="1315"
              width="8.27518"
              height="88"
              fill="black"
            />
            <rect
              x="229.366"
              y="1315"
              width="4.21492"
              height="88"
              fill="black"
            />
            <rect
              x="240.218"
              y="1315"
              width="4.21492"
              height="88"
              fill="black"
            />
            <rect
              x="251.069"
              y="1315"
              width="6.16427"
              height="88"
              fill="black"
            />
            <rect
              x="260.435"
              y="1315"
              width="2.18522"
              height="88"
              fill="black"
            />
            <rect
              x="272.766"
              y="1315"
              width="6.32498"
              height="88"
              fill="black"
            />
            <rect
              x="283.931"
              y="1315"
              width="4.3714"
              height="88"
              fill="black"
            />
            <rect
              x="289.395"
              y="1315"
              width="4.29343"
              height="88"
              fill="black"
            />
            <rect
              x="294.858"
              y="1315"
              width="4.29343"
              height="88"
              fill="black"
            />
            <rect x="300.322" y="1315" width="11.71" height="88" fill="black" />
            <rect
              x="320.229"
              y="1315"
              width="6.32292"
              height="88"
              fill="black"
            />
            <rect
              x="327.879"
              y="1315"
              width="11.1623"
              height="88"
              fill="black"
            />
            <rect
              x="342.319"
              y="1315"
              width="5.85502"
              height="88"
              fill="black"
            />
            <rect
              x="349.97"
              y="1315"
              width="5.62039"
              height="88"
              fill="black"
            />
            <rect
              x="360.273"
              y="1315"
              width="4.60591"
              height="88"
              fill="black"
            />
            <rect
              x="369.562"
              y="1315"
              width="7.88463"
              height="88"
              fill="black"
            />
            <rect
              x="380.569"
              y="1315"
              width="8.04047"
              height="88"
              fill="black"
            />
            <rect
              x="393.371"
              y="1315"
              width="6.24513"
              height="88"
              fill="black"
            />
            <rect
              x="402.427"
              y="1315"
              width="2.5758"
              height="88"
              fill="black"
            />
            <rect
              x="409.92"
              y="1315"
              width="9.36775"
              height="88"
              fill="black"
            />
            <rect
              x="420.614"
              y="1315"
              width="11.6315"
              height="88"
              fill="black"
            />
            <rect
              x="435.056"
              y="1315"
              width="2.88853"
              height="88"
              fill="black"
            />
            <rect
              x="440.754"
              y="1315"
              width="2.88853"
              height="88"
              fill="black"
            />
            <rect
              x="453.244"
              y="1315"
              width="4.52752"
              height="88"
              fill="black"
            />
            <rect
              x="458.786"
              y="1315"
              width="6.5571"
              height="88"
              fill="black"
            />
            <rect
              x="469.748"
              y="1315"
              width="6.52396"
              height="88"
              fill="black"
            />
            <rect
              x="484.189"
              y="1315"
              width="7.85082"
              height="88"
              fill="black"
            />
            <rect
              x="500.705"
              y="1315"
              width="6.40092"
              height="88"
              fill="black"
            />
            <rect
              x="494.85"
              y="1315"
              width="3.20134"
              height="88"
              fill="black"
            />
            <rect
              x="170.166"
              y="1022"
              width="6.08911"
              height="88"
              fill="black"
            />
            <rect
              x="177.27"
              y="1022"
              width="4.44985"
              height="88"
              fill="black"
            />
            <rect
              x="186.324"
              y="1022"
              width="8.27518"
              height="88"
              fill="black"
            />
            <rect
              x="199.439"
              y="1022"
              width="4.21492"
              height="88"
              fill="black"
            />
            <rect
              x="210.291"
              y="1022"
              width="4.21492"
              height="88"
              fill="black"
            />
            <rect
              x="223.062"
              y="1022"
              width="4.2458"
              height="88"
              fill="black"
            />
            <rect
              x="230.508"
              y="1022"
              width="2.18522"
              height="88"
              fill="black"
            />
            <rect
              x="242.839"
              y="1022"
              width="6.32498"
              height="88"
              fill="black"
            />
            <rect
              x="254.004"
              y="1022"
              width="4.3714"
              height="88"
              fill="black"
            />
            <rect
              x="259.468"
              y="1022"
              width="4.29343"
              height="88"
              fill="black"
            />
            <rect
              x="264.932"
              y="1022"
              width="4.29343"
              height="88"
              fill="black"
            />
            <rect x="270.396" y="1022" width="11.71" height="88" fill="black" />
            <rect
              x="290.302"
              y="1022"
              width="5.79936"
              height="88"
              fill="black"
            />
            <rect
              x="297.952"
              y="1022"
              width="11.1623"
              height="88"
              fill="black"
            />
            <rect
              x="312.393"
              y="1022"
              width="5.85502"
              height="88"
              fill="black"
            />
            <rect
              x="320.043"
              y="1022"
              width="5.62039"
              height="88"
              fill="black"
            />
            <rect
              x="328.841"
              y="1022"
              width="7.61844"
              height="88"
              fill="black"
            />
            <rect
              x="339.636"
              y="1022"
              width="7.88463"
              height="88"
              fill="black"
            />
            <rect
              x="350.643"
              y="1022"
              width="2.03365"
              height="88"
              fill="black"
            />
            <rect
              x="358.495"
              y="1022"
              width="9.28431"
              height="88"
              fill="black"
            />
            <rect
              x="369.018"
              y="1022"
              width="6.05841"
              height="88"
              fill="black"
            />
            <rect
              x="379.993"
              y="1022"
              width="12.917"
              height="88"
              fill="black"
            />
            <rect
              x="399.595"
              y="1022"
              width="2.72381"
              height="88"
              fill="black"
            />
            <rect
              x="405.129"
              y="1022"
              width="2.88853"
              height="88"
              fill="black"
            />
            <rect
              x="410.827"
              y="1022"
              width="2.88853"
              height="88"
              fill="black"
            />
            <rect
              x="416.432"
              y="1022"
              width="11.0178"
              height="88"
              fill="black"
            />
            <rect
              x="428.859"
              y="1022"
              width="9.48419"
              height="88"
              fill="black"
            />
            <rect
              x="439.821"
              y="1022"
              width="4.09313"
              height="88"
              fill="black"
            />
            <rect
              x="449.361"
              y="1022"
              width="7.92313"
              height="88"
              fill="black"
            />
            <rect
              x="470.778"
              y="1022"
              width="6.40092"
              height="88"
              fill="black"
            />
            <rect
              x="463.116"
              y="1022"
              width="4.81492"
              height="88"
              fill="black"
            />
            <rect
              x="483.012"
              y="1022"
              width="4.47915"
              height="88"
              fill="black"
            />
            <rect
              x="489.145"
              y="1022"
              width="4.23625"
              height="88"
              fill="black"
            />
            <rect
              x="500.084"
              y="1022"
              width="6.26594"
              height="88"
              fill="black"
            />
            <rect
              x="514.604"
              y="1022"
              width="7.91072"
              height="88"
              fill="black"
            />
            <rect
              x="530.867"
              y="1022"
              width="6.16703"
              height="88"
              fill="black"
            />
            <rect
              x="525.325"
              y="1022"
              width="2.57523"
              height="88"
              fill="black"
            />
            <rect
              x="170.166"
              y="728"
              width="6.40101"
              height="88"
              fill="black"
            />
            <rect
              x="177.547"
              y="728"
              width="4.17277"
              height="88"
              fill="black"
            />
            <rect
              x="186.318"
              y="728"
              width="8.28109"
              height="88"
              fill="black"
            />
            <rect
              x="199.439"
              y="728"
              width="4.21492"
              height="88"
              fill="black"
            />
            <rect
              x="210.291"
              y="728"
              width="4.21492"
              height="88"
              fill="black"
            />
            <rect x="223.062" y="728" width="4.2458" height="88" fill="black" />
            <rect
              x="230.508"
              y="728"
              width="2.18522"
              height="88"
              fill="black"
            />
            <rect
              x="242.839"
              y="728"
              width="6.32498"
              height="88"
              fill="black"
            />
            <rect x="254.004" y="728" width="4.3714" height="88" fill="black" />
            <rect
              x="259.468"
              y="728"
              width="4.29343"
              height="88"
              fill="black"
            />
            <rect
              x="264.932"
              y="728"
              width="4.29343"
              height="88"
              fill="black"
            />
            <rect x="270.396" y="728" width="11.71" height="88" fill="black" />
            <rect
              x="290.302"
              y="728"
              width="5.79936"
              height="88"
              fill="black"
            />
            <rect
              x="297.952"
              y="728"
              width="11.1623"
              height="88"
              fill="black"
            />
            <rect
              x="312.393"
              y="728"
              width="5.85502"
              height="88"
              fill="black"
            />
            <rect
              x="320.043"
              y="728"
              width="5.62039"
              height="88"
              fill="black"
            />
            <rect
              x="330.184"
              y="728"
              width="4.93382"
              height="88"
              fill="black"
            />
            <rect
              x="339.636"
              y="728"
              width="7.88463"
              height="88"
              fill="black"
            />
            <rect
              x="350.643"
              y="728"
              width="7.59219"
              height="88"
              fill="black"
            />
            <rect
              x="363.044"
              y="728"
              width="6.27789"
              height="88"
              fill="black"
            />
            <rect
              x="372.082"
              y="728"
              width="2.99351"
              height="88"
              fill="black"
            />
            <rect
              x="378.993"
              y="728"
              width="10.3656"
              height="88"
              fill="black"
            />
            <rect
              x="392.342"
              y="728"
              width="9.97677"
              height="88"
              fill="black"
            />
            <rect
              x="405.129"
              y="728"
              width="2.88853"
              height="88"
              fill="black"
            />
            <rect
              x="410.197"
              y="728"
              width="2.93856"
              height="88"
              fill="black"
            />
            <rect
              x="415.432"
              y="728"
              width="11.9526"
              height="88"
              fill="black"
            />
            <rect
              x="428.859"
              y="728"
              width="9.48419"
              height="88"
              fill="black"
            />
            <rect x="439.821" y="728" width="6.1305" height="88" fill="black" />
            <rect
              x="450.361"
              y="728"
              width="10.3732"
              height="88"
              fill="black"
            />
            <rect
              x="470.778"
              y="728"
              width="11.3734"
              height="88"
              fill="black"
            />
            <rect
              x="463.116"
              y="728"
              width="4.81492"
              height="88"
              fill="black"
            />
            <rect
              x="483.487"
              y="728"
              width="4.00332"
              height="88"
              fill="black"
            />
            <rect
              x="489.145"
              y="728"
              width="9.30389"
              height="88"
              fill="black"
            />
            <rect
              x="500.084"
              y="728"
              width="6.26594"
              height="88"
              fill="black"
            />
            <rect
              x="514.604"
              y="728"
              width="7.91072"
              height="88"
              fill="black"
            />
            <rect
              x="530.867"
              y="728"
              width="6.16703"
              height="88"
              fill="black"
            />
            <rect
              x="525.325"
              y="728"
              width="2.57523"
              height="88"
              fill="black"
            />
            <rect
              x="65.4883"
              y="434"
              width="6.40101"
              height="88"
              fill="black"
            />
            <rect
              x="436.961"
              y="434"
              width="5.61424"
              height="88"
              fill="black"
            />
            <rect
              x="72.8691"
              y="434"
              width="4.17277"
              height="88"
              fill="black"
            />
            <rect
              x="444.342"
              y="434"
              width="4.17277"
              height="88"
              fill="black"
            />
            <rect
              x="81.6406"
              y="434"
              width="8.28109"
              height="88"
              fill="black"
            />
            <rect x="455" y="434" width="11.3782" height="88" fill="black" />
            <rect
              x="94.7617"
              y="434"
              width="5.42489"
              height="88"
              fill="black"
            />
            <rect
              x="103.613"
              y="434"
              width="5.48528"
              height="88"
              fill="black"
            />
            <rect
              x="111.218"
              y="434"
              width="12.7759"
              height="88"
              fill="black"
            />
            <rect
              x="475.086"
              y="434"
              width="2.43029"
              height="88"
              fill="black"
            />
            <rect
              x="480.568"
              y="434"
              width="2.43029"
              height="88"
              fill="black"
            />
            <rect x="125.83" y="434" width="2.18522" height="88" fill="black" />
            <rect x="486.05" y="434" width="6.10795" height="88" fill="black" />
            <rect
              x="132.937"
              y="434"
              width="3.89705"
              height="88"
              fill="black"
            />
            <rect
              x="143.785"
              y="434"
              width="5.69607"
              height="88"
              fill="black"
            />
            <rect
              x="497.303"
              y="434"
              width="5.72315"
              height="88"
              fill="black"
            />
            <rect
              x="154.791"
              y="434"
              width="5.46289"
              height="88"
              fill="black"
            />
            <rect
              x="507.748"
              y="434"
              width="6.14639"
              height="88"
              fill="black"
            />
            <rect x="515.69" y="434" width="5.26299" height="88" fill="black" />
            <rect
              x="163.866"
              y="434"
              width="11.3581"
              height="88"
              fill="black"
            />
            <rect
              x="526.264"
              y="434"
              width="3.79886"
              height="88"
              fill="black"
            />
            <rect
              x="540.661"
              y="434"
              width="2.78499"
              height="88"
              fill="black"
            />
            <rect
              x="546.141"
              y="434"
              width="2.78499"
              height="88"
              fill="black"
            />
            <rect
              x="553.647"
              y="434"
              width="6.05658"
              height="88"
              fill="black"
            />
            <rect
              x="562.398"
              y="434"
              width="2.87461"
              height="88"
              fill="black"
            />
            <rect
              x="575.243"
              y="434"
              width="4.58117"
              height="88"
              fill="black"
            />
            <rect
              x="584.546"
              y="434"
              width="7.40451"
              height="88"
              fill="black"
            />
            <rect
              x="597.211"
              y="434"
              width="6.05714"
              height="88"
              fill="black"
            />
            <rect
              x="606.463"
              y="434"
              width="6.05714"
              height="88"
              fill="black"
            />
            <rect
              x="618.948"
              y="434"
              width="9.64985"
              height="88"
              fill="black"
            />
            <rect
              x="630.086"
              y="434"
              width="4.17099"
              height="88"
              fill="black"
            />
            <rect
              x="635.745"
              y="434"
              width="5.96709"
              height="88"
              fill="black"
            />
            <rect
              x="185.625"
              y="434"
              width="5.79936"
              height="88"
              fill="black"
            />
            <rect
              x="176.682"
              y="434"
              width="5.79936"
              height="88"
              fill="black"
            />
            <rect
              x="198.284"
              y="434"
              width="6.15321"
              height="88"
              fill="black"
            />
            <rect
              x="207.483"
              y="434"
              width="5.68486"
              height="88"
              fill="black"
            />
            <rect
              x="215.021"
              y="434"
              width="5.96489"
              height="88"
              fill="black"
            />
            <rect x="223.708" y="434" width="6.0687" height="88" fill="black" />
            <rect
              x="234.959"
              y="434"
              width="5.74097"
              height="88"
              fill="black"
            />
            <rect
              x="245.427"
              y="434"
              width="2.81066"
              height="88"
              fill="black"
            />
            <rect
              x="250.914"
              y="434"
              width="6.27789"
              height="88"
              fill="black"
            />
            <rect
              x="267.404"
              y="434"
              width="2.99351"
              height="88"
              fill="black"
            />
            <rect
              x="274.316"
              y="434"
              width="4.83851"
              height="88"
              fill="black"
            />
            <rect
              x="285.588"
              y="434"
              width="6.27888"
              height="88"
              fill="black"
            />
            <rect
              x="296.427"
              y="434"
              width="4.19139"
              height="88"
              fill="black"
            />
            <rect x="305.58" y="434" width="11.9526" height="88" fill="black" />
            <rect
              x="322.073"
              y="434"
              width="2.75492"
              height="88"
              fill="black"
            />
            <rect
              x="329.542"
              y="434"
              width="3.52652"
              height="88"
              fill="black"
            />
            <rect
              x="335.145"
              y="434"
              width="9.54989"
              height="88"
              fill="black"
            />
            <rect
              x="349.357"
              y="434"
              width="3.02528"
              height="88"
              fill="black"
            />
            <rect
              x="365.942"
              y="434"
              width="5.77272"
              height="88"
              fill="black"
            />
            <rect
              x="356.835"
              y="434"
              width="5.61773"
              height="88"
              fill="black"
            />
            <rect
              x="373.344"
              y="434"
              width="5.78127"
              height="88"
              fill="black"
            />
            <rect
              x="385.704"
              y="434"
              width="4.53581"
              height="88"
              fill="black"
            />
            <rect
              x="395.031"
              y="434"
              width="6.26011"
              height="88"
              fill="black"
            />
            <rect
              x="406.082"
              y="434"
              width="3.96027"
              height="88"
              fill="black"
            />
            <rect
              x="426.189"
              y="434"
              width="7.29632"
              height="88"
              fill="black"
            />
            <rect
              x="414.833"
              y="434"
              width="2.57523"
              height="88"
              fill="black"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "HeadingMotion",
  data() {
    return {
      time: "",
    };
  },
  created() {
    this.time = moment().format("H:mm");
  },
};
</script>
<style scoped lang="scss">
.text {
  font-family: "Open Sans";
  fill: #000;
}
.display-icons-text {
  font-size: 20px;
  fill: #a3a3a3;
  &.selected {
    fill: #216fd6;
  }
}
.display-buttons-sub {
  font-size: 19.6343px;
  letter-spacing: 0.325em;
  fill: #232020;
}
.display-subtext {
  font-size: 34px;
  fill: #3e74b0;
}
.display-time {
  font-size: 26px;
  letter-spacing: 0.05em;
  fill: #232020;
}
.text-curtain-header {
  font-size: 58px;
  font-weight: bold;
}
.text-curtain {
  font-size: 32px;
}
.text-curtain-subtext {
  font-size: 32px;
  fill: #007afb;
}
.motion {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 2;
  .motion__svg {
    position: absolute;
    left: 0;
    top: 0;
    max-height: 100%;
    max-width: 100%;
  }
}
.drop-shadow {
  filter: drop-shadow(0px 3.18298px 9.54894px rgba(48, 49, 51, 0.24))
    drop-shadow(0px 12.7319px 25.4638px rgba(48, 49, 51, 0.24));
}
.animated {
  animation-delay: 0s;
  animation-duration: 5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}
.animated-phone-number {
  animation-name: animate-phone-number;
  animation-timing-function: steps(5, end);
}
.animated-curtain {
  animation-name: animate-curtain-slide;
  animation-timing-function: ease-out;
}
.animated-button-hash {
  animation-name: animate-button-press-hash;
}
.animated-button-star {
  animation-name: animate-button-press-star;
}
.animated-button-zero {
  animation-name: animate-button-press-zero;
}
.animated-button-six {
  animation-name: animate-button-press-six;
}
</style>
<style lang="scss">
$fillBtn: #e2e2e2;
$fillBtnPress: #a3a3a3;
@keyframes animate-curtain-slide {
  0% {
    transform: translate(105px, 1615px);
    fill-opacity: 0;
  }
  45% {
    transform: translate(105px, 1615px);
    fill-opacity: 0;
  }
  55% {
    transform: translate(105px, 170px);
    fill-opacity: 1;
  }
  85% {
    transform: translate(105px, 170px);
    fill-opacity: 1;
  }
  93% {
    transform: translate(105px, 1615px);
    fill-opacity: 0;
  }
  100% {
    transform: translate(105px, 1615px);
    fill-opacity: 0;
  }
}
@keyframes animate-phone-number {
  0% {
    width: 214px;
    x: -14px;
  }
  36% {
    width: 0px;
    x: 200px;
  }
  100% {
    width: 0px;
    x: 200px;
  }
}
@keyframes animate-button-press-star {
  0% {
    fill: $fillBtn;
  }
  6% {
    fill: $fillBtn;
  }
  9% {
    fill: $fillBtnPress;
  }
  13% {
    fill: $fillBtn;
  }
  100% {
    fill: $fillBtn;
  }
}
@keyframes animate-button-press-zero {
  0% {
    fill: $fillBtn;
  }
  20% {
    fill: $fillBtn;
  }
  23% {
    fill: $fillBtnPress;
  }
  27% {
    fill: $fillBtn;
  }
  100% {
    fill: $fillBtn;
  }
}
@keyframes animate-button-press-six {
  0% {
    fill: $fillBtn;
  }
  27% {
    fill: $fillBtn;
  }
  30% {
    fill: $fillBtnPress;
  }
  34% {
    fill: $fillBtn;
  }
  100% {
    fill: $fillBtn;
  }
}
@keyframes animate-button-press-hash {
  0% {
    fill: $fillBtn;
  }
  13% {
    fill: $fillBtn;
  }
  16% {
    fill: $fillBtnPress;
  }
  20% {
    fill: $fillBtn;
  }
  34% {
    fill: $fillBtn;
  }
  37% {
    fill: $fillBtnPress;
  }
  41% {
    fill: $fillBtn;
  }
  100% {
    fill: $fillBtn;
  }
}
</style>
