<template>
  <div class="client-card-row pa-5 pa-md-10">
    <div class="client-image d-flex justify-center align-center mb-3 mb-md-6">
      <img :src="clientData.logo" alt="" />
    </div>
    <div
      v-if="hasDescription && isHtml"
      class="client-description body1"
      v-html="clientData.description"
    />
    <div v-else-if="hasDescription && !isHtml" class="client-description body1">
      <p>{{ clientData.description }}</p>
    </div>
    <div v-if="hasLink" class="client-link mt-3">
      <a :href="link" target="_blank">{{ linkTitle }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // Данные клиента
    clientData: {
      type: Object,
      required: true,
    },
    isHtml: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    hasDescription() {
      return (
        this.clientData.description &&
        this.clientData.description.trim().length > 0
      );
    },
    hasLink() {
      return (
        this.clientData.website && this.clientData.website.trim().length > 0
      );
    },
    linkTitle() {
      const link = this.clientData.website;
      return link.replace(/^https?:\/\//, "");
    },
    link() {
      const link = this.clientData.website;
      if (link.startsWith("http://") || link.startsWith("https://"))
        return link;
      return `http://${link}`;
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.client-card-row {
  height: 100%;
  border: 2px solid var(--v-corporateBorder-base);

  .client-image {
    height: 180px;
  }
}
</style>
