<template>
  <div v-if="pageLoaded">
    <v-row>
      <v-col cols="12">
        <breadcrumbs class="no-marker px-0" :items="breadcrumbsItems" />
      </v-col>
    </v-row>

    <article-with-contents
      ref="refarticle"
      :contents="pageData.fields.contents"
      :class="`sv-article-category-${pageData.pid}`"
    >
      <template v-slot:aside>
        <badge :text="categoryField('title')" :color="categoryField('color')" />
      </template>

      <v-row>
        <v-col>
          <h1>{{ pageData.fields.title }}</h1>
        </v-col>
      </v-row>
      <v-row v-if="pageData.fields.subtitle">
        <v-col class="pt-0">
          <span class="subtitle1">{{ pageData.fields.subtitle }}</span>
        </v-col>
      </v-row>

      <v-row v-if="(pageData.fields.presentation || '').length > 0">
        <v-col>
          <masked-icon-link
            :mask-icon="`${baseUrl}img/pdf.svg`"
            :title="pageData.products_page.fields.button_presentation || ''"
            :href="pageData.fields.presentation"
            :color="categoryField('color')"
          />
        </v-col>
      </v-row>

      <v-row class="section">
        <v-col>
          <article>
            <kk-dynamic-html
              class="body2"
              :html="pageData.fields.full_text_before"
            />

            <form-communication
              :form-id="formId"
              :person-set="pageData.links.person || []"
              class="mb-5 mb-sm-10"
            />

            <kk-dynamic-html :html="pageData.fields.full_text" />
          </article>
        </v-col>
      </v-row>

      <v-row v-if="catchupProducts.length > 0" class="section">
        <v-col cols="12" class="mb-4 mb-md-8">
          <span class="heading2">{{
            pageData.products_page.fields.catchup_title
          }}</span>
        </v-col>
        <v-col
          v-for="catchupProduct in catchupProducts"
          :key="catchupProduct.id"
          class="col-12 col-xxl-6"
        >
          <product-card :product-id="catchupProduct.id" short />
        </v-col>
      </v-row>
    </article-with-contents>
  </div>
</template>

<script>
import LandingRepository from "@/api/landing.js";
import Badge from "@/components/Badge.vue";
import FormCommunication from "@/components/FormCommunication.vue";
import Breadcrumbs from "@/components/Article/Breadcrumbs.vue";
import ArticleWithContents from "@/components/Article/ArticleWithContents.vue";
import MaskedIconLink from "@/components/MaskedIconLink.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ProductCard from "@/components/ProductCard/ProductCard.vue";
export default {
  components: {
    Badge,
    FormCommunication,
    ArticleWithContents,
    MaskedIconLink,
    Breadcrumbs,
    KkDynamicHtml,
    ProductCard,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.pageData.products_page.fields.title
      ),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        links: { person: [], feedback_form: [], product: [] },
        main_page: { fields: {} },
        products_page: {
          fields: {},
          links: { feedback_form: [] },
        },
      },
    };
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_page.fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.products_page.fields.title || "",
          to: { name: "Products" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.fields.title || "",
          disabled: true,
        },
      ];
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
    category() {
      return this.$store.getters.getProductCategory(this.pageData.pid);
    },
    categoryField() {
      return (field) => (this.category ? this.category[field] : "");
    },
    formId() {
      if ((this.pageData?.links?.feedback_form || []).length > 0) {
        return this.pageData.links.feedback_form[0];
      }
      return this.pageData.products_page.links.feedback_form[0] || 0;
    },
    catchupProducts() {
      const result = [];
      Object.entries(this.pageData.links || {}).forEach((entry) => {
        entry[1].forEach((id) => {
          const product = this.$store.getters.getProduct(id);
          if (product) {
            result.push(product);
          }
        });
      });
      return result;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    return this.loadPage();
  },
  methods: {
    async loadPage() {
      try {
        const { data } = await LandingRepository.getFreesmsc();
        this.pageData = data;
        this.$store.commit("setLangsAvail", data.published);
        this.pageLoaded = true;
        this.$emit("page-loaded");
        this.$nextTick(() => {
          this.$refs.refarticle.initObserver();
        });
      } catch (error) {
        return true;
      }
    },
  },
};
</script>
