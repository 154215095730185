<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 4.10699L19.8932 3.00019L12.0001 10.8932L4.10691 3L3 4.10691L10.8932 12.0001L3.00019 19.8932L4.10699 21L12 13.107L19.893 20.9999L20.9999 19.893L13.107 12L21 4.10699Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
