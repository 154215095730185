<template>
  <div v-if="pageLoaded" v-resize="onResize" class="body-dms-digest">
    <div class="content-dms-digest">
      <heading
        :page-data="pageData"
        @scroll-to-trust="onTrustSolution"
        @scroll-to-special-offer="onSpecialOfferSolution"
      />

      <trust-us :trust-data="pageData.dms_digest_trust_us" />

      <statistics-desktop
        v-if="desktop"
        ref="refstatdesktop"
        :statistics-data="pageData.dms_digest_statistics"
      />
      <statistics-tablet-mobile
        v-if="!desktop"
        ref="refstatmobile"
        :statistics-data="pageData.dms_digest_statistics"
      />

      <data-analytics
        :data-analytics-data="pageData.dms_digest_data_analytics"
      />

      <new-dms :new-dms-data="pageData.dms_digest_new_dms" />

      <special-offer :special-offer-data="pageData.dms_digest_special_offer" />

      <form-communication
        :form-id="pageData.links.feedback_form[0] || 0"
        :person-set="pageData.links.person"
      />
    </div>

    <zuruck />
  </div>
</template>

<script>
import LandingRepository from "@/api/landing.js";
import FormCommunication from "@/components/FormCommunication.vue";
import Heading from "./Heading.vue";
import TrustUs from "./TrustUs.vue";
import StatisticsDesktop from "./Statistics/StatisticsDesktop.vue";
import StatisticsTabletMobile from "./Statistics/StatisticsTabletMobile.vue";
import DataAnalytics from "./DataAnalytics.vue";
import NewDms from "./NewDms.vue";
import SpecialOffer from "./SpecialOffer.vue";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    FormCommunication,
    Heading,
    TrustUs,
    StatisticsDesktop,
    StatisticsTabletMobile,
    DataAnalytics,
    NewDms,
    SpecialOffer,
    Zuruck,
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        links: { feedback_form: [], person: [] },
        dms_digest_trust_us: { fields: {}, children: [] },
        dms_digest_statistics: { fields: {}, children: [] },
        dms_digest_data_analytics: { fields: {}, children: [] },
        dms_digest_new_dms: { fields: {}, children: [] },
        dms_digest_special_offer: { fields: {} },
      },
    };
  },
  computed: {
    desktop() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await LandingRepository.getDmsDigest();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
      this.$nextTick(() => {
        this.onResize();
      });
    },
    onTrustSolution() {
      elementScrollIntoView(document.querySelector("#trust-solution"), {
        behavior: "smooth",
      });
    },
    onSpecialOfferSolution() {
      elementScrollIntoView(document.querySelector("#special-offer-solution"), {
        behavior: "smooth",
      });
    },
    async onResize() {
      if (this.$refs.refstatdesktop) {
        this.$refs.refstatdesktop.refreshChart();
      }
      if (this.$refs.refstatmobile) {
        this.$refs.refstatmobile.refreshChart();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-dms-digest {
  margin-top: -92px;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -80px;
  overflow-x: clip;
  overflow-y: visible;
}
.content-dms-digest {
  padding-top: 92px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 80px;
}
@media (min-width: 640px) {
  .body-dms-digest {
    margin-left: -127px;
    margin-right: -127px;
    margin-bottom: -160px;
  }
  .content-dms-digest {
    padding-left: 127px;
    padding-right: 127px;
    padding-bottom: 160px;
  }
}

@media (min-width: 1280px) {
  .body-dms-digest {
    margin-top: -160px;
    margin-left: -55px;
    margin-right: -55px;
    margin-bottom: -160px;
  }
  .content-dms-digest {
    padding-top: 160px;
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 160px;
  }
}

@media (min-width: 1440px) {
  .body-dms-digest {
    margin-left: -135px;
    margin-right: -135px;
    margin-bottom: -160px;
  }
  .content-dms-digest {
    padding-left: 135px;
    padding-right: 135px;
    padding-bottom: 160px;
  }
}

@media (min-width: 1920px) {
  .body-dms-digest {
    margin-left: -175px;
    margin-right: -175px;
    margin-bottom: -160px;
  }
  .content-dms-digest {
    padding-left: 175px;
    padding-right: 175px;
    padding-bottom: 160px;
  }
}
</style>
