import Repository from "@/plugins/axios";

export default {
  // Получить список новостей (отфильтрованный по тэгу / разбитый постранично)
  // params = { offset: 0, limit: 3, tags: ['Связь', 'Узбеки'] }
  // можно params = {}, тогда выдаст всё
  getNewsList(params) {
    return Repository.get("/news", { params });
  },

  // Получить новость + 3 последних новости
  // newsId -- это поле id новости
  getNewsItem(newsId) {
    return Repository.get(`/news/${newsId}`);
  },
};
