import { render, staticRenderFns } from "./Why.vue?vue&type=template&id=db4b32a4&scoped=true"
import script from "./Why.vue?vue&type=script&lang=js"
export * from "./Why.vue?vue&type=script&lang=js"
import style0 from "./Why.vue?vue&type=style&index=0&id=db4b32a4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db4b32a4",
  null
  
)

export default component.exports