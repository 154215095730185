<template>
  <div class="section" style="z-index: 2; position: relative">
    <div class="heading1 text-center margin-100">
      {{ freeTestData.fields.title || "" }}
      <span style="color: var(--v-primary-base)">
        {{ freeTestData.fields.title_accent }}
      </span>
      {{ freeTestData.fields.title_after_accent }}
    </div>
    <v-row class="margin-100 mt-8 mt-md-16">
      <v-col cols="12" lg="6" align-self="center">
        <div
          class="px-5 px-md-10 py-5 py-md-10"
          style="background-color: #2196f30d"
        >
          <div v-html="freeTestData.fields.text"></div>
          <div class="mt-4 mt-md-10">
            <v-btn
              depressed
              x-large
              color="primary"
              :href="freeTestData.fields.link_details"
            >
              {{ freeTestData.fields.button_details }}
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="6" align-self="center">
        <div class="image-shadow">
          <v-img max-height="100%" :src="freeTestData.fields.image" eager />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    freeTestData: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.image-shadow {
  border-radius: 6px;
  box-shadow: 0px 7.46367px 18.65917px 0px rgba(16, 73, 118, 0.15),
    0px 1.49273px 5.59775px 0px rgba(20, 90, 147, 0.1);
}
.margin-100 {
  margin-left: -5px;
  margin-right: -5px;
}
@media (min-width: 1440px) {
  .margin-100 {
    margin-left: 100px;
    margin-right: 100px;
  }
}
</style>
