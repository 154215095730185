<template>
  <div class="d-inline-block sv-badge-container">
    <span class="sv-badge" :style="{ 'background-color': color }">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "SvBadge",
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>
<style lang="scss" scoped>
.sv-badge {
  display: inline-block;
  font-size: 12px;
  line-height: 18px !important;
  border-radius: 14px;
  padding: 2px 12px 0;
  margin: 4px 0px;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  color: white;
}
.sv-badge-container ~ .sv-badge-container {
  margin-left: 12px;
}
@media (min-width: 640px) {
  .sv-badge {
    font-size: 16px;
    line-height: 27px !important;
  }
}
</style>
