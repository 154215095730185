<template>
  <div>
    <v-row class="section__title-row">
      <v-col class="text-center">
        <span class="heading2">{{ connectData.fields.title || "" }}</span>
      </v-col>
    </v-row>
    <v-row class="section align-center justify-center">
      <v-col
        cols="12"
        lg="7"
        class="col-xxl-5 order-lg-1 pl-lg-16 mb-8 mb-lg-0"
      >
        <v-img :src="connectData.fields.image" />
      </v-col>
      <v-col cols="12" lg="5" class="col-xxl-4 order-lg-0">
        <v-row
          v-for="(item, index) in connectData.children"
          :key="index"
          class="mb-6"
        >
          <v-col cols="12" sm="" class="flex-grow-0 pr-sm-6 pb-3 pb-sm-0">
            <ordered-list-number :index="index" />
          </v-col>
          <div class="col-sm col-12" v-html="item.fields.description" />
        </v-row>
        <v-row>
          <v-col cols="12">
            <div
              class="corporateBlue-lighten4 pa-5 pa-md-10"
              v-html="connectData.fields.text"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrderedListNumber from "@/components/OrderedListNumber.vue";
export default {
  name: "Connect",
  components: {
    OrderedListNumber,
  },
  props: {
    connectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
