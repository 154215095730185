import Repository from "@/plugins/axios";

export default {
  // Получить контент страницы "Карьера"
  getCareer() {
    return Repository.get("/career");
  },

  // Получить контент страницы открытой вакансии
  // offerName -- это поле node_name вакансии
  getCareerJobOffer(offerName) {
    return Repository.get(`/career/${offerName}`);
  },
};
