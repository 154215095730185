<template>
  <div>
    <v-row class="section__title-row">
      <v-col class="text-center">
        <span class="heading2">{{ extensionData.fields.title || "" }}</span>
      </v-col>
    </v-row>
    <v-row class="section justify-center">
      <v-col cols="12" lg="4" class="col-xxl-3">
        <div
          class="corporateBlue-lighten4 pa-5 pa-md-10"
          style="height: 100%"
          v-html="extensionData.fields.text"
        />
      </v-col>
      <v-col cols="12" lg="8" class="col-xxl-6">
        <product-card
          :product-id="extensionData.links.product[0] || 0"
          style="height: 100%"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard/ProductCard.vue";

export default {
  name: "Extension",
  components: {
    ProductCard,
  },
  props: {
    extensionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
