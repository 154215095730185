<template>
  <div>
    <div class="heading1 text-center">
      {{ newDmsData.fields.title || "" }}
    </div>
    <v-row id="new-dms-elephant" class="new-dms-section">
      <v-col
        v-if="$vuetify.breakpoint[`lgAndUp`]"
        lg="6"
        offset-lg="1"
        class="d-flex justify-center new-dms-item-block"
      >
        <div class="new-dms-item-image">
          <div
            class="new-dms-elephant-block"
            :style="getElephantBlockSolutionStyle()"
          >
            <v-img
              ref="new_dms_elephant"
              eager
              class="new-dms-elephant"
              :src="elephant.fields.image_slon"
              :style="getElephantSolutionStyle()"
              @load="$emit('trigger-resize')"
            />
          </div>
          <div
            class="new-dms-oracle-block"
            :style="getOracleBlockSolutionStyle()"
          >
            <v-img
              eager
              :src="elephant.fields.image_victim"
              :style="getOracleSolutionStyle()"
              @load="$emit('trigger-resize')"
            />
          </div>
        </div>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint[`lgAndUp`]"
        lg="4"
        offset-lg="1"
        class="d-flex new-dms-item-block"
      >
        <div class="new-dms-item-text">
          <v-row>
            <v-col class="subtitle1 pb-0">
              {{ elephant.fields.title }}
            </v-col>
          </v-row>
          <v-row>
            <div class="col pt-2 pt-md-3" v-html="elephant.fields.text" />
          </v-row>
        </div>
      </v-col>
      <v-col
        v-if="!$vuetify.breakpoint[`lgAndUp`]"
        cols="12"
        class="d-flex justify-center new-dms-item-block"
        style="flex-direction: column"
      >
        <div class="d-flex new-dms-item-image" style="flex: 1 0 0">
          <div
            style="
              flex: 0 0 fit-content;
              align-self: center;
              position: relative;
            "
          >
            <div
              class="new-dms-elephant-block"
              :style="getElephantBlockSolutionStyle()"
            >
              <v-img
                ref="new_dms_elephant"
                eager
                class="new-dms-elephant"
                :src="elephant.fields.image_slon"
                :style="getElephantSolutionStyle()"
                @load="$emit('trigger-resize')"
              />
            </div>
            <div
              class="new-dms-oracle-block"
              :style="getOracleBlockSolutionStyle()"
            >
              <v-img
                eager
                max-width="90vw"
                :src="elephant.fields.image_victim"
                :style="getOracleSolutionStyle()"
                @load="$emit('trigger-resize')"
              />
            </div>
          </div>
        </div>
        <div class="new-dms-item-text">
          <v-row>
            <v-col class="subtitle1 pb-0">
              {{ elephant.fields.title }}
            </v-col>
          </v-row>
          <v-row>
            <div class="col pt-2 pt-md-3" v-html="elephant.fields.text" />
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row id="new-dms-technologies" class="new-dms-section">
      <v-col
        v-if="$vuetify.breakpoint[`lgAndUp`]"
        lg="6"
        offset-lg="1"
        class="d-flex justify-center new-dms-item-block"
      >
        <div class="new-dms-item-image">
          <v-row>
            <v-col
              v-for="icon in technologies.children"
              :key="icon.title"
              cols="3"
            >
              <v-img
                eager
                :src="icon.fields.image"
                :style="getTechnologySolutionStyle(icon.fields.ordr)"
                @load="$emit('trigger-resize')"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint[`lgAndUp`]"
        lg="4"
        offset-lg="1"
        class="d-flex new-dms-item-block"
      >
        <div class="new-dms-item-text">
          <v-row>
            <v-col class="subtitle1 pb-0">
              {{ technologies.fields.title }}
            </v-col>
          </v-row>
          <v-row>
            <div class="col pt-2 pt-md-3" v-html="technologies.fields.text" />
          </v-row>
        </div>
      </v-col>
      <v-col
        v-if="!$vuetify.breakpoint[`lgAndUp`]"
        cols="12"
        class="d-flex justify-center new-dms-item-block"
        style="flex-direction: column"
      >
        <div class="d-flex new-dms-item-image" style="flex: 1 0 0">
          <div
            style="
              flex: 0 0 fit-content;
              align-self: center;
              position: relative;
            "
          >
            <v-row>
              <v-col
                v-for="icon in technologies.children"
                :key="icon.title"
                cols="3"
                align-self="center"
              >
                <v-img
                  eager
                  :src="icon.fields.image"
                  :style="getTechnologySolutionStyle(icon.fields.ordr)"
                  @load="$emit('trigger-resize')"
                />
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="new-dms-item-text">
          <v-row>
            <v-col class="subtitle1 pb-0">
              {{ technologies.fields.title }}
            </v-col>
          </v-row>
          <v-row>
            <div class="col pt-2 pt-md-3" v-html="technologies.fields.text" />
          </v-row>
        </div>
      </v-col>
    </v-row>
    <web-interface :web-interface="webInterface" />
  </div>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
import WebInterface from "./WebInterface.vue";
export default {
  name: "NewDms",
  components: { WebInterface },
  mixins: [ProgressObserverMixin],
  props: {
    newDmsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      obvOptions: {
        viewportTopPct: 0,
        viewportBottomPct: 100,
        pivotBegin: "center", // avail: top, bottom, center
        pivotEnd: "center", // avail: top, bottom, center
      },
    };
  },
  computed: {
    elephant() {
      return this.newDmsData.dms_digest_new_dms_slon;
    },
    technologies() {
      return this.newDmsData.dms_digest_new_dms_fading;
    },
    webInterface() {
      return this.newDmsData.dms_digest_new_dms_rolling;
    },
    getOracleBlockSolutionStyle() {
      return () => {
        if (this.$vuetify.breakpoint[`mdAndUp`]) {
          const progress = this.obv(`new-dms-elephant`);
          if (progress < 0.5) return;
          let scale_progress = (progress - 0.5) * 2;
          const transform = `translateY(${(100 * scale_progress) / 2}%)`;
          return {
            transform,
          };
        } else {
          const progress = this.obv(`new-dms-elephant`);
          if (progress < 0.25) return;
          let scale_progress = (progress - 0.25) * 4;
          scale_progress = scale_progress > 1 ? 1 : scale_progress;
          const transform = `translateY(${(100 * scale_progress) / 2}%)`;
          return {
            transform,
          };
        }
      };
    },
    getOracleSolutionStyle() {
      return () => {
        if (this.$vuetify.breakpoint[`mdAndUp`]) {
          const progress = this.obv(`new-dms-elephant`);
          if (progress < 0.5) return;
          let scale_progress = (progress - 0.5) * 2;
          const transform = `scale(1, ${1 - scale_progress})`;
          const top = `${
            (this.$refs.new_dms_elephant.$el.offsetHeight * scale_progress) / 2
          }px`;
          return {
            transform,
            top,
          };
        } else {
          const progress = this.obv(`new-dms-elephant`);
          if (progress < 0.25) return;
          let scale_progress = (progress - 0.25) * 4;
          scale_progress = scale_progress > 1 ? 1 : scale_progress;
          const transform = `scale(1, ${1 - scale_progress})`;
          const top = `${
            (this.$refs.new_dms_elephant.$el.offsetHeight * scale_progress) / 2
          }px`;
          return {
            transform,
            top,
          };
        }
      };
    },
    getElephantBlockSolutionStyle() {
      return () => {
        if (this.$vuetify.breakpoint[`mdAndUp`]) {
          const progress = this.obv(`new-dms-elephant`);
          if (progress < 0.5) {
            return {
              transform: "translate(-50%, -100%)",
              top: "0",
            };
          }
          let scale_progress = progress - 0.5;
          const transform = `translate(-50%, ${-100 * (1 - scale_progress)}%)`;
          const top = `${100 * scale_progress}%`;
          return {
            transform,
            top,
          };
        } else {
          const progress = this.obv(`new-dms-elephant`);
          if (progress < 0.25) {
            return {
              transform: "translate(-50%, -100%)",
              top: "0",
            };
          }
          let scale_progress = (progress - 0.25) * 2;
          scale_progress = scale_progress > 0.5 ? 0.5 : scale_progress;
          const transform = `translate(-50%, ${-100 * (1 - scale_progress)}%)`;
          const top = `${100 * scale_progress}%`;
          return {
            transform,
            top,
          };
        }
      };
    },
    getElephantSolutionStyle() {
      return () => {
        if (this.$vuetify.breakpoint[`mdAndUp`]) {
          const progress = this.obv(`new-dms-elephant`);
          const base_scale = 2;
          let scale_progress = progress * 2;
          scale_progress = scale_progress > 1 ? 1 : scale_progress;
          let max_scale = base_scale * scale_progress;
          max_scale = max_scale < 1 ? 1 : max_scale;
          let elephant_scale = base_scale / max_scale;
          let oracle_top = 100 * (progress * 2 - 1);
          oracle_top = oracle_top > 0 ? 0 : oracle_top;
          const transform = `translateY(${oracle_top}vh) scale(${elephant_scale}, ${elephant_scale})`;
          const opacity = progress > 0 ? 1 : 0;
          return {
            transform,
            opacity,
          };
        } else {
          const progress = this.obv(`new-dms-elephant`);
          const base_scale = 2;
          let scale_progress = progress * 4;
          scale_progress = scale_progress > 1 ? 1 : scale_progress;
          let max_scale = base_scale * scale_progress;
          max_scale = max_scale < 1 ? 1 : max_scale;
          let elephant_scale = base_scale / max_scale;
          let oracle_top = 100 * (progress * 4 - 1);
          oracle_top = oracle_top > 0 ? 0 : oracle_top;
          const transform = `translateY(${oracle_top}vh) scale(${elephant_scale}, ${elephant_scale})`;
          const opacity = progress > 0 ? 1 : 0;
          return {
            transform,
            opacity,
          };
        }
      };
    },
    getTechnologySolutionStyle() {
      return (order) => {
        if (this.$vuetify.breakpoint[`mdAndUp`]) {
          const progress = this.obv(`new-dms-technologies`);
          const items_count = this.technologies.children?.length ?? 0;
          if (!items_count || progress < 0.25) return { opacity: 0 };
          const opacity_progress = ((progress - 0.25) * 4) / 3;
          const max = order / items_count;
          const min = (order - 1) / items_count;
          let opacity =
            min > opacity_progress ? 0 : (opacity_progress - min) / (max - min);
          opacity = opacity > 1 ? 1 : opacity;
          return {
            opacity,
          };
        } else {
          const progress = this.obv(`new-dms-technologies`);
          const items_count = this.technologies.children?.length ?? 0;
          if (!items_count || progress == 0) return { opacity: 0 };
          let opacity_progress = progress * 2;
          opacity_progress = opacity_progress > 1 ? 1 : opacity_progress;
          const max = order / items_count;
          const min = (order - 1) / items_count;
          let opacity =
            min > opacity_progress ? 0 : (opacity_progress - min) / (max - min);
          opacity = opacity > 1 ? 1 : opacity;
          return {
            opacity,
          };
        }
      };
    },
  },
  mounted() {
    this.obvAddElement(`new-dms-elephant`);
    this.obvAddElement(`new-dms-technologies`);
  },
};
</script>
<style lang="scss" scoped>
.new-dms-section {
  height: 100vh;
}
.new-dms-item-block {
  position: sticky;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0;
  height: 100vh;
}
.new-dms-item-image {
  position: relative;
}
.new-dms-item-image,
.new-dms-item-text {
  height: fit-content;
  align-self: center;
}
.new-dms-item-text {
  padding: 12px 16px;
  box-shadow: 0px 3.29981px 12.3743px rgba(20, 90, 147, 0.1),
    0px 16.499px 41.2476px rgba(16, 73, 118, 0.15);
  border-radius: 12px;
}
.new-dms-elephant-block {
  position: absolute;
  left: 50%;
  max-width: 80%;
}
.new-dms-elephant {
  opacity: 0;
}
.new-dms-webInterface-image {
  position: absolute;
  left: 50%;
  top: 50%;
}
@media (min-width: 640px) {
  .new-dms-item-text {
    padding: 20px 24px;
  }
}
@media (min-width: 1024px) {
  .new-dms-section {
    height: 200vh;
  }
}
@media (min-width: 1280px) {
  .new-dms-elephant-block {
    max-width: 100%;
  }
}
</style>
