<template>
  <div class="kk-arrow" :style="btnStyle" v-on="$listeners">
    <v-icon :size="24">mdi-chevron-right</v-icon>
  </div>
</template>
<script>
export default {
  name: "KkLink",
  props: {
    rotate: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    btnStyle() {
      return {
        transform: `rotateZ(${this.rotate}deg)`,
      };
    },
  },
};
</script>
<style scoped lang="scss">
.kk-arrow {
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 12px 0px rgba(48, 49, 51, 0.03),
    0px 0px 24px 0px rgba(48, 49, 51, 0.03);

  .v-icon {
    color: rgba(0, 0, 0, 0.32);
  }

  &:hover,
  &.hover {
    box-shadow: 0px 0px 1px 0px rgba(48, 49, 51, 0.15),
      0px 4px 8px 0px rgba(48, 49, 51, 0.1);

    .v-icon {
      color: rgba(0, 0, 0, 0.64);
    }
  }
}

@media (min-width: 1024px) {
  .kk-arrow {
    width: 36px;
    height: 36px;
  }
}
</style>
