<template>
  <div class="section__gallery">
    <div ref="reftouchpanel" class="gallery-touch-panel">
      <div class="scroller__container">
        <slot></slot>
        <div class="scroller" :class="blurring ? 'blurring' : ''">
          <div class="scroller__item left" :style="leftItem">
            <image-extended :image="leftElement" class="scroller__image" />
          </div>
          <div class="scroller__item this" :style="thisItem">
            <image-extended :image="thisElement" class="scroller__image" />
          </div>
          <div class="scroller__item right" :style="rightItem">
            <image-extended :image="rightElement" class="scroller__image" />
          </div>
          <div class="scroller__shader" />
        </div>
      </div>
    </div>
    <div
      v-if="!indexFirst"
      class="ear ear--left clickable"
      :class="{ fullscr: blurring }"
    >
      <kk-arrow :rotate="180" @click.stop.prevent="scrollLeft" />
    </div>
    <div
      v-if="!indexLast || !$vuetify.breakpoint.xlOnly"
      class="ear ear--right clickable"
    >
      <kk-arrow :rotate="0" @click.stop.prevent="scrollRight" />
    </div>
  </div>
</template>
<script>
import KkArrow from "@/components/KkArrow.vue";
import ImageExtended from "@/components/ImageExtended.vue";
export default {
  name: "ImageGallery",
  components: { KkArrow, ImageExtended },
  props: {
    items: {
      type: Array,
      required: true,
    },
    nonCycled: {
      type: Boolean,
      default: false,
    },
    blurred: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // constant props
      animationDuration: 0.5,
      animationTimingFunction: "ease-in-out",
      shadingTime: 0.5,
      swipeDistance: 80,
      // vars
      activeItemIndex: 0,
      movingLeft: false,
      movingRight: false,
      touchstartX: 0,
      touchstartY: 0,
      blurring: false,
      pastTransitionEnd: true,
    };
  },
  computed: {
    moving() {
      return this.movingLeft || this.movingRight;
    },
    indexFirst() {
      return this.nonCycled && this.activeItemIndex == 0;
    },
    indexLast() {
      return this.nonCycled && this.activeItemIndex == this.items.length - 1;
    },
    leftElement() {
      const index =
        this.activeItemIndex > 0
          ? this.activeItemIndex - 1
          : this.items.length - 1;
      return this.items[index];
    },
    thisElement() {
      return this.items[this.activeItemIndex];
    },
    rightElement() {
      const index =
        this.activeItemIndex < this.items.length - 1
          ? this.activeItemIndex + 1
          : 0;
      return this.items[index];
    },
    leftItem() {
      const transition = this.movingLeft
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const left = this.movingLeft ? "0%" : "-100%";
      return { transition, left };
    },
    thisItem() {
      const transition = this.moving
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const nonLeft = this.movingRight ? "-50%" : "0%";
      const left = this.movingLeft ? "50%" : nonLeft;
      return { transition, left };
    },
    rightItem() {
      const transition = this.movingRight
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const left = this.movingRight ? "0%" : "100%";
      return { transition, left };
    },
  },
  mounted() {
    const zone = this.$refs.reftouchpanel;
    zone.addEventListener("pointerdown", this.onPointerDown, false);
    zone.addEventListener("pointerup", this.onPointerUp, false);
  },
  destroyed() {
    const zone = this.$refs.reftouchpanel;
    if (zone) {
      zone.removeEventListener("pointerdown", this.onPointerDown);
      zone.removeEventListener("pointerup", this.onPointerUp);
    }
  },
  methods: {
    onPointerDown(event) {
      this.touchstartX = event.screenX;
      this.touchstartY = event.screenY;
    },

    onPointerUp(event) {
      if (this.touchstartX < event.screenX - this.swipeDistance) {
        this.scrollLeft();
      } else if (this.touchstartX > event.screenX + this.swipeDistance) {
        this.scrollRight();
      }
    },

    async scrollLeft() {
      if (this.moving) return;
      if (this.indexFirst) return;

      this.blurring = false;

      this.$emit("scroll-left", this.activeItemIndex);

      this.movingLeft = true;
      await new Promise((resolve) =>
        setTimeout(resolve, this.animationDuration * 1000)
      );

      if (this.activeItemIndex == 0) {
        this.activeItemIndex = this.items.length - 1;
      } else {
        this.activeItemIndex--;
      }

      this.movingLeft = false;

      if (this.indexFirst) {
        this.$emit("end-left");
      }
    },

    async scrollRight() {
      if (this.moving) return;
      if (this.indexLast) return;

      this.$emit("scroll-right", this.activeItemIndex);

      this.movingRight = true;
      this.pastTransitionEnd = false;

      await new Promise((resolve) =>
        setTimeout(resolve, this.animationDuration * 1000)
      );

      if (this.activeItemIndex == this.items.length - 2) {
        this.blurring = true;
      }

      if (this.activeItemIndex == this.items.length - 1) {
        this.activeItemIndex = 0;
      } else {
        this.activeItemIndex++;
      }

      this.movingRight = false;
      if (this.indexLast) {
        this.$emit("end-right");
      }

      await new Promise((resolve) =>
        setTimeout(resolve, this.shadingTime * 1000)
      );
      this.pastTransitionEnd = true;
    },

    scrollTo(index) {
      this.activeItemIndex = index;
    },

    reset() {
      this.blurring = false;
      this.activeItemIndex = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.section__gallery {
  position: relative;
  overflow: visible;

  .ear {
    position: absolute;
    width: 26px;
    top: 50%;
    margin-top: -26px;
    z-index: 4;

    @media (max-width: 1023px) {
      top: 100%;
      margin-top: 26px;
    }

    &.ear--left {
      left: -55px;
      @media (max-width: 1023px) {
        left: 4px;
        &.fullscr {
          display: none;
        }
      }
    }
    &.ear--right {
      right: -55px;
      @media (max-width: 1023px) {
        right: 4px;
      }
    }
  }

  .gallery-touch-panel {
    height: var(--height);
  }

  --height: 100%;
  --shadingTime: 0.5s;

  .scroller__container {
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    height: var(--height);

    .scroller {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: var(--height);
      overflow-x: visible;
      filter: blur(0px);
      transition: filter var(--shadingTime) ease-out;

      .scroller__shader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        z-index: 3;
        opacity: 0;
        transition: opacity var(--shadingTime) ease-out;
      }

      &.blurring {
        filter: blur(4px);
        .scroller__shader {
          opacity: 0.24;
        }
      }

      .scroller__item {
        overflow-x: hidden;
        overflow-y: hidden;
        position: absolute;
        width: 100%;
        height: var(--height);

        &.this {
          z-index: 1;
        }
        &.left,
        &.right {
          z-index: 2;
        }

        .scroller__image {
          position: absolute;
          min-width: 100%;
          max-width: none;
          width: 100%;
          height: var(--height);
          left: 0;
          top: 0;
        }
      }
    }
  }
}
</style>
