<template>
  <div
    id="smsc-heading-block"
    class="d-flex justify-center"
    :style="getSolutionStyle()"
  >
    <div
      ref="heading"
      v-resize="onResize"
      class="border heading-main-block"
      :class="{ hovered: hovered, 'not-hovered': !hovered }"
      style="position: relative; background-color: #f3f5f6"
      @mouseover="hovered = true"
    >
      <v-img
        eager
        class="border-left"
        style="width: 17.3%"
        :src="`${baseUrl}img/menu_smsc.svg`"
        @load="onResize"
      />
      <v-img
        eager
        class="border-right"
        style="left: 17.3%; top: 0; width: 82.7%; position: absolute"
        :src="`${baseUrl}img/top_menu.svg`"
      />
      <div
        :style="{ padding: spaceOutside }"
        style="
          left: 17.3%;
          top: 6.605%;
          width: 82.7%;
          height: 93.385%;
          position: absolute;
        "
      >
        <div
          class="d-flex"
          :style="{
            'margin-bottom': spaceBetween,
            gap: spaceBetween,
          }"
        >
          <div
            class="bordered-block"
            style="flex: 0 1 25%"
            :style="{ 'max-width': `calc(25% - ${spaceBetween}*3/4)` }"
          >
            <div class="animate-image">
              <v-img
                eager
                class="animate-top-1"
                :src="headingData.fields.top_1"
              />
            </div>
          </div>
          <div
            class="bordered-block"
            style="flex: 0 1 25%"
            :style="{ 'max-width': `calc(25% - ${spaceBetween}*3/4)` }"
          >
            <div class="animate-image">
              <v-img
                eager
                class="animate-top-2"
                :src="headingData.fields.top_2"
              />
            </div>
          </div>
          <div
            class="bordered-block"
            style="flex: 0 1 25%"
            :style="{ 'max-width': `calc(25% - ${spaceBetween}*3/4)` }"
          >
            <div class="animate-image">
              <v-img
                eager
                class="animate-top-3"
                :src="headingData.fields.top_3"
              />
            </div>
          </div>
          <div
            class="bordered-block"
            style="flex: 0 1 25%"
            :style="{ 'max-width': `calc(25% - ${spaceBetween}*3/4)` }"
          >
            <div class="animate-image">
              <v-img
                eager
                class="animate-top-4"
                :src="headingData.fields.top_4"
              />
            </div>
          </div>
        </div>
        <div class="d-flex" :style="{ 'margin-bottom': spaceBetween }">
          <div class="bordered-block">
            <div class="animate-image">
              <v-img
                eager
                class="animate-heatmap"
                :src="headingData.fields.heatmap"
              />
            </div>
          </div>
        </div>
        <div class="d-flex" :style="{ 'margin-bottom': spaceBetween }">
          <div class="bordered-block">
            <div class="animate-image">
              <v-img
                eager
                class="animate-line-graph"
                :src="headingData.fields.line_graph"
              />
            </div>
          </div>
        </div>
        <div class="d-flex" :style="{ gap: spaceBetween }">
          <div
            class="bordered-block"
            :style="{ 'max-width': `calc(50% - ${spaceBetween} / 2)` }"
            style="flex: 0 1 50%"
          >
            <div class="animate-image">
              <v-img
                eager
                class="animate-bottom-1"
                :src="headingData.fields.bottom_1"
              />
            </div>
          </div>
          <div
            class="bordered-block"
            :style="{ 'max-width': `calc(50% - ${spaceBetween} / 2)` }"
            style="flex: 0 1 50%"
          >
            <div class="animate-image">
              <v-img
                eager
                class="animate-bottom-2"
                :src="headingData.fields.bottom_2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
export default {
  mixins: [ProgressObserverMixin],
  props: {
    headingData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      componentSize: 0,
      hovered: false,
    };
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
    spaceBetween() {
      return 12 * this.spaceCoefficient + "px";
    },
    spaceOutside() {
      return 20 * this.spaceCoefficient + "px";
    },
    spaceCoefficient() {
      return this.componentSize / 1252;
    },
    getSolutionStyle() {
      return () => {
        const progress = this.obv(`smsc-heading-block`);
        if (progress > 0.25) {
          this.hovered = true;
        }
      };
    },
  },
  mounted() {
    this.obvAddElement(`smsc-heading-block`);
    this.$nextTick(() => {
      this.onResize();
    });
  },
  methods: {
    onResize() {
      this.componentSize = this.$refs.heading.offsetWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.heading-main-block {
  width: 100%;
  background: #f3f5f6;
  box-shadow: 0px 4.95648px 12.3912px 0px rgba(16, 73, 118, 0.15),
    0px 0.9913px 3.71736px 0px rgba(20, 90, 147, 0.1);
}
.border {
  border-radius: 2px;
}
.border-left {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.border-right {
  border-top-right-radius: 2px;
}
@media (min-width: 640px) {
  .border {
    border-radius: 4px;
  }
  .border-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .border-right {
    border-top-right-radius: 4px;
  }
}
@media (min-width: 1024px) {
  .heading-main-block {
    width: 80%;
  }
  .border {
    border-radius: 5px;
  }
  .border-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .border-right {
    border-top-right-radius: 5px;
  }
}
@media (min-width: 1280px) {
  .border {
    border-radius: 6px;
  }
  .border-left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .border-right {
    border-top-right-radius: 6px;
  }
}
@media (min-width: 1440px) {
  .border {
    border-radius: 8px;
  }
  .border-left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .border-right {
    border-top-right-radius: 8px;
  }
}
@keyframes opacity {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes animate-top-1 {
  0% {
    transform: translate(-100%, -50%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@keyframes animate-top-2 {
  0% {
    transform: translate(-100%, 100%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@keyframes animate-top-3 {
  0% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@keyframes animate-top-4 {
  0% {
    transform: translate(100%, -50%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@keyframes animate-heatmap {
  0% {
    transform: translate(20%, 200%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@keyframes animate-line-graph {
  0% {
    transform: translate(-20%, 200%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@keyframes animate-bottom-1 {
  0% {
    transform: translate(40%, 20%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@keyframes animate-bottom-2 {
  0% {
    transform: translate(40%, -20%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
.not-hovered {
  .animate-top-1,
  .animate-top-2,
  .animate-top-3,
  .animate-top-4,
  .animate-heatmap,
  .animate-line-graph,
  .animate-bottom-1,
  .animate-bottom-2 {
    opacity: 0;
  }
}
.hovered {
  .animate-image {
    animation: opacity 1.5s;
  }
  .animate-top-1 {
    animation: animate-top-1 2s;
  }
  .animate-top-2 {
    animation: animate-top-2 2s;
  }
  .animate-top-3 {
    animation: animate-top-3 2s;
  }
  .animate-top-4 {
    animation: animate-top-4 2s;
  }
  .animate-heatmap {
    animation: animate-heatmap 2s;
  }
  .animate-line-graph {
    animation: animate-line-graph 2s;
  }
  .animate-bottom-1 {
    animation: animate-bottom-1 2s;
  }
  .animate-bottom-2 {
    animation: animate-bottom-2 2s;
  }
}
.bordered-block {
  border: 2px solid var(--v-corporateBorder-base);
  border-radius: 2px;
  flex: 1 1 0;
  max-width: 100%;
  .animate-image {
    margin: -2px;
  }
}
</style>
