<template>
  <v-row class="justify-center">
    <v-col cols="12" class="col-xxl-9">
      <v-row class="section__title-row offer-child">
        <v-col class="text-center">
          <span class="heading1">{{ sectionData.fields.title || "" }}</span>
        </v-col>
      </v-row>

      <v-row class="section__content align-stretch">
        <v-col cols="12">
          <div
            v-for="(solution, solutionIndex) in sectionData.children"
            :key="solutionIndex"
            class="solution pa-5 pa-md-10 mb-8 mb-md-10"
          >
            <v-row>
              <v-col cols="12" md="" class="flex-grow-0 pr-sm-6 pb-3 pb-sm-0">
                <ordered-list-number :index="solutionIndex" />
              </v-col>
              <v-col cols="12" md="">
                <h2 class="heading2 mb-5">{{ solution.fields.title }}</h2>
                <div class="body1" v-html="solution.fields.text" />
              </v-col>
            </v-row>
            <v-row
              v-if="solution.children && solution.children.length == 2"
              no-gutters
              class="mt-6 flex-md-nowrap align-stretch"
            >
              <v-col class="product-simple-card primary" cols="12" md="">
                <div class="subtitle1 mb-4">
                  {{ solution.children[0].fields.title }}
                </div>
                <div class="subtitle2">
                  {{ solution.children[0].fields.subtitle }}
                </div>
                <div
                  v-if="productLink(solution.children[0].links.product)"
                  class="mt-8 mb-4"
                >
                  <router-link
                    :to="`/${productLink(solution.children[0].links.product)}`"
                    class="product-card-details-btn btn-text"
                  >
                    {{ buttonDetailsTitle }}
                    <v-icon color="primary">mdi-chevron-right</v-icon>
                  </router-link>
                </div>
              </v-col>
              <v-col
                class="flex-md-grow-0 d-flex align-center justify-center px-3"
                cols="12"
                md=""
              >
                <div class="heading1 corporateBorder--text">+</div>
              </v-col>
              <v-col class="product-simple-card" cols="12" md="">
                <div class="subtitle1">
                  {{ solution.children[1].fields.title }}
                </div>
                <div class="subtitle2 mt-4">
                  {{ solution.children[1].fields.subtitle }}
                </div>
                <div
                  v-if="productLink(solution.children[1].links.product)"
                  class="mt-4 mt-md-8 mb-0 mb-md-4"
                >
                  <router-link
                    :to="`/${productLink(solution.children[1].links.product)}`"
                    class="product-card-details-btn btn-text"
                  >
                    {{ buttonDetailsTitle }}
                    <v-icon color="primary">mdi-chevron-right</v-icon>
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import OrderedListNumber from "@/components/OrderedListNumber.vue";
export default {
  name: "Solution",
  components: { OrderedListNumber },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    buttonDetailsTitle() {
      return this.$store.getters.getCommonFields.button_details;
    },
    productLink() {
      return (links) => {
        if (links.length == 0) return null;
        const product = this.$store.getters.getProduct(links[0]);
        return product?.node_name || "";
      };
    },
  },
};
</script>
<style scoped lang="scss">
.solution {
  background: #fff;
  box-shadow: 0px 3.3px 12.37px rgba(20, 90, 147, 0.1),
    0px 16.5px 41.25px rgba(16, 73, 118, 0.15);
  border-radius: 12px;

  .product-simple-card {
    padding: 40px;
    background-color: var(--v-corporateBlue-lighten4);

    &.primary {
      background-color: var(--v-corporateBlue-base);
      color: #fff;
    }
  }
}

@media (min-width: 1024px) {
  .product-simple-card {
    padding: 20px;
    flex: 0 1 auto;
  }
}
</style>
