<template>
  <div class="section">
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ aboutData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="align-start">
      <v-col cols="12" lg="5" xl="6">
        <v-img :src="aboutData.fields.photo" @load="$emit('trigger-resize')" />
      </v-col>
      <v-col cols="12" lg="7" xl="6">
        <div class="corporateGrey--text" v-html="aboutData.fields.text" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "AboutUs",
  props: {
    aboutData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
