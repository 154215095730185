<template>
  <div class="section">
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ approachData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="section__content justify-space-between">
      <v-col cols="12" lg="4" xl="4">
        <!-- sticky -->
        <div class="d-none d-md-block sv-sticky-container">
          <div class="sv-sticky">
            <approach-process
              :approach-fields="approachData.fields"
              @trigger-feedback="$emit('trigger-feedback')"
            />
          </div>
        </div>
        <!-- not sticky -->
        <approach-process
          :approach-fields="approachData.fields"
          class="d-block d-md-none"
          @trigger-feedback="$emit('trigger-feedback')"
        />
      </v-col>
      <v-col cols="12" lg="7" xl="7" class="cut-last-margin pb-md-8">
        <v-row
          v-for="(advantage, index) in approachData.children"
          :key="index"
          no-gutters
          class="align-top mb-12"
        >
          <v-col cols="12" sm="" class="flex-grow-0 pr-sm-6 pb-3 pb-sm-0">
            <ordered-list-number :index="index" />
          </v-col>
          <v-col cols="12" sm="">
            <div class="subtitle1 mb-1 mb-md-3">
              {{ advantage.fields.title }}
            </div>
            <div
              class="corporateGrey--text cut-last-margin mb-3"
              v-html="advantage.fields.description"
            />
            <div
              v-for="(usecase, indexCase) in advantage.children || []"
              :key="indexCase"
              class="corporateBlue-lighten4 cut-last-margin pa-5 pa-md-10 mb-6"
            >
              <v-row no-gutters class="align-center">
                <div class="col" v-html="usecase.fields.description" />
                <v-col
                  v-if="hasLogo(usecase)"
                  cols="12"
                  md="3"
                  lg="4"
                  class="pt-4 pt-md-0 pl-md-4 d-flex flex-grow-0 justify-center"
                >
                  <v-img :src="getClientLogo(usecase)" max-width="100" />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrderedListNumber from "@/components/OrderedListNumber.vue";
import ApproachProcess from "./ApproachProcess.vue";
export default {
  name: "Approach",
  components: {
    OrderedListNumber,
    ApproachProcess,
  },
  props: {
    approachData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getClientLogo(usecase) {
      if (usecase?.links?.client?.[0]) {
        const client = this.$store.getters.getClient(usecase.links.client[0]);
        if (client) {
          return client.logo;
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    },
    hasLogo(usecase) {
      return this.getClientLogo(usecase) !== undefined;
    },
  },
};
</script>
<style scoped lang="scss">
.sv-sticky-container {
  padding-bottom: 0px;
  height: 100%;

  .sv-sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 120px;
  }
}
</style>
