<template>
  <div class="section">
    <title-with-button
      :title="mapData.fields.title || ''"
      :button-title="mapData.fields.button_all || ''"
      :to="{ name: 'Clients' }"
    />

    <div class="sv-content-container">
      <div class="sv-sticky-container">
        <div class="sv-sticky">
          <v-img
            :src="mapData.fields.map"
            max-height="calc(100vh - 160px)"
            contain
            eager
          />
        </div>
      </div>
      <div v-if="mapData.fields.map" class="sv-static-gap d-none d-md-block">
        <v-img
          :src="mapData.fields.map"
          max-height="calc(100vh - 160px)"
          contain
          eager
        />
      </div>
      <div class="sv-static">
        <v-img :src="mapData.fields.clients" />
      </div>
      <div v-if="mapData.fields.map" class="sv-static-gap d-none d-md-block">
        <v-img
          :src="mapData.fields.map"
          max-height="calc(100vh - 160px)"
          contain
          eager
        />
      </div>
    </div>
  </div>
</template>
<script>
import TitleWithButton from "@/components/TitleWithButton.vue";
export default {
  name: "ParallaxMap",
  components: { TitleWithButton },
  props: {
    mapData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.sv-content-container {
  position: relative;

  .sv-sticky-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .sv-sticky {
      position: sticky;
      position: -webkit-sticky;
      top: 120px;
    }
  }

  .sv-static-gap {
    opacity: 0;
  }
}

@media (max-width: 1023px) {
  .sv-content-container {
    .sv-sticky-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .sv-sticky {
        position: static;
        flex-grow: 0;
        max-width: 100%;
      }
    }
  }
}
</style>
