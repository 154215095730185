<template>
  <div class="mb-8 mb-md-16">
    <v-row no-gutters>
      <v-col cols="12">
        <chart ref="chart" :chart-options="currentChartOptions" />
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-0">
      <v-col class="d-flex justify-center">
        <v-btn-toggle
          v-model="selectedChartIndex"
          mandatory
          class="team-chart-selector"
          active-class="team-chart-selector__active"
        >
          <v-row
            no-gutters
            class="justify-start justify-md-center flex-nowrap flex-md-wrap"
          >
            <v-col cols="" md="8" lg="">
              <v-row no-gutters class="flex-nowrap">
                <v-col
                  v-for="category1 in chartsCategories1"
                  :key="category1.index"
                >
                  <v-btn x-large tile depressed block class="chart-index-btn">
                    {{ category1.fields.title }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="" md="8" lg="">
              <v-row no-gutters class="flex-nowrap">
                <v-col
                  v-for="category2 in chartsCategories2"
                  :key="category2.index"
                >
                  <v-btn x-large tile depressed block class="chart-index-btn">
                    {{ category2.fields.title }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Chart from "@/components/Charts/Chart.vue";
import ChartConfigFactory from "@/components/Charts/ChartConfigFactory.js";

export default {
  name: "TeamCharts",
  components: {
    Chart,
  },
  props: {
    chartsCategories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedChartIndex: 0,
      chartConfig: ChartConfigFactory(),
    };
  },
  computed: {
    chartsCategories1() {
      const half = Math.floor(
        (this.chartsCategoriesWithTrueIndexes.length + 1) / 2
      );
      return this.chartsCategoriesWithTrueIndexes.slice(0, half);
    },
    chartsCategories2() {
      const half = Math.floor(
        (this.chartsCategoriesWithTrueIndexes.length + 1) / 2
      );
      return this.chartsCategoriesWithTrueIndexes.slice(half);
    },
    chartsCategoriesWithTrueIndexes() {
      return this.chartsCategories.map((item, index) => ({ index, ...item }));
    },
    currentChartOptions() {
      const { title, fact, graph_template } =
        this.chartsCategories[this.selectedChartIndex].fields;
      const series = this.chartsCategories[this.selectedChartIndex].children;
      return this.chartConfig.getConfig(graph_template, {
        title,
        fact,
        series,
      });
    },
  },
  created() {
    this.selectedChartIndex = this.chartsCategories.length - 1;
  },
  methods: {
    refreshChart() {
      if (this.$refs.chart) this.$refs.chart.refreshChart();
    },
  },
};
</script>
<style lang="scss">
.team-chart-selector {
  overflow-x: auto;

  .theme--light.chart-index-btn {
    border: none;
    &:hover::before,
    &::before {
      opacity: 0;
    }
    .v-ripple__container {
      display: none !important;
    }
  }
  .team-chart-selector__active {
    background-color: var(--v-primary-base) !important;
    color: white !important;
  }
}
</style>
