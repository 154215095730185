<template>
  <div id="special-offer-solution">
    <div class="accent-lg text-center">
      {{ specialOfferData.fields.title_before || "" }}
      <span class="special-offer-update">
        {{ specialOfferData.fields.title_update || "" }}
      </span>
      <div class="special-offer-update-spinner">
        <div class="special-offer-update-spinner-inner"></div>
      </div>
      {{ specialOfferData.fields.title_after || "" }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecialOffer",
  props: {
    specialOfferData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
#special-offer-solution {
  margin-top: -90px;
  padding-top: 90px;
  margin-bottom: 80px;
}
.special-offer-update {
  color: var(--v-primary-base);
}
.special-offer-update-spinner {
  height: 26px;
  width: 26px;
  display: inline-block;
  background: conic-gradient(
    from 90deg at 50% 50%,
    rgba(33, 150, 243, 0) 0deg,
    var(--v-primary-base) 359.96deg,
    rgba(33, 150, 243, 0) 360deg
  );
  border-radius: 50%;
  animation: spin 1s infinite linear;
}
.special-offer-update-spinner-inner {
  background: #ffffff;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-left: 6px;
  margin-top: 6px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: 1024px) {
  #special-offer-solution {
    margin-bottom: 160px;
  }
  .special-offer-update-spinner {
    height: 54px;
    width: 54px;
  }
  .special-offer-update-spinner-inner {
    height: 34px;
    width: 34px;
    margin-left: 10px;
    margin-top: 10px;
  }
}
@media (min-width: 1440px) {
  .special-offer-update-spinner {
    height: 64px;
    width: 64px;
  }
  .special-offer-update-spinner-inner {
    height: 40px;
    width: 40px;
    margin-left: 12px;
    margin-top: 12px;
  }
}
</style>
