<template>
  <v-row class="justify-center">
    <v-col cols="12" class="col-xxl-9">
      <v-row class="section__title-row offer-child">
        <v-col class="text-center">
          <span class="heading1">{{ sectionData.fields.title || "" }}</span>
        </v-col>
      </v-row>

      <v-row class="section__content align-stretch">
        <v-col cols="12" lg="6" class="resolution-block">
          <div
            v-for="(resolution, resolutionIndex) in sectionData
              .ceir_resolution_items.children"
            :key="resolutionIndex"
            class="resolution-block__item"
          >
            <div class="subtitle1 corporateGrey--text mb-3 mb-sm-6">
              {{ resolution.fields.title }}
            </div>
            <div v-html="resolution.fields.text" />
          </div>
        </v-col>
        <v-col cols="12" lg="6">
          <div class="typ-block mb-16">
            <div
              class="typ-block__item body3"
              v-html="sectionData.ceir_resolution_actions.fields.text"
            />
            <div
              v-for="(action, actionIndex) in sectionData
                .ceir_resolution_actions.children"
              :key="actionIndex"
              class="typ-block__item body3"
              v-html="action.fields.text"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Resolutions",
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.resolution-block {
  .resolution-block__item {
    padding: 40px;
    background-color: var(--v-primaryAdditional4-base);
    margin-bottom: 30px;
  }
}

.typ-block {
  background: #fff;
  box-shadow: 0px 3.3px 12.37px rgba(20, 90, 147, 0.1),
    0px 16.5px 41.25px rgba(16, 73, 118, 0.15);
  border-radius: 12px;

  .typ-block__item {
    border-bottom: 1px solid #e0e0e0;
    padding: 18px;
  }

  & > .typ-block__item:last-child {
    border-bottom: none;
  }
}
</style>
