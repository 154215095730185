<template>
  <div class="section__carousel">
    <div
      ref="reftouchpanel"
      class="gallery-touch-panel"
      :style="{ height: `${height}` }"
    >
      <div class="scroller__container">
        <div class="scroller">
          <div class="scroller__item left" :style="leftItem">
            <div class="scroller__bg">
              <image-extended
                :image="leftElement"
                class="scroller__image"
                fill="transparent"
              />
            </div>
          </div>
          <div class="scroller__item this" :style="thisItem">
            <div class="scroller__bg">
              <image-extended
                :image="thisElement"
                class="scroller__image"
                fill="transparent"
              />
            </div>
          </div>
          <div class="scroller__item right" :style="rightItem">
            <div class="scroller__bg">
              <image-extended
                :image="rightElement"
                class="scroller__image"
                fill="transparent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageExtended from "@/components/ImageExtended.vue";
export default {
  name: "ImageCarousel",
  components: { ImageExtended },
  props: {
    items: {
      type: Array,
      required: true,
    },
    height: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      // constant props
      animationDuration: 0.8,
      carouselInterval: 3,
      animationTimingFunction: "cubic-bezier(0.175, 0.885, 0.625, 1.445)",
      swipeDistance: 80,
      // vars
      activeItemIndex: 1,
      movingRight: false,
      timer: null,
      time: 0,
    };
  },
  computed: {
    leftElement() {
      const index =
        this.activeItemIndex > 0
          ? this.activeItemIndex - 1
          : this.items.length - 1;
      return this.items[index];
    },
    thisElement() {
      return this.items[this.activeItemIndex];
    },
    rightElement() {
      const index =
        this.activeItemIndex < this.items.length - 1
          ? this.activeItemIndex + 1
          : 0;
      return this.items[index];
    },
    leftItem() {
      const transition = this.movingRight
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const left = this.movingRight ? "-50%" : "0%";
      return { transition, left };
    },
    thisItem() {
      const transition = this.movingRight
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const left = this.movingRight ? "0%" : "50%";
      return { transition, left };
    },
    rightItem() {
      const transition = this.movingRight
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const left = this.movingRight ? "50%" : "100%";
      return { transition, left };
    },
  },
  mounted() {
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.scrollRight();
      }, this.carouselInterval * 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },

    async scrollRight() {
      this.movingRight = true;

      await new Promise((resolve) =>
        setTimeout(resolve, this.animationDuration * 1000)
      );

      if (this.activeItemIndex == this.items.length - 1) {
        this.activeItemIndex = 0;
      } else {
        this.activeItemIndex++;
      }

      this.movingRight = false;
    },

    reset() {
      this.activeItemIndex = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.section__carousel {
  width: 100%;
  position: relative;
  overflow: visible;

  .gallery-touch-panel {
    height: var(--height);
  }

  --height: 100%;

  .scroller__container {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    height: var(--height);

    .scroller {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: var(--height);
      overflow-x: visible;

      .scroller__item {
        overflow-x: hidden;
        overflow-y: hidden;
        position: absolute;
        width: 50%;
        height: var(--height);
        padding-right: 16px;

        .scroller__bg {
          width: 100%;
          height: 100%;
          background-color: var(--v-corporateBlue-lighten2);
          padding: 4px;
        }

        &.this {
          z-index: 1;
        }
        &.left,
        &.right {
          z-index: 2;
        }

        .scroller__image {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }
  }
}
</style>
