<template>
  <v-dialog
    content-class="dialog"
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-card class="card">
      <v-row>
        <v-col>
          <v-row no-gutters>
            <v-col class="text-right">
              <v-btn icon @click="$emit('input', false)">
                <v-icon color="rgba(0, 0, 0, 0.32)">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="text-center">
              <span class="heading2">{{ subscribeSection.fields.title }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-6">
            <div class="col body1" v-html="subscribeSection.fields.text" />
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-btn
                depressed
                x-large
                color="primary"
                @click="$emit('input', false)"
                >{{ subscribeSection.fields.button }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "NewsSubscribeModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    subscribeSection() {
      return this.$store.getters.getSubscribeSection;
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss">
::v-deep .dialog {
  max-width: 560px;
  width: 100vw;
  margin: 0;
  max-height: 100% !important;
}
::v-deep .card {
  padding: 32px 48px;
}
</style>
