<template>
  <div>
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ whatData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="section__title pb-4 pb-md-8 mb-4 mb-md-16">
      <v-col cols="12" xl="8" lg="10">
        <div class="body2" v-html="whatData.fields.subtitle || ''" />
      </v-col>
    </v-row>

    <div class="section">
      <image-scroll-sticky
        ref="sticky"
        :items="whatData.children"
        :paragraph-max-multiplier="1.2"
        :pad-image="`${baseUrl}img/pad.svg`"
        :item-style="{
          left: '0%',
          top: '0%',
          width: '100%',
        }"
      />
    </div>
  </div>
</template>

<script>
import ImageScrollSticky from "@/components/ImageScrollSticky.vue";
export default {
  name: "WhatWeOffer",
  components: {
    ImageScrollSticky,
  },
  props: {
    whatData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
  methods: {
    updateSticky() {
      if (this.$refs.sticky) {
        this.$refs.sticky.updateSticky();
      }
    },
  },
};
</script>
