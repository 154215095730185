<template>
  <v-row
    v-if="statisticsList && statisticsList.length > 0"
    class="justify-center justify-md-space-between section"
  >
    <v-col
      v-for="(statItem, index) in statisticsList"
      :key="index"
      cols="12"
      md=""
      class="flex-grow-0"
      style="z-index: 2; min-width: 250px"
    >
      <v-row class="justify-center justify-md-start">
        <v-col class="text-left" style="max-width: 300px">
          <img
            :src="statItem.fields.image_amount"
            alt=""
            @load="$emit('trigger-resize')"
          />
        </v-col>
      </v-row>
      <v-row class="justify-center justify-md-start">
        <div
          class="col text-left"
          style="max-width: 300px"
          v-html="statItem.fields.detail"
        />
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "InstallationStatistics",
  data() {
    return {};
  },
  computed: {
    statisticsList() {
      return this.$store.getters.getInstallStats;
    },
  },
  methods: {},
};
</script>
