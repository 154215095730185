<template>
  <div ref="eChart" class="echarts" :style="getChartStyle" />
</template>

<script>
export default {
  name: "ChartResponsive",
  props: {
    chartOptions: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      chartObject: null,
    };
  },
  computed: {
    getChartStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
  watch: {
    async chartOptions(val) {
      await this.initEchart();
      let currentOptions = this.chartObject.getOption();
      if (
        val.series.length != currentOptions.series.length ||
        (val.graphic || []).length != (currentOptions.graphic || []).length
      ) {
        this.chartObject.clear();
      }
      this.chartObject.setOption(val);
    },
  },

  mounted() {
    this.$nextTick(async () => {
      await this.initEchart();
      this.chartObject.setOption(this.chartOptions);
    });
  },
  methods: {
    async initEchart() {
      if (this.chartObject == null) {
        const echarts = await import("echarts/core");
        const { LegendComponent, GridComponent, TooltipComponent } =
          await import("echarts/components");
        const { BarChart, PieChart } = await import("echarts/charts");
        const { CanvasRenderer } = await import("echarts/renderers");

        // Register the required components
        echarts.use([
          BarChart,
          PieChart,
          GridComponent,
          LegendComponent,
          CanvasRenderer,
          TooltipComponent,
        ]);

        this.chartObject = echarts.init(this.$refs.eChart);
      }
    },
    refreshChart() {
      if (this.chartObject) {
        this.chartObject.resize();
      }
    },
  },
};
</script>
