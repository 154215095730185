<template>
  <!-- если персоны HR не будет, то и связываться не с кем, ничего вообще не рисуем, сорян -->
  <v-row v-if="withPerson" class="justify-center">
    <v-col>
      <div class="pa-5 pa-md-10 corporateBlue-lighten4">
        <v-row class="align-end">
          <v-col cols="12" md="">
            <div class="heading2 mb-3 mb-md-6">{{ teamFields.hr_title }}</div>
            <div>{{ teamFields.hr_text }}</div>
          </v-col>
          <v-col cols="12" md="">
            <v-row
              class="flex-wrap flex-lg-nowrap align-end justify-center justify-md-end"
            >
              <v-col class="flex-grow-0">
                <v-row class="align-center flex-nowrap" no-gutters>
                  <v-col class="flex-grow-0 mr-3 mr-md-6">
                    <v-avatar size="88">
                      <img :src="person.image_sm" alt="" />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <div class="subtitle1 span-white-space mb-05">
                      {{ person.title }}
                    </div>
                    <div class="span-white-space caption-sc mb-1">
                      {{ person.position }}
                    </div>
                    <div class="d-flex justify-start align-center">
                      <a
                        v-if="person.social_telegram"
                        :href="`https://t.me/${person.social_telegram}`"
                        class="d-block"
                        target="_blank"
                      >
                        <v-img
                          :src="`${baseUrl}img/telegram.svg`"
                          width="20"
                          contain
                          class="flex-grow-0 mr-2"
                        />
                      </a>
                      <a
                        v-if="person.social_whatsapp"
                        :href="`https://wa.me/${person.social_whatsapp}`"
                        class="d-block"
                        target="_blank"
                      >
                        <v-img
                          :src="`${baseUrl}img/whatsapp.svg`"
                          width="20"
                          contain
                          class="flex-grow-0 mr-2"
                        />
                      </a>
                      <a
                        v-if="person.phone.length > 0"
                        class="d-block text-no-wrap"
                        :href="`tel:${person.phone}`"
                        >{{ person.phone }}</a
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="" class="flex-md-grow-0">
                <v-btn
                  :to="{ name: 'Career', hash: '#job-offers' }"
                  color="primary"
                  depressed
                  x-large
                  outlined
                  exact
                  block
                >
                  {{ teamFields.hr_button }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "TeamHrForm",
  props: {
    teamFields: {
      type: Object,
      required: true,
    },
    teamLinks: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    withPerson() {
      return this.person !== undefined;
    },
    person() {
      if (this.teamLinks?.person?.length) {
        return this.$store.getters.getPerson(this.teamLinks.person[0]);
      } else {
        return undefined;
      }
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
<style scoped>
.span-white-space {
  white-space: nowrap;
}
.mb-05 {
  margin-bottom: 2px;
}
</style>
