<template>
  <div>
    <horizontal-scroller
      :items="employeesData.children"
      :item-width="reviewWidth"
      item-key="id"
      has-control-buttons
      class="section"
    >
      <template #prepend_to_buttons>
        <span class="heading2">{{ employeesData.fields.title || "" }}</span>
      </template>
      <template #static_block_left>
        <v-col class="rating-hh pt-8 mb-8 mb-lg-0">
          <v-row dense class="d-flex align-start justify-start">
            <employees-about-company-rating
              :rating="employeesData.fields.rating"
              class="col col-12 mb-1"
            />
            <v-col class="flex-grow-0 subtitle2 text-no-wrap align-self-center">
              {{ employeesData.fields.text_hh }}
            </v-col>
            <v-col class="flex-grow-0 align-self-center px-2">
              <img class="d-block" :src="`${baseUrl}img/hh-logo.svg`" alt="" />
            </v-col>
            <v-col cols="12" md="" class="flex-md-grow-0 mt-4">
              <v-btn
                outlined
                x-large
                block
                link
                :href="employeesData.fields.button_link"
                target="_blank"
                color="primary"
              >
                {{ employeesData.fields.button_title }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-slot="{ item }">
        <div class="review__padding-box">
          <div
            :id="`eac-review-${item.id}`"
            class="review"
            :style="reviewStyle"
            @click="showReview(item)"
          >
            <div
              :id="`eac-review-${item.id}-content`"
              class="review-content body1"
              v-html="item.fields.description"
            />
            <div class="review-fog" />
          </div>
        </div>
      </template>
    </horizontal-scroller>

    <v-dialog v-model="showDialog" content-class="dialog" width="600">
      <v-card
        class="card px-3 px-sm-6 px-md-12 pb-3 pb-sm-6 pb-md-12 pt-2 pt-sm-4 pt-md-8"
      >
        <v-card-text>
          <v-row no-gutters>
            <v-col class="text-right">
              <v-btn icon @click="hideReview">
                <v-icon color="rgba(0, 0, 0, 0.32)">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <span class="heading2">{{
                employeesData.fields.dialog_title
              }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-3 mt-md-6 mb-0">
            <v-col class="body1">
              <div v-html="currentReviewText" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import EmployeesAboutCompanyRating from "./EmployeesAboutCompanyRating.vue";

export default {
  name: "EmployeesAboutCompany",

  components: {
    HorizontalScroller,
    EmployeesAboutCompanyRating,
  },

  props: {
    employeesData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showDialog: false,
      currentReviewText: "",
    };
  },

  computed: {
    reviewWidth() {
      return this.$vuetify.breakpoint.sm ? 280 : 370;
    },

    paddingReview() {
      return this.$vuetify.breakpoint.sm ? 40 : 20;
    },

    reviewStyle() {
      const width = this.reviewWidth;
      return {
        "min-width": `${width}px`,
        "max-width": `${width}px`,
      };
    },

    baseUrl() {
      return process.env.BASE_URL;
    },
  },

  methods: {
    showReview(item) {
      this.currentReviewText = item.fields.description;
      this.showDialog = true;
    },

    hideReview() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-hh.col {
  min-width: 290px;
  max-width: 350px;
}

.review__padding-box {
  padding: 15px;

  .review {
    border: 2px solid var(--v-corporateBorder-base);
    padding: 20px;
    height: 250px;
    overflow-y: hidden;
    position: relative;
  }

  .review-fog {
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 70%);
    z-index: 2;
  }
}

@media (min-width: 1024px) {
  .review__padding-box {
    padding: 15px;

    .review {
      padding: 40px;
      height: 324px;
    }

    .review-fog {
      height: 120px;
    }
  }
}
</style>
