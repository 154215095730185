<template>
  <v-row v-if="formData !== undefined" class="align-stretch">
    <v-col v-if="highlightedVisible" cols="12" lg="7" xl="7">
      <div
        class="d-flex flex-column justify-center cut-last-margin corporateBlue-lighten4"
        :class="
          $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
            ? 'pl-16 pr-16 pt-10 pb-10'
            : 'pl-5 pr-5 pt-5 pb-5'
        "
        style="z-index: 2; height: 100%"
      >
        <v-row no-gutters class="flex-grow-0">
          <v-col>
            <span class="heading2" v-html="field('header')"></span>
          </v-col>
        </v-row>
        <v-row
          v-if="hasField('text_before')"
          no-gutters
          class="my-6 flex-grow-0"
        >
          <div class="col" v-html="field('text_before')" />
        </v-row>
        <v-row v-if="withPerson" class="align-center flex-grow-0" dense>
          <v-col class="flex-grow-0 align-self-start pr-2">
            <v-avatar size="88">
              <img
                :src="person.image_sm"
                alt="contact person"
                eager
                @load="$emit('trigger-resize')"
              />
            </v-avatar>
          </v-col>
          <v-col class="pl-1">
            <div class="subtitle2 span-white-space mb-05">
              {{ person.title }}
            </div>
            <div class="span-white-space caption-sc">
              {{ person.position }}
            </div>
            <div class="d-flex justify-start align-center">
              <a
                v-if="person.social_telegram && field('enabled_social') == 1"
                :href="`https://t.me/${person.social_telegram}`"
                class="d-block"
                target="_blank"
              >
                <v-img
                  :src="`${baseUrl}img/telegram.svg`"
                  width="20"
                  contain
                  class="flex-grow-0 mr-2"
                />
              </a>
              <a
                v-if="person.social_whatsapp && field('enabled_social') == 1"
                :href="`https://wa.me/${person.social_whatsapp}`"
                class="d-block"
                target="_blank"
              >
                <v-img
                  :src="`${baseUrl}img/whatsapp.svg`"
                  width="20"
                  contain
                  class="flex-grow-0 mr-2"
                />
              </a>
              <a
                v-if="person.phone.length > 0"
                :href="`tel:${person.phone}`"
                class="d-block text-no-wrap"
                >{{ person.phone }}</a
              >
            </div>
            <div v-if="compiledEmail.length > 0">
              <a :href="`mailto:${compiledEmail}`">
                <v-icon color="primary" class="mr-1">mdi-email</v-icon>
                {{ compiledEmail }}
              </a>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="hasField('text_after')"
          no-gutters
          class="mt-6 flex-grow-0"
        >
          <div class="col" v-html="field('text_after')" />
        </v-row>
      </div>
    </v-col>
    <v-col xs="12" :lg="highlightedVisible ? 5 : 12">
      <validation-observer ref="formCommunicationValidator">
        <v-row v-if="hasField('field_name')">
          <v-col class="pt-0 pb-0 mb-2">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              mode="eager"
              :name="field('field_name')"
            >
              <v-text-field
                v-model="name"
                :label="field('field_name')"
                maxlength="50"
                clearable
                clear-icon="mdi-close-circle"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="hasField('field_phone')" class="pt-0 pb-0 mb-2">
            <validation-provider
              v-slot="{ errors }"
              rules="required|phone"
              mode="eager"
              :name="field('field_phone')"
            >
              <v-text-field
                v-model="phone"
                :label="field('field_phone')"
                maxlength="25"
                clearable
                clear-icon="mdi-close-circle"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col v-if="hasField('field_email')" class="pt-0 pb-0 mb-2">
            <validation-provider
              v-slot="{ errors }"
              rules="required|email"
              mode="eager"
              :name="field('field_email')"
            >
              <v-text-field
                v-model="email"
                :label="field('field_email')"
                maxlength="50"
                clearable
                clear-icon="mdi-close-circle"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="hasField('field_comment')">
          <v-col class="pt-0 pb-0 mb-2">
            <v-text-field
              v-model="comment"
              :label="field('field_comment')"
              maxlength="100"
              clearable
              clear-icon="mdi-close-circle"
            />
          </v-col>
        </v-row>
        <v-row v-if="hasField('button_attach')">
          <v-col class="pt-0 pb-0">
            <validation-provider
              v-slot="{ errors }"
              rules="size:5120"
              mode="eager"
            >
              <v-file-input
                v-model="fileinput"
                :placeholder="field('button_attach')"
                persistent-placeholder
                flat
                solo
                clearable
                clear-icon="mdi-close-circle"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="12" md="" class="flex-grow-0">
            <v-btn
              depressed
              x-large
              color="primary"
              :disabled="!agreement || !btnEnabled"
              block
              @click="commitForm"
              >{{ field("button_send") }}</v-btn
            >
          </v-col>
          <v-col cols="12" md="" class="pt-2 d-flex">
            <v-checkbox v-model="agreement" class="mt-0 pt-0" hide-details />
            <div class="agreement-label caption-sc" @click="toggleAgreement">
              <span class="textLight--text">{{ field("agreement") }}</span>
              <span>
                <router-link
                  :to="{ name: 'PrivacyPolicy' }"
                  class="caption-sc textLight--text text-decoration-underline"
                >
                  {{ field("agreement_link") }}
                </router-link>
              </span>
            </div>
          </v-col>
        </v-row>
      </validation-observer>

      <news-subscribe-modal v-model="showSubscriptionDialog" />
    </v-col>
  </v-row>
</template>
<script>
import SupplementRepository from "@/api/supplement.js";
import NewsSubscribeModal from "@/components/NewsSubscribeModal.vue";
import random from "lodash/random";

export default {
  name: "FormCommunication",
  components: {
    NewsSubscribeModal,
  },
  props: {
    formId: {
      type: Number,
      required: true,
    },
    kind: {
      type: String,
      validator: function (value) {
        // Значение должно соответствовать одной из этих строк
        return ["feedback", "subscription"].indexOf(value) !== -1;
      },
      default: "feedback",
    },
    personSet: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fileinput: null,
      btnEnabled: true,
      name: "",
      phone: "",
      comment: "",
      email: "",
      agreement: true,
      showSubscriptionDialog: false,
    };
  },
  computed: {
    formData() {
      return this.$store.getters.getFeedbackForm(this.formId);
    },
    withPerson() {
      return this.person !== undefined;
    },
    personLinks() {
      if (this.personSet.length > 0) {
        return this.personSet;
      } else if (this.formData.links.person) {
        return this.formData.links.person;
      } else {
        return undefined;
      }
    },
    person() {
      if (this.personLinks?.length) {
        const personId = this.personLinks[random(this.personLinks.length - 1)];
        return this.$store.getters.getPerson(personId);
      } else {
        return undefined;
      }
    },
    hasField() {
      return (field) => this.field(field).length > 0;
    },
    highlightedVisible() {
      return (
        this.hasField("header") ||
        this.hasField("text_before") ||
        this.hasField("header") ||
        this.withPerson ||
        this.hasField("text_after")
      );
    },
    field() {
      return (field) => {
        if (
          this.formData.fields[field] !== undefined &&
          this.formData.fields[field] !== "inherit"
        ) {
          return this.formData.fields[field];
        }
        if (this.formDataDefault[field] !== undefined) {
          return this.formDataDefault[field];
        }
        return "";
      };
    },
    nodename() {
      if (this.formData.node_name !== undefined) {
        return this.formData.node_name;
      }
      return this.$store.getters.defaultFeedbackFormName;
    },
    formDataDefault() {
      return this.$store.getters.defaultFeedbackForm;
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
    compiledEmail() {
      if (this.hasField("override_email")) {
        return this.field("override_email");
      } else {
        return this.person ? this.person.email : "";
      }
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        if (this.$refs.formCommunicationValidator)
          this.$refs.formCommunicationValidator.reset();
      }
    },
  },
  methods: {
    toggleAgreement(event) {
      const tagName = event.target.tagName || "";
      if (tagName.toLowerCase() == "a") return;
      this.agreement = !this.agreement;
    },

    async commitForm() {
      if (!(await this.$refs.formCommunicationValidator.validate())) return;
      switch (this.kind) {
        case "feedback":
          return this.sendFeedback();
        case "subscription":
          return this.sendSubscription();
      }
      return false;
    },

    async sendFeedback() {
      const payload = new FormData();
      payload.append("name", this.name);
      payload.append("phone", this.phone);
      payload.append("email", this.email);
      payload.append("comment", this.comment);
      payload.append("nodename", this.nodename);
      payload.append("location", window.location.href);
      const trace = window.b24Tracker?.guest?.getTrace() || undefined;
      if (trace) {
        payload.append("trace", trace);
      }
      if (this.fileinput) {
        payload.append("attach", this.fileinput, this.fileinput.name);
      }
      this.btnEnabled = false;
      const { data } = await SupplementRepository.feedback(payload);
      this.btnEnabled = true;
      if (data.code == 0) {
        if (this.$metrika) {
          console.debug("r#f.b.p");
          this.$metrika.reachGoal("form-bottom-page");
        }
        this.resetForm();
        this.$router.push({ name: "Thanks" });
      } else {
        alert(this.$t(`Common.feedback.error${data.code}`));
      }
    },

    async sendSubscription() {
      const { email } = this;
      const { data } = await SupplementRepository.subscribe({ email });
      if (data.code == 0) {
        this.resetForm();
        this.showSubscriptionDialog = true;
      } else {
        alert(this.$t(`Common.feedback.error${data.code}`));
      }
    },

    resetForm() {
      this.fileinput = "";
      this.name = "";
      this.phone = "";
      this.comment = "";
      this.email = "";
      this.agreement = true;
      if (this.$refs.formCommunicationValidator)
        this.$refs.formCommunicationValidator.reset();
    },
  },
};
</script>
<style scoped>
@media (min-width: 1024px) {
  .span-white-space {
    white-space: nowrap;
  }
}
.mb-05 {
  margin-bottom: 2px;
}
.agreement-label {
  cursor: pointer;
}
</style>
