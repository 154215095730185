<template>
  <div id="statistics-mobile">
    <statistics-title :title="statisticsData.fields.title || ''" />

    <div
      v-for="(block, blockIndex) in statisticsData.children"
      :key="blockIndex"
      class="section"
    >
      <statistics-paragraph
        :title="block.fields.title"
        :text="block.fields.text"
      />

      <statistics-chart-donut
        v-if="block.fields.typ == 'donut'"
        :ref="`refchart${blockIndex}`"
        :chart-keys="block.keys"
        :chart-payload="block.payload"
        :chart-angles="block.angles"
        :width="graphWidth"
      />

      <statistics-chart-bars
        v-else-if="block.fields.typ == 'bars'"
        :ref="`refchart${blockIndex}`"
        :chart-keys="block.keys"
        :chart-payload="block.payload"
        :width="graphWidth"
      />
    </div>
  </div>
</template>

<script>
import StatisticsTitle from "./StatisticsTitle.vue";
import StatisticsParagraph from "./StatisticsParagraph.vue";
import StatisticsChartDonut from "./StatisticsChartDonut.vue";
import StatisticsChartBars from "./StatisticsChartBars.vue";

export default {
  name: "StatisticsTabletMobile",
  components: {
    StatisticsTitle,
    StatisticsParagraph,
    StatisticsChartDonut,
    StatisticsChartBars,
  },
  props: {
    statisticsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      graphWidth: 0,
    };
  },
  computed: {
    mainLoading() {
      return this.$store.getters.getMainLoading;
    },
  },
  watch: {
    mainLoading(val) {
      if (!val) {
        this.refreshChart();
      }
    },
  },
  mounted() {
    this.refreshChart();
  },
  methods: {
    async refreshChart() {
      const el = document.getElementById("statistics-mobile");
      if (el) {
        this.graphWidth = el.clientWidth;
      } else {
        console.log("no statistics mobile element found!");
      }
      await this.$nextTick();

      this.statisticsData.children.forEach((block, blockIndex) => {
        const ref = this.$refs[`refchart${blockIndex}`];
        if (ref && ref[0]) {
          ref[0].refreshChart();
        }
      });
    },
  },
};
</script>
