<template>
  <div
    id="partners"
    class="section"
    style="padding-top: 100px; margin-top: -100px"
  >
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ partnersData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="section__content list-content mb-10">
      <v-col cols="12" lg="4">
        <div class="mb-3 mb-md-6" v-html="partnersData.fields.text" />
        <v-row>
          <v-col cols="12" md="" class="flex-md-grow-0">
            <v-btn
              depressed
              outlined
              x-large
              block
              color="primary"
              @click="$emit('trigger-feedback')"
            >
              {{ partnersData.fields.button }}
            </v-btn>
          </v-col>
          <v-col
            v-if="(partnersData.fields.presentation_file || '').length > 0"
          >
            <masked-icon-link
              :mask-icon="`${baseUrl}img/pdf.svg`"
              :title="partnersData.fields.presentation_title || ''"
              :href="partnersData.fields.presentation_file || ''"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="8">
        <v-row>
          <v-col
            v-for="(partner, index) in partnersData.children"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            class="d-flex justify-center align-center"
          >
            <div class="pa-5 pa-md-10">
              <v-img :src="partner.fields.logo" max-width="170" />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MaskedIconLink from "@/components/MaskedIconLink.vue";

export default {
  name: "Partners",
  components: {
    MaskedIconLink,
  },
  props: {
    partnersData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
