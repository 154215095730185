import Repository from "@/plugins/axios";

export default {
  // Получить контент лэндинга "Мультиплатформа"
  getMultiplatform() {
    return Repository.get("/multiplatform");
  },

  // Получить контент лэндинга "eSIM"
  getEsim() {
    return Repository.get("/esim");
  },

  // Получить контент лэндинга "CEIR"
  getCeir() {
    return Repository.get("/ceir");
  },

  // Получить контент лэндинга "FreeSMSC"
  getFreesmsc() {
    return Repository.get("/freesmsc");
  },

  // Получить контент лэндинга "DMS Digest"
  getDmsDigest() {
    return Repository.get("/dms-digest");
  },

  // Получить контент лэндинга "SMSC"
  getSmsc() {
    return Repository.get("/smsc");
  },
};
