import Vue from "vue";
import VueYandexMetrika from "vue-yandex-metrika";
import router from "../router";

const getMetrikaId = function () {
  switch (window.location.hostname) {
    case "www.svyazcom.ru":
    case "svyazcom.ru":
      return 19460893; // ID счётчика для svyazcom.ru
    case "www.soft.sc":
    case "soft.sc":
      return 95727023; // ID счётчика для soft.sc
    default:
      return null;
  }
};

export default function () {
  const metrikaId = getMetrikaId();

  if (metrikaId) {
    Vue.use(VueYandexMetrika, {
      id: metrikaId,
      router,
      env: process.env.NODE_ENV,
      options: {
        webvisor: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
      },
    });
  }
}
