<template>
  <div class="section">
    <v-row class="section__title-row">
      <v-col>
        <span class="heading2">{{ managementData.fields.title || "" }}</span>
      </v-col>
    </v-row>

    <v-row class="section__content align-stretch">
      <v-col cols="12" md="6" lg="7">
        <v-row class="persons__block">
          <v-col
            v-for="(person, index) in persons"
            :key="index"
            cols="6"
            class="person__container"
          >
            <v-img
              :src="person.image_lg"
              class="mb-1 mb-md-3"
              eager
              @load="onLoad"
            />
            <div class="subtitle1 mb-1">{{ person.title }}</div>
            <div>{{ person.position }}</div>
          </v-col>
        </v-row>
      </v-col>
      <!-- sticky -->
      <v-col
        cols="12"
        md="6"
        lg="5"
        class="d-none d-md-block sv-sticky-container"
      >
        <div
          v-if="(managementData.children || []).length > 0"
          class="sv-sticky"
        >
          <random-refresh-block
            :title="managementData.fields.title_random_fact"
            :text="randomFact"
            @request-random-item="generateRandomFact"
          />
        </div>
      </v-col>
      <!-- not sticky -->
      <v-col cols="12" md="6" lg="5" class="d-block d-md-none">
        <random-refresh-block
          :title="managementData.fields.title_random_fact"
          :text="randomFact"
          @request-random-item="generateRandomFact"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RandomRefreshBlock from "@/components/RandomRefreshBlock.vue";
import shuffle from "lodash/shuffle";

export default {
  name: "Management",
  components: { RandomRefreshBlock },
  props: {
    managementData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      randomFactsIndex: 0,
      randomFactsBox: [],
      randomFact: "",
    };
  },
  computed: {
    persons() {
      return this.managementData.links.person.map((id) =>
        this.$store.getters.getPerson(id)
      );
    },
    cursorFact() {
      return this.randomFactsBox[this.randomFactsIndex]
        ? this.randomFactsBox[this.randomFactsIndex].fields.description
        : "";
    },
  },
  methods: {
    restartRandomFacts() {
      this.resetRandomFacts();
      this.generateRandomFact();
    },
    resetRandomFacts() {
      this.randomFactsBox = shuffle(this.managementData.children);
      this.randomFactsIndex = 0;
      // чтобы избежать повторения при повторном перемешивании массива
      if (this.cursorFact == this.randomFact) {
        this.randomFactsIndex++;
      }
    },
    generateRandomFact() {
      this.randomFactsIndex++;
      if (this.randomFactsIndex >= this.randomFactsBox.length) {
        this.resetRandomFacts();
      }
      if (this.cursorFact.length) {
        this.randomFact = this.cursorFact;
      }
    },
    onLoad() {
      this.$emit("trigger-resize");
    },
  },
};
</script>
<style scoped lang="scss">
.sv-sticky-container {
  padding-bottom: 89px;

  .sv-sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 120px;
  }
}

@media (max-width: 1279px) {
  .sv-sticky-container {
    padding-bottom: 149px;
  }
}
</style>
