import axios from "axios";
import Vue from "vue";

const {
  VUE_APP_API_SUBFOLDER,
  VUE_APP_API_PORT,
  VUE_APP_API_HOST,
  VUE_APP_CURRENT_API_HOST,
} = process.env;

const port = VUE_APP_API_PORT ? `:${VUE_APP_API_PORT}` : "";
const host = VUE_APP_API_HOST || "http://localhost";
const subFolder = VUE_APP_API_SUBFOLDER ? `/${VUE_APP_API_SUBFOLDER}` : "";

const baseURL =
  VUE_APP_CURRENT_API_HOST === "true"
    ? `${window.location.protocol}//${window.location.host}${subFolder}`
    : `${host}${port}${subFolder}`;

const defaultOptions = {
  baseURL,
  withCredentials: VUE_APP_CURRENT_API_HOST !== "true",
};

const axiosClient = axios.create(defaultOptions);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response && [404].includes(error.response.status)) {
      Vue.router.push({ name: "PageNotFound" });
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
