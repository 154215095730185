<template>
  <v-row>
    <!-- Большое фото -->
    <v-col cols="12" md="8" xl="6">
      <image-extended :image="blockData.fields.image_1" class="image-big" />
    </v-col>

    <!-- Маленькое фото и текст -->
    <v-col cols="12" md="4" xl="6" class="d-flex flex-column">
      <v-row class="flex-grow-0">
        <v-col cols="12">
          <image-extended
            :image="blockData.fields.image_2"
            class="image-small"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="h-100 d-flex align-center">
          <div class="gallery-txt-block" v-html="blockData.fields.text" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  name: "Gallery3BlockRight",

  components: { ImageExtended },

  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.image-big {
  height: 110vw;

  @media (min-width: 640px) {
    height: 90vw;
  }

  @media (min-width: 1024px) {
    height: 58vw;
  }

  @media (min-width: 1440px) {
    height: 50vw;
  }

  @media (min-width: 1920px) {
    height: 44vw;
  }
}

.image-small {
  height: 60vw;

  @media (min-width: 640px) {
    height: 45vw;
  }

  @media (min-width: 1024px) {
    height: 18vw;
  }

  @media (min-width: 1440px) {
    height: 27vw;
  }
}

.gallery-txt-block {
  @media (min-width: 1024px) {
    padding-bottom: 30px;
    max-width: 570px;
  }
}
</style>
