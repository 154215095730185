<template>
  <v-row class="justify-center justify-lg-space-between section">
    <v-col
      v-for="(item, index) in items"
      :key="index"
      cols="12"
      md="4"
      :style="`min-width: ${minContainerWidth}px`"
    >
      <v-row no-gutters class="justify-center justify-lg-start mb-5 mb-md-6">
        <v-col :style="`max-width: ${maxContentWidth}px`">
          <div class="row-icon-text-blocks-image flex-grow-0">
            <img
              :src="item.fields.icon"
              eager
              alt=""
              @load="$emit('trigger-resize')"
            />
          </div>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="subtitle1 justify-center justify-lg-start mb-5 mb-md-6"
      >
        <div
          class="col"
          :style="`max-width: ${maxContentWidth}px`"
          v-html="item.fields.title"
        />
      </v-row>
      <v-row no-gutters class="justify-center justify-lg-start">
        <div
          class="col body1"
          :style="`max-width: ${maxContentWidth}px`"
          v-html="item.fields.description"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "RowIconTextBlocks",
  props: {
    // ожидается массив объектов вида
    // { fields: {title: '...', description: '...', icon: '...' }}
    items: {
      type: Array,
      required: true,
    },
    maxContentWidth: {
      type: Number,
      default: 490,
    },
    minContainerWidth: {
      type: Number,
      default: 250,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.row-icon-text-blocks-image {
  display: flex;
  width: 120px;
  height: 120px;
  justify-content: center;
  padding: 25px;
  box-shadow: 0px 3px 12px rgba(0, 133, 238, 0.1),
    0px 4px 12px rgba(33, 150, 243, 0.05);
  border-radius: 50%;
}
</style>
